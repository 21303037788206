import { Component, Input, Output, EventEmitter, OnInit, HostListener, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

declare var moment: any;

@Component({
    selector: 'dialenga-date-picker',
    templateUrl: './dialenga-date-picker.component.html',
    styleUrls: ['./_dialenga-date-picker.scss']
})
export class DialengaDatePickerComponent implements OnInit, OnChanges {
    @Input() isDisabled?: false;
    @Input() isReadOnly?: false;
    @Input() showTodayBtn?: boolean;
    @Input() inputId: string;
    @Input() showingCalendar: boolean;
    @Input() required?: { state: boolean; errorMessage: string };
    @Input() minDate?: Date;
    @Input() maxDate?: Date;
    @Input() classes?: string;
    @Input() showWeekDay?: boolean;
    @Input() inputModel: any;
    @Output() inputChanged: EventEmitter<any> = new EventEmitter();
    @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() inputModelRestoredDueNotValidData: EventEmitter<any> = new EventEmitter<any>();

    private lastDate: string;
    public date: string;
    public validRequired = true;
    public errorMessage: string;
    public isInputModelAlreadyEdited = false;
    public calendarInstance: any;
    public hideInputDate: any;
    public isInputFocused = false;

    @HostListener('document:click', ['$event'])
    clickout(event) {
        const element = this.eRef.nativeElement;
        if (!element.contains(event.target)) {
            this.showingCalendar = false;
        }
    }

    constructor(
        private eRef: ElementRef,
        private translate: TranslateService
    ) {
        this.translate = translate;
    }

    ngOnInit() {
        if (this.inputModel) {
            this.lastDate = moment(this.inputModel).format('yyyy-MM-DD');
            this.date = moment(this.inputModel).format('yyyy-MM-DD');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.inputModel && !changes.inputModel.firstChange) {
            this.lastDate = moment(this.inputModel).format('yyyy-MM-DD');
            this.date = moment(this.inputModel).format('yyyy-MM-DD');
            this.calendarInstance.setVal(this.date);
        }
        if (changes.maxDate) {
            this.maxDate = !!changes.maxDate.currentValue ? new Date(changes.maxDate.currentValue) : null;
        }
        if (changes.minDate) {
            this.minDate = !!changes.minDate.currentValue ? new Date(changes.minDate.currentValue) : null;
        }
    }

    modelValueChanged(): void {
        this.showingCalendar = false;
        if (this.date !== this.lastDate) {
            this.lastDate = this.date;
            this.inputModel = this.date ? new Date(this.date) : null;
            this.inputModelChange.emit(this.inputModel);
            this.inputChanged.emit(null);
        }
    }

    isDateBetweenValidValues(): boolean {
        const date = new Date(this.date).getTime();
        return (!this.minDate || date >= this.minDate.getTime()) && (!this.maxDate || date <= this.maxDate.getTime());
    }

    checkIsValidDate(): void {
        let year = [];
        year = this.date ? this.date.split('-') : ['0'];
        // tslint:disable-next-line: radix
        if (parseInt(year[0]) >= 1900 && parseInt(year[0]) <= 9999 && this.isDateBetweenValidValues()) {
            this.calendarInstance.setVal(this.date);
            this.modelValueChanged();
        } else {
            this.date = this.lastDate ? this.lastDate : '';
            this.inputModelRestoredDueNotValidData.emit();
        }
    }

    modelValueOnFocus(): void {
        this.isInputFocused = true;
    }

    modelValueOnFocusOut(event): void {
        this.isInputFocused = false;
        if (!this.isInputModelAlreadyEdited) {
            this.checkIsValidDate();
        }

        this.isInputModelAlreadyEdited = false;
    }

    modelValueCancel(event: any): void {
        this.isInputModelAlreadyEdited = true;
        this.date = this.lastDate;
        this.calendarInstance.setVal(this.date);
    }

    modelValueAccept(event: any): void {
        this.isInputModelAlreadyEdited = true;
        this.checkIsValidDate();
    }

    openCalendar(): void {
        this.showingCalendar = true;
        if (this.calendarInstance) {
            this.calendarInstance.show();
        }
    }

    deleteDate(): void {
        this.isInputModelAlreadyEdited = true;
        this.date = null;
        this.modelValueChanged();
    }

    settingDateFromCalendar(): void {
        this.date = this.calendarInstance.getVal();
        this.modelValueChanged();
    }

    getWeekDay(): number {
        let day = new Date(this.date).getDay();
        return day === 0 ? 7 : day;
    }
}
