import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './workplace-holiday-modal.component.html'
})
export class WorkplaceHolidayModalComponent implements AfterViewInit {
    public modalTitle: string = this.data.modalTitle;
    public days: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    public months: Array<any>;

    public holidayForm: holidayForm = {
        id: this.data.holiday.id,
        name: this.data.holiday.name,
        day: this.data.holiday.day,
        month: this.data.holiday.month,
        year: this.data.holiday.year
    };
    public isFormDataValid: boolean = true;
    public isFormDataChanged: boolean = false;
    private initialHolidayForm: holidayForm;

    @ViewChild('holidayName') holidayNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<WorkplaceHolidayModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (window['getLocalizedMonthsNames']) {
            this.months = window['getLocalizedMonthsNames']();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.holidayNameRef.nativeElement.focus(), 200);
        this.initialHolidayForm = { ...this.holidayForm };
    }

    // PRIVATE METHODS

    private checkFormdataValid(): void {
        this.isFormDataValid = !!this.holidayForm.name && !!this.holidayForm.day && !!this.holidayForm.month;
    }

    private checkFormdataChanged(): void {
        this.isFormDataChanged = JSON.stringify(this.holidayForm) !== JSON.stringify(this.initialHolidayForm);
    }

    validateModel(): void {
        this.checkFormdataChanged();
        this.checkFormdataValid();
    }

    onChange(): void {
        this.validateModel();
    }

    onAccept(): void {
        this.dialogRef.close({ accept: true, holiday: this.holidayForm });
    }

    onCancel(): void {
        this.dialogRef.close({ cancel: true });
    }
}

export type holidayForm = {
    id?: number;
    name?: string;
    day: number;
    month: number;
    year: number;
};
