<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 id="validate-conversation-title" class="modal-title" [translate]="'conversations.modal.validate.title'"></h3>
</div>

<div class="modal-body">
    <!-- Validation status -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'conversations.modal.validate.status.label'"></span>
        </div>
        <div class="group-form__data group-form__data--inline">
            <button
                #validateRequest
                id="validate-btn"
                class="button button--states button--states--approved"
                [ngClass]="{'button--states--approved--selected' : validationForm.validation === REQUEST_VALIDATION_STATE.VALIDATED}"
                (click)="selectValidationStatus(REQUEST_VALIDATION_STATE.VALIDATED)">
                <span class="button__label" style="padding: 5px 20px 5px 20px;" [translate]="'conversations.modal.validate.status.validated'"></span>
            </button>
            <button
                #rejectRequest
                id="reject-btn"
                class="button button--states button--states--reject"
                [ngClass]="{'button--states--reject--selected' : validationForm.validation === REQUEST_VALIDATION_STATE.REJECTED}"
                (click)="selectValidationStatus(REQUEST_VALIDATION_STATE.REJECTED)">
                <span class="button__label" style="padding: 5px 20px 5px 20px;" [translate]="'conversations.modal.validate.status.rejected'"></span>
            </button>
        </div>
    </div>
    <!-- Comment -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'conversations.modal.validate.comments'"></span>
        </div>
        <div class="group-form__data">
            <dialenga-textarea
                class="width-100-percent new-rich-editor"
                textareaId="request-validation-comment"
                [isDisabled]="!validationForm.validation"
                [(inputModel)]="validationForm.message"
                (inputChanged)="onChange()"
                [showLinkBtns]="true"
                [isRichFormat]="true"
                [maxLength]="{ amount: 240, warningAmount: 220 }">
            </dialenga-textarea>
        </div>
    </div>
    <!-- Attachments -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'conversations.modal.validate.attachFiles'"></span>
        </div>

        <div class="group-form__data">
            <div class="flex direction-column">
                <div class="flex flex-wrap">
                    @for (attachment of attachedResources; track attachment) {
                        <div class="flex align-items-baseline margin-bottom-10 margin-right-10">
                            <div class="attachment hover-disabled">
                                <span class="attachment__icon icon icon-attachment"></span>
                                <span class="attachment__filename" [textContent]="attachment.name | lowercase"></span>
                            </div>
                            <span
                                class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5"
                                (click)="removeAttachedFile($index)"></span>
                        </div>
                    }
                </div>
                <div class="flex align-items-center">
                    <button class="button button--small" (click)="attacthResource()" [disabled]="!validationForm.validation">
                        <span class="button__label" [translate]="'documents.import.attachFiles'"></span>
                    </button>
                    <input #attachFile type="file" title class="upload" style="display: none;" (change)="onFileSelected($event.target.files)" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'validate-conversation-button'"
        [classes]="'button button--secondary margin-right-10'"
        (action)="onAccept()"
        [isValid]="isFormValid"
        [text]="'global.modal.accept'"></loading-button>
    <button type="button" class="button" (click)="onCancel()">
        <span class="button__label" [translate]="'global.modal.cancel'"></span>
    </button>
</div>
