<section class="main-container" [ngClass]="{'main-container--with-preview' : selectedTab === REQUESTS_SETTINGS_TABS.MOTIVES}">
    <header class="main-container__header">
        <nav class="flex direction-column" aria-label="breadcrumb">
            <ul class="breadcrumb">
                <li>
                    <a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
                </li>
            </ul>
            <h2 [translate]="'conversations.title'"></h2>
        </nav>

        <div class="flex justify-self-end">
            @if (selectedTab === REQUESTS_SETTINGS_TABS.MOTIVES) {
                <button
                    id="create-motive-button"
                    data-cy="create-motive-button"
                    class="button button--secondary button--small margin-right-10"
                    (click)="createNewMotive()">
                    <span class="button__icon icon icon-add"></span>
                    <span class="button__label" [translate]="'global.form.new'"></span>
                </button>
                <preview-button
                    class="preview-launcher margin-bottom-10"
                    [(isPreviewOpened)]="previewState.opened"
                    [isOnlyIcon]="true"
                    kendoTooltip
                    [title]="'message.preview.preview' | translate">
                </preview-button>
            }
            @if (selectedTab === REQUESTS_SETTINGS_TABS.FEEDBACK_TRAYS) {
                <loading-button
                    [buttonId]="'newFeedbackTray'"
                    [classes]="'button button--secondary button--small'"
                    [iconClass]="'icon-add'"
                    [text]="'feedbackTrays.new'"
                    [isValid]="true"
                    (action)="navigateTo('/settings/conversations/feedback-trays/create')">
                </loading-button>
                <feedback-action-button
                    [buttonId]="'clone-tray-button'"
                    [classes]="'button--small button--only-icon margin-left-10'"
                    [tooltipText]="'feedbackTray.clone.cloneTooltip'"
                    (action)="cloneSelectedTray()"
                    [iconClass]="'icon-clone'"
                    [isValid]="isTraySelected">
                </feedback-action-button>
            }
        </div>
    </header>

    <section class="main-container__content">
        <div class="main-container--full-width">
            <article class="tabs-language-container">
                <div class="tab-nav tab-nav--inline" style="width:100%">
                    <button
                        id="motives"
                        data-cy="motives"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === REQUESTS_SETTINGS_TABS.MOTIVES}"
                        [translate]="'motives.title'"
                        (click)="loadTabContent(REQUESTS_SETTINGS_TABS.MOTIVES)"></button>
                    <button
                        id="feedback-trays"
                        data-cy="feedback-trays"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === REQUESTS_SETTINGS_TABS.FEEDBACK_TRAYS}"
                        [translate]="'feedbackTrays.title'"
                        (click)="loadTabContent(REQUESTS_SETTINGS_TABS.FEEDBACK_TRAYS)"></button>
                </div>
            </article>
        </div>
    </section>

    <div class="margin-top-24">
        @if (selectedTab === REQUESTS_SETTINGS_TABS.MOTIVES) {
            <requests-motives-list [(addNewMotive)]="addNewMotive" [(isMotivesUpdated)]="isMotivesUpdated"></requests-motives-list>
        }
        @if (selectedTab === REQUESTS_SETTINGS_TABS.FEEDBACK_TRAYS) {
            <feedback-trays [(cloneTray)]="cloneTray" (traySelected)="onTraySelected($event)"></feedback-trays>
        }
    </div>

    @if (selectedTab === REQUESTS_SETTINGS_TABS.MOTIVES) {
        <motives-preview-device [previewState]="previewState" [(isMotivesUpdated)]="isMotivesUpdated"></motives-preview-device>
    }
</section>
