<div class="main-container__content">
    <div class="main-container--full-width">
        @if ((!extLinksDatagrid?.total || extLinksDatagrid?.total == 0) && !isSyncing) {
            <div class="info-advice filter-container">
                <p [translate]="'externalLinks.advice'"></p>
            </div>
        }
        @if (!externalLinksAdviceStatus && extLinksDatagrid?.total && extLinksDatagrid?.total > 0 && !isSyncing) {
            <div class="info-advice relative filter-container">
                <p class="bold" [translate]="'externalLinks.adviceAppOrderTitle'"></p>
                <p [translate]="'externalLinks.adviceAppOrderDescription'"></p>
                <span class="margin-left-5 info-advice__close-button icon icon-close" (click)="hideAdviceAppMessage()" style="cursor: pointer"></span>
            </div>
        }
        <!-- External links list -->
        @if (extLinksDatagrid?.total > 0 || isSyncing) {
            <!-- Header list -->
            <section class="extlinks-list__header margin-top-20">
                <span></span>
                <span [translate]="'externalLinks.form.name'"></span>
                <span [translate]="'model.audit.requestURL'"></span>
                <span [translate]="'externalLinks.form.visibility'"></span>
                <span></span>
            </section>
            <!-- Content items -->
            @if (!isSyncing) {
                <section cdkDropList class="extlinks-list" (cdkDropListDropped)="onDroppedItem($event)">
                    @for (extLink of extLinksDatagrid.data; track extLink) {
                        <div class="extlinks-list__item" cdkDrag (dblclick)="updateExternalLink(extLink.id)">
                            <!-- Drag drop icon -->
                            <img class="black-45" src="ng1/assets/img/drag.svg" cdkDragHandle />
                            <!-- Link name -->
                            <span class="truncate cursor-default" [textContent]="extLink.contents[selectedLanguage].name"></span>
                            <!-- Link url -->
                            <span class="truncate cursor-default" [textContent]="extLink.url"></span>
                            <!-- Link visibility population -->
                            @if (extLink.populationKind == POPULATION.ALL) {
                                <div class="segments-list">
                                    <span class="icon icon-20 icon-globe icon-group-rounded"></span>
                                    <span class="margin-left-10" [translate]="'categories.availableInAll'"></span>
                                </div>
                            } @else {
                                <div class="segments-list" kendoTooltip filter="span.icon" position="top" offset="8">
                                    @for (segment of extLink.segments | slice:0:4; track segment; let i = $index) {
                                        <span
                                            class="icon icon-20 icon-user-groups-filled icon-group-rounded"
                                            [title]="extLink.segments?.length > 1 ? segment.name: ''"></span>
                                    }
                                    @if (extLink.segments?.length > 4) {
                                        <article class="segments-list__more">
                                            <span class="segments-list__more-label" [textContent]="'+' + (extLink.segments.length - 4)"></span>
                                        </article>
                                    }
                                    @if (extLink.segments?.length === 1) {
                                        <span class="margin-left-10" [textContent]="extLink.segments[0].name"></span>
                                    }
                                    @if (extLink.segments?.length === 0) {
                                        <span class="icon nucleo icon-warning-circle-fill" [title]="'externalLinks.form.noSelectedGroups' | translate"></span>
                                    }
                                </div>
                            }
                            <div class="flex justify-end ctaBtns-on-hover" kendoTooltip filter="span.icon" offset="8">
                                <span
                                    class="extlinks-list__item-icon icon icon-edit"
                                    (click)="updateExternalLink(extLink.id)"
                                    [title]="'global.form.edit' | translate"></span>
                                <span
                                    class="extlinks-list__item-icon icon icon-delete"
                                    (click)="deleteExternalLink(extLink)"
                                    [title]="'global.form.delete' | translate"></span>
                            </div>
                        </div>
                    }
                </section>
            }
            <!-- Skeleton for external links list -->
            @if (isSyncing) {
                <section class="extlinks-list">
                    @for (row of dataSkeleton; track row) {
                        <div class="extlinks-list__item" [ngClass]="{'opacity-50': $index == 1, 'opacity-30': $index == 2}">
                            <!-- Drag drop icon -->
                            <span style="width: 32px; height: 32px;"></span>
                            <!-- Link name -->
                            <kendo-skeleton
                                shape="text"
                                animation="pulse"
                                [width]="177"
                                [height]="20"
                                style="border-radius: 4px; max-width: 35%; width: 35%"></kendo-skeleton>
                            <!-- Link url -->
                            <kendo-skeleton
                                shape="text"
                                animation="pulse"
                                [width]="235"
                                [height]="20"
                                style="border-radius: 4px; max-width: 90%"></kendo-skeleton>
                            <!-- Link visibility population -->
                            <div class="flex align-items-center">
                                <kendo-skeleton shape="circle" animation="pulse" [width]="28" [height]="28"></kendo-skeleton>
                                <kendo-skeleton
                                    shape="text"
                                    animation="pulse"
                                    [width]="100"
                                    [height]="20"
                                    style="border-radius: 4px; max-width: 40%"
                                    class="margin-left-10"></kendo-skeleton>
                            </div>
                            <!-- CTA buttons -->
                            <span style="width: 150px; height: 32px;"></span>
                        </div>
                    }
                </section>
            }
        }
        <!-- No data feedback -->
        @if (extLinksDatagrid.total == 0 && !isSyncing) {
            <no-results [imageTitle]="'externalLinks.noResultsTitle'" [description]="'externalLinks.noResultsText'"></no-results>
        }
    </div>

    <!-- Links preview -->
    <div class="mobile-preview-container padding-top-40" [ngClass]="{'base-preview--visible' : isPreviewVisible}" id="external-links-preview">
        <div class="mobile-preview-container__close icon icon-close" (click)="isPreviewVisibleFn(false)"></div>

        <div class="text-center">
            <div class="tab-nav tab-nav--inline">
                <span class="tab-nav__item ng-scope tab-nav__item--selected" [textContent]="'message.preview.preview' | translate"></span>
            </div>
        </div>
        @if (almostOneLinkIsSegmented() && !isSyncing) {
            <div class="disclaimer margin-top-16">
                <span class="icon icon-24 icon-warning-circle-fill"></span>
                <span [translate]="'externalLinks.previewDisclaimer'"></span>
            </div>
        }
        <div class="mobile-container">
            <div class="device-container">
                <div class="device-details-container">
                    <div class="preview-header">
                        <div class="preview-header__first-row">
                            <span class="preview-header__icon icon icon-wifi black"></span>
                            <div class="flex">
                                <span class="preview-header__label margin-right-5 black">74%</span>
                                <span class="preview-header__icon icon icon-battery black"></span>
                            </div>
                        </div>
                    </div>
                    @if (!isSyncing) {
                        <div class="scroll-device">
                            <section class="submenu-preview">
                                <div
                                    class="link-item link-item__skeleton-top"
                                    #topSkeleton
                                    [ngClass]="{'border-radius-0': topSkeleton.nextElementSibling?.offsetHeight >= 340}">
                                    <kendo-skeleton shape="circle" [animation]="false" [width]="17" [height]="17"></kendo-skeleton>
                                    <kendo-skeleton shape="text" [animation]="false" [width]="130" [height]="16" style="border-radius: 6px;"></kendo-skeleton>
                                </div>
                                @if (extLinksDatagrid.data.length > 0) {
                                    <div class="links-container">
                                        @for (extLink of sortExternalLinksDescending(); track extLink) {
                                            <div class="link-item">
                                                <!-- Link icon -->
                                                <span class="link-item__icon icon-20 gallery " [ngClass]="extLink.icon"></span>
                                                <!-- Link name -->
                                                <span class="link-item__name truncate" [textContent]="extLink.contents[selectedLanguage].name"></span>
                                            </div>
                                        }
                                    </div>
                                }
                                <div class="link-item link-item__skeleton-bottom">
                                    <kendo-skeleton shape="circle" [animation]="false" [width]="17" [height]="17"></kendo-skeleton>
                                    <kendo-skeleton shape="text" [animation]="false" [width]="130" [height]="16" style="border-radius: 6px;"></kendo-skeleton>
                                </div>
                            </section>
                        </div>
                    }
                    @if (!isSyncing) {
                        <span class="preview-menu">
                            <span class="preview-menu__dummy-pulsation"></span>
                        </span>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
