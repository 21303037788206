import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { CompaniesService } from '../companies/companies.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { UtilsService } from '../../shared/services/utils.service';
import { isEqual, cloneDeep } from 'lodash';
import { latLng, tileLayer, icon, MapOptions, Map, Marker, IconOptions, MarkerOptions } from 'leaflet';

@Component({
    selector: 'company-general',
    templateUrl: './company-general.component.html',
    styleUrls: ['./_company-general.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompanyGeneralComponent implements OnChanges, AfterViewInit {
    @Input() companyForm: any;
    @Input() companyId: number;
    @Output() companyFormChange: EventEmitter<any> = new EventEmitter<any>();

    private _rootScope: any;
    private initialDataForm: any = {};
    private companyMap: Map;
    private companyMarkerMap: Marker;

    public isValidURLWebsite: boolean = true;
    public isValidURLFacebook: boolean = true;
    public isValidURLYoutube: boolean = true;
    public isValidURLTwitter: boolean = true;
    public isValidURLLinkedin: boolean = true;
    public isValidURLInstagram: boolean = true;
    public isValidEmail: boolean = true;
    public IMAGE_CROPPER_COMPANY = {
        MINIMUM_HEIGHT: 300,
        MINIMUM_WIDTH: 500,
        MAX_WIDTH: 750,
        MAX_HEIGHT: 500,
        ASPECT_RATIO: 'none'
    };
    public companyImage: Array<any> = [];

    public isFormDataValid: boolean;
    public isFormDataChanged: boolean = false;
    private companiesPath: string = '/settings/my-company/companies';

    @ViewChild('staticMap') staticMap: ElementRef;

    constructor(
        private upgrade: UpgradeModule,
        private companiesService: CompaniesService,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        private formUtilsService: FormUtilsService,
        private utilsService: UtilsService
    ) {
        this._rootScope = this.upgrade.$injector.get('$rootScope');
    }

    ngAfterViewInit(): void {
        this.companyMapInitialize();
        this.companyMapUpdate();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.companyForm && changes.companyForm.currentValue && changes.companyForm.previousValue !== changes.companyForm.currentValue) {
            this.companyMapUpdate();
            this.setInitialFormData();
        }
    }

    setInitialFormData(): void {
        this.initialDataForm = cloneDeep(this.companyForm);
        this.companyFormChange.emit(this.companyForm);
        this.onChange();
    }

    private companyMapInitialize(): void {
        const mapOptions: MapOptions = {
            layers: [
                tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                    maxZoom: 18,
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions" target="_blank">CARTO</a>',
                    subdomains: 'abcd',
                    tileSize: 256
                })
            ],
            center: latLng(40.749357547, -73.986190129),
            zoom: 11,
            zoomControl: false,
            touchZoom: false,
            boxZoom: false,
            dragging: false,
            doubleClickZoom: false,
            scrollWheelZoom: false,
            keyboard: false,
            tapHold: false
        };
        const iconMarkerOptions: IconOptions = {
            iconUrl: '/ng1/assets/img/location.svg',
            iconSize: [64, 64],
            iconAnchor: [32, 32]
        };
        const markerOptions: MarkerOptions = {
            icon: icon(iconMarkerOptions),
            opacity: 0
        };

        if (!this.companyMap) {
            this.companyMap = new Map(this.staticMap.nativeElement, mapOptions);
            if (!this.companyMarkerMap) {
                this.companyMarkerMap = new Marker(latLng(40.749357547, -73.986190129), markerOptions).addTo(this.companyMap);
            }
        }
    }

    private companyMapUpdate(): void {
        if (this.companyMap) {
            if (!this.companyForm.latitude && !this.companyForm.longitude) {
                this.companyMap.setView(latLng(40.749357547, -73.986190129), 11);
                this.companyMarkerMap.setOpacity(0);
            } else {
                this.companyMap.setView(
                    latLng(parseFloat(this.companyForm.latitude || 40.749357547), parseFloat(this.companyForm.longitude || -73.986190129)),
                    17
                );
                this.companyMarkerMap.setLatLng(latLng(parseFloat(this.companyForm.latitude || 0), parseFloat(this.companyForm.longitude || 0)));
                this.companyMarkerMap.setOpacity(1);
            }
            this.onChange();
        }
    }

    onChange() {
        this.checkIfDataFormAreChanged();
        this.checkIfDataFormAreValid();
    }

    companyFormSave(): void {
        this.companiesService.update(this.companyId, this.companyForm, this.companyImage).subscribe(
            (response: any) => {
                this.companyForm = response;
                this.setInitialFormData();
                this.formUtilsService.finishSubmitAction();

                this.notificationService.showSuccessNotification();
            },
            (onError: any) => {}
        );
    }

    validateUrls(dataUrl: string) {
        let isValidURL = false;
        if (dataUrl) {
            isValidURL = this.utilsService.validateURL(dataUrl);
            if (isValidURL) {
                dataUrl = this.utilsService.concatURLPrefixIfNotExists(dataUrl);
            }
            return { valid: isValidURL, data: dataUrl };
        }
        return { valid: isValidURL, data: dataUrl };
    }

    onChangeUrl(urlsToCheck: Array<string>) {
        let urlTested: any = {};
        urlsToCheck.forEach((urlCheck) => {
            switch (urlCheck) {
                case 'website':
                    urlTested = this.validateUrls(this.companyForm.website);
                    this.isValidURLWebsite = urlTested.valid;
                    this.companyForm.website = urlTested.data;
                    break;
                case 'facebook':
                    urlTested = this.validateUrls(this.companyForm.facebook);
                    this.isValidURLFacebook = urlTested.valid;
                    this.companyForm.facebook = urlTested.data;
                    break;
                case 'youtube':
                    urlTested = this.validateUrls(this.companyForm.youtube);
                    this.isValidURLYoutube = urlTested.valid;
                    this.companyForm.youtube = urlTested.data;
                    break;
                case 'twitter':
                    urlTested = this.validateUrls(this.companyForm.twitter);
                    this.isValidURLTwitter = urlTested.valid;
                    this.companyForm.twitter = urlTested.data;
                    break;
                case 'linkedin':
                    urlTested = this.validateUrls(this.companyForm.linkedin);
                    this.isValidURLLinkedin = urlTested.valid;
                    this.companyForm.linkedin = urlTested.data;
                    break;
                case 'instagram':
                    urlTested = this.validateUrls(this.companyForm.instagram);
                    this.isValidURLInstagram = urlTested.valid;
                    this.companyForm.instagram = urlTested.data;
                    break;
                default:
                    break;
            }
            if (urlsToCheck.length == 1) {
                this.onChange();
            }
        });
    }

    testUrlLink(url: string) {
        this.utilsService.showUrlLink(url);
    }

    onChangeEmail(): void {
        this.isValidEmail = this.utilsService.validateEmail(this.companyForm.email);
        this.onChange();
    }

    onChangeMapCoord(): void {
        this.companyMapUpdate();
    }

    goBack(): void {
        this._rootScope.goBackAndCheckForModifications(this.isFormDataChanged, this.companiesPath);
    }

    checkIfDataFormAreChanged(): void {
        this.isFormDataChanged = !isEqual(this.initialDataForm, this.companyForm);
    }

    checkIfDataFormAreValid(): void {
        this.isFormDataValid =
            this.isValidURLWebsite &&
            this.isValidURLFacebook &&
            this.isValidURLYoutube &&
            this.isValidURLTwitter &&
            this.isValidURLLinkedin &&
            this.isValidURLInstagram &&
            this.isValidEmail;
    }
}
