<section class="language-selector flex align-items-center">
    @if (availableLanguages.length > 0) {
        <button
            type="button"
            class="language-selector__default"
            [class.selected]="selectedLanguage === defaultLanguage"
            [translate]="'global.language.' + defaultLanguage"
            (click)="setLanguage(defaultLanguage)"></button>
    }

    @for (language of availableLanguages; track language) {
        @if ((language.name !== defaultLanguage) && isLanguageAdded(language)) {
            <button
                type="button"
                class="language-selector__added"
                [class.selected]="selectedLanguage === language.name"
                [translate]="'global.language.' + language.name"
                (click)="setLanguage(language.name)"></button>
        }
    }

    @if (availableLanguages.length > 0) {
        <button class="language-selector__dropdown-toggle" (click)="toggleSelector()" title="{{'global.form.select' | translate}}">
            <span class="icon nucleo icon-language-selector"></span>
        </button>
    }

    @if (isDropDownOpened) {
        <ul class="language-selector__language-list">
            @for (language of availableLanguages; track language) {
                <li class="language-selector__available-language">
                    <span [translate]="'global.language.' + language.name"></span>
                    <button type="button" class="language-toggle" (click)="toggleLanguage(language)">
                        <span
                            [ngClass]="{'remove-btn': !!language.added, 'add-btn': !language.added}"
                            [translate]="'global.form.' + (language.added ? 'remove' : 'add')"></span>
                    </button>
                </li>
            }
        </ul>
    }
</section>
