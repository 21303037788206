<div class="main-container__content">
    <div class="main-container--full-width">
        <!-- Advice text -->
        <div class="info-advice">
            <p [translate]="'schemes.advice'"></p>
        </div>
        <!-- Filters section -->
        <section class="filter-container">
            <div class="flex direction-column justify-content-end">
                <!-- Schemas Actives / Archived button bar -->
                <div class="button-bar">
                    <button class="button button--small" [ngClass]="{'button--selected' : dataFilterOpts.active}" (click)="getSchemasByIsActive(true)">
                        <span
                            class="button__label"
                            [translate]="'global.listFilters.activeSelection'"
                            [translateParams]="{gender: 'male', number: 'plural'}"></span>
                    </button>
                    <button class="button button--small" [ngClass]="{'button--selected' : !dataFilterOpts.active}" (click)="getSchemasByIsActive(false)">
                        <span
                            class="button__label"
                            [translate]="'global.listFilters.archivedSelection'"
                            [translateParams]="{gender: 'male', number: 'plural'}"></span>
                    </button>
                </div>
            </div>
            <!-- Input search filter -->
            <div class="search-filter-container align-items-center">
                <input
                    data-cy="input-search"
                    type="text"
                    class="input input--search"
                    [ngClass]="dataFilterOpts.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'"
                    [(ngModel)]="dataFilterOpts.name"
                    (input)="listItemsSearch()"
                    ngModelOptions="{ debounce: 200 }"
                    maxlength="1024"
                    [placeholder]="'schemes.grid.searchPlaceholder' | translate" />
                <div class="icon icon-remove" (click)="clearSearchInput()"></div>
            </div>
        </section>
        <!-- Kendo grid list -->
        @if (schemasDatagrid?.total > 0) {
            <kendo-grid
                #schemasGrid
                [data]="schemasDatagrid"
                class="master-grid user-select-none margin-bottom-40"
                [sortable]="true"
                [sort]="gridSort"
                (sortChange)="onSortChange($event)"
                (cellClick)="onCellClick($event)"
                (dblclick)="onDblClick($event)"
                [pageable]="gridPagerSettings"
                (pageChange)="onPageChange($event)"
                [skip]="dataPagingOpts.skip"
                [pageSize]="dataPagingOpts.size"
                [navigable]="true"
                [resizable]="false"
                [selectable]="false"
                [loading]="isSyncing">
                <kendo-grid-messages
                    [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                    [pagerOf]="'global.lists.pagerOf' | translate"
                    [pagerItems]="'global.lists.pagerItems' | translate">
                </kendo-grid-messages>
                <!-- Schema name column-->
                <kendo-grid-column field="name" [headerStyle]="{'min-width': '45%'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center cursor-pointer">
                            <span class="truncate" [translate]="'model.schemes.name'"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center padding-left-5">
                            <span class="truncate cursor-default" [textContent]="dataItem.name"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- Schema employees count column-->
                <kendo-grid-column
                    field="totalEmployees"
                    [width]="150"
                    [sortable]="false"
                    [headerStyle]="{'cursor': 'default !important', 'pointer-events': 'none'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center justify-content-center margin-left-30">
                            <span [translate]="'model.schemes.employees'"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center justify-content-center">
                            <span class="cursor-default" [textContent]="dataItem.totalEmployees"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- CTA buttons column -->
                <kendo-grid-command-column title="" [width]="136" [style]="{ 'text-align': 'end' }">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                            <a class="k-button k-master-button" (click)="updateSchema(dataItem)" [title]="'global.form.edit' | translate">
                                <span class="icon icon-edit"></span>
                            </a>
                            @if (!dataItem.deletable || !dataItem.active) {
                                <a
                                    class="k-button k-master-button"
                                    (click)="archiveOrActivateSchema(dataItem)"
                                    [title]="(dataItem.active ? 'global.form.archive' : 'global.form.activate') | translate">
                                    <span class="icon" [ngClass]="dataItem.active ? 'icon-archive' : 'icon-unarchive'"></span>
                                </a>
                            }
                            @if (dataItem.deletable && dataItem.active) {
                                <a class="k-button k-master-button" (click)="deleteSchema(dataItem)" [title]="'global.form.delete' | translate">
                                    <span class="icon icon-delete"></span>
                                </a>
                            }
                        </div>
                    </ng-template>
                </kendo-grid-command-column>
                <!-- Custom Pager -->
                <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                    <article class="dui-grid-pager">
                        <kendo-pager-info></kendo-pager-info>
                        @if (schemasDatagrid.total > 10) {
                            @if (totalPages > 1) {
                                <article class="dui-grid-pager__numbers-wrap">
                                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                </article>
                            }
                            <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                        }
                    </article>
                </ng-template>
            </kendo-grid>
        }
        <!-- Skeleton schemas grid -->
        @if (schemasDatagrid?.total == 0 && isSyncing) {
            <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid org-grid" [rowClass]="skeletonRowCallback">
                <!-- Schema name -->
                <kendo-grid-column>
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center">
                            <span class="truncate" [translate]="'model.schemes.name'"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center">
                            <kendo-skeleton shape="text" animation="pulse" [width]="300" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- Schema employees count -->
                <kendo-grid-column [width]="150">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center justify-content-center margin-left-15">
                            <span [translate]="'model.schemes.employees'"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center justify-content-center">
                            <kendo-skeleton shape="text" animation="pulse" [width]="20" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- CTA column -->
                <kendo-grid-command-column [width]="136"></kendo-grid-command-column>
            </kendo-grid>
        }
        <!-- No data information -->
        @if (schemasDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) {
            <no-results
                [imageTitle]="'schemes.noResultsTitle'"
                [description]="dataFilterOpts.active ? 'schemes.noResultsText' : 'schemes.noResultsArchiveText'">
            </no-results>
        }
        @if (schemasDatagrid.total == 0 && dataFilterOpts.name && !isSyncing) {
            <no-search-results></no-search-results>
        }
    </div>
</div>
