import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from '../../shared/services/dialog.service';
import { RequestsService } from '../requests/requests.service';
import { UtilsService } from '../../shared/services/utils.service';
import { Constants } from '../../constants';

@Component({
    templateUrl: './request-create-modal.component.html'
})
export class RequestCreateModalComponent implements OnInit {
    public isFormValid: boolean = false;
    public requestForm: any = {
        employee: null,
        tray: null,
        motive: null,
        message: '',
        linkedPublications: []
    };
    public attachedResources: Array<File> = [];
    public availablesTrays: any;
    public availablesMotives: any;
    public CONTENT_KIND = Constants.CONTENT_KIND;

    @ViewChild('attachFile') private attachFileRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<RequestCreateModalComponent>,
        private requestsService: RequestsService,
        private utilsService: UtilsService,
        private dialogService: DialogService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.requestsService.getAllTraysForResponsible().subscribe(
            (response) => {
                this.availablesTrays = response;
            },
            (error) => {}
        );
        this.requestsService.getAllMotivesForResponsible().subscribe(
            (response) => {
                this.availablesMotives = response;
            },
            (error) => {}
        );
        if (!!this.data.user) {
            this.requestForm.employee = this.data.user;
        }
    }

    private validateModel(): void {
        this.isFormValid =
            this.requestForm.employee &&
            !!this.requestForm.employee.id &&
            !!this.requestForm.tray &&
            !!this.requestForm.motive &&
            ((this.requestForm.message && this.requestForm.message !== '<p></p>') ||
                (this.attachedResources && this.attachedResources.length > 0) ||
                this.requestForm.linkedPublications?.length > 0);
    }

    getMotiveTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    }

    attacthResource(): void {
        this.attachFileRef.nativeElement.click();
    }

    internalLinkSelection(): void {
        this.dialogService.openInternalLinkModalSelection().subscribe((result) => {
            if (result?.success) {
                this.requestForm.linkedPublications.push(result.content);
                this.validateModel();
            }
        });
    }

    onFileSelected(files: File): void {
        if (files) {
            this.attachedResources.push(files[0]);
            this.onChange();
        }
    }

    removeAttachedFile(index: number): void {
        if (this.attachedResources.length > index) {
            this.attachedResources.splice(index, 1);
        }
        this.onChange();
    }

    removeLink(index: number): void {
        if (this.requestForm.linkedPublications.length > index) {
            this.requestForm.linkedPublications.splice(index, 1);
        }
        this.validateModel();
    }

    getVideoImgPreview(videoUrl: string): string {
        let imgPreviewUrl: string = '';
        const videoId = this.utilsService.getVideoId(videoUrl);
        if (videoId) {
            imgPreviewUrl = 'https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg';
        }
        return imgPreviewUrl;
    }

    onChange(): void {
        this.validateModel();
    }

    onAccept(): void {
        const newRequest = {
            form: {
                toUserId: this.requestForm.employee.id,
                tray: this.requestForm.tray,
                motive: this.requestForm.motive,
                message: this.requestForm.message,
                linkedPublications: this.requestForm.linkedPublications
            },
            resources: this.attachedResources
        };

        this.requestsService.createRequestWithEmployee(newRequest).subscribe(
            (onSuccess: any) => {
                this.dialogRef.close({ success: onSuccess });
            },
            (onError: any) => {}
        );
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }
}
