@if (availableIn) {
    <div class="mobile-preview-container" [ngClass]="{'mobile-preview-container--visible': isOpened}">
        <div class="step-views-container" [ngClass]="{'margin-bottom-70' : availableIn !== AVAILABLE_IN.ALL}">
            <p class="title-view" data-translate="message.preview.highlighted"></p>
            <div class="text-center margin-top-20">
                <div class="tab-nav tab-nav--inline">
                    <span class="tab-nav__item tab-nav__item--selected" [translate]="'message.preview.preview'"></span>
                </div>
            </div>
            @if (availableIn === AVAILABLE_IN.ALL) {
                <ul class="number margin-vertical-20">
                    <li class="" [ngClass]="{'selected': previewVisible === PREVIEW.INBOX}" (click)="setPreviewVisible(PREVIEW.INBOX)">1</li>
                    <li class="" [ngClass]="{'selected': previewVisible === PREVIEW.KNOWLEDGE_CENTER}" (click)="setPreviewVisible(PREVIEW.KNOWLEDGE_CENTER)">
                        2
                    </li>
                </ul>
            }
        </div>
        <div class="mobile-preview-container__close icon icon-close" (click)="onClick()"></div>
        <!-- INBOX -->
        @if ((previewVisible === PREVIEW.INBOX && availableIn === AVAILABLE_IN.ALL) || availableIn === AVAILABLE_IN.INBOX) {
            <div class="mobile-container">
                <div class="device-container">
                    <div class="device-details-container">
                        <div class="preview-header">
                            <div class="preview-header__first-row">
                                <span class="preview-header__icon icon icon-wifi"></span>
                                <div class="flex">
                                    <span class="preview-header__label margin-right-5">74%</span>
                                    <span class="preview-header__icon icon icon-battery"></span>
                                </div>
                            </div>
                            <div class="preview-header__second-row">
                                <div class="preview-header__title" [translate]="'messages.appTitle'"></div>
                            </div>
                        </div>
                        <div class="preview-new preview-new--only-category">
                            <div class="preview-new__img-container">
                                @if (category?.contents[language]?.name) {
                                    <span
                                        class="preview-new__img-container__category"
                                        [ngStyle]="{'background-color': color}"
                                        [textContent]="category?.contents[language]?.name"></span>
                                }
                                @if (!category || !category.contents[language] || !category.contents[language].name) {
                                    <span
                                        class="preview-new__img-container__category"
                                        [ngStyle]="{'background-color': color}"
                                        [translate]="'model.category.nameplaceholder'"></span>
                                }
                                <div class="preview-new__default-image">
                                    <div class="preview-new__default-image__color" [ngStyle]="{'background-color': color}"></div>
                                    <div class="preview-new__default-image__image" [ngStyle]="{'background-image': 'url('+ getImage()  +')'}"></div>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <img src="/ng1/assets/img/skeleton-category-preview.svg" alt="" class="padding-10 width-100-percent" />
                            <img src="/ng1/assets/img/skeleton-card.svg" alt="" class="padding-10 padding-top-20 width-100-percent" />
                        </div>
                    </div>
                </div>
            </div>
        }
        <!-- KNOWLEDGE CENTER -->
        @if ((previewVisible === PREVIEW.KNOWLEDGE_CENTER && availableIn === AVAILABLE_IN.ALL) || availableIn === AVAILABLE_IN.KNOWLEDGE_CENTER) {
            <div class="mobile-container">
                <div class="device-container">
                    <div class="device-details-container">
                        <div class="preview-header">
                            <div class="preview-header__first-row">
                                <span class="preview-header__icon icon icon-wifi"></span>
                                <div class="flex">
                                    <span class="preview-header__label margin-right-5">74%</span>
                                    <span class="preview-header__icon icon icon-battery"></span>
                                </div>
                            </div>
                            <div class="category-preview-mobile__header">
                                <p class="category-preview-mobile__header__title" [translate]="'global.navbar.knowledgecenter'"></p>
                            </div>
                        </div>
                        <div class="category-preview-mobile">
                            <div class="category-preview__item">
                                <div class="category-preview__item__texts">
                                    @if (category?.contents[language]?.name) {
                                        <span class="category-preview__item__text" [textContent]="category?.contents[language]?.name"></span>
                                    }
                                    @if (!category || !category.contents[language] || !category.contents[language].name) {
                                        <span class="category-preview__item__text" [translate]="'model.category.nameplaceholder'"></span>
                                    }
                                    <p class="category-preview__item__subtitle" [textContent]="category?.contents[language]?.subtitle"></p>
                                </div>
                                @if (!!color && !!darkenColor) {
                                    <div
                                        class="category-preview__item__gradient"
                                        [ngStyle]="{'background-image': 'linear-gradient(-45deg, ' + darkenColor + ' 0%, ' + color + ' 100%)'}"></div>
                                }
                                <div class="category-preview__item__image" [ngStyle]="{'background-image': 'url('+ getImage()  +')'}"></div>
                            </div>
                            <div class="category-preview__item category-preview__item--empty"></div>
                            <div class="category-preview__item category-preview__item--empty"></div>
                            <div class="category-preview__item category-preview__item--empty"></div>
                            <div class="category-preview__item category-preview__item--empty"></div>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
}
