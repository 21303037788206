import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, GridComponent, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

import { Constants } from '../../constants';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { UtilsService } from '../../shared/services/utils.service';

import { RequestsService } from '../requests/requests.service';
import { CreateMotiveModalComponent } from './create-motive-modal.component';

@Component({
    selector: 'requests-motives-list',
    templateUrl: './requests-settings-motives.component.html',
    styleUrls: ['./_requests-settings-motives.scss']
})
export class RequestsSettingsMotiveComponent implements OnInit, OnChanges {
    @Input() addNewMotive: boolean;
    @Output() addNewMotiveChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() isMotivesUpdatedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('motivesGrid') motivesGrid: GridComponent;

    userSession: any;
    defaultLanguage: string;

    stateIsActive: boolean;
    inputSearchField: string;
    isSyncing: boolean;

    motivesDataGrid: GridDataResult;
    gridPagerSettings: { buttonCount: number; pageSizes: number[] };
    dataPagingOpts: { page: number; size: number; skip: number; sort: string };
    totalPages: number;

    dataSkeleton = [{}, {}, {}];
    gridSort: SortDescriptor[];

    private clickedRowItem: any;

    constructor(
        private dialog: MatDialog,
        private translate: TranslateService,
        private dialogService: DialogService,
        private notificationService: DuiNotificationsService,
        private formUtilsService: FormUtilsService,
        private utilsService: UtilsService,
        private requestsService: RequestsService
    ) {
        this.userSession = window['getUserSession']();
        this.defaultLanguage = this.userSession.companyDefaultLanguage;

        this.stateIsActive = true;
        this.inputSearchField = '';
        this.isSyncing = false;

        this.motivesDataGrid = {
            data: [],
            total: 0
        };

        this.gridPagerSettings = {
            buttonCount: 5,
            pageSizes: [5, 10, 20, 100]
        };

        this.dataPagingOpts = {
            page: 0,
            size: Constants.PAGE_SIZE,
            skip: 0,
            sort: 'contents.motive,asc'
        };

        this.totalPages = 0;
    }

    ngOnInit() {
        this.getMotivesData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.addNewMotive && changes.addNewMotive.currentValue !== changes.addNewMotive.previousValue && !changes.addNewMotive.firstChange) {
            if (changes.addNewMotive.currentValue) {
                this.openMotiveEditionModal();
                this.formUtilsService.finishSubmitAction();
                this.addNewMotiveChange.emit(false);
            }
        }
    }

    private getMotivesData() {
        this.isSyncing = true;

        const filters = {
            page: this.dataPagingOpts.page,
            size: this.dataPagingOpts.size,
            sort: this.dataPagingOpts.sort,
            archived: !this.stateIsActive,
            ['contents.motive']: this.inputSearchField
        };

        this.requestsService.getAllMotives(filters).subscribe(
            (response) => {
                this.motivesDataGrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
            }
        );
    }

    // MODALS

    openMotiveEditionModal(id?: number): void {
        const dialogSettings = new MatDialogConfig();

        dialogSettings.data = {
            modalTitle: this.translate.instant(id ? 'motives.modal.editionTitle' : 'motives.modal.creationTitle')
        };
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '6vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        if (id) {
            dialogSettings.data.motiveId = id;
        }

        this.dialog
            .open(CreateMotiveModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.accept) {
                    const createOrUpdate: string = id ? 'updateMotive' : 'createMotive';
                    this.requestsService[createOrUpdate](result?.motive).subscribe(
                        (response: any) => {
                            const message = id
                                ? this.translate.instant('global.messages.changesUpdated.success')
                                : this.translate.instant('motives.modal.create.notification', { name: response.motive });
                            this.notificationService.showSuccessNotification(message);
                            this.getMotivesData();
                            this.isMotivesUpdatedChange.emit(true);
                        },
                        (error: any) => {
                            const errorMsg = this.translate.instant('global.messages.error.unknown');
                            this.notificationService.showErrorNotification(errorMsg);
                            console.error(error);
                            this.isMotivesUpdatedChange.emit(false);
                        }
                    );
                }
            });
    }

    openMotiveDeletionModal(dataItem): void {
        const dialogValues: DialogValueOptions = {
            title: 'motives.modal.delete.title',
            message: 'motives.modal.delete.message',
            messageParam: { name: dataItem.motive },
            acceptButtonText: 'global.form.delete'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.requestsService.deleteMotive(dataItem.id).subscribe(
                    () => {
                        const message = this.translate.instant('motives.modal.delete.notification', { name: dataItem.motive });
                        this.notificationService.showSuccessNotification(message);
                        this.getMotivesData();
                        this.isMotivesUpdatedChange.emit(true);
                    },
                    (error) => {
                        this.notificationService.showErrorNotification();
                        console.error(error);
                        this.isMotivesUpdatedChange.emit(false);
                    }
                );
            }
        });
    }

    openArchiveMotiveModal(dataItem): void {
        const dialogValues: DialogValueOptions = {
            title: 'motives.modal.archive.title',
            message: 'motives.modal.archive.message',
            messageParam: { name: dataItem.motive },
            acceptButtonText: 'global.form.archive'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.requestsService.archiveMotive(dataItem.id).subscribe(
                    () => {
                        const message = this.translate.instant('motives.modal.archive.notification', { name: dataItem.motive });
                        this.notificationService.showSuccessNotification(message);
                        this.getMotivesData();
                        this.isMotivesUpdatedChange.emit(true);
                    },
                    (error) => {
                        this.notificationService.showErrorNotification();
                        console.error(error);
                        this.isMotivesUpdatedChange.emit(false);
                    }
                );
            }
        });
    }

    openActivateMotiveModal(dataItem): void {
        const dialogValues: DialogValueOptions = {
            title: 'motives.modal.activate.title',
            message: 'motives.modal.activate.message',
            messageParam: { name: dataItem.motive },
            acceptButtonText: 'global.form.activate'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.requestsService.activateMotive(dataItem.id).subscribe(
                    () => {
                        const message = this.translate.instant('motives.modal.activate.notification', { name: dataItem.motive });
                        this.notificationService.showSuccessNotification(message);
                        this.getMotivesData();
                        this.isMotivesUpdatedChange.emit(true);
                    },
                    (error) => {
                        this.notificationService.showErrorNotification();
                        console.error(error);
                        this.isMotivesUpdatedChange.emit(false);
                    }
                );
            }
        });
    }

    // ACTIONS

    getMotivesByIsActive(isActive: boolean): void {
        if (this.stateIsActive === isActive) {
            return;
        }

        this.stateIsActive = isActive;
        this.resetPagingOptions();
        this.getMotivesData();
    }

    updateSearch(clearInput: boolean = false): void {
        if (!clearInput && this.inputSearchField.length < 3) {
            return;
        }

        if (clearInput) {
            this.inputSearchField = '';
        }

        this.resetPagingOptions();
        this.getMotivesData();
    }

    editMotive(id: number): void {
        this.openMotiveEditionModal(id);
    }

    deleteMotive(dataItem: any): void {
        this.openMotiveDeletionModal(dataItem);
    }

    archiveMotive(dataItem: any): void {
        this.openArchiveMotiveModal(dataItem);
    }

    activateMotive(dataItem: any): void {
        this.openActivateMotiveModal(dataItem);
    }

    // GRID

    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : 'contents.motive,asc';
        this.getMotivesData();
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
    }

    onDblClick(event: MouseEvent): void {
        const gridListElement = this.motivesGrid.wrapper.nativeElement.querySelector('kendo-grid-list');

        if (gridListElement.contains(event.target as Node) && this.clickedRowItem) {
            this.openMotiveEditionModal(this.clickedRowItem.id);
        }
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / event.take;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;

        this.getMotivesData();
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }

    // UTILS

    isDefaultLanguageHidden(availableLanguages: string[]): boolean {
        return !availableLanguages.includes(this.defaultLanguage);
    }

    decimalToHex(color: number): string {
        return this.utilsService.decimalToHex(color);
    }

    private resetPagingOptions(): void {
        this.dataPagingOpts.page = 0;
        this.dataPagingOpts.skip = 0;
    }
}
