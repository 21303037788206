import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Constants } from '../../constants';
import { UpgradeModule } from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { FileSaverService } from 'ngx-filesaver';
import { RemoteSignatureService } from '../remote-signature/remote-signature.service';
import { UtilsService } from '../../shared/services/utils.service';
import { DocumentPreviewModalComponent } from '../documents-list/document-preview-modal.component';
import { DocumentsService } from '../documents/documents.service';

@Component({
    selector: 'remote-signature-detail',
    templateUrl: './remote-signature-detail.html',
    styleUrls: ['./_remote-signature-detail.scss']
})
export class RemoteSignatureDetailComponent implements OnInit {
    private _route: any;
    private userSession: any;
    public userLoggedHasAdminRole: boolean;
    public isConfidentialProfile: boolean;

    public SIGNATURE_BATCH_STATES = Constants.SIGNATURE_BATCH_STATES;
    public DOCUMENT_STATES = Constants.DOCUMENT_STATES;

    public showTracking: boolean = false;
    public batchSignatureId: string = '';
    public batchData: any = {
        documents: [],
        employee: null,
        expiration: null,
        reminder: null
    };
    public selectedFileData: any;
    public selectedFileLog = [];
    public isFileLogLoaded: boolean = false;
    public batchDocumentsDatagrid: Array<any> = [];
    public selectedRow = [];
    public selectRowEventFired = false;

    constructor(
        private location: Location,
        private upgrade: UpgradeModule,
        private translate: TranslateService,
        private dialogService: DialogService,
        private remoteSignatureService: RemoteSignatureService,
        private utilsService: UtilsService,
        public documentViewModal: MatDialog,
        private documentsService: DocumentsService,
        private fileSaverService: FileSaverService,
        private notificationService: DuiNotificationsService
    ) {
        this._route = this.upgrade.$injector.get('$route');
        this.batchSignatureId = this._route.current.params.id;
        this.userSession = window['getUserSession']();
        this.userLoggedHasAdminRole = this.userSession.roles.indexOf(Constants.USER_ROLES.admin) !== -1;
    }

    ngOnInit() {
        this.getBatchData();
    }

    getBatchData(): void {
        this.remoteSignatureService.findOne(this.batchSignatureId).subscribe(
            (response) => {
                this.batchData = response;
                this.batchData.batchDate = new Date(this.batchData.batchDate);
                this.batchDocumentsDatagrid = response.documents;
                this.isConfidentialProfile = response.employee.confidentialProfile;
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    navigateTo(url: string) {
        if (url == 'back') {
            this.location.back();
        } else {
            this.location.go(url);
        }
    }

    getDocumentStateBgColor(docState: number): string {
        return this.remoteSignatureService.documentStateBgColor(docState);
    }

    getDocumentLabelText(state: number): string {
        return this.translate.instant(this.remoteSignatureService.documentStateLabelText(state));
    }

    onSelectDocument(event: any) {
        this.selectedFileData = event.selectedRows[0].dataItem;
        this.selectRowEventFired = true;
        this.showTracking = true;
        this.isFileLogLoaded = false;

        this.remoteSignatureService.getDocumentActivityLog(this.selectedFileData.id).subscribe(
            (response) => {
                this.selectedFileLog = response;
                this.isFileLogLoaded = true;
            },
            (error) => {
                if (error.status === 422) {
                    this.selectedFileLog = [];
                    this.isFileLogLoaded = true;
                }
            }
        );
    }

    onCellClick() {
        // if click in selected row, the kendo selectionChange event is not triggered. In this case, deselect the row manually when click
        if (!this.selectRowEventFired) {
            this.selectedRow = [];
            this.showTracking = false;
        }
        this.selectRowEventFired = false;
    }

    getDocumentTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    }

    getBatchStateColor(state: any): string {
        return this.remoteSignatureService.batchStateColor(state);
    }

    getBatchStateTextLabel(state: any): string {
        return this.remoteSignatureService.batchStateTextLabel(state);
    }

    getFinishedDocsCount(): number {
        let finalizedCount = this.batchData.documents.filter((item) => {
            return item.state !== 1;
        });
        return finalizedCount.length;
    }

    finishedDocsPercent(): string {
        return (this.getFinishedDocsCount() * 100) / this.batchData.documentsCount + '%';
    }

    actionViewDocument(dataItem: any) {
        dataItem.iconMimeType = this.utilsService.getIconFromMimeType(dataItem.resource.mimetype);
        const modalSettings = new MatDialogConfig();
        modalSettings.data = dataItem;
        modalSettings.width = '1200px';
        modalSettings.height = '90vh';
        const documentViewModalRef = this.documentViewModal.open(DocumentPreviewModalComponent, modalSettings);
    }

    actionDownloadDocument(dataItem: any) {
        this.documentsService.downloadDocument(dataItem.resource.url).subscribe(
            (response) => {
                this.fileSaverService.save(response.body, dataItem.resource.nameWithExtension);
            },
            (error: any) => {}
        );
    }

    actionDownloadEvidenceReport(dataItem: any): void {
        this.remoteSignatureService.downloadEvidenceReport(dataItem.reportResource.url).subscribe((response) => {
            this.fileSaverService.save(response.body, dataItem.reportResource.nameWithExtension);
        });
    }

    cancelBatch(): void {
        const dialogValues: DialogValueOptions = {
            title: 'remoteSignature.batch.modal.single.title',
            message: 'remoteSignature.batch.modal.single.message',
            acceptButtonTextClass: 'button--cancel'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.remoteSignatureService.cancelBatch(this.batchSignatureId).subscribe(
                    (success: any) => {
                        this.batchData.cancellable = false;
                        this.batchData.state = this.SIGNATURE_BATCH_STATES.CANCELLED;
                        this.getBatchData();

                        const message = this.translate.instant('remoteSignature.batch.modal.single.cancelSuccess');
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError: any) => {}
                );
            }
        });
    }
}
