<div
    class="avatar"
    [ngClass]="{'avatar--small' : small == true, 'avatar--medium' : medium == true, 'avatar--large' : large == true, 'avatar--xlarge': xlarge == true}"
    [title]="title ? title : ''"
    [ngStyle]="{'background-color': !thumbnail ? avatarBGColor : 'transparent'}"
    kendoTooltip
    filter="span[name='avatar-state']"
    offset="8">
    @if (!thumbnail && initials) {
        <span class="avatar__label" [textContent]="initials"></span>
    }
    @if (!thumbnail && !initials && chatroom) {
        <span class="avatar__icon icon icon-group"></span>
    }
    @if (!thumbnail && !initials && anonymous) {
        <span class="avatar__icon icon icon-heisenberg"></span>
    }
    @if (thumbnail) {
        <span class="avatar__thumbnail" [ngStyle]="!!thumbnail && {background: 'url(' + thumbnail + ')'}"></span>
    }
    @if (state == USER_STATES.PENDING_ACTIVATION || state == USER_STATES.LOCKED || state == USER_STATES.EXPIRED_CREDENTIALS) {
        <span class="avatar__state" name="avatar-state" [title]="getUserStatusText()"> </span>
    }
    @if (state == USER_STATES.REGISTERED && !access) {
        <span class="avatar__state-pending-access" name="avatar-state" [title]="getUserStatusText()"> </span>
    }
</div>
