import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort'
})
export class ArraySortPipe implements PipeTransform {
    transform(array: any, field: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            const firstField = typeof a[field] === 'string' ? a[field].toLowerCase() : a[field];
            const secondField = typeof b[field] === 'string' ? b[field].toLowerCase() : b[field];
            if (firstField < secondField) {
                return -1;
            } else if (firstField > secondField) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}
