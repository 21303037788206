<!-- close button -->
@if (options.closeButton) {
<button (click)="remove()" type="button" class="toast-close-button" aria-label="Close">
    <span aria-hidden="true" class="icon icon-clear-2"></span>
</button>
}

<!-- icon toast -->
<span [ngClass]="getIconClass()"></span>
<svg class="spinner secondary margin-left-10" width="25px" height="25px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" style="display: none">
    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
</svg>
<div class="flex direction-column">
    <!-- title -->
    @if (title) {
    <div [class]="options.titleClass" [attr.aria-label]="title">{{ title }} @if (duplicatesCount) { [{{ duplicatesCount + 1 }}] }</div>
    }

    <!-- message -->
    @if (message && options.enableHtml) {
    <div role="alert" [class]="options.messageClass" [innerHTML]="message"></div>
    } @else {
    <div role="alert" [class]="options.messageClass" [attr.aria-label]="message">{{ message }}</div>
    }
</div>

<!-- progressBar -->
@if (options.progressBar) {
<div>
    <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
}
