import { Component, EventEmitter, HostListener, Input, Output, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { AttachedContentUtilsService } from '../../shared/services/attached-content-utils.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from '../../shared/services/dialog.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ImageCropperModalComponent } from '../image-cropper-modal/image-cropper-modal.component';

@Component({
    selector: 'image-uploader',
    templateUrl: './image-uploader.component.html'
})
export class ImageUploaderComponent implements OnChanges {
    public isDropActive = false;

    @Input() image: Array<any>;
    @Input() imageUrl: string;
    @Input() croppedImageSrc: string;
    @Input() cropOptions: any;
    @Input() roundedCanvas?: boolean;
    @Input() gifsAllowed: boolean;
    @Input() picsAllowed: boolean;
    @Input() uploadAllowed: boolean;
    @Input() insertInEditor: boolean;
    @Input() acceptedFormats?: string;
    @Input() textLabel?: string;
    @Input() hideSearchBtn?: boolean;
    @Input() canvasHeight?: string;
    @Input() showTransformImage?: boolean;
    @Input() modalTitle?: string;
    @Input() modalAdviceText?: string;
    @Input() modalWidth?: string;
    @Input() modalHeight?: string;
    @Input() isCategoryImg?: boolean;
    @Output() onChanges: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() imageChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    @Output() imageUrlChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() croppedImageSrcChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('imageFileUpload') imageFileUploadRef: ElementRef;

    constructor(
        private attachedContentUtilsService: AttachedContentUtilsService,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        private dialogService: DialogService,
        private Dialog: MatDialog
    ) {}

    @HostListener('dragover', ['$event']) onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDropActive = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDropActive = false;
    }

    @HostListener('drop', ['$event']) onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDropActive = false;
        this.onSelectImage(event.dataTransfer.files);
    }

    ngOnChanges(changes: any): void {
        if (changes?.imageUrl) {
            this.croppedImageSrc = this.imageUrl;
        }
    }

    processCroppedImage(croppedImage: any, selectedFile: any) {
        // Update image preview
        this.croppedImageSrc = croppedImage;
        // Update image to form content
        let multiPartImage = this.attachedContentUtilsService.dataURItoBlob(croppedImage);
        this.image.push({ name: selectedFile.name, image: multiPartImage });

        this.imageChange.emit(this.image);
        this.croppedImageSrcChange.emit(this.croppedImageSrc);
        this.onChanges.emit();
    }

    onSelectImage(files: FileList) {
        if (files.length > 0) {
            var selectedImage = files[0];
            this.attachedContentUtilsService.selectImage(selectedImage).subscribe(
                (response: any) => {
                    if (response.size.width < this.cropOptions.MINIMUM_WIDTH || response.size.height < this.cropOptions.MINIMUM_HEIGHT) {
                        const notificationOptions: NotificationOptions = {
                            kind: 'error',
                            message: this.translate.instant('message.imageSizeError_Ang', {
                                width: this.cropOptions.MINIMUM_WIDTH,
                                height: this.cropOptions.MINIMUM_HEIGHT
                            })
                        };
                        this.notificationService.showNotification(notificationOptions);
                    } else {
                        const dialogSettings = new MatDialogConfig();
                        dialogSettings.data = {
                            title: this.modalTitle || 'message.imageCategoryModalTitle',
                            subtitle: this.modalAdviceText || 'message.imageCategoryModalSubtitle',
                            showSubtitle: true,
                            cropperOptions: this.cropOptions,
                            originImage: response.image,
                            roundedCanvas: this.roundedCanvas,
                            showTransformImage: this.showTransformImage
                        };
                        dialogSettings.width = this.modalWidth || '900px';
                        dialogSettings.position = { top: '4vh' };
                        dialogSettings.panelClass = ['animated', 'slideInDown'];
                        if (this.modalHeight) {
                            dialogSettings.height = this.modalHeight;
                        }

                        this.Dialog.open(ImageCropperModalComponent, dialogSettings)
                            .afterClosed()
                            .subscribe((response) => {
                                if (response?.accepted) {
                                    this.processCroppedImage(response.image, selectedImage);
                                } else {
                                    this.imageFileUploadRef.nativeElement.value = null;
                                }
                            });
                    }
                },
                (onError) => {
                    const notificationOptions: NotificationOptions = {
                        kind: 'error',
                        message: this.translate.instant('message.imageFormatError')
                    };
                    this.notificationService.showNotification(notificationOptions);
                }
            );
        }
    }

    itemHasImage(): boolean {
        return !!this.croppedImageSrc;
    }

    onImageSearch(): void {
        this.dialogService
            .openImageBank(this.cropOptions, this.roundedCanvas, this.gifsAllowed, this.picsAllowed, this.uploadAllowed, this.insertInEditor)
            .subscribe((response: any) => {
                if (response && response.accepted) {
                    this.processCroppedImage(response.data.imageSrc, { name: response.data.filename });
                }
            });
    }

    deleteImage(): void {
        this.imageFileUploadRef.nativeElement.value = null;
        this.croppedImageSrc = '';
        this.imageUrl = '';
        this.image = [];
        this.imageUrlChange.emit(this.imageUrl);
        this.imageChange.emit(this.image);
        this.croppedImageSrcChange.emit(this.croppedImageSrc);
        this.onChanges.emit();
    }
}
