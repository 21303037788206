import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators';
import { Location } from '@angular/common';
import { DuiNotificationsService } from './shared/services/dui-notifications.service';
import { FormUtilsService } from './shared/services/form-utils.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class CustomInterceptor implements HttpInterceptor {
    constructor(
        private location: Location,
        private formUtilsService: FormUtilsService,
        private readonly injector: Injector
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({
            withCredentials: true
        });

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                // Manage error status codes
                switch (error.status) {
                    case 401: // Unauthorized: Invalid authentication credentials | session expiration
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('refreshToken');
                        break;
                    case 403: // Forbidden: server denied response
                        try {
                            const translate = this.injector.get(TranslateService);
                            const notificationService = this.injector.get(DuiNotificationsService);
                            notificationService.showErrorNotification(translate.instant('403.notification'));
                        } catch {}
                        break;
                    case 404: // Url not found
                        this.location.replaceState('/not-found');
                        break;
                    case 422: // Unprocessable content
                        try {
                            const notificationService = this.injector.get(DuiNotificationsService);
                            notificationService.showErrorNotification(error.error?.localizedError);
                        } catch {}
                        this.formUtilsService.finishSubmitAction();
                        break;
                    case 500: // Internal server error
                        // TO DO: Save the current request to implement retries
                        this.location.replaceState('/server-error');
                        break;
                    default:
                        break;
                }
                return throwError(error);
            })
        );
    }
}
