<dui-popover class="dui-popover-avatar">
    <ng-content popoverItem></ng-content>
    <div class="dui-popover-container">
        <div class="dui-popover-container__item">
            <div class="dui-popover-container__item__label">
                <span class="margin-right-5" [translate]="'users.dni'"></span>
            </div>
            <div class="dui-popover-container__item__value" (mouseenter)="showCopyIcon('nationalId')" (mouseleave)="hideCopyIcon('nationalId')">
                @if (nationalId) {
                    <span class="dui-popover-container__item__value__text" [textContent]="nationalId"></span>
                }
                @if (nationalId && isCopyIconVisible['nationalId']) {
                    <span class="dui-popover-container__item__value__icon icon nucleo icon-copy" (click)="copyToClipboard(nationalId)"></span>
                }
                @if (!nationalId) {
                    <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
                }
            </div>
        </div>
        <div class="dui-popover-container__item margin-top-15">
            <div class="dui-popover-container__item__label">
                <span class="margin-right-5" [translate]="'users.userId'"></span>
            </div>
            <div class="dui-popover-container__item__value" (mouseenter)="showCopyIcon('username')" (mouseleave)="hideCopyIcon('username')">
                @if (username) {
                    <span class="dui-popover-container__item__value__text" [textContent]="username"></span>
                }
                @if (username && isCopyIconVisible['username']) {
                    <span class="dui-popover-container__item__value__icon icon nucleo icon-copy" (click)="copyToClipboard(username)"></span>
                }
                @if (!username) {
                    <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
                }
            </div>
        </div>
        <div class="dui-popover-container__item margin-top-15">
            <div class="dui-popover-container__item__label">
                <span class="margin-right-5" [translate]="'users.internalId'"></span>
            </div>
            <div class="dui-popover-container__item__value" (mouseenter)="showCopyIcon('internalId')" (mouseleave)="hideCopyIcon('internalId')">
                @if (internalId) {
                    <span class="dui-popover-container__item__value__text" [textContent]="internalId"></span>
                }
                @if (internalId && isCopyIconVisible['internalId']) {
                    <span class="dui-popover-container__item__value__icon icon nucleo icon-copy" (click)="copyToClipboard(internalId)"></span>
                }
                @if (!internalId) {
                    <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
                }
            </div>
        </div>
        @if (largeMode) {
            <!-- Company -->
            <div class="dui-popover-container__item margin-top-15 min-width-350">
                <div class="dui-popover-container__item__label">
                    <span class="margin-right-5" [translate]="'users.company'"></span>
                </div>
                <div class="dui-popover-container__item__value">
                    @if (employee?.subsidiaryCompany?.name) {
                        <span class="dui-popover-container__item__value__text" [textContent]="employee?.subsidiaryCompany?.name"></span>
                    } @else {
                        <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
                    }
                </div>
            </div>
            <!-- Headquarter -->
            <div class="dui-popover-container__item margin-top-15">
                <div class="dui-popover-container__item__label">
                    <span class="margin-right-5" [translate]="'workplaces.grid.searchPlaceholder'"></span>
                </div>
                <div class="dui-popover-container__item__value">
                    @if (employee?.headquarter?.name) {
                        <span class="dui-popover-container__item__value__text" [textContent]="employee?.headquarter?.name"></span>
                    } @else {
                        <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
                    }
                </div>
            </div>
            <!-- Department -->
            <div class="dui-popover-container__item margin-top-15">
                <div class="dui-popover-container__item__label">
                    <span class="margin-right-5" [translate]="'users.department'"></span>
                </div>
                <div class="dui-popover-container__item__value">
                    @if (employee?.department?.name) {
                        <span class="dui-popover-container__item__value__text" [textContent]="employee?.department?.name"></span>
                    } @else {
                        <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
                    }
                </div>
            </div>
            <!-- Team -->
            <div class="dui-popover-container__item margin-top-15">
                <div class="dui-popover-container__item__label">
                    <span class="margin-right-5" [translate]="'users.team'"></span>
                </div>
                <div class="dui-popover-container__item__value">
                    @if (employee?.team?.name) {
                        <span class="dui-popover-container__item__value__text" [textContent]="employee?.team?.name"></span>
                    } @else {
                        <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
                    }
                </div>
            </div>
            <!-- Company position -->
            <div class="dui-popover-container__item margin-top-15">
                <div class="dui-popover-container__item__label">
                    <span class="margin-right-5" [translate]="'users.companyPositionShort'"></span>
                </div>
                <div class="dui-popover-container__item__value">
                    @if (employee?.companyPosition) {
                        <span class="dui-popover-container__item__value__text" [textContent]="employee?.companyPosition"></span>
                    } @else {
                        <span class="dui-popover-container__item__value__text--empty" [translate]="'global.form.notSet'"></span>
                    }
                </div>
            </div>
        }
    </div>
</dui-popover>
