import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CategoryService } from '../category/category.service';
import { RequestsService } from '../requests/requests.service';
import { cloneDeep, isEqual } from 'lodash';

@Component({
    templateUrl: 'create-motive-modal.component.html',
    styleUrls: ['./_create-motive-modal.component.scss']
})
export class CreateMotiveModalComponent implements OnInit {
    private userSession: any;
    private initialMotiveForm: MotiveForm;

    selectedLanguage: string;
    colorSelectorActive: boolean;
    availableColors: any;
    defaultLanguage: any;
    isFormDataChanged: boolean;
    isFormDataValid: boolean;

    motiveForm: MotiveForm;

    @ViewChild('motiveInAppInput') motiveInAppInput: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<CreateMotiveModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private categoryService: CategoryService,
        private requestsService: RequestsService
    ) {
        this.userSession = window['getUserSession']();

        this.defaultLanguage = this.userSession.companyDefaultLanguage;
        this.selectedLanguage = this.userSession.companyDefaultLanguage;

        this.isFormDataChanged = false;
        this.isFormDataValid = true;

        this.colorSelectorActive = false;
        this.availableColors = Object.values(this.categoryService.availableColors);

        this.motiveForm = {
            contents: {
                [this.defaultLanguage]: {
                    motiveInApp: '',
                    motive: ''
                }
            },
            color: this.availableColors[0]
        };

        this.initialMotiveForm = cloneDeep(this.motiveForm);
    }

    ngOnInit() {
        setTimeout(() => this.motiveInAppInput.nativeElement.focus(), 200);

        if (this.data.motiveId) {
            this.requestsService.getMotive(this.data.motiveId).subscribe(
                (response) => {
                    this.motiveForm = response;
                    this.initialMotiveForm = cloneDeep(this.motiveForm);
                    this.validateModel();
                },
                (error) => {
                    console.error('Error getting motive', error);
                }
            );
        }
    }

    private checkFormdataChanged(): void {
        this.isFormDataChanged = !isEqual(this.motiveForm, this.initialMotiveForm);
    }

    private checkFormdataValid(): void {
        const motive = this.motiveForm;
        let valid = !!motive.color && !!motive.contents;

        if (valid) {
            const languages = Object.keys(motive.contents);
            valid = languages.every((language) => {
                const motiveContent = motive.contents[language];
                return motiveContent.motive && motiveContent.motive.length > 1 && motiveContent.motiveInApp && motiveContent.motiveInApp.length > 1;
            });
        }

        this.isFormDataValid = valid;
    }

    validateModel(): void {
        this.checkFormdataChanged();
        this.checkFormdataValid();
    }

    toggleColorSelector() {
        this.colorSelectorActive = !this.colorSelectorActive;
    }

    selectColor(color: number) {
        this.motiveForm.color = color;
        this.colorSelectorActive = false;
        this.validateModel();
    }

    modalSaveOnEnter(event: KeyboardEvent): void {
        if (event.key === 'Enter' && this.isFormDataValid) {
            this.onAccept();
        }
    }

    onChange() {
        this.validateModel();
    }

    onAccept() {
        this.dialogRef.close({ accept: true, motive: this.motiveForm });
    }

    onCancel(): void {
        this.dialogRef.close({ cancel: true });
    }
}

interface MotiveContents {
    motiveInApp: string;
    motive: string;
}

interface MotiveForm {
    contents: {
        [language: string]: MotiveContents;
    };
    color: number;
}
