import { Component, Input } from '@angular/core';

@Component({
    selector: 'avatar-hexagon',
    templateUrl: './avatar-hexagon.component.html',
    styleUrls: ['./_avatar-hexagon.scss']
})
export class AvatarHexagonComponent {
    @Input() color?: any;
    @Input() initials: boolean;
}
