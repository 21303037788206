<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="'model.segment.newGroup' | translate"></h3>
</div>
<div class="modal-body">
    <!-- Segment name -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'model.segment.name' | translate"></span>
        </div>
        <div class="group-form__data">
            <input
                #segmentName
                id="segmentName"
                name="segmentName"
                data-cy="segment-name"
                type="text"
                class="input"
                [ngClass]="{'input--error': isNameDuplicated}"
                maxlength="64"
                [(ngModel)]="segmentForm.name"
                (ngModelChange)="onChange()"
                (keyup)="isNameDuplicated = false"
                (keyup.enter)="onEnterPressed()"
                [placeholder]="'model.segment.nameplaceholder' | translate" />
        </div>
    </div>
    <!-- Segment description -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'model.segment.description' | translate"></span>
            <span class="group-form__label__text group-form__label__text--optional" [textContent]="'global.form.optional' | translate"></span>
        </div>
        <div class="group-form__data">
            <textarea
                id="segmentDescription"
                class="textarea-autogrow"
                [(ngModel)]="segmentForm.description"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                [placeholder]="'model.segment.descriptionplaceholder' | translate"
                maxlength="140">
            </textarea>
        </div>
    </div>
</div>
<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'create-segment'"
        [classes]="'button--secondary margin-right-10'"
        [text]="'model.segment.createSegment'"
        (action)="onAccept()"
        [isValid]="isFormValid">
    </loading-button>
    <button id="cancel-segment" type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>
