import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

import { Constants } from '../../constants';
import { DocumentsHistoryService } from '../documents-history/documents-history.service';

@Component({
    selector: 'document-transaction-detail',
    templateUrl: 'document-transaction-detail.component.html',
    styleUrls: ['_document-transaction-detail.component.scss']
})
export class DocumentTransactionDetailComponent implements OnInit {
    private _route: any;
    private transactionId: number;

    public DOCUMENT_TRANSACTION_STATE = Constants.DOCUMENT_TRANSACTION_STATE;
    public PAGE_SIZE: number = Constants.PAGE_SIZE;

    public numResults: number = 0;
    public transactions: any = {};

    public DOCUMENT_SUBMISSION_KIND: { [key: string]: { id: number; color: string; text: string } } = {
        PAYROLL: { id: 1, color: '#D6B665', text: this.translate.instant('documents.import.detail.payroll') },
        EXTRA: { id: 2, color: '#D68D65', text: this.translate.instant('documents.import.detail.extraPayroll') },
        CERT: { id: 3, color: '#A465D6', text: this.translate.instant('documents.import.detail.cert') },
        TIME_REGISTRATION: { id: 4, color: '#E582EE', text: this.translate.instant('documents.import.detail.timeRegistration') },
        OTHER: { id: 5, color: '#B3B3B3', text: this.translate.instant('documents.import.detail.other') },
        REMOTE_SIGNATURE: { id: 6, color: '#415EA8', text: this.translate.instant('documents.import.detail.remoteSignature') },
        ADVANCE: { id: 7, color: '#A4D765', text: this.translate.instant('documents.import.detail.advance') },
        AUTHORIZATION: { id: 8, color: '#63C88C', text: this.translate.instant('documents.import.detail.authorization') },
        EMPLOYMENT_CONTRACT: { id: 9, color: '#54CDCD', text: this.translate.instant('documents.import.detail.contracts') },
        HANDBOOKS: { id: 10, color: '#51B3EA', text: this.translate.instant('documents.import.detail.handbooks') },
        MIXED: { id: 100, color: '#D9D9D9', text: this.translate.instant('documents.import.detail.mixed') }
    };

    public transactionKind: any;

    public errorsDataGrid: any = {};
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };
    public gridSort: SortDescriptor[];

    @ViewChild('errorsGrid') errorsGrid: GridComponent;

    constructor(
        private location: Location,
        private upgrade: UpgradeModule,
        private translate: TranslateService,
        private documentsHistoryService: DocumentsHistoryService
    ) {
        this._route = this.upgrade.$injector.get('$route');
        this.transactionId = this._route.current.params.id;
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    exportAsExcel(): void {
        this.errorsGrid.saveAsExcel();
    }

    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        if (!!sort[0].dir) {
            this.errorsDataGrid.reverse();
        }
    }

    getTransactionDetail(): { id: number; color: string; text: string } {
        const foundDetail = Object.values(this.DOCUMENT_SUBMISSION_KIND).find((d) => d.id === this.transactions.kind);
        return foundDetail;
    }

    ngOnInit() {
        this.documentsHistoryService.findOne(this.transactionId).subscribe((response) => {
            this.transactions = response;
            this.errorsDataGrid = response.errors;
            this.numResults = response.errorEntries;
            this.transactionKind = this.transactions.kind ? this.getTransactionDetail() : null;
        });
    }
}
