<div class="main-container">
    <div class="main-container__header" style="min-width: 891px">
        <div class="flex direction-column width-100-percent" spellcheck="false">
            <ul class="breadcrumb">
                <li>
                    <a href="/global-settings" [textContent]="'global.navbar.globalSettings' | translate"></a>
                </li>
            </ul>
            <h2 [translate]="'timeTracking.mainTitle'"></h2>
        </div>

        <div class="flex justify-self-end">
            @if (selectedTab === TIME_TRACKING_TABS.SCHEDULES) {
                <button id="new-schedule" data-cy="new-schedule" class="button button--secondary button--small" (click)="createSchedule()">
                    <span class="button__icon icon icon-add"></span>
                    <span class="button__label" [translate]="'timeTracking.newSchedule'"></span>
                </button>
            }
        </div>
    </div>

    <div class="tabs-language-container" style="min-width: 891px">
        <div class="tab-nav tab-nav--inline" style="width: 100%">
            <!-- Schedules tab -->
            <span
                id="schedules-tab"
                data-cy="schedules-tab"
                class="tab-nav__item"
                [ngClass]="{ 'tab-nav__item--selected': selectedTab === TIME_TRACKING_TABS.SCHEDULES }"
                [translate]="'timeTracking.tabs.schedules'"
                (click)="loadTabContent(TIME_TRACKING_TABS.SCHEDULES)">
            </span>

            <!-- Global tab -->
            <span
                id="global-tab"
                data-cy="global-tab"
                class="tab-nav__item"
                [ngClass]="{ 'tab-nav__item--selected': selectedTab === TIME_TRACKING_TABS.GLOBAL }"
                [translate]="'timeTracking.tabs.global'"
                (click)="loadTabContent(TIME_TRACKING_TABS.GLOBAL)">
            </span>

            <!-- Notifications tab -->
            <span
                id="notifications-tab"
                data-cy="notifications-tab"
                class="tab-nav__item"
                [ngClass]="{ 'tab-nav__item--selected': selectedTab === TIME_TRACKING_TABS.NOTIFICATIONS }"
                [translate]="'timeTracking.tabs.notifications'"
                (click)="loadTabContent(TIME_TRACKING_TABS.NOTIFICATIONS)">
            </span>
        </div>
    </div>

    <section class="margin-top-20" style="min-width: 891px;">
        @if (selectedTab === TIME_TRACKING_TABS.SCHEDULES) {
            <time-tracking-schedules></time-tracking-schedules>
        }

        @if (selectedTab === TIME_TRACKING_TABS.GLOBAL) {
            <time-tracking-global></time-tracking-global>
        }

        @if (selectedTab === TIME_TRACKING_TABS.NOTIFICATIONS) {
            <time-tracking-notifications></time-tracking-notifications>
        }
    </section>
</div>
