<div class="main-container" [ngClass]="{'main-container--with-preview': selectedRow.length > 0}">
    <div class="main-container__header">
        <div class="flex align-items-start justify-space-between width-100-percent">
            <loading-button
                [buttonId]="'go-back'"
                [classes]="'button button--small button--only-icon'"
                kendoTooltip
                filter="button"
                offset="10"
                [iconClass]="'icon-back'"
                (action)="goBack()"
                [title]="'global.form.goBack' | translate"
                [isValid]="true">
            </loading-button>
            <!-- <loading-button [classes]="'button button--small'" [iconClass]="'icon-export'" [text]="'timeTracking.list.downloadReport'"
      [isValid]="true">
    </loading-button> -->
        </div>
    </div>
    <div class="main-container__content">
        <div class="main-container--full-width">
            <!-- Employee header -->
            <div class="employee-header margin-bottom-8">
                <div class="employee-header__userdata margin-right-24">
                    <avatar
                        [name]="userRecordsForm.user?.name"
                        [surname]="userRecordsForm.user?.surname"
                        [large]="true"
                        [thumbnails]="{thumbnail_S: userRecordsForm.user?.avatarThumbnailSUrl, thumbnail_M: userRecordsForm.user?.avatarThumbnailMUrl }">
                    </avatar>
                    <div class="flex direction-column margin-left-24">
                        <h3 class="font-size-22" [textContent]="userRecordsForm.user?.fullName"></h3>
                        <span class="font-size-13">{{startDate}} - {{endDate}}</span>
                    </div>
                </div>
            </div>
            <!-- Employee time records -->
            @if (dayRecordsDatagrid?.total > 0) {
                <kendo-grid
                    #userTimeRecordsGrid
                    [data]="dayRecordsDatagrid"
                    class="master-grid user-select-none margin-bottom-40"
                    [sortable]="true"
                    [sort]="gridSort"
                    (sortChange)="onSortChange($event)"
                    (cellClick)="onCellClick()"
                    [navigable]="true"
                    [resizable]="false"
                    [loading]="isSyncing"
                    [pageable]="totalPages > 1 ? gridPagerSettings : false"
                    (pageChange)="onPageChange($event)"
                    [skip]="dataPagingOpts.skip"
                    [pageSize]="dataPagingOpts.size"
                    [selectable]="{ checkboxOnly: false, mode: 'single' }"
                    kendoGridSelectBy
                    [(selectedKeys)]="selectedRow"
                    (selectionChange)="onSelectRecord($event)">
                    <kendo-grid-messages
                        [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                        [pagerOf]="'global.lists.pagerOf' | translate"
                        [pagerItems]="'global.lists.pagerItems' | translate">
                    </kendo-grid-messages>
                    <!-- Day record state -->
                    <kendo-grid-column [width]="6" [class]="'cursor-default padding-0'" [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span
                                class="flex width-100-percent"
                                style="height: 67px"
                                [ngClass]="{'bg-pending-color': !!dataItem.remainingSeconds && !!dataItem.workedSeconds && (dataItem.workedSeconds < dataItem.remainingSeconds),
                                        'bg-rejected-color': !!dataItem.remainingSeconds && !dataItem.workedSeconds}"></span>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Day Record date -->
                    <kendo-grid-column field="date" class="cursor-default" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center user-select-none cursor-pointer">
                                <span [textContent]="'documents.history.date' | translate"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span
                                    class="truncate"
                                    [ngClass]="{'opacity-50': !!dataItem.publicHoliday || !dataItem.workday || dataItem.fullDayAbsence || dataItem.fullDayHoliday}"
                                    [textContent]="getFormattedDate(dataItem.date)"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Total hours -->
                    <kendo-grid-column [sortable]="false" [style]="{'padding': '.929em 1.1em'}">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center">
                                <span [translate]="'timeTracking.list.grid.scheduledHours'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                @if (
                                    dataItem.workdaySeconds && !dataItem.publicHoliday && dataItem.workday && !dataItem.fullDayAbsence && !dataItem.fullDayHoliday
                                ) {
                                    <span [textContent]="dataItem.workdaySeconds | hoursMinutes:{inputKind: 'seconds', showSign: false}"></span>
                                }
                                @if (
                                    !dataItem.workdaySeconds || !!dataItem.publicHoliday || !dataItem.workday || dataItem.fullDayAbsence || dataItem.fullDayHoliday
                                ) {
                                    <span>-</span>
                                }
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Worked hours -->
                    <kendo-grid-column [sortable]="false" [style]="{'padding': '.929em .929em'}">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center">
                                <span [translate]="'timeTracking.list.grid.workedHours'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex direction-column">
                                @if (dataItem.workedSeconds || (!dataItem.workedSeconds && dataItem.remainingSeconds)) {
                                    <span [textContent]="dataItem.workedSeconds | hoursMinutes:{inputKind: 'seconds', showSign: false}"></span>
                                }
                                @if (
                                    dataItem.remainingSeconds || (dataItem.workedSeconds && (dataItem.fullDayHoliday || dataItem.fullDayAbsence || !dataItem.workday))
                                ) {
                                    <div class="flex align-items-center relative" [style.maxWidth.px]="105">
                                        <kendo-progressbar
                                            class="workedTime-linechart"
                                            [label]="false"
                                            [ngClass]="{'overworked': (dataItem.workedSeconds > dataItem.remainingSeconds)  && dataItem.workday && !dataItem.fullDayHoliday && !dataItem.fullDayAbsence}"
                                            [max]="dataItem.remainingSeconds"
                                            [value]="dataItem.workedSeconds">
                                        </kendo-progressbar>
                                        @if (
                                            (dataItem.workedSeconds > dataItem.remainingSeconds) && dataItem.workday && !dataItem.fullDayHoliday && !dataItem.fullDayAbsence
                                        ) {
                                            <kendo-progressbar
                                                class="overworkedTime-linechart"
                                                [label]="false"
                                                [style.width.px]="dataItem.workedSeconds - dataItem.remainingSeconds >= 2400 ? 105 : dataItem.workedSeconds - dataItem.remainingSeconds >= 1200 ? 100 : 95"
                                                [max]="1"
                                                [value]="1">
                                            </kendo-progressbar>
                                        }
                                    </div>
                                }
                                @if (!dataItem.remainingSeconds && !dataItem.workedSeconds) {
                                    <span>-</span>
                                }
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Balance -->
                    <kendo-grid-column [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center padding-left-8">
                                <span [translate]="'timeTracking.list.grid.balance'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                @if (dataItem.workedSeconds > 0 && (dataItem.workedSeconds !== dataItem.remainingSeconds)) {
                                    <span
                                        [textContent]="(dataItem.workedSeconds - dataItem.remainingSeconds) | hoursMinutes:{inputKind: 'seconds', showSign: true}"></span>
                                }
                                @if (!dataItem.workedSeconds || (dataItem.workedSeconds === dataItem.remainingSeconds)) {
                                    <span [textContent]="'-'"></span>
                                }
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Entry time column-->
                    <kendo-grid-column [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center padding-left-8">
                                <span [innerHTML]="'timeTracking.list.grid.entry' | translate"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span [textContent]="dataItem.firstEntryTime || '-'"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Departure time column-->
                    <kendo-grid-column [sortable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center padding-left-8">
                                <span [innerHTML]="'timeTracking.list.grid.departure' | translate"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span [textContent]="dataItem.lastEntryTime || '-'"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Record Kind -->
                    <kendo-grid-column [width]="145" [sortable]="false" [style]="{'padding': '0.929em 0.5em'}">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center">
                                <span [translate]="'model.users.documents.kind'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            @if (dataItem.workday && !dataItem.fullDayHoliday && !dataItem.fullDayAbsence && !dataItem.publicHoliday) {
                                <div class="multiple-kinds" kendoTooltip filter="span.label-kind__text" position="top">
                                    @if (dataItem.partialHoliday) {
                                        <div class="label-kind" [ngStyle]="{'background-color': utils.decimalToHex(specificLabelKinds.holiday)}">
                                            <span
                                                class="label-kind__text truncate"
                                                [translate]="'timeTracking.list.grid.holidayTag'"
                                                [title]="(dataItem.kinds?.length > 0 ? 'timeTracking.list.grid.holidayTag' : '') | translate"></span>
                                        </div>
                                    }
                                    @if (dataItem.partialAbsence) {
                                        <div class="label-kind" [ngStyle]="{'background-color': utils.decimalToHex(specificLabelKinds.absence)}">
                                            <span
                                                class="label-kind__text truncate"
                                                [translate]="'timeTracking.list.grid.absenceTag'"
                                                [title]="(dataItem.kinds?.length > 0 ? 'timeTracking.list.grid.absenceTag' : '') | translate"></span>
                                        </div>
                                    }
                                    @for (kind of dataItem.kinds; track kind) {
                                        <div class="label-kind" [ngStyle]="{'background-color': utils.decimalToHex(kind.color)}">
                                            <span class="label-kind__text truncate" [textContent]="kind.name" [title]="kind.name"></span>
                                        </div>
                                    }
                                </div>
                            }
                            @if (!dataItem.workday && !dataItem.publicHoliday) {
                                <div class="label-kind" [ngStyle]="{'background-color': utils.decimalToHex(specificLabelKinds.notWorkingDay)}">
                                    <span class="label-kind__text truncate" [translate]="'timeTracking.list.grid.noWorkdayTag'"></span>
                                </div>
                            }
                            @if (!!dataItem.publicHoliday) {
                                <div class="label-kind" [ngStyle]="{'background-color': utils.decimalToHex(specificLabelKinds.publicHoliday)}">
                                    <span class="label-kind__text truncate" [textContent]="dataItem.publicHoliday" [title]="dataItem.publicHoliday"></span>
                                </div>
                            }
                            @if (dataItem.fullDayAbsence || dataItem.fullDayHoliday) {
                                <div
                                    class="label-kind"
                                    [ngStyle]="{'background-color': utils.decimalToHex(dataItem.fullDayHoliday ? specificLabelKinds.holiday : specificLabelKinds.absence)}">
                                    <span
                                        class="label-kind__text truncate"
                                        [translate]="dataItem.fullDayHoliday ? 'timeTracking.list.grid.holidayTag' : 'timeTracking.list.grid.absenceTag'"></span>
                                </div>
                            }
                        </ng-template>
                    </kendo-grid-column>
                    <!-- CTA buttons column -->
                    <kendo-grid-command-column title="" [width]="55" [style]="{ 'text-align': 'end' }">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                                @if (getItemDate(dataItem.date) <= today) {
                                    <a class="k-button k-master-button" [title]="'conversations.detail' | translate">
                                        <span class="icon icon-edit"></span>
                                    </a>
                                }
                            </div>
                        </ng-template>
                    </kendo-grid-command-column>
                    <ng-template kendoGridNoRecordsTemplate></ng-template>
                </kendo-grid>
            }

            <!-- Skeleton time records list grid -->
            @if (dayRecordsDatagrid?.total == 0 && isSyncing) {
                <div>
                    <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
                        <kendo-grid-column [width]="6"></kendo-grid-column>
                        <!-- Day Record date -->
                        <kendo-grid-column class="cursor-default">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [translate]="'documents.history.date'"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Scheduled hours -->
                        <kendo-grid-column [style]="{'padding': '.929em 1.1em'}">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [translate]="'timeTracking.list.grid.scheduledHours'"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="30" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Worked hours -->
                        <kendo-grid-column [style]="{'padding': '.929em .929em'}">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [translate]="'timeTracking.list.grid.workedHours'"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate>
                                <div class="flex direction-column">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="30" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                    <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="12" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Balance -->
                        <kendo-grid-column>
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center padding-left-8">
                                    <span [translate]="'timeTracking.list.grid.balance'"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="30" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Entry column-->
                        <kendo-grid-column>
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center padding-left-8">
                                    <span [innerHTML]="'timeTracking.list.grid.entry' | translate"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="30" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Departure column-->
                        <kendo-grid-column>
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center padding-left-8">
                                    <span [innerHTML]="'timeTracking.list.grid.departure' | translate"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="30" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Record Kind -->
                        <kendo-grid-column [width]="145">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [translate]="'model.users.documents.kind'"></span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- CTA buttons column -->
                        <kendo-grid-command-column [width]="55"></kendo-grid-command-column>
                    </kendo-grid>
                </div>
            }
            @if (dayRecordsDatagrid.total == 0 && !isSyncing) {
                <no-results [imageTitle]="'timeTracking.list.grid.noResultsTitle'" [description]="'timeTracking.list.grid.noResultsText'"> </no-results>
            }
        </div>
    </div>
</div>
<time-record-edit [(selectedRow)]="selectedRow" [user]="userRecordsForm.user" [selectedDay]="selectedDay" (selectedDayChange)="onDayRecordsChanges()">
</time-record-edit>
