import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';

@Component({
    selector: 'requests-settings',
    templateUrl: 'requests-settings.component.html',
    styleUrls: ['./_requests-settings.component.scss']
})
export class RequestsSettingsComponent implements OnInit {
    private _route: any;

    REQUESTS_SETTINGS_TABS: {
        MOTIVES: number;
        FEEDBACK_TRAYS: number;
    };
    selectedTab: number;
    selectedTray: any;
    addNewMotive: boolean;
    cloneTray: boolean;
    isTraySelected: boolean;

    previewState: { opened: boolean };
    isMotivesUpdated: boolean;

    constructor(
        private location: Location,
        private upgrade: UpgradeModule
    ) {
        this._route = this.upgrade.$injector.get('$route');
        this.REQUESTS_SETTINGS_TABS = Constants.REQUESTS_SETTINGS_TABS;
        this.selectedTab = this._route.current.locals.selectedTab;
        this.previewState = { opened: false };
        this.addNewMotive = false;
        this.cloneTray = false;
        this.isTraySelected = false;
        this.isMotivesUpdated = false;
    }

    ngOnInit() {}

    createNewMotive(): void {
        this.addNewMotive = true;
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    cloneSelectedTray(): void {
        this.cloneTray = true;
    }

    onTraySelected(isSelected: boolean) {
        this.isTraySelected = isSelected;
    }

    loadTabContent(tab: number): void {
        const tabNameMap: { [key: number]: string } = {
            [this.REQUESTS_SETTINGS_TABS.MOTIVES]: 'motives',
            [this.REQUESTS_SETTINGS_TABS.FEEDBACK_TRAYS]: 'feedback-trays'
        };

        if (this.selectedTab === tab) {
            return;
        }

        this.selectedTab = tab;
        const tabName = tabNameMap[tab];

        if (tabName) {
            this._route.updateParams({ tabName });
        }
    }
}
