<div class="main-container">
    <div class="main-container__header">
        <h2 translate="global.navbar.globalSettings"></h2>
    </div>

    <div class="main-container__content">
        <div class="main-container--normal-width">
            @if (!!companyBrand) {
                <div class="setting-header margin-top-30">
                    <p class="setting-header__title" [textContent]="companyBrand"></p>
                </div>
            }
            <div class="setting-menu margin-top-20">
                @for (item of settingsMenu; track item) {
                    @if (isItemAvailable(item)) {
                        <div class="setting-menu__item" (click)="navigateTo(item.url)">
                            <span class="setting-menu__icon icon {{item.icon}}"></span>
                            <div class="flex direction-column justify-center margin-left-20">
                                <p class="setting-menu__title" [textContent]="item.title | translate"></p>
                                <p class="setting-menu__text" [textContent]="item.description | translate"></p>
                            </div>
                            @if (false) {
                                <span class="setting-menu__resume" [textContent]="item.activity"></span>
                            }
                        </div>
                    }
                }
            </div>
        </div>
    </div>
</div>
