<div class="dialog-modal-header">
    <div class="flex align-items-center">
        <h3 [textContent]="data.title | translate"></h3>
    </div>
    <button type="button" class="button button--only-icon dialog-modal__button-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
</div>
<div class="dialog-modal-body text-center">
    <div [innerHTML]="data.message | translate:data.messageParam"></div>
    @if (data.skipModalAlias) {
    <div class="checkbox text-center margin-top-20">
        <label class="flex align-items-center justify-content-center">
            <input name="skip-modal" type="checkbox" [(ngModel)]="skipModalValue" />
            <span class="margin-left-5 non-selectable black-60" [translate]="'global.modal.skipMessage'"></span>
        </label>
    </div>
    }
</div>
<div class="modal-footer flex justify-end">
    <button id="confirmation-modal-accept-button" class="button" [ngClass]="data.acceptButtonTextClass" (click)="onAccept()" cdkFocusInitial>
        <span class="button__label" [textContent]="data.acceptButtonText | translate"></span>
    </button>
    @if (!data.hideCancelButton) {
    <div class="margin-left-10">
        <button id="confirmation-modal-cancel-button" class="button" (click)="onCancel()">
            <span class="button__label" [textContent]="data.cancelButtonText | translate"></span>
        </button>
    </div>
    }
</div>
