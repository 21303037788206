<div class="boxes-user-categories-container">
    <div class="group">
        @if (availableFilekinds && availableFilekinds.length > 0) {
            <a
                [routerLink]=""
                queryParamsHandling="preserve"
                class="remove-all"
                [translate]="'segments.addAll'"
                (click)="addAllFilteredAvailableFilekinds()"></a>
        }
        @if (!availableFilekinds || availableFilekinds.length > 0) {
            <ul class="box-groups provider relative-to-view" id="available-filekinds-container">
                @for (filekind of availableFilekinds; track filekind) {
                    <li
                        class="category-item non-selectable text-left"
                        attr.data-name="{{filekind.name}}"
                        [ngClass]="{'added': filekind.added, 'selected': filekind.selected }"
                        (click)="selectFilekindFromAvailables(filekind, $event)"
                        (dblclick)="doubleClickedFilekind(filekind)">
                        @if (filekind.added) {
                            <span class="icon icon-sm icon-accept-1 black"></span>
                        }
                        <span class="icon icon-tag-fill-s margin-right-10" [ngStyle]="{'color': filekindColor(filekind.color)}"></span>
                        <span [textContent]="filekind.name"></span>
                    </li>
                }
            </ul>
        }
        @if (availableFilekinds?.length == 0) {
            <div class="box-groups flex justify-center align-items-center relative-to-view">
                @if (!filterAvailable && isSyncing === false) {
                    <no-results [imageTitle]="'model.segment.noResultsTitle'"></no-results>
                }
                @if (filterAvailable && isSyncing === false) {
                    <no-search-results></no-search-results>
                }
            </div>
        }
    </div>

    <div
        id="add-selected-items-button"
        class="icon icon-arrow-fill"
        [ngClass]="{'active': selectedFilekinds.length > 0 }"
        (click)="addSelectedFilekinds()"></div>

    <div class="group">
        @if (addedFilekinds?.length > 0) {
            <a
                [routerLink]=""
                queryParamsHandling="preserve"
                class="remove-all"
                [translate]="'segments.removeAll'"
                (click)="removeAllFilteredAddedFilekinds()"></a>
        }
        @if (filteredAddedFilekinds?.length > 0) {
            <ul id="added-filekinds-container" class="box-groups relative-to-view">
                @for (addedFilekind of filteredAddedFilekinds; track trackByFn($index, addedFilekind)) {
                    <li class="category-item text-left">
                        <span class="icon icon-tag-fill-s margin-right-10" [ngStyle]="{'color': filekindColor(addedFilekind.color)}"></span>
                        <span [textContent]="addedFilekind.name"></span>
                        <div class="icon icon-remove" (click)="removeAddedItemFromList(addedFilekind)"></div>
                    </li>
                }
            </ul>
        }
        @if (filteredAddedFilekinds?.length == 0) {
            <div class="box-groups flex justify-center align-items-center relative-to-view">
                @if (!filterAdded && isSyncing === false) {
                    <no-results></no-results>
                }
                @if (filterAdded && isSyncing === false) {
                    <no-search-results></no-search-results>
                }
            </div>
        }
    </div>
</div>
