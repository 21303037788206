<div class="main-container__content">
    <div class="main-container--full-width">
        <!-- Free disposal days -->
        @for (group of schemaAbsencesForm.content; track group) {
            @if (group.kind === ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS && group.enabled) {
                <ng-container [ngTemplateOutlet]="absencesMotivesList" [ngTemplateOutletContext]="{$implicit:group}"></ng-container>
            }
        } @empty {
            <!-- A template when there is no data can be displayed here -->
        }

        <!-- Rest of absences motives -->
        @for (group of schemaAbsencesForm.content | sort: 'kind'; track group) {
            @if (group.kind !== ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS) {
                <ng-container [ngTemplateOutlet]="absencesMotivesList" [ngTemplateOutletContext]="{$implicit:group}"></ng-container>
            }
        } @empty {
            <!-- A template when there is no data can be displayed here -->
        }

        <ng-template #absencesMotivesList let-group>
            <!-- Absences group title -->
            <h3 class="margin-top-40 margin-bottom-20" [textContent]="group?.contents[group?.language]"></h3>
            <!-- Absence list -->
            <div class="table">
                <!-- Header -->
                <section class="table__head">
                    <div class="table__head__row">
                        <div class="table__head__cell max-width-100"></div>
                        <div class="table__head__cell" [translate]="'model.absences.denomination'"></div>
                        <div class="table__head__cell" [translate]="'model.absences.comments'"></div>
                        <div class="table__head__cell table__head__cell--justify-center max-width-150" [translate]="'model.absences.requireNote'"></div>
                        <div
                            class="table__head__cell table__head__cell--justify-center max-width-150"
                            [translate]="group.kind !== ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS ? 'model.absences.remunerable' : ''"></div>
                        <div class="table__head__cell max-width-100"></div>
                    </div>
                </section>
                <!-- Body -->
                <section class="table__body">
                    @for (motive of group.motives | sort: 'name'; track motive) {
                        <div class="table__body__row">
                            <div class="table__body__cell max-width-100">
                                @if (group.kind !== ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS) {
                                    <label class="toggle__container">
                                        <div class="toggle toggle--small" [ngClass]="{'toggle--active': motive?.enabled}">
                                            <span class="toggle__indicator"></span>
                                        </div>
                                        <input
                                            class="toggle__input"
                                            id="is-motive-active"
                                            name="isMotiveActive"
                                            type="checkbox"
                                            value=""
                                            (click)="toggleActiveMotive(group,motive)" />
                                    </label>
                                }
                            </div>
                            <div class="table__body__cell">
                                <span
                                    class="table__body__content table__body__content--truncate black-85"
                                    [textContent]="motive.contents[selectedLanguage]?.name"></span>
                            </div>
                            <div class="table__body__cell">
                                <span
                                    class="table__body__content table__body__content--truncate"
                                    [textContent]="motive.contents[selectedLanguage]?.comments"></span>
                            </div>
                            <div class="table__body__cell table__body__cell--justify-center max-width-150">
                                <span [translate]="motive.requiresNote ? 'global.toggle.on' : 'global.toggle.off'"></span>
                            </div>
                            <div class="table__body__cell table__body__cell--justify-center max-width-150">
                                @if (group.kind !== ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS) {
                                    <span [translate]="motive?.remunerable ? 'global.toggle.on' : 'global.toggle.off'"></span>
                                }
                            </div>
                            <div class="table__body__cell table__body__cell--icons table__body__cell max-width-100">
                                <div class="table__body__content table__body__content--icons visible-on-hover" kendoTooltip filter="span.icon-edit">
                                    <span
                                        class="table__body__icon icon icon-edit contenteditable"
                                        [title]="'global.form.edit' | translate"
                                        (click)="updateAbsenceMotive(group, motive)">
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                </section>
                @if (group.kind !== ABSENCE_MOTIVE_GROUPS.PERSONAL_DAYS) {
                    <div class="margin-top-20">
                        <span
                            [id]="'new-motive-group-' + group.kind"
                            class="link text-decoration-none cursor-pointer"
                            [translate]="'global.form.addNew'"
                            (click)="updateAbsenceMotive(group)">
                        </span>
                    </div>
                }
            </div>
        </ng-template>
    </div>
</div>
