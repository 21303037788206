<div class="main-container__content">
    <div class="main-container--full-width">
        <div class="info-advice filter-container">
            <p [translate]="'timeTracking.schedules.advice'"></p>
        </div>
        <!-- Filters section -->
        <section class="filter-container">
            <div class="flex">
                <div class="flex direction-column justify-content-end">
                    <!-- Schedules Actives / Archived button bar -->
                    <div class="button-bar">
                        <button class="button button--small" [ngClass]="{'button--selected' : dataFilterOpts.active}" (click)="getSchedulesByIsActive(true)">
                            <span
                                class="button__label"
                                [translate]="'global.listFilters.activeSelection'"
                                [translateParams]="{gender: 'male', number: 'plural'}"></span>
                        </button>
                        <button class="button button--small" [ngClass]="{'button--selected' : !dataFilterOpts.active}" (click)="getSchedulesByIsActive(false)">
                            <span
                                class="button__label"
                                [translate]="'global.listFilters.archivedSelection'"
                                [translateParams]="{gender: 'male', number: 'plural'}"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="search-filter-container align-items-center">
                <input
                    type="text"
                    class="input input--search"
                    maxlength="1024"
                    [placeholder]="'global.form.search' | translate"
                    [(ngModel)]="dataFilterOpts.name"
                    (input)="listItemsSearch()"
                    ngModelOptions="{ debounce: 200 }"
                    [ngClass]="dataFilterOpts.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'" />
                <div class="icon icon-remove" (click)="clearSearchInput()"></div>
            </div>
        </section>

        @if (schedulesDatagrid?.total > 0) {
            <kendo-grid
                #schedulesGrid
                [data]="schedulesDatagrid"
                class="master-grid user-select-none margin-bottom-40"
                [sortable]="true"
                [sort]="gridSort"
                (sortChange)="onSortChange($event)"
                (cellClick)="onCellClick($event)"
                (dblclick)="onDblClick($event)"
                [pageable]="gridPagerSettings"
                (pageChange)="onPageChange($event)"
                [skip]="dataPagingOpts.skip"
                [pageSize]="dataPagingOpts.size"
                [navigable]="true"
                [resizable]="false"
                [selectable]="false"
                [loading]="isSyncing">
                <kendo-grid-messages
                    [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                    [pagerOf]="'global.lists.pagerOf' | translate"
                    [pagerItems]="'global.lists.pagerItems' | translate">
                </kendo-grid-messages>
                <!-- Schedule name -->
                <kendo-grid-column field="name" [headerStyle]="{'min-width': '45%'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center cursor-pointer">
                            <span class="truncate" [translate]="'timeTracking.schedules.grid.scheduleName'"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center">
                            <span class="truncate cursor-default" [textContent]="dataItem.name"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- Schedule flexible -->
                <kendo-grid-column [sortable]="false" [width]="150" [headerStyle]="{'cursor': 'default !important', 'pointer-events': 'none'}">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center">
                            @if (dataItem.flexibleSchedule) {
                                <span class="schedule-flexible truncate cursor-default" [translate]="'timeTracking.schedules.grid.flexible'"></span>
                            }
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- Schedule total hours per week -->
                <kendo-grid-column [sortable]="false" [width]="150" [headerStyle]="{'cursor': 'default !important', 'pointer-events': 'none'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center justify-content-center margin-left-20">
                            <span [translate]="'timeTracking.schedules.grid.totalHours'"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center justify-content-center">
                            <span class="cursor-default" [textContent]="dataItem.totalHours + 'h ' + (dataItem.minutes ? dataItem.minutes + 'm' : '' )"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- Schedules employees count -->
                <kendo-grid-column
                    field="employeeCount"
                    [width]="150"
                    [sortable]="false"
                    [headerStyle]="{'cursor': 'default !important', 'pointer-events': 'none'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center justify-content-center margin-left-15">
                            <span [translate]="'timeTracking.schedules.grid.employees'"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center justify-content-center">
                            <span class="cursor-default" [textContent]="dataItem.employeeCount"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- CTA buttons column -->
                <kendo-grid-command-column title="" width="136" [style]="{ 'text-align': 'end' }">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                            <a class="k-button k-master-button" (click)="updateSchedule(dataItem)" [title]="'global.form.edit' | translate">
                                <span class="icon icon-edit"></span>
                            </a>
                            @if (!dataItem.deletable || !dataItem.active) {
                                <a
                                    class="k-button k-master-button"
                                    (click)="archiveOrActivateSchedule(dataItem)"
                                    [title]="(dataItem.active ? 'global.form.archive' : 'global.form.activate') | translate">
                                    <span class="icon" [ngClass]="dataItem.active ? 'icon-archive' : 'icon-unarchive'"></span>
                                </a>
                            }
                            @if (dataItem.deletable && dataItem.active) {
                                <a class="k-button k-master-button" (click)="deleteSchedule(dataItem)" [title]="'global.form.delete' | translate">
                                    <span class="icon icon-delete"></span>
                                </a>
                            }
                        </div>
                    </ng-template>
                </kendo-grid-command-column>
                <ng-template kendoGridNoRecordsTemplate></ng-template>
                <!-- Custom Pager -->
                <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                    <article class="dui-grid-pager">
                        <kendo-pager-info></kendo-pager-info>
                        @if (schedulesDatagrid.total > 10) {
                            <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                                <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                            </article>
                            <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                        }
                    </article>
                </ng-template>
            </kendo-grid>
        }

        <!-- Skeleton schedules grid -->
        @if (schedulesDatagrid?.total == 0 && isSyncing) {
            <div>
                <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid org-grid" [rowClass]="skeletonRowCallback">
                    <!-- Schedule name -->
                    <kendo-grid-column>
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center cursor-pointer">
                                <span class="truncate" [translate]="'timeTracking.schedules.grid.scheduleName'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <kendo-skeleton shape="text" animation="pulse" [width]="300" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Schedule flexible -->
                    <kendo-grid-column [width]="150"></kendo-grid-column>
                    <!-- Schedule total hours per week -->
                    <kendo-grid-column [width]="150">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center justify-content-center">
                                <span [translate]="'timeTracking.schedules.grid.totalHours'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center justify-content-center">
                                <kendo-skeleton shape="text" animation="pulse" [width]="20" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Schedules employees count -->
                    <kendo-grid-column [width]="150">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center justify-content-center">
                                <span [translate]="'timeTracking.schedules.grid.employees'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center justify-content-center">
                                <kendo-skeleton shape="text" animation="pulse" [width]="20" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- CTA column -->
                    <kendo-grid-command-column [width]="136"></kendo-grid-command-column>
                </kendo-grid>
            </div>
        }

        <!-- No data information -->
        @if (schedulesDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) {
            <no-results
                [imageTitle]="'timeTracking.schedules.noResultsTitle'"
                [description]="dataFilterOpts.active ? 'timeTracking.schedules.noResultsText' : 'timeTracking.schedules.noResultsArchiveText'">
            </no-results>
        }
        @if (schedulesDatagrid.total == 0 && dataFilterOpts.name && !isSyncing) {
            <no-search-results></no-search-results>
        }
    </div>
</div>
