import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class RemoteSignatureService {
    public apiUrl: string;
    private BATCH_KIND_FILTER: any = {
        ALL: 0,
        SINGLE: 1,
        MASSIVE: 2
    };

    constructor(
        private httpClient: HttpClient,
        private translate: TranslateService
    ) {
        this.apiUrl = Constants.apiUrl;
    }

    getBatchSignatureSummary(startDate: string, endDate: string, batchKind: number, massiveBatchId: string) {
        const httpOptions = {
            params: {
                startDate: startDate,
                endDate: endDate
            }
        };
        if (batchKind && batchKind !== this.BATCH_KIND_FILTER.ALL) {
            httpOptions.params['onlyMassive'] = batchKind === this.BATCH_KIND_FILTER.MASSIVE;
        }
        if (!!massiveBatchId) {
            httpOptions.params['massiveBatchId'] = massiveBatchId;
        }
        return this.httpClient.get<any>(`${this.apiUrl}/remotesignature-batches/summary`, httpOptions);
    }

    findAll(pagerOptions: any, filteringOptions: any, massiveBatchId: string) {
        const urlForFindAll = this.apiUrl + (!!massiveBatchId ? '/remotesignature-batches/massive/' + massiveBatchId + '/batches' : '/remotesignature-batches');

        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                sort: pagerOptions.sort || ''
            },
            reportProgress: true
        };

        if (!massiveBatchId) {
            httpOptions.params['startDate'] = filteringOptions.startDate;
            httpOptions.params['endDate'] = filteringOptions.endDate;
        }

        if (filteringOptions.state) {
            if (typeof filteringOptions.state === 'number') {
                httpOptions.params['state'] = this.getBatchStateFilter(filteringOptions.state);
            } else if (typeof filteringOptions.state === 'object') {
                var states = [];
                filteringOptions.state.forEach((state) => {
                    states.push(this.getBatchStateFilter(state));
                });
                httpOptions.params['state'] = states;
                filteringOptions.state = Constants.SIGNATURE_BATCH_STATES.FINISHED;
            }
        }
        if (filteringOptions.hasRejectedFiles) {
            httpOptions.params['hasRejectedFiles'] = filteringOptions.hasRejectedFiles;
        }
        if (filteringOptions.name) {
            if (!!massiveBatchId) {
                httpOptions.params['employeeName'] = filteringOptions.name;
            } else {
                httpOptions.params['search'] = filteringOptions.name;
            }
        }
        if (filteringOptions.batchKind && filteringOptions.batchKind !== this.BATCH_KIND_FILTER.ALL) {
            httpOptions.params['massiveBatch'] = filteringOptions.batchKind === this.BATCH_KIND_FILTER.MASSIVE;
        }
        return this.httpClient.get<any>(urlForFindAll, httpOptions);
    }

    findOne(batchId: string) {
        return this.httpClient.get<any>(`${this.apiUrl}/remotesignature-batches/` + batchId);
    }

    cancelBatch(batchId: string) {
        return this.httpClient.put(`${this.apiUrl}/remotesignature-batches/${batchId}/cancel`, null);
    }

    cancelMassiveBatch(batchId: string) {
        return this.httpClient.put(`${this.apiUrl}/remotesignature-batches/massive/${batchId}/cancel`, null);
    }

    findOneMassiveBatch(batchId: string) {
        return this.httpClient.get<any>(`${this.apiUrl}/remotesignature-batches/massive/` + batchId);
    }

    createRemoteSignatureBatch(batch: any, documents: Array<any>) {
        const formData = new FormData();
        formData.append('batch', new Blob([JSON.stringify(batch)], { type: 'application/json' }));
        let index: number;
        if (documents && documents.length > 0) {
            for (index = 0; index < documents.length; index++) {
                let filename = documents[index].name;
                if (!!documents[index].extension && !filename.endsWith('.' + documents[index].extension)) {
                    filename += '.' + documents[index].extension;
                }
                formData.append('documents', documents[index].file, filename);
            }
        }
        return this.httpClient.post<any>(`${this.apiUrl}/remotesignature-batches`, formData);
    }

    downloadBatchDocuments(id: number) {
        return this.httpClient.get(`${this.apiUrl}/remotesignature-batches/` + id + '/files', {
            withCredentials: true,
            observe: 'response',
            responseType: 'blob'
        });
    }

    getDocumentActivityLog(id: number) {
        return this.httpClient.get<any>(`${this.apiUrl}/remote-signatures/` + id + '/activity');
    }

    downloadEvidenceReport(url: string): any {
        return this.httpClient.get(url, { withCredentials: true, observe: 'response', responseType: 'blob' });
    }

    getBatchStateFilter(state: number): string {
        var filterState = '';
        switch (state) {
            case Constants.SIGNATURE_BATCH_STATES.SENT:
                filterState = Constants.BATCH_STATES_FILTER.SENT;
                break;
            case Constants.SIGNATURE_BATCH_STATES.IN_PROGRESS:
                filterState = Constants.BATCH_STATES_FILTER.IN_PROGRESS;
                break;
            case Constants.SIGNATURE_BATCH_STATES.FINISHED:
                filterState = Constants.BATCH_STATES_FILTER.FINISHED;
                break;
            case Constants.SIGNATURE_BATCH_STATES.EXPIRED:
                filterState = Constants.BATCH_STATES_FILTER.EXPIRED;
                break;
            case Constants.SIGNATURE_BATCH_STATES.CANCELLED:
                filterState = Constants.BATCH_STATES_FILTER.CANCELLED;
                break;
            default:
                break;
        }
        return filterState;
    }

    documentStateBgColor(state: number): string {
        let docStateColor = '';
        switch (state) {
            case Constants.DOCUMENT_STATES.SIGNED:
                docStateColor = 'bg-approved-color';
                break;
            case Constants.DOCUMENT_STATES.REJECTED:
                docStateColor = 'bg-rejected-color';
                break;
            case Constants.DOCUMENT_STATES.EXPIRED:
                docStateColor = 'bg-expired-color';
                break;
            case Constants.DOCUMENT_STATES.CANCELLED:
                docStateColor = 'bg-cancelled-color';
                break;
            default:
                break;
        }
        return docStateColor;
    }

    documentStateLabelText(state: number): string {
        let labelText = ' ';
        switch (state) {
            case Constants.DOCUMENT_STATES.SIGNED:
                labelText = 'documents.states.signed';
                break;
            case Constants.DOCUMENT_STATES.REJECTED:
                labelText = 'documents.states.rejected';
                break;
            case Constants.DOCUMENT_STATES.EXPIRED:
                labelText = 'documents.states.expired';
                break;
            case Constants.DOCUMENT_STATES.CANCELLED:
                labelText = 'documents.states.cancelled';
                break;
            default:
                break;
        }
        return labelText;
    }

    batchStateColor(state: any): string {
        let stateClass: string = '';
        switch (state) {
            case Constants.SIGNATURE_BATCH_STATES.SENT:
                stateClass = 'signature-state--sended';
                break;
            case Constants.SIGNATURE_BATCH_STATES.IN_PROGRESS:
                stateClass = 'signature-state--in-progress';
                break;
            case Constants.SIGNATURE_BATCH_STATES.FINISHED:
                stateClass = 'signature-state--completed';
                break;
            case Constants.SIGNATURE_BATCH_STATES.EXPIRED:
                stateClass = 'signature-state--expired';
                break;
            case Constants.SIGNATURE_BATCH_STATES.CANCELLED:
                stateClass = 'signature-state--cancelled';
                break;
            default:
                break;
        }
        return stateClass;
    }

    batchStateTextLabel(state: any): string {
        let stateLabel: string = '';
        switch (state) {
            case Constants.SIGNATURE_BATCH_STATES.SENT:
                stateLabel = this.translate.instant('remoteSignature.batch.stateLabel.sent');
                break;
            case Constants.SIGNATURE_BATCH_STATES.IN_PROGRESS:
                stateLabel = this.translate.instant('remoteSignature.batch.stateLabel.inProgress');
                break;
            case Constants.SIGNATURE_BATCH_STATES.FINISHED:
                stateLabel = this.translate.instant('remoteSignature.batch.stateLabel.completed');
                break;
            case Constants.SIGNATURE_BATCH_STATES.EXPIRED:
                stateLabel = this.translate.instant('remoteSignature.batch.stateLabel.expired');
                break;
            case Constants.SIGNATURE_BATCH_STATES.CANCELLED:
                stateLabel = this.translate.instant('remoteSignature.batch.stateLabel.cancelled');
                break;
            default:
                break;
        }
        return stateLabel;
    }
}
