<header class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [translate]="'feedbackTray.clone.title'"></h3>
</header>
<section class="modal-body scroll6">
    <!-- Tray identification data -->
    <p class="bold margin-bottom-20" [translate]="'feedbackTray.groupTitle.identification'"></p>
    <!-- Tray name in App -->
    <div class="group-form group-form--label-220">
        <div class="group-form__label">
            <span class="group-form__label__text margin-right-10" [translate]="'feedbackTray.app.nameInApp'"></span>
            <span class="group-form__label__help icon icon-help-fill" kendoTooltip [title]="'feedbackTray.tooltips.name' | translate"></span>
        </div>
        <div class="group-form__data">
            <input
                #trayNameInApp
                id="tray-name-in-app"
                data-cy="tray-name-in-app"
                class="input"
                [ngClass]="{'input--error': nameAlreadyExist}"
                [(ngModel)]="cloneTrayForm.nameInApp"
                (ngModelChange)="onChangeName()"
                maxlength="64"
                [placeholder]="'feedbackTray.app.nameInAppExample' | translate" />
        </div>
    </div>
    <!-- Tray initials -->
    <div class="group-form group-form--label-220">
        <div class="group-form__label">
            <span class="group-form__label__text margin-right-10" [translate]="'feedbackTray.app.initials'"></span>
            <span class="group-form__label__help icon icon-help-fill" kendoTooltip [title]="'feedbackTray.tooltips.initials' | translate"></span>
        </div>
        <div class="group-form__data">
            <input
                #trayInitials
                id="tray-initials"
                data-cy="tray-initials"
                class="input max-width-100"
                [(ngModel)]="cloneTrayForm.initials"
                (ngModelChange)="onChange()"
                maxlength="3"
                autocomplete="off" />
        </div>
    </div>
    <!-- Tray alias in backoffice -->
    <div class="group-form group-form--label-220">
        <div class="group-form__label">
            <span class="group-form__label__text margin-right-10" [translate]="'feedbackTray.alias'"></span>
            <span class="group-form__label__help icon icon-help-fill" kendoTooltip [title]="'feedbackTray.tooltips.alias' | translate"></span>
            <span class="group-form__label__text group-form__label__text--optional" [translate]="'global.form.optional'"></span>
        </div>
        <div class="group-form__data group-form__data--column">
            <input
                #trayAlias
                id="tray-alias"
                data-cy="tray-alias"
                class="input max-width-200"
                [(ngModel)]="cloneTrayForm.alias"
                (ngModelChange)="onChange()"
                maxlength="64"
                [placeholder]="'feedbackTray.clone.aliasPlaceholder' | translate" />
        </div>
    </div>
    <p class="bold margin-bottom-16 padding-top-16" [translate]="'feedbackTray.groupTitle.copySettings'"></p>
    <!-- Copy settings options -->
    <div class="flex direction-column">
        <!-- Responsibles -->
        <label class="checkbox__container margin-bottom-16">
            <span class="checkbox icon" [ngClass]="cloneTrayForm.copyResponsible ? 'checkbox--on icon-checkbox-label-active': 'icon-checkbox-label'"></span>
            <div class="flex direction-column">
                <span class="checkbox__label checkbox__label" [translate]="'feedbackTray.responsibles'"></span>
            </div>
            <input
                type="checkbox"
                #selectAllCheckboxId
                id="select-all-checkbox-id"
                data-cy="select-all-checkbox-id"
                class="checkbox__input"
                [(ngModel)]="cloneTrayForm.copyResponsible"
                (ngModelChange)="onChange()" />
        </label>
        <!-- Questionnaire -->
        <label class="checkbox__container margin-bottom-16" [ngClass]="{'disabled-events disabled': !cloneTrayForm.hasQuestionnaire}">
            <span class="checkbox icon" [ngClass]="cloneTrayForm.copyQuestionnaire ? 'checkbox--on icon-checkbox-label-active': 'icon-checkbox-label'"></span>
            <div class="flex direction-column">
                <span class="checkbox__label checkbox__label" [translate]="'conversations.automaticConversation'"></span>
            </div>
            <input
                type="checkbox"
                #copyQuestionnaireCheckbox
                id="copy-questionnaire-checkbox"
                data-cy="copy-questionnaire-checkbox"
                class="checkbox__input"
                [(ngModel)]="cloneTrayForm.copyQuestionnaire"
                (ngModelChange)="onChange()" />
        </label>
        <!-- Tray recipients -->
        <label class="checkbox__container margin-bottom-16">
            <span class="checkbox icon" [ngClass]="cloneTrayForm.copyRecipients ? 'checkbox--on icon-checkbox-label-active': 'icon-checkbox-label'"></span>
            <div class="flex direction-column">
                <span class="checkbox__label checkbox__label" [translate]="'feedbackTray.tabs.usersAndGroups'"></span>
            </div>
            <input
                type="checkbox"
                #copyRecipientsCheckbox
                id="copy-recipients-checkbox"
                data-cy="copy-recipients-checkbox"
                class="checkbox__input"
                [(ngModel)]="cloneTrayForm.copyRecipients"
                (ngModelChange)="onChange()" />
        </label>
    </div>
</section>
<footer class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'add-motives'"
        [classes]="'button--secondary margin-right-10'"
        [text]="'global.modal.accept'"
        (action)="onAccept()"
        [isValid]="isFormValid && hasChanges">
    </loading-button>
    <button type="button" class="button" (click)="onCancel()">
        <span class="button__label" [translate]="'global.modal.cancel'"></span>
    </button>
</footer>
