import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numberColor'
})
export class NumberColorPipe implements PipeTransform {
    transform(color: number): string {
        return '#' + ('00000' + (color | 0).toString(16)).substr(-6);
    }
}
