import { Component, Input, Output, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormUtilsService } from '../../services/form-utils.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'toggle-input',
    templateUrl: './toggle-input.component.html'
})
export class ToggleInputComponent implements OnInit, OnDestroy {
    @Input() inputId: string;
    @Input() isDisabled?: false;
    @Input() inputModel: boolean;
    @Input() label?: string;
    @Input() isSmall?: false;
    @Output() inputModelChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() inputChanged?: EventEmitter<any> = new EventEmitter();
    @Output() checkboxDeactivationFunction?: EventEmitter<any> = new EventEmitter();

    private confirmCheckboxDeactivationSubscription: Subscription;

    constructor(private formUtilsService: FormUtilsService) {}

    ngOnInit() {
        if (this.checkboxDeactivationFunction && this.checkboxDeactivationFunction.observers.length > 0) {
            this.confirmCheckboxDeactivationSubscription = this.formUtilsService.confirmCheckboxDeactivationEmitter$.subscribe((response: any) => {
                if (response.inputId === this.inputId) {
                    setTimeout(() => {
                        this.inputModel = response.checkboxValue;
                    });
                    this.inputModelChange.emit(response.checkboxValue);
                    if (this.inputChanged) {
                        this.inputChanged.emit(null);
                    }
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.confirmCheckboxDeactivationSubscription) {
            this.confirmCheckboxDeactivationSubscription.unsubscribe();
        }
    }

    modelValueChanged(): void {
        if (this.checkboxDeactivationFunction && this.checkboxDeactivationFunction.observers.length > 0 && this.inputModel === false) {
            setTimeout(() => {
                this.inputModel = true;
            });
            this.checkboxDeactivationFunction.emit(null);
        } else {
            this.inputModelChange.emit(this.inputModel);
            if (this.inputChanged) {
                this.inputChanged.emit(null);
            }
        }
    }
}
