import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'decimalLocalized'
})
export class DecimalLocalizedPipe implements PipeTransform {
    private countDecimals(value: number): number {
        if (Math.floor(value) === value) {
            return 0;
        }
        return value.toString().split('.')[1].length || 0;
    }

    transform(value: number, maxNumDecimals: number = 2): string {
        if (value == null) {
            return '0';
        }
        const numDecimals = this.countDecimals(value);
        const decimals = Math.min(numDecimals, maxNumDecimals);
        return value.toFixed(decimals);
    }
}
