import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'documents-upload',
    templateUrl: './documents-upload.component.html',
    styleUrls: ['./_documents-upload.component.scss']
})
export class DocumentsUploadComponent {
    public isDropActive = false;
    @Input() imageUpload?: boolean;
    @Output() addDocuments: EventEmitter<any> = new EventEmitter();

    constructor() {}

    @HostListener('dragover', ['$event']) onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDropActive = true;
    }

    @HostListener('dragleave', ['$event']) onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDropActive = false;
    }

    @HostListener('drop', ['$event']) onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.isDropActive = false;
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            this.addDocuments.emit(files);
        }
    }

    selectFiles(files: FileList) {
        if (files && files.length > 0) {
            this.addDocuments.emit(files);
        }
    }
}
