import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ArrayUtilsService {
    constructor() {}

    isLocalizedKeyIncluded = (key: string): boolean => {
        // List of internal attributes that we don't want to check against localized contents
        const excludedKeys: string[] = ['imageOrder', 'resourceOrder'];
        return excludedKeys.indexOf(key) === -1;
    };

    objectHasAnyValue = (objectContent: any): boolean => {
        // If we have no proper value, we return false
        if (typeof objectContent === 'undefined') {
            return false;
        }

        // We're considering now every single property for a given element
        // If the object has no attributes (keys) or every key is empty, we return false
        // In other case, we return true
        for (const key in objectContent) {
            if (
                Object.prototype.hasOwnProperty.call(objectContent, key) &&
                this.isLocalizedKeyIncluded(key) &&
                objectContent[key] &&
                objectContent[key].toString().trim() !== ''
            ) {
                return true;
            }
        }
        return false;
    };

    removeDuplicates = (array: any, equalityProperty: string): Array<any> => {
        let processedArray = array.concat();
        for (var i = 0; i < processedArray.length; i++) {
            for (var j = i + 1; j < processedArray.length; j++) {
                if (
                    (equalityProperty && processedArray[i][equalityProperty] === processedArray[j][equalityProperty]) ||
                    processedArray[i] === processedArray[j]
                ) {
                    processedArray.splice(j--, 1);
                }
            }
        }
        return processedArray;
    };
}
