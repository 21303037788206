var t = {
  photo: "https://api.pexels.com/v1/",
  video: "https://api.pexels.com/videos/",
  collections: "https://api.pexels.com/v1/collections/"
};
function r(r, e) {
  var n = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "User-Agent": "Pexels/JavaScript",
        Authorization: r
      }
    },
    o = t[e];
  return function (t, r) {
    return fetch("" + o + t + "?" + function (t) {
      return Object.keys(t).map(function (r) {
        return r + "=" + t[r];
      }).join("&");
    }(r || {}), n).then(function (t) {
      if (!t.ok) throw new Error(t.statusText);
      return t.json();
    });
  };
}
function e(t) {
  var e = r(t, "collections");
  return {
    all: function (t) {
      return void 0 === t && (t = {}), e("", t);
    },
    media: function (t) {
      var r = t.id,
        n = function (t, r) {
          if (null == t) return {};
          var e,
            n,
            o = {},
            i = Object.keys(t);
          for (n = 0; n < i.length; n++) r.indexOf(e = i[n]) >= 0 || (o[e] = t[e]);
          return o;
        }(t, ["id"]);
      return e("" + r, n);
    },
    featured: function (t) {
      return void 0 === t && (t = {}), e("featured", t);
    }
  };
}
function n(t) {
  return !(!t || !t.photos);
}
var o = {
  __proto__: null,
  isPhotos: n,
  isVideos: function (t) {
    return !(!t || !t.videos);
  },
  isError: function (t) {
    return !!t.error;
  }
};
function i(t) {
  var e = r(t, "photo");
  return {
    search: function (t) {
      return e("/search", t);
    },
    curated: function (t) {
      return void 0 === t && (t = {}), e("/curated", t);
    },
    show: function (t) {
      return e("/photos/" + t.id);
    },
    random: function () {
      try {
        var t = Math.floor(1e3 * Math.random());
        return Promise.resolve(this.curated({
          page: t,
          per_page: 1
        })).then(function (t) {
          return n(t) ? t.photos[0] : t;
        });
      } catch (t) {
        return Promise.reject(t);
      }
    }
  };
}
function u(t) {
  var e = r(t, "video");
  return {
    search: function (t) {
      return e("/search", t);
    },
    popular: function (t) {
      return void 0 === t && (t = {}), e("/popular", t);
    },
    show: function (t) {
      return e("/videos/" + t.id);
    }
  };
}
function c(t) {
  if (!t || "string" != typeof t) throw new TypeError("An ApiKey must be provided when initiating the Pexel's client.");
  return {
    typeCheckers: o,
    photos: i(t),
    videos: u(t),
    collections: e(t)
  };
}
require("isomorphic-fetch");
export { c as createClient };
