import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { SegmentsManagementModalComponent } from '../../shared/components/segments-management-modal/segments-management-modal.component';
import { ChatroomsService } from '../chatrooms/chatrooms.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { isEqual, cloneDeep } from 'lodash';

@Component({
    selector: 'chatroom-general',
    templateUrl: './chatroom-general.component.html'
})
export class ChatroomGeneralComponent implements OnInit {
    @Input() chatId: number;
    @Input() chatroomForm: any;
    @Input() initialDataForm: any;
    @Output() chatroomFormChange: EventEmitter<any> = new EventEmitter<any>();

    private _rootScope: any;
    public CHATROOM_STATES: any;
    public IMAGE_CROPPER_CHATROOM: any;
    public CHATROOM_USERS_SYNCHRO_KIND: any;
    public SEGMENTATION_KIND: any;
    public POPULATION: any;

    public isFormDataValid: boolean;
    public isFormDataChanged: boolean = false;
    public isChatNameDuplicated: boolean = false;
    private chatroomsPath: string = '/settings/chatrooms';

    constructor(
        private upgrade: UpgradeModule,
        private chatroomsService: ChatroomsService,
        private notificationService: DuiNotificationsService,
        private Dialog: MatDialog,
        private translate: TranslateService,
        private formUtilsService: FormUtilsService
    ) {
        this._rootScope = this.upgrade.$injector.get('$rootScope');
        this.CHATROOM_STATES = Constants.CHATROOM_STATES;
        this.IMAGE_CROPPER_CHATROOM = Constants.IMAGE_CROPPER_CHATROOM;
        this.CHATROOM_USERS_SYNCHRO_KIND = Constants.CHATROOM_USERS_SYNCHRO_KIND;
        this.SEGMENTATION_KIND = Constants.SEGMENTATION_KIND;
        this.POPULATION = Constants.POPULATION;
    }

    ngOnInit() {}

    setFormData(): void {
        this.initialDataForm = cloneDeep(this.chatroomForm);
        this.chatroomFormChange.emit(this.chatroomForm);
        this.onChange();
    }

    onChange() {
        this.checkIfDataFormAreChanged();
        this.checkIfDataFormAreValid();
    }

    onChangeChatName(): void {
        this.isChatNameDuplicated = false;
        this.onChange();
    }

    chatroomFormSave(): void {
        this.chatroomsService.updateChatroom(this.chatId, this.chatroomForm, this.chatroomForm.image).subscribe(
            (response: any) => {
                this.setFormData();
                this.formUtilsService.finishSubmitAction();

                this.notificationService.showSuccessNotification();
            },
            (onError: any) => {
                this.formUtilsService.finishSubmitAction();
                if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
                    this.isChatNameDuplicated = true;
                }
            }
        );
    }

    addAdminsToChat(): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            title: this.translate.instant('chatrooms.modal.admins.title'),
            users: [...this.chatroomForm.admins]
        };
        dialogSettings.width = '65vw';
        dialogSettings.position = { top: '4vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.Dialog.open(ChatroomAdminsModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.arePendingChanges && result?.addedUsers) {
                    this.chatroomForm.admins = result.addedUsers;
                    this.onChange();
                }
            });
    }

    addSegments(): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            title: this.translate.instant('chatrooms.modal.segments.title'),
            segments: [...this.chatroomForm.segments],
            segmentationKind: this.chatroomForm.segmentationKind !== null ? this.chatroomForm.segmentationKind : this.SEGMENTATION_KIND.UNION,
            showSegmentationSelection: true,
            population: this.POPULATION.SEGMENTS
        };
        dialogSettings.width = '1100px';
        dialogSettings.minWidth = '600px';
        dialogSettings.maxWidth = '90vw';
        dialogSettings.position = { top: '4vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.Dialog.open(SegmentsManagementModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.arePendingChanges || result?.segmentationKindChanged) {
                    if (result?.arePendingChanges && result?.addedSegments) {
                        this.chatroomForm.segments = result.addedSegments;
                    }
                    if (result?.segmentationKindChanged) {
                        this.chatroomForm.segmentationKind = result.segmentationKind;
                    }
                    this.onChange();
                }
            });
    }

    removeSegmentFromChatroom(index: number): void {
        this.chatroomForm.segments.splice(index, 1);
        this.onChange();
    }

    changeActivateState(): void {
        this.chatroomForm.state = this.chatroomForm.state === this.CHATROOM_STATES.ACTIVE ? this.CHATROOM_STATES.INACTIVE : this.CHATROOM_STATES.ACTIVE;
    }

    goBack(): void {
        this._rootScope.goBackAndCheckForModifications(this.isFormDataChanged, this.chatroomsPath);
    }

    checkIfDataFormAreChanged(): void {
        this.isFormDataChanged = !isEqual(this.initialDataForm, this.chatroomForm);
    }

    checkIfDataFormAreValid(): void {
        this.isFormDataValid = !!this.chatroomForm.name;
    }
}
