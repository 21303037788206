import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Constants } from '../../constants';
import { RequestsService } from '../requests/requests.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { FileSaverService } from 'ngx-filesaver';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { UtilsService } from '../../shared/services/utils.service';
import { RequestValidationModalComponent } from './request-validation-modal.component';

@Component({
    selector: 'request-detail',
    templateUrl: './request-detail.component.html',
    styleUrls: ['./_request-detail.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RequestDetailComponent implements OnInit, OnChanges {
    public request: any = {};
    public requestTray: any = {
        id: null,
        alias: null,
        name: null
    };
    public requestMotive: any = {
        id: null,
        motive: null,
        color: null
    };
    public traysItems: any;
    public motivesItems: any;
    public requestResources: any = [];
    public requestConversation: any = [];
    public requestNotes: any = [];
    public newMessage: any = {
        message: '',
        linkedPublications: []
    };
    public newNote: string = '';
    public isValidMessage: boolean = false;
    public isValidNote: boolean = false;
    public arePendingChanges: boolean = false;

    public requestDetailAttachments: any = {
        files: [],
        images: []
    };
    public requestNotesAttachments: any = {
        files: [],
        images: []
    };

    public isAdmin: boolean;
    public REQUEST_VALIDATION_STATE = Constants.REQUEST_VALIDATION_STATE;
    public CONVERSATION_NOTES = Constants.CONVERSATION_NOTES;
    public MESSAGE_STATES = Constants.MESSAGE_STATES;
    public CONTENT_KIND = Constants.CONTENT_KIND;
    public REQUEST_TABS: any = {
        DETAIL: 1,
        FILES: 2,
        NOTES: 3
    };
    public selectedTab: number = this.REQUEST_TABS.DETAIL;
    public isLoadingData: boolean = false;
    public textEditorOptionsVisible: boolean = false;
    public dataSkeleton = [{}, {}, {}];

    private userSession: any;

    @Input() requestData: any;
    @Output() reloadData: EventEmitter<any> = new EventEmitter<any>();
    @Output() trayOrMotiveChanged: EventEmitter<any> = new EventEmitter<any>();
    @Output() validationChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('conversationContainer') private conversationContainerRef: ElementRef;
    @ViewChild('notesContainer') private notesContainerRef: ElementRef;
    @ViewChild('attachFile') private attachFileRef: ElementRef;

    constructor(
        private requestsService: RequestsService,
        private fileSaverService: FileSaverService,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        public formUtilsService: FormUtilsService,
        private utilsService: UtilsService,
        private dialogService: DialogService,
        private dialog: MatDialog
    ) {
        this.userSession = window['getUserSession']();
        this.isAdmin = this.userSession.roles.indexOf(Constants.USER_ROLES.admin) !== -1;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.requestData && changes.requestData.currentValue !== changes.requestData.previousValue && !changes.requestData.firstChange) {
            this.request = changes.requestData.currentValue;
            this.requestTray = {
                id: this.request?.minimalTray.id || null,
                alias: this.request?.minimalTray.alias,
                name: this.request?.minimalTray.nameInList
            };
            this.requestMotive = {
                id: this.request?.minimalMotive.id || null,
                motive: this.request?.minimalMotive.motive || '',
                color: this.request?.minimalMotive.color || ''
            };
            this.selectedTab = this.REQUEST_TABS.DETAIL;
            this.checkSelectedTabAndLoadTheirData();
        }
    }

    ngOnInit(): void {
        this.requestsService.getAllTrays().subscribe(
            (response: any) => {
                this.traysItems = response.content;
            },
            (error) => {}
        );

        this.requestsService.getAllMotives().subscribe(
            (response: any) => {
                this.motivesItems = response.content;
            },
            (error) => {}
        );
    }

    // API methods

    private loadRequestConversation(): void {
        this.requestConversation = [];
        this.requestsService.getResponsibleConversation(this.request.id).subscribe(
            (response) => {
                this.requestConversation = response;
                this.isLoadingData = false;
                this.scrollMessagesToBottom();
            },
            (error) => {}
        );
    }

    private loadRequestResources(): void {
        this.requestResources = [];
        this.requestsService.getRequestResources(this.request.id).subscribe(
            (response) => {
                this.requestResources = response;
                this.isLoadingData = false;
            },
            (error) => {}
        );
    }

    private loadRequestNotes(): void {
        this.requestNotes = [];
        this.requestsService.getResponsibleRequestNotes(this.request.id).subscribe(
            (response) => {
                this.requestNotes = response;
                this.isLoadingData = false;
                this.scrollMessagesToBottom();
            },
            (error) => {}
        );
    }

    private closeRequestAndReloadList(): void {
        this.requestsService.closeRequest(this.request.id).subscribe(() => {
            this.reloadData.emit(null);
            const message = this.translate.instant('conversations.closeSuccessMessage');
            this.notificationService.showSuccessNotification(message);
        });
    }

    private reopenRequestAndReloadList(): void {
        this.requestsService.reopenRequest(this.request.id).subscribe(() => {
            this.reloadData.emit(null);
            const message = this.translate.instant('conversations.reopenSuccessMessage');
            this.notificationService.showSuccessNotification(message);
        });
    }

    // Public methods

    loadTabContent(selectedTab: number): void {
        this.selectedTab = selectedTab;
        this.checkSelectedTabAndLoadTheirData();
    }

    validateModel(): void {
        this.isValidMessage =
            (!!this.newMessage?.message && this.newMessage?.message !== '' && this.newMessage?.message !== '<p></p>') ||
            this.requestDetailAttachments.files.length > 0 ||
            this.requestDetailAttachments.images.length > 0 ||
            this.newMessage.linkedPublications.length > 0;
        this.isValidNote = !!this.newNote && this.newNote !== '' && this.newNote !== '<p></p>';
        this.arePendingChanges = this.isValidMessage || this.isValidNote;
    }

    sendMessage(): void {
        let resources = [];

        if (this.selectedTab === this.REQUEST_TABS.DETAIL) {
            this.newMessage.message = this.newMessage.message ? this.newMessage.message.replace(/\r?\n/g, '<br />') : '';
            resources = this.requestDetailAttachments.files.concat(this.requestDetailAttachments.images);
            this.requestsService.addMessageToRequestAsResponsible(this.request.id, this.newMessage, resources).subscribe(
                (onSuccess: any) => {
                    this.resetData();
                    this.loadRequestConversation(); // OR this.requestConversation.messages.push(response); and scrollbottom

                    this.reloadData.emit(onSuccess);
                    this.formUtilsService.finishSubmitAction();
                },
                (onError: any) => {}
            );
        }
        if (this.selectedTab === this.REQUEST_TABS.NOTES) {
            let message = this.newNote ? this.newNote.replace(/\r?\n/g, '<br />') : '';
            resources = this.requestNotesAttachments.files.concat(this.requestNotesAttachments.images);
            this.requestsService.addNoteToRequestAsResponsible(this.request.id, message, resources).subscribe((response: any) => {
                this.resetData();
                this.requestConversation.notesCount++;
                this.loadRequestNotes(); // OR this.requestNotes.push(response); and scrollbottom

                this.formUtilsService.finishSubmitAction();
            });
        }
    }

    downloadResource(resourceUrl: string, nameWithExtension: string): void {
        this.requestsService.downloadResource(resourceUrl).subscribe(
            (response: any) => {
                this.fileSaverService.save(response.body, nameWithExtension);
            },
            (onError: any) => {}
        );
    }

    openFileSelection(): void {
        this.attachFileRef.nativeElement.click();
    }

    onFileSelected(files: any): void {
        if (this.selectedTab === this.REQUEST_TABS.DETAIL) {
            if (this.isImage(files[0].type)) {
                this.selectImage(files[0]).then((response: any) => {
                    files[0].src = response.image.src;
                    this.requestDetailAttachments.images.push(files[0]);
                    this.validateModel();
                });
            } else {
                this.requestDetailAttachments.files.push(files[0]);
                this.validateModel();
            }
        }
        if (this.selectedTab === this.REQUEST_TABS.NOTES) {
            if (this.isImage(files[0].type)) {
                this.selectImage(files[0]).then((response: any) => {
                    files[0].src = response.image.src;
                    this.requestNotesAttachments.images.push(files[0]);
                    this.validateModel();
                    // self.updatePlaceholder();
                });
            } else {
                this.requestNotesAttachments.files.push(files[0]);
                this.validateModel();
                // self.updatePlaceholder();
            }
        }
    }

    removeFile(index: number): void {
        this.removeAttachedFile(
            this.selectedTab === this.REQUEST_TABS.DETAIL ? this.requestDetailAttachments.files : this.requestNotesAttachments.files,
            index
        );
    }

    removeImage(index: number): void {
        this.removeAttachedFile(
            this.selectedTab === this.REQUEST_TABS.DETAIL ? this.requestDetailAttachments.images : this.requestNotesAttachments.images,
            index
        );
    }

    removeLink(index: number): void {
        if (this.newMessage.linkedPublications.length > index) {
            this.newMessage.linkedPublications.splice(index, 1);
        }
        this.validateModel();
    }

    closeRequest(): void {
        const skipModalAlias = 'closeRequest';
        let shouldModalBeDisplayed = this.dialogService.shouldModalBeDisplayed(skipModalAlias);

        if (!shouldModalBeDisplayed) {
            this.closeRequestAndReloadList();
        } else {
            const dialogValues: DialogValueOptions = {
                title: 'conversations.modal.close.title',
                message: 'conversations.modal.close.message',
                acceptButtonText: 'global.modal.close',
                skipModalAlias: skipModalAlias
            };

            this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
                if (result?.accept) {
                    this.closeRequestAndReloadList();
                }
            });
        }
    }

    reopenRequest(): void {
        if (
            !!this.request.validationState &&
            (this.request.validationState === this.REQUEST_VALIDATION_STATE.VALIDATED ||
                this.request.validationState === this.REQUEST_VALIDATION_STATE.REJECTED)
        ) {
            const skipModalAlias = 'reopenConversation';
            let shouldModalBeDisplayed = this.dialogService.shouldModalBeDisplayed(skipModalAlias);
            if (!shouldModalBeDisplayed) {
                this.reopenRequestAndReloadList();
            } else {
                const dialogValues: DialogValueOptions = {
                    title: 'conversations.modal.reopen.title',
                    message: 'conversations.modal.reopen.message',
                    acceptButtonText: 'global.modal.accept',
                    skipModalAlias: skipModalAlias
                };

                this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
                    if (result?.accept) {
                        this.reopenRequestAndReloadList();
                    }
                });
            }
        } else {
            this.reopenRequestAndReloadList();
        }
    }

    validationRequest(): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(RequestValidationModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    this.requestsService.validateRequest(this.request.id, result.validationForm, result.attachments).subscribe(
                        (onSuccess) => {
                            this.requestConversation.notesCount++;
                            if (this.selectedTab === this.REQUEST_TABS.NOTES) {
                                this.checkSelectedTabAndLoadTheirData();
                            }
                            this.validationChanged.emit(result.validationForm.validation);
                            const translationKey =
                                result.validationForm.validation === this.REQUEST_VALIDATION_STATE.VALIDATED
                                    ? 'conversations.validatedConversation'
                                    : 'conversations.rejectedConversation';
                            this.notificationService.showSuccessNotification(this.translate.instant(translationKey));
                        },
                        (onError) => {}
                    );
                }
            });
    }

    discardValidation(): void {
        document.getElementById('dismiss-request-validation').click();
        this.requestsService.discardRequestValidation(this.request.id).subscribe(
            (response) => {
                this.requestConversation.notesCount++;
                if (this.selectedTab === this.REQUEST_TABS.NOTES) {
                    this.checkSelectedTabAndLoadTheirData();
                }
                this.validationChanged.emit(this.REQUEST_VALIDATION_STATE.PENDING);
                const message = this.translate.instant('conversations.validationDiscarded');
                this.notificationService.showSuccessNotification(message);
            },
            (onError) => {}
        );
    }

    onTrayChanged(): void {
        const dialogValues: DialogValueOptions = {
            title: 'conversations.modal.changeTray.title',
            message: 'conversations.modal.changeTray.message_Angular',
            messageParam: { tray: this.requestTray.alias || this.requestTray.name },
            acceptButtonText: 'global.form.send'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.requestsService.changeTray(this.request.id, this.requestTray.id).subscribe((response: any) => {
                    this.trayOrMotiveChanged.emit(this.requestTray);
                });
            } else {
                this.requestTray = {
                    id: this.request?.minimalTray.id,
                    alias: this.request?.minimalTray.alias,
                    name: this.request?.minimalTray.nameInList
                };
            }
        });
    }

    onMotiveChanged(): void {
        this.requestsService.changeMotive(this.request.id, this.requestMotive.id).subscribe((response: any) => {
            document.getElementById('request-metadata').click();
            this.trayOrMotiveChanged.emit(this.requestTray);
        });
    }

    openInternalLinkSelection(): void {
        this.dialogService.openInternalLinkModalSelection().subscribe((result) => {
            if (result?.success) {
                this.newMessage.linkedPublications.push(result.content);
                this.validateModel();
            }
        });
    }

    // Miscelaneous methods

    isImage(mimetype: string): boolean {
        return mimetype ? mimetype.startsWith('image/') : false;
    }

    getMotiveTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    }

    getResourceMetadata(resource: any): string {
        let metadata = '';
        metadata = !!resource.extension ? this.utilsService.getResourceSize(resource.weight) + ' · ' + resource.extension.slice(1).toUpperCase() : '';
        return metadata;
    }

    getDialogQuestionnaireItemPositionClass(index: number): string {
        let classToApply = '';
        if (index == 0 || (index > 0 && !this.requestConversation.messages[index - 1].automaticConversation)) {
            classToApply = 'auto-conversation__content--top';
        }
        if (
            index > 0 &&
            this.requestConversation.messages[index - 1].automaticConversation &&
            index < this.requestConversation.messages.length - 1 &&
            this.requestConversation.messages[index + 1].automaticConversation
        ) {
            classToApply = 'auto-conversation__content';
        }
        if (
            index == this.requestConversation.messages.length - 1 ||
            (index < this.requestConversation.messages.length - 1 && !this.requestConversation.messages[index + 1].automaticConversation)
        ) {
            classToApply = 'auto-conversation__content--bottom';
        }
        return classToApply;
    }

    getDialogItemPositionClass(message: any, index: number): string {
        let classToApply = '';
        const prevMsg = index > 0 ? this.requestConversation.messages[index - 1] : null;
        const nextMsg = index < this.requestConversation.messages.length - 1 ? this.requestConversation.messages[index + 1] : null;
        if (
            !prevMsg ||
            (!!prevMsg && prevMsg.automaticConversation) ||
            (!!prevMsg && !prevMsg.automaticConversation && message.sentOn - prevMsg.sentOn > 60000)
        ) {
            classToApply = 'dialog-conversation__content--first';
        }
        if (!!prevMsg && !prevMsg.automaticConversation && message.sentOn - prevMsg.sentOn <= 60000) {
            if (!nextMsg || nextMsg.sentOn - message.sentOn > 60000) {
                classToApply = 'dialog-conversation__content--last';
            } else {
                classToApply = 'dialog-conversation__content--middle';
            }
        }
        return classToApply;
    }

    scrollMessagesToBottom(): void {
        try {
            const container = this.selectedTab === this.REQUEST_TABS.DETAIL ? this.conversationContainerRef : this.notesContainerRef;
            setTimeout(() => {
                container.nativeElement.scrollTo({ top: container.nativeElement.scrollHeight, behavior: 'auto' });
            });
        } catch (err) {}
    }

    showMessageSentOnInfo(currentMessage: any, index: number): boolean {
        if (index === 0) {
            return true;
        } else {
            const prevMsgAutomatic = this.requestConversation.messages[index - 1].automaticConversation;
            if (prevMsgAutomatic || (!prevMsgAutomatic && currentMessage.sentOn - this.requestConversation.messages[index - 1].sentOn > 60000)) {
                return true;
            } else {
                return false;
            }
        }
    }

    getVideoImgPreview(videoUrl: string): string {
        let imgPreviewUrl: string = '';
        const videoId = this.utilsService.getVideoId(videoUrl);
        if (videoId) {
            imgPreviewUrl = 'https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg';
        }
        return imgPreviewUrl;
    }

    private checkSelectedTabAndLoadTheirData(): void {
        this.isLoadingData = true;
        if (this.selectedTab === this.REQUEST_TABS.DETAIL && this.request?.id) {
            this.loadRequestConversation();
        }
        if (this.selectedTab === this.REQUEST_TABS.FILES && this.request?.id) {
            this.loadRequestResources();
        }
        if (this.selectedTab === this.REQUEST_TABS.NOTES && this.request?.id) {
            this.loadRequestNotes();
        }
    }

    private selectImage(file: any): any {
        let promise = new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (event) => {
                var imageData = event.target.result;
                resolve({
                    image: {
                        src: imageData,
                        name: file.name,
                        type: file.type
                    },
                    footnotes: {}
                });
            };
            reader.onerror = (event) => {
                reject();
            };
            reader.readAsDataURL(file);
        });
        return promise;
    }

    private removeAttachedFile(resourceArray: Array<any>, index: number): void {
        if (resourceArray.length > index) {
            resourceArray.splice(index, 1);
        }
        // self.updatePlaceholder();
        this.validateModel();
    }

    private resetData(resetAll?: boolean) {
        if (this.selectedTab === this.REQUEST_TABS.DETAIL || resetAll) {
            this.newMessage.message = '';
            this.newMessage.linkedPublications = [];
            this.requestDetailAttachments.images = [];
            this.requestDetailAttachments.files = [];
            this.isValidMessage = false;
        }
        if (this.selectedTab === this.REQUEST_TABS.NOTES || resetAll) {
            this.newNote = '';
            this.requestNotesAttachments.images = [];
            this.requestNotesAttachments.files = [];
            this.isValidNote = false;
        }
        this.validateModel();
    }
}
