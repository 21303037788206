import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RequestsService } from '../requests/requests.service';

@Component({
    selector: 'motives-preview-device',
    templateUrl: './motives-preview-device.component.html'
})
export class MotivesPreviewDeviceComponent implements OnInit, OnChanges {
    @Input() previewState: any;
    @Input() isMotivesUpdated: any[];
    @Output() isMotivesUpdatedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    motives: any[];

    constructor(private requestsService: RequestsService) {}

    ngOnInit(): void {
        this.getMotives();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isMotivesUpdated && changes.isMotivesUpdated.currentValue !== changes.isMotivesUpdated.previousValue) {
            if (this.isMotivesUpdated) {
                this.getMotives();
            }
        }
    }

    getMotives(): void {
        this.requestsService.getAllMotives().subscribe((response: any) => {
            this.motives = response.content;
            this.isMotivesUpdatedChange.emit(false);
        });
    }

    closePreview(): void {
        this.previewState.opened = false;
    }
}
