<div class="main-container__content margin-top-40">
    <div class="main-container--medium-width">
        <h3 class="bold margin-bottom-24" [translate]="'timeTracking.notifications.title'"></h3>

        <!-- Notification for Incomplete or pending time tracking registration  -->
        <div class="group-form group-form--label-300">
            <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="10">
                <span class="group-form__label__text margin-right-8" [translate]="'timeTracking.notifications.pendingDay'"></span>
                <span class="group-form__label__help icon icon-help-fill" [title]="'timeTracking.notifications.pendingDayTooltip' | translate"></span>
            </div>

            <div class="group-form__data">
                <toggle-input
                    class="margin-left-8"
                    [inputId]="'day-pending'"
                    [(inputModel)]="timeTrackingNotificationsForm.pendingRecordNotification"
                    (inputModelChange)="setPendingRecordNotification()">
                </toggle-input>
            </div>
        </div>

        <!-- Notification for ending period review -->
        <div class="group-form group-form--label-300">
            <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="10">
                <span
                    class="group-form__label__text margin-right-8"
                    [translate]="'timeTracking.notifications.periodEndReview'"
                    [translateParams]="{reviewKind: timeTrackingNotificationsForm.shiftEditionPeriod}"></span>
                <span class="group-form__label__help icon icon-help-fill" [title]="'timeTracking.notifications.periodEndReviewTooltip' | translate"></span>
            </div>

            <div class="group-form__data">
                <toggle-input
                    class="margin-left-8"
                    [inputId]="'period-end-review'"
                    [(inputModel)]="timeTrackingNotificationsForm.periodClosingNotification"
                    (inputModelChange)="setPeriodClosingNotification()"
                    [isDisabled]="closedPeriodNotificationDisabled">
                </toggle-input>
                @if (closedPeriodNotificationDisabled) {
                    <span class="margin-left-8" [translate]="'timeTracking.notifications.periodEndReviewDisabled'"></span>
                }
            </div>

            @if (timeTrackingNotificationsForm.periodClosingNotification && !closedPeriodNotificationDisabled) {
                <section class="margin-left-8" style="padding-left: 300px">
                    <div class="info-advice margin-bottom-10" style="max-width: 408px">
                        <p [translate]="'timeTracking.notifications.periodEndReviewAdvice'"></p>
                    </div>
                </section>
            }
        </div>
    </div>
</div>
