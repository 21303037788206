import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { WorkplacesService } from '../workplaces/workplaces.service';

@Component({
    selector: 'workplace',
    templateUrl: './workplace.component.html'
})
export class WorkplaceComponent implements OnInit {
    public WORKPLACES_TABS: any;
    public workplaceForm: any = {};
    public workplaceEmployees: Array<any> = [];
    public addNewHoliday: boolean = false;
    public selectedTab: number;

    private _route: any;
    private workplaceId: number;
    private EDITION_ALLOWED_KEYS: Array<string> = Constants.EDITION_ALLOWED_KEYS;

    @ViewChild('workplaceName') workplaceNameRef: ElementRef;

    constructor(
        private location: Location,
        private upgrade: UpgradeModule,
        private titleService: Title,
        private workplacesService: WorkplacesService,
        private translate: TranslateService,
        public formUtilsService: FormUtilsService,
        private dialog: MatDialog,
        private notificationService: DuiNotificationsService
    ) {
        this.WORKPLACES_TABS = Constants.WORKPLACES_TABS;
        this._route = this.upgrade.$injector.get('$route');
        this.workplaceId = this._route.current.params.id;

        this.selectedTab = this._route.current.locals.selectedTab;
    }

    ngOnInit() {
        this.workplacesService.findOne(this.workplaceId).subscribe(
            (response: any) => {
                this.workplaceForm = response;
                this.parseWorkplaceForm();
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    // PRIVATE METHODS

    private checkWorkplaceNameAndSave(newName: string): void {
        if (!newName) {
            this.workplaceNameRef.nativeElement.textContent = this.workplaceForm.name;
            return;
        } else if (newName !== this.workplaceForm.name) {
            this.workplaceForm.name = this.workplaceNameRef.nativeElement.textContent;
            this.workplaceFormSave();
        }
    }

    private parseWorkplaceForm(): void {
        this.workplaceForm.latitude = this.workplaceForm.latitude !== null ? parseFloat(this.workplaceForm.latitude) : null;
        this.workplaceForm.longitude = this.workplaceForm.longitude !== null ? parseFloat(this.workplaceForm.longitude) : null;
        this.workplaceForm.description = this.workplaceForm.description === null ? '' : this.workplaceForm.description;
        this.workplaceForm.address = this.workplaceForm.address === null ? '' : this.workplaceForm.address;
        this.workplaceForm.businessHours = this.workplaceForm.businessHours === null ? '' : this.workplaceForm.businessHours;
        this.workplaceForm.undergrounds = this.workplaceForm.undergrounds === null ? '' : this.workplaceForm.undergrounds;
        this.workplaceForm.buses = this.workplaceForm.buses === null ? '' : this.workplaceForm.buses;
        this.workplaceForm.trains = this.workplaceForm.trains === null ? '' : this.workplaceForm.trains;
        this.workplaceForm.parkings = this.workplaceForm.parkings === null ? '' : this.workplaceForm.parkings;
        this.workplaceForm.electricSpots = this.workplaceForm.electricSpots === null ? '' : this.workplaceForm.electricSpots;
    }

    private workplaceFormSave(): void {
        this.workplacesService.update(this.workplaceId, this.workplaceForm).subscribe(
            (response: any) => {
                this.workplaceForm = response;

                const notificationOptions: NotificationOptions = {
                    kind: 'success',
                    message: this.translate.instant('global.messages.changesUpdated.success')
                };
                this.notificationService.showNotification(notificationOptions);
            },
            (respError: any) => {}
        );
    }

    // PUBLIC METHODS

    onWorkplaceNameBlur(): void {
        this.checkWorkplaceNameAndSave(this.workplaceNameRef.nativeElement.textContent);
    }

    onWorkplaceNameKeyDown(event: any) {
        if (this.workplaceNameRef.nativeElement.textContent.length >= 64 && this.EDITION_ALLOWED_KEYS.indexOf(event.code) === -1) {
            event.preventDefault();
            return;
        }
        if (event.code === 'Escape' || event.code === 'Enter') {
            if (event.code === 'Escape') {
                this.workplaceNameRef.nativeElement.textContent = this.workplaceForm.name;
            }
            this.workplaceNameRef.nativeElement.blur();
        }
    }

    onWorkplaceNamePaste(event: any): void {
        event.preventDefault();

        let selectedText = window.getSelection().toString();
        let textToPaste = event.clipboardData.getData('text');
        if (selectedText) {
            this.workplaceNameRef.nativeElement.textContent = this.workplaceNameRef.nativeElement.textContent.replace(
                selectedText,
                textToPaste.substring(0, 64)
            );
        } else {
            this.workplaceNameRef.nativeElement.textContent += textToPaste.substring(0, 64 - this.workplaceNameRef.nativeElement.textContent.length);
        }
        this.checkWorkplaceNameAndSave(this.workplaceNameRef.nativeElement.textContent);
    }

    loadTabContent(tab: number): void {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            if (tab === this.WORKPLACES_TABS.LOCATION) {
                this.titleService.setTitle(this.translate.instant('workplaces.tabs.location'));
                this._route.updateParams({ tabName: 'location' });
            } else if (tab === this.WORKPLACES_TABS.HOLIDAYS) {
                this.titleService.setTitle(this.translate.instant('workplaces.tabs.holidays'));
                this._route.updateParams({ tabName: 'holidays' });
            } else if (tab === this.WORKPLACES_TABS.EMPLOYEES) {
                this.titleService.setTitle(this.translate.instant('workplaces.tabs.employees'));
                this._route.updateParams({ tabName: 'employees' });
            }
        }
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    addEmployeesToWorkplace(): void {
        // get all the employees from company for pre-charge modal
        this.workplacesService.findAllEmployees(this.workplaceId, { page: 0, size: 20000 }, null).subscribe(
            (response) => {
                this.formUtilsService.finishSubmitAction();
                const dialogSettings = new MatDialogConfig();
                dialogSettings.data = {
                    title: this.translate.instant('workplaces.employees.modal.title'),
                    users: response.content
                };
                dialogSettings.width = '65vw';
                dialogSettings.position = { top: '4vh' };
                dialogSettings.panelClass = ['animated', 'slideInDown'];
                this.dialog
                    .open(ChatroomAdminsModalComponent, dialogSettings)
                    .afterClosed()
                    .subscribe((result) => {
                        this.formUtilsService.finishSubmitAction();
                        if (result?.arePendingChanges && result?.addedUsers) {
                            this.workplaceEmployees = result.addedUsers;
                        }
                    });
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    addHolidaysToWorkplace(): void {
        this.addNewHoliday = true;
    }
}
