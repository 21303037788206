import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../../shared/services/dui-notifications.service';
import { TimeRecordEditModalComponent } from './time-record-edit-modal.component';
import { TimeTrackingService } from '../../../components/time-tracking/time-tracking.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'time-record-edit',
    templateUrl: './time-record-edit.component.html',
    styleUrls: ['./_time-record-edit.scss']
})
export class TimeRecordEditComponent implements OnChanges {
    public dayDetailForm: any = {};
    public months: Array<any> = [];
    public isSyncing: boolean;
    public decimalToHex: (color: any) => string;

    @Input() selectedRow: Array<any>;
    @Input() user: any;
    @Input() selectedDay: any;
    @Output() selectedRowChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedDayChange: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private translate: TranslateService,
        private timeTrackingService: TimeTrackingService,
        public utilsService: UtilsService,
        private Dialog: MatDialog,
        private dialogService: DialogService,
        private notificationService: DuiNotificationsService
    ) {
        if (window['getLocalizedMonthsNames']) {
            this.months = window['getLocalizedMonthsNames']();
        }
        this.decimalToHex = this.utilsService.decimalToHex;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.selectedRow?.currentValue &&
            changes.selectedRow?.currentValue !== changes.selectedRow?.previousValue &&
            changes.selectedRow.previousValue?.length > 0 &&
            changes.selectedRow.currentValue?.length > 0
        ) {
            this.isSyncing = true;
        }
        if (changes.selectedDay?.currentValue && changes.selectedDay?.currentValue !== changes.selectedDay?.previousValue && !changes.selectedDay.firstChange) {
            // Call endpoint for retrieve day records
            if (changes.selectedDay.currentValue.date) {
                this.timeTrackingService.findUserTimeRecordsDetailByDay(this.user.id, changes.selectedDay.currentValue.date).subscribe(
                    (response) => {
                        this.dayDetailForm = response;
                        this.isSyncing = false;
                    },
                    (error) => {}
                );
            }
        }
    }

    private getDefaultRecordKind(): any {
        let kind: any = this.dayDetailForm.enabledKinds.find((kind: any) => {
            if (kind.id === 1) {
                return kind;
            }
        });
        return kind;
    }

    private openEditionModal(title: string, records: Array<any>, editionMode: boolean): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            title: title,
            recordDate: this.dayDetailForm.date,
            userId: this.dayDetailForm.user.id,
            dayRecords: records,
            recordKinds: this.dayDetailForm.enabledKinds,
            editionMode: editionMode
        };
        dialogSettings.width = '459px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.Dialog.open(TimeRecordEditModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    this.selectedDayChange.emit();
                }
            });
    }

    getFormattedDate(): string {
        const date = new Date(this.selectedDay.date);
        return this.translate.instant('global.form.formattedDateLong', {
            day: date.getDate(),
            month: this.months[date.getMonth()]?.name,
            year: date.getFullYear()
        });
    }

    onCloseDetail(): void {
        this.selectedRow = [];
        this.selectedRowChange.emit(this.selectedRow);
    }

    addRecords(): void {
        let records: Array<any> = [];

        if (!!this.dayDetailForm.timePeriods) {
            this.dayDetailForm.timePeriods.forEach((period: any) => {
                records.push({
                    id: null,
                    recordDate: this.dayDetailForm.date,
                    startTime: period.startTime,
                    endTime: period.endTime,
                    kind: this.dayDetailForm.enabledKinds.length > 0 ? this.getDefaultRecordKind() : [],
                    valid: period.valid
                });
            });
        }

        const title = this.translate.instant('timeTracking.detail.addInterval');
        const editionMode = false;
        this.openEditionModal(title, records, editionMode);
    }

    editRecord(record: any): void {
        let records: Array<any> = [];
        records.push(record);

        const title = this.translate.instant('timeTracking.detail.editInterval');
        const editionMode = true;
        this.openEditionModal(title, records, editionMode);
    }

    deleteRecord(record: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'timeTracking.detail.deleteInterval',
            message: 'timeTracking.detail.deleteIntervalMsg'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.timeTrackingService.deleteUserDayRecord(record.id).subscribe(
                    (onSuccess) => {
                        this.selectedDayChange.emit();
                    },
                    (onError) => {
                        this.notificationService.showErrorNotification();
                    }
                );
            }
        });
    }
}
