import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../constants';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeedbackTraysService {
    private apiUrl: string;
    private feedbackTraysApiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.feedbackTraysApiUrl = this.apiUrl + '/feedback-trays';
    }

    findAll(options: any): Observable<any> {
        let params = new HttpParams()
            .set('page', options.page || '0')
            .set('size', options.size || Constants.PAGE_SIZE.toString())
            .set('archived', options.archived || 'false')
            .set('sort', options.sort ? options.sort : 'lastModifiedDate,desc');

        if (options.alias) {
            params = params.set('alias', options.alias);
        }

        const httpOptions = {
            params: params,
            reportProgress: true
        };

        return this.httpClient.get(`${this.apiUrl}/feedback-trays`, httpOptions);
    }

    duplicateTray(id: number, data: any): Observable<any> {
        return this.httpClient.post<any>(`${this.apiUrl}/feedback-trays/${id}/duplicate`, data);
    }

    deleteTray(id: number): Observable<any> {
        return this.httpClient.delete<any>(`${this.apiUrl}/feedback-trays/${id}`);
    }

    activateTray(id: number): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/feedback-trays/${id}/activate`, null);
    }

    archiveTray(id: number): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/feedback-trays/${id}/archive`, null);
    }
}
