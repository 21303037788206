import { Component, Input, OnInit } from '@angular/core';
import { CategoriesTreeService } from '../categories-tree/categories-tree.service';

@Component({
    selector: 'categories-filter',
    templateUrl: './categories-filter.component.html'
})
export class CategoriesFilterComponent implements OnInit {
    @Input() isEditable: boolean;

    public categoriesPath: Array<any> = [];

    constructor(private categoriesTreeService: CategoriesTreeService) {}

    ngOnInit() {
        this.categoriesTreeService.selectedCategoryPath.subscribe((categoriesPath) => {
            this.categoriesPath = categoriesPath;
        });
    }

    filterByCategory(categoryId) {
        if (categoryId) {
            this.categoriesTreeService.selectCategory(categoryId);
        }
    }

    removeCategoryFilter() {
        this.categoriesTreeService.clearSelectedCategoryPath();
    }
}
