import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NormalizeUnicodePipe } from '../../pipes/normalize-unicode.pipe';
import { Constants } from '../../../constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'avatar',
    templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnChanges, OnInit {
    @Input() name: string;
    @Input() surname: string;
    @Input() small: boolean;
    @Input() medium: boolean;
    @Input() large: boolean;
    @Input() xlarge: boolean;
    @Input() thumbnails?: any;
    @Input() state?: number;
    @Input() access?: string;
    @Input() title?: string;
    @Input() chatroom?: boolean;
    @Input() anonymous?: boolean;

    initials: string;
    avatarBGColor: string;
    thumbnail: string;
    public USER_STATES;

    constructor(
        private normalizeUnicodePipe: NormalizeUnicodePipe,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.USER_STATES = Constants.USER_STATES;
        this.state = this.state || null;
    }

    ngOnChanges(changes: any) {
        if (changes.name && changes.surname) {
            if (!!changes.name.currentValue && !!changes.surname.currentValue) {
                this.getUserInitialsAndColor();
            } else if (changes.name.currentValue !== changes.name.previousValue && changes.surname.currentValue !== changes.surname.previousValue) {
                this.initials = '';
                this.avatarBGColor = null;
                this.thumbnail = null;
            }
        } else if (changes.name || changes.surname) {
            this.getUserInitialsAndColor();
        }
        if (changes.thumbnails) {
            this.setThumbnail();
        }
    }

    private getUserInitialsAndColor() {
        const nameInitial = this.name.charAt(0);
        const surnameInitial = this.surname.charAt(0);
        this.initials = nameInitial + surnameInitial;
        this.avatarBGColor = this.getAvatarBGColor(nameInitial, surnameInitial);

        // this.setThumbnail();
    }

    private setThumbnail(): void {
        if (!!this.small) {
            this.thumbnail = this.thumbnails?.thumbnail_S || this.thumbnails?.thumbnail_M;
        } else {
            // If large or if size is not specified
            this.thumbnail = this.thumbnails?.thumbnail_M || this.thumbnails?.thumbnail_S;
        }
    }

    private getLetterColor(initial: string, colorsArray: Array<string>): string {
        const normalizedInitial = this.normalizeUnicodePipe.transform(initial);
        let avatarColor: string;
        switch (true) {
            case normalizedInitial >= 'A' && normalizedInitial <= 'C':
                avatarColor = colorsArray[0];
                break;
            case normalizedInitial >= 'D' && normalizedInitial <= 'F':
                avatarColor = colorsArray[1];
                break;
            case normalizedInitial >= 'G' && normalizedInitial <= 'I':
                avatarColor = colorsArray[2];
                break;
            case normalizedInitial >= 'J' && normalizedInitial <= 'L':
                avatarColor = colorsArray[3];
                break;
            case normalizedInitial === 'M' || normalizedInitial === 'N' || normalizedInitial === 'Ñ':
                avatarColor = colorsArray[4];
                break;
            case normalizedInitial >= 'O' && normalizedInitial <= 'Q':
                avatarColor = colorsArray[5];
                break;
            case normalizedInitial >= 'R' && normalizedInitial <= 'T':
                avatarColor = colorsArray[6];
                break;
            case normalizedInitial >= 'U' && normalizedInitial <= 'Z':
                avatarColor = colorsArray[7];
                break;
            default:
                avatarColor = '#8C8C8C';
                break;
        }
        return avatarColor;
    }

    private getAvatarBGColor(nameInitial: string, surnameInitial: string): string {
        const namesColorsFirst = ['#E24D48', '#D73965', '#9036AA', '#6141B1', '#4254AF', '#758DF1', '#4AA8EE', '#54BAD1'];
        const namesColorsSecond = ['#40B5A4', '#76AE66', '#97C05C', '#D0DA59', '#EBDA57', '#F6C244', '#F19C38', '#ED6337'];

        let avatarBGColor: string;
        if (nameInitial >= 'A' && nameInitial <= 'M') {
            avatarBGColor = this.getLetterColor(surnameInitial, namesColorsFirst);
        } else {
            avatarBGColor = this.getLetterColor(surnameInitial, namesColorsSecond);
        }
        return avatarBGColor;
    }

    getUserStatusText(): string {
        let userStateInfo: string;
        switch (this.state) {
            case this.USER_STATES.PENDING_ACTIVATION:
                userStateInfo = this.translate.instant('global.userstates.pendingactivation');
                break;
            case this.USER_STATES.LOCKED:
                userStateInfo = this.translate.instant('global.userstates.locked');
                break;
            case this.USER_STATES.EXPIRED_CREDENTIALS:
                userStateInfo = this.translate.instant('global.userstates.expiredCredentials');
                break;
            case this.USER_STATES.REGISTERED:
                if (!this.access) {
                    userStateInfo = this.translate.instant('global.userstates.pendingAccess');
                }
                break;
            default:
                break;
        }
        return userStateInfo;
    }
}
