import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DepartmentsService } from '../departments/departments.service';
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { cloneDeep, isEqual } from 'lodash';

@Component({
    selector: 'department',
    templateUrl: './department.component.html'
})
export class DepartmentComponent implements OnInit {
    public isSyncing: boolean = false;

    private _route: any;
    private initialDataForm: any = {};

    public departmentEmployeesDatagrid: GridDataResult = {
        data: [],
        total: 0
    };

    public gridPagerSettings: any = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };

    public dataPagingOpts: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: ['name,asc']
    };

    public dataFilterOpts: any = {
        active: true,
        name: ''
    };

    public totalPages: number = 0;
    public dataSkeleton: any[] = [{}, {}, {}];
    public gridSort: SortDescriptor[];
    private clickedRowItem: any = '';

    public optionResponsible: boolean;
    public departmentForm: any = {};
    public inputSearchField: string = '';

    private departmentId: number;

    @ViewChild('departmentEmployeesGrid') departmentEmployeesGrid: GridComponent;
    @ViewChild('departmentName') departmentNameRef: ElementRef;

    constructor(
        private departmentsService: DepartmentsService,
        private Dialog: MatDialog,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        private upgrade: UpgradeModule,
        public dialogService: DialogService,
        public formUtilsService: FormUtilsService
    ) {
        this._route = this.upgrade.$injector.get('$route');
        this.departmentId = this._route.current.params.id;
    }

    ngOnInit() {
        this.departmentsService.findOne(this.departmentId).subscribe((response: any) => {
            this.setFormData(response);
        });

        this.getDepartmentEmployeesGridData(this.departmentId, this.dataPagingOpts, this.dataFilterOpts);
    }

    //** Setting Form Data functions **//

    onChange(): void {
        if (!isEqual(this.initialDataForm, this.departmentForm)) {
            this.departmentFormSave();
        }
    }

    setFormData(dataFromResponse: any): void {
        this.departmentForm = Object.assign(this.departmentForm, dataFromResponse);
        this.optionResponsible = !!this.departmentForm.responsible ? true : false;
        this.initialDataForm = cloneDeep(this.departmentForm);
        this.onChange();
    }

    setResponsible() {
        this.optionResponsible = !this.optionResponsible;
        if (!this.optionResponsible && !!this.departmentForm.responsible) {
            this.departmentForm.responsible = null;
            this.departmentFormSave();
        }
    }

    //** Grid related functions **//

    getDepartmentEmployeesGridData(departmentId: number, pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;
        this.departmentsService.getEmployees(departmentId, pagingOptions, filterOptions).subscribe(
            (response) => {
                this.departmentEmployeesDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : ['lastModifiedDate,desc', 'name,asc'];
        this.getDepartmentEmployeesGridData(this.departmentId, this.dataPagingOpts, this.dataFilterOpts);
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / this.dataPagingOpts.size;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;
        this.getDepartmentEmployeesGridData(this.departmentId, this.dataPagingOpts, this.dataFilterOpts);
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }

    //** ContentEditable Functions **//

    private checkDepartmentNameAndSave(newName: string): void {
        if (!newName) {
            this.departmentNameRef.nativeElement.textContent = this.departmentForm.name;
            return;
        } else if (newName !== this.departmentForm.name) {
            this.departmentForm.name = this.departmentNameRef.nativeElement.textContent;
            this.departmentFormSave();
        }
    }

    onInputCompanyNameEvent(event: any) {
        if (event.type === 'keydown') {
            event.preventDefault();
            if (event.code === 'Escape') {
                this.departmentNameRef.nativeElement.textContent = this.departmentForm.name;
            }
            this.departmentNameRef.nativeElement.blur();
        }
        this.checkDepartmentNameAndSave(this.departmentNameRef.nativeElement.textContent);
    }

    onInputCompanyNamePaste(event: any): void {
        event.preventDefault();

        var selectedText = window.getSelection().toString();
        if (selectedText) {
            this.departmentNameRef.nativeElement.textContent = this.departmentNameRef.nativeElement.textContent.replace(
                selectedText,
                event.clipboardData.getData('text')
            );
        } else {
            this.departmentNameRef.nativeElement.textContent += event.clipboardData.getData('text');
        }
        this.checkDepartmentNameAndSave(this.departmentNameRef.nativeElement.textContent);
    }

    //** Search input Functions **//

    clearSearchInput() {
        this.dataFilterOpts.name = '';
        this.getDepartmentEmployeesGridData(this.departmentId, this.dataPagingOpts, this.dataFilterOpts);
    }

    listItemsSearch() {
        if (this.dataFilterOpts.name.length >= 3 || this.dataFilterOpts.name.length === 0) {
            this.getDepartmentEmployeesGridData(this.departmentId, this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    //** Row Action Functions **//

    deleteEmployeeFromDepartment(item: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'departments.employees.modal.delete.title',
            message: 'departments.employees.modal.delete.message',
            messageParam: { fullName: item.fullName }
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;

                this.departmentsService.deleteEmployee(this.departmentId, item.id).subscribe(
                    (onSuccess) => {
                        const message = this.translate.instant('departments.employees.modal.delete.deleted', { fullName: item.fullName });
                        this.notificationService.showSuccessNotification(message);
                        this.getDepartmentEmployeesGridData(this.departmentId, this.dataPagingOpts, this.dataFilterOpts);
                    },
                    (onError) => {}
                );
            }
        });
    }

    //** Add Users Modal Functions **//

    openAddUsersModal(): void {
        this.departmentsService.getEmployees(this.departmentId, { page: 0, size: 20000 }, null).subscribe(
            (response) => {
                this.formUtilsService.finishSubmitAction();

                const dialogSettings = new MatDialogConfig();
                dialogSettings.data = {
                    title: this.translate.instant('departments.employees.modal.add.title'),
                    users: response.content
                };
                dialogSettings.width = '65vw';
                dialogSettings.position = { top: '4vh' };
                dialogSettings.panelClass = ['animated', 'slideInDown'];

                this.Dialog.open(ChatroomAdminsModalComponent, dialogSettings)
                    .afterClosed()
                    .subscribe((result) => {
                        this.formUtilsService.finishSubmitAction();

                        if (result?.arePendingChanges && result?.addedUsers) {
                            this.departmentsService.updateEmployees(this.departmentId, result.addedUsers).subscribe(
                                (response: any) => {
                                    this.departmentEmployeesDatagrid.data = result.addedUsers;
                                    this.departmentEmployeesDatagrid.total = result.addedUsers.length;
                                    this.formUtilsService.finishSubmitAction();
                                    this.notificationService.showSuccessNotification();
                                },
                                (onError: any) => {}
                            );
                        }
                    });
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    //** Department Save Info Functions **//

    departmentFormSave(): void {
        this.departmentsService.update(this.departmentId, this.departmentForm).subscribe(
            (response: any) => {
                this.formUtilsService.finishSubmitAction();
                this.setFormData(response);
                this.notificationService.showSuccessNotification();
            },
            (onError: any) => {
                this.departmentForm.name = this.initialDataForm.name;
                this.departmentNameRef.nativeElement.textContent = this.initialDataForm.name;
            }
        );
    }
}
