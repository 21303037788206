<div class="main-container">
    <section class="main-container__header" style="min-width: 891px;">
        <div class="flex align-items-end justify-space-between width-100-percent">
            <!-- Breadcrumbs and editable schema name -->
            <article class="flex direction-column width-100-percent" spellcheck="false">
                <ul class="breadcrumb">
                    <li>
                        <a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
                    </li>
                    <li>
                        <a id="go-schemas" href="/settings/timeoff/schemes" [translate]="'timeoffSettings.tabs.schemes'"></a>
                    </li>
                </ul>
                <div class="editable-wrapper">
                    <h2
                        #schemaName
                        class="icon icon-edit contenteditable editable-title width-50-percent"
                        [textContent]="schemaForm?.timeOffSchema?.name"
                        ([ngModel])="schemaForm?.timeOffSchema?.name"
                        [contentEditable]="true"
                        prevent-event="dragover drop"
                        maxlength="64"
                        (blur)="onSchemaNameBlur()"
                        (keydown)="onSchemaNameKeyDown($event)"
                        (paste)="onSchemaNamePaste($event)"></h2>
                    <span class="bottom-line"></span>
                </div>
            </article>
            <!-- Action buttons -->
            <div class="flex align-items-center" kendoTooltip filter="button" position="top">
                <!-- Add employees to schema -->
                @if (selectedTab === TIMEOFF_SCHEMA_TABS.EMPLOYEES) {
                    <loading-button
                        [buttonId]="'add-employees-modal'"
                        [classes]="'button button--secondary button--small'"
                        [iconClass]="'icon-add'"
                        [text]="'schemes.employees.addEmployees'"
                        (action)="addEmployeesToSchema()"
                        [isValid]="true">
                    </loading-button>
                }
            </div>
        </div>
    </section>
    <section class="main-container__content">
        <div class="main-container--full-width">
            <!-- Schema tabs -->
            <div class="tabs-language-container">
                <div class="tab-nav tab-nav--inline" style="width:100%">
                    <span
                        id="general-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === TIMEOFF_SCHEMA_TABS.GENERAL}"
                        [translate]="'schemes.tabs.general'"
                        (click)="loadTabContent(TIMEOFF_SCHEMA_TABS.GENERAL)">
                    </span>
                    <span
                        id="absences-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === TIMEOFF_SCHEMA_TABS.ABSENCES}"
                        [translate]="'schemes.tabs.absences'"
                        (click)="loadTabContent(TIMEOFF_SCHEMA_TABS.ABSENCES)">
                    </span>
                    <span
                        id="employees-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === TIMEOFF_SCHEMA_TABS.EMPLOYEES}"
                        [translate]="'schemes.tabs.employees'"
                        (click)="loadTabContent(TIMEOFF_SCHEMA_TABS.EMPLOYEES)">
                    </span>
                    <!-- Language selector if absences tab is selected -->
                    @if (selectedTab === TIMEOFF_SCHEMA_TABS.ABSENCES) {
                        <div id="timeoff-abs-lang" class="language-selector">
                            @if (availableCompanyLanguages.length <= 4) {
                                <span
                                    class="default-language"
                                    [ngClass]="{ 'selected': selectedLanguage === defaultLanguage }"
                                    [translate]="'global.language.' + defaultLanguage"
                                    (click)="selectedLanguage = defaultLanguage"></span>
                                @for (language of availableCompanyLanguages | sort; track language; let idx = $index) {
                                    @if (defaultLanguage !== language) {
                                        <span
                                            [ngClass]="{ 'selected': selectedLanguage === language }"
                                            [translate]="'global.language.' + language"
                                            (click)="selectedLanguage = language"></span>
                                    }
                                }
                            }

                            @if (availableCompanyLanguages.length > 4) {
                                <kendo-dropdownlist
                                    id="language-select"
                                    name="languageSelect"
                                    class="dui-dropdown"
                                    [data]="availableCompanyLanguages"
                                    [(ngModel)]="selectedLanguage"
                                    [popupSettings]="{ animate: { type: 'expand', direction: 'down' }, popupClass: 'dialenga-dropdownlist__popup', width: 144 }">
                                    <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>
                                    <ng-template kendoDropDownListNoDataTemplate>
                                        <h5 class="uppercase" [translate]="'global.form.emptyDataSet'"></h5>
                                    </ng-template>
                                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                                        <span class="selected padding-right-8" [translate]="'global.language.' + dataItem"> </span>
                                    </ng-template>
                                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                                        <span class="dialenga-dropdownlist__popup-value" [translate]="'global.language.' + dataItem"> </span>
                                    </ng-template>
                                </kendo-dropdownlist>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </section>

    @if (selectedTab === TIMEOFF_SCHEMA_TABS.GENERAL) {
        <schema-general [schemaId]="schemaId" [selectedLanguage]="selectedLanguage" [(schemaForm)]="schemaForm"></schema-general>
    }
    @if (selectedTab === TIMEOFF_SCHEMA_TABS.ABSENCES) {
        <schema-absences [schemaId]="schemaId" [selectedLanguage]="selectedLanguage" [defaultLanguage]="defaultLanguage"></schema-absences>
    }
    @if (selectedTab === TIMEOFF_SCHEMA_TABS.EMPLOYEES) {
        <schema-employees [schemaId]="schemaId" [(employees)]="schemaEmployees"></schema-employees>
    }
</div>
