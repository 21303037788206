import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { TimeTrackingService } from '../time-tracking/time-tracking.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { isEqual, cloneDeep } from 'lodash';
import { Constants } from '../../constants';

declare var moment: any;

@Component({
    selector: 'schedule-general',
    templateUrl: './schedule-general.component.html',
    styleUrls: ['./_schedule-general.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScheduleGeneralComponent implements OnChanges {
    @Input() scheduleForm: any;
    @Input() scheduleId: number;
    @Output() scheduleFormChange: EventEmitter<any> = new EventEmitter<any>();

    public SCHEDULE_PERIOD_KIND: any;
    public days: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    public months: Array<any>;

    public rateControl: any;
    private _rootScope: any;
    private initialDataForm: any = {};

    public isFormDataValid: boolean;
    public isFormDataChanged: boolean = false;
    public formHasErrorsChecked: boolean = false;
    public overlapErrors: boolean = false;
    public weekDaysSelection: Array<any>;
    private schedulesPath: string = '/settings/time-tracking/schedules';

    @ViewChild('scheduleName') scheduleNameRef: ElementRef;

    constructor(
        private upgrade: UpgradeModule,
        private timeTrackingService: TimeTrackingService,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        private formUtilsService: FormUtilsService
    ) {
        this._rootScope = this.upgrade.$injector.get('$rootScope');
        this.SCHEDULE_PERIOD_KIND = Constants.SCHEDULE_PERIOD_KIND;

        this.weekDaysSelection = [
            { name: this.translate.instant('global.form.weekDaysAbbr', { day: 1 }), dayOfWeek: 1, active: false, totalTime: { hours: 0, minutes: 0 } },
            { name: this.translate.instant('global.form.weekDaysAbbr', { day: 2 }), dayOfWeek: 2, active: false, totalTime: { hours: 0, minutes: 0 } },
            { name: this.translate.instant('global.form.weekDaysAbbr', { day: 3 }), dayOfWeek: 3, active: false, totalTime: { hours: 0, minutes: 0 } },
            { name: this.translate.instant('global.form.weekDaysAbbr', { day: 4 }), dayOfWeek: 4, active: false, totalTime: { hours: 0, minutes: 0 } },
            { name: this.translate.instant('global.form.weekDaysAbbr', { day: 5 }), dayOfWeek: 5, active: false, totalTime: { hours: 0, minutes: 0 } },
            { name: this.translate.instant('global.form.weekDaysAbbr', { day: 6 }), dayOfWeek: 6, active: false, totalTime: { hours: 0, minutes: 0 } },
            { name: this.translate.instant('global.form.weekDaysAbbr', { day: 7 }), dayOfWeek: 7, active: false, totalTime: { hours: 0, minutes: 0 } }
        ];
        this.months = [
            { value: 1, abbr: this.translate.instant('global.form.monthsAbbr', { month: 1 }) },
            { value: 2, abbr: this.translate.instant('global.form.monthsAbbr', { month: 2 }) },
            { value: 3, abbr: this.translate.instant('global.form.monthsAbbr', { month: 3 }) },
            { value: 4, abbr: this.translate.instant('global.form.monthsAbbr', { month: 4 }) },
            { value: 5, abbr: this.translate.instant('global.form.monthsAbbr', { month: 5 }) },
            { value: 6, abbr: this.translate.instant('global.form.monthsAbbr', { month: 6 }) },
            { value: 7, abbr: this.translate.instant('global.form.monthsAbbr', { month: 7 }) },
            { value: 8, abbr: this.translate.instant('global.form.monthsAbbr', { month: 8 }) },
            { value: 9, abbr: this.translate.instant('global.form.monthsAbbr', { month: 9 }) },
            { value: 10, abbr: this.translate.instant('global.form.monthsAbbr', { month: 10 }) },
            { value: 11, abbr: this.translate.instant('global.form.monthsAbbr', { month: 11 }) },
            { value: 12, abbr: this.translate.instant('global.form.monthsAbbr', { month: 12 }) }
        ];
    }

    private calculateTotalTimePerDay(timePeriodDay: any, schedule: any): void {
        let weekDayIndex = this.getIndexDayInWeekDaysSelection(timePeriodDay.dayOfWeek, schedule);

        if (timePeriodDay.timePeriods.length === 0) {
            schedule.weekDaysSelection[weekDayIndex].totalTime = { hours: 0, minutes: 0 };
        } else {
            let totalTimeDay = 0;
            timePeriodDay.timePeriods.forEach((period: any) => {
                if (period.startTime !== '00:00' || period.endTime !== '00:00') {
                    let start = period.startTime.split(':');
                    start = start.length === 1 && start[0] === '' ? ['0', '0'] : start;

                    let end = period.endTime.split(':');
                    end = end.length === 1 && end[0] === '' ? ['0', '0'] : end;

                    let startDate = moment().startOf('day').set('hour', parseInt(start[0])).set('minute', parseInt(start[1]));
                    let endDate = moment().startOf('day').set('hour', parseInt(end[0])).set('minute', parseInt(end[1]));

                    endDate = endDate < startDate ? endDate.add(1, 'day') : endDate;

                    totalTimeDay += moment.duration(endDate - startDate).asHours();
                }
            });
            schedule.weekDaysSelection[weekDayIndex].totalTime = {
                hours: Math.floor(totalTimeDay),
                minutes: Math.round((totalTimeDay - Math.floor(totalTimeDay)) * 60)
            };
        }
    }

    private setCursorPosition(element, pos) {
        let range: any, selection: any;
        range = document.createRange();
        selection = window.getSelection();

        if (pos === 'start') {
            range.setStart(element.childNodes[0], 1);
            range.collapse(true);
        } else if (pos === 'end') {
            range.selectNodeContents(element);
            range.collapse(false);
        }
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }

    private checkScheduleName(newName: string, element: any): void {
        if (!newName) {
            element.innerText = this.scheduleForm.schedules[element.id].name;
            return;
        } else if (newName !== this.scheduleForm.schedules[element.id].name) {
            this.scheduleForm.schedules[element.id].name = element.innerText;
            this.onChange();
        }
    }

    private getIndexDayInWeekDaysSelection(dayOfWeek: number, schedule: any): number {
        return schedule.weekDaysSelection.findIndex((weekDay) => {
            return weekDay.dayOfWeek == dayOfWeek;
        });
    }

    private setInitialFormData(): void {
        this.initialDataForm = cloneDeep(this.scheduleForm);
        this.scheduleFormChange.emit(this.scheduleForm);
        this.onChange();
    }

    private initWeekDaysSelectionAllSchedules(): void {
        this.scheduleForm.schedules?.forEach((schedule: any) => {
            schedule.weekDaysSelection = cloneDeep(this.weekDaysSelection);

            schedule.workDays.forEach((day: any) => {
                let index = schedule.weekDaysSelection.findIndex((weekDay) => {
                    return weekDay.dayOfWeek == day.dayOfWeek;
                });
                schedule.weekDaysSelection[index].active = true;
                this.calculateTotalTimePerDay(day, schedule);
            });
        });
    }

    private checkAndParseDataBeforeSave(): void {
        this.scheduleForm.schedules.forEach((schedule: any) => {
            // if schedule margins are empty, set flexibleSchedule to false
            if (
                !schedule.scheduleMargin.priorMarginIn &&
                !schedule.scheduleMargin.priorMarginOut &&
                !schedule.scheduleMargin.laterMarginIn &&
                !schedule.scheduleMargin.laterMarginOut
            ) {
                schedule.scheduleMargin.flexibleSchedule = false;
            }
            // Remove empty or invalid timePeriods from workDays if they are not first
            schedule.workDays.forEach((workday: any) => {
                workday.timePeriods = workday.timePeriods.filter((period: any, index: number) => {
                    return index === 0 || period.startTime !== '00:00' || period.endTime !== '00:00';
                });
            });
            // Remove empty or invalid datePeriods if they are not first
            schedule.datePeriods = schedule.datePeriods.filter((datePeriod: any, index: number) => {
                return index === 0 || (!!datePeriod.startDay && !!datePeriod.startMonth && !!datePeriod.endDay && !!datePeriod.endMonth);
            });
            schedule.datePeriods = schedule.periodKind === this.SCHEDULE_PERIOD_KIND.ALWAYS ? [] : schedule.datePeriods;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.scheduleForm && changes.scheduleForm.currentValue && changes.scheduleForm.previousValue !== changes.scheduleForm.currentValue) {
            this.initWeekDaysSelectionAllSchedules();
            this.setInitialFormData();
        }
    }

    checkIfDataFormAreChanged(): void {
        this.isFormDataChanged = !isEqual(this.initialDataForm, this.scheduleForm);
    }

    checkIfDataFormAreValid(): void {
        this.scheduleForm.schedules?.every((schedule: any) => {
            this.isFormDataValid = this.allPeriodsInScheduleAreValid(schedule);
            return this.isFormDataValid;
        });
    }

    onChange() {
        this.checkIfDataFormAreChanged();
        this.checkIfDataFormAreValid();
    }

    onScheduleNameEvent(event: any) {
        if (event.type === 'keydown') {
            event.preventDefault();
            if (event.code === 'Escape') {
                event.target.innerText = this.scheduleForm.schedules[event.target.id].name;
            }
            event.target.blur();
        }
        this.checkScheduleName(event.target.innerText, event.target);
    }

    onScheduleNamePaste(event: any): void {
        event.preventDefault();

        var selectedText = window.getSelection().toString();
        if (selectedText) {
            event.target.innerText = event.target.innerText.replace(selectedText, event.clipboardData.getData('text'));
        } else {
            event.target.innerText += event.clipboardData.getData('text');
        }
        this.checkScheduleName(event.target.innerText, event.target);
        this.setCursorPosition(event.target, 'end');
    }

    scheduleFormSave(): void {
        this.checkAndParseDataBeforeSave();
        this.timeTrackingService.update(this.scheduleId, this.scheduleForm).subscribe(
            (response: any) => {
                this.scheduleForm = response;
                this.setInitialFormData();
                this.formHasErrorsChecked = false;
                this.overlapErrors = false;
                this.formUtilsService.finishSubmitAction();

                this.notificationService.showSuccessNotification();
            },
            (response: any) => {
                this.formHasErrorsChecked = true;
                if (response.error?.schedules) {
                    this.overlapErrors = true;
                    this.scheduleForm = response.error;
                    this.initWeekDaysSelectionAllSchedules();
                } else {
                    this.overlapErrors = false;
                }
            }
        );
    }

    createSchedule(): void {
        let newSchedule = {
            datePeriods: [{ startDay: null, startMonth: null, endDay: null, endMonth: null }],
            id: null,
            name: 'Horario adicional',
            parent: false,
            periodKind: this.SCHEDULE_PERIOD_KIND.CUSTOM,
            scheduleMargin: {
                flexibleSchedule: false,
                laterMarginIn: null,
                laterMarginOut: null,
                priorMarginIn: null,
                priorMarginOut: null
            },
            weekDaysSelection: [],
            workDays: [
                { dayOfWeek: 1, id: null, timePeriods: [{ startTime: '00:00', endTime: '00:00' }] },
                { dayOfWeek: 2, id: null, timePeriods: [{ startTime: '00:00', endTime: '00:00' }] },
                { dayOfWeek: 3, id: null, timePeriods: [{ startTime: '00:00', endTime: '00:00' }] },
                { dayOfWeek: 4, id: null, timePeriods: [{ startTime: '00:00', endTime: '00:00' }] },
                { dayOfWeek: 5, id: null, timePeriods: [{ startTime: '00:00', endTime: '00:00' }] }
            ]
        };
        setTimeout(() => {
            this.scheduleForm.schedules.push(newSchedule);

            let schedule = this.scheduleForm.schedules[this.scheduleForm.schedules.length - 1];
            schedule.weekDaysSelection = cloneDeep(this.weekDaysSelection);

            schedule.workDays.forEach((day: any) => {
                let index = schedule.weekDaysSelection.findIndex((weekDay) => {
                    return weekDay.dayOfWeek == day.dayOfWeek;
                });
                schedule.weekDaysSelection[index].active = true;
            });
            this.onChange();
        }, 100);
    }

    removeSchedule(idx: number): void {
        this.scheduleForm.schedules.splice(idx, 1);
        this.onChange();
    }

    setSchedulePeriodKind(schedule: any, kind: number): void {
        schedule.periodKind = kind;
        if (kind === this.SCHEDULE_PERIOD_KIND.CUSTOM && schedule.datePeriods?.length < 1) {
            schedule.datePeriods.push({ startDay: null, startMonth: null, endDay: null, endMonth: null });
        }
        this.onChange();
    }

    existsOneValidPeriod(schedule: any): boolean {
        let validPeriod: number = schedule.datePeriods.findIndex((datePeriod: any) => {
            return !!datePeriod.startDay && !!datePeriod.startMonth && !!datePeriod.endDay && !!datePeriod.endMonth;
        });
        return validPeriod !== -1;
    }

    allPeriodsInScheduleAreValid(schedule: any): boolean {
        if (schedule.periodKind === this.SCHEDULE_PERIOD_KIND.ALWAYS) {
            return true;
        } else {
            let invalidPeriod: number = schedule.datePeriods.findIndex((datePeriod: any) => {
                return !datePeriod.startDay || !datePeriod.startMonth || !datePeriod.endDay || !datePeriod.endMonth;
            });
            return invalidPeriod === -1;
        }
    }

    addPeriodDate(schedule: any): void {
        schedule.datePeriods.push({ startDay: null, startMonth: null, endDay: null, endMonth: null, valid: true });
    }

    deletePeriodDate(schedule: any, periodIndex: number): void {
        schedule.datePeriods.splice(periodIndex, 1);
        // this.datePeriodNotValid = false;
        this.onChange();
    }

    onlyOneDayIsActive(schedule: any): boolean {
        let activeDays = schedule.weekDaysSelection.filter((day: any) => {
            return day.active;
        });
        return activeDays.length === 1;
    }

    setActiveOrIdleDay(schedule: any, day: any): void {
        if (day.active) {
            // remove period time from schedule
            let index = schedule.workDays.findIndex((weekDay) => {
                return weekDay.dayOfWeek == day.dayOfWeek;
            });
            if (index !== -1) {
                schedule.workDays.splice(index, 1);
            }
            day.totalTime = { hours: 0, minutes: 0 };
        } else {
            // add period time to scheduleForm
            schedule.workDays.push({ dayOfWeek: day.dayOfWeek, timePeriods: [{ startTime: '00:00', endTime: '00:00' }] });
            schedule.workDays.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
        }
        day.active = !day.active;
        this.onChange();
    }

    addPeriodTime(timePeriodDay: any): void {
        timePeriodDay.timePeriods.push({ startTime: '00:00', endTime: '00:00', valid: true });
    }

    deletePeriodTime(timePeriodDay: any, idx: number, schedule: any): void {
        timePeriodDay.timePeriods.splice(idx, 1);
        this.calculateTotalTimePerDay(timePeriodDay, schedule);
        this.onChange();
    }

    onChangePeriod(timePeriodDay: any, schedule: any): void {
        this.calculateTotalTimePerDay(timePeriodDay, schedule);
        this.onChange();
    }

    changePeriodTime(event: any, period: any, element: any) {
        // Not exist event if keypressed is supr or backspace
        if (!event) {
            element.value = !period[element.name] ? '00:00' : period[element.name];
        }
    }

    onCopyTimePeriod(event: any, element: any) {
        event.preventDefault();
        event.clipboardData.setData('text/plain', element.value);
    }

    onPasteTimePeriod(event: any, period: any, element: any) {
        event.preventDefault();
        const valueToPaste = event.clipboardData.getData('text');
        if (new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$').test(valueToPaste)) {
            element.value = valueToPaste;
            period[element.name] = valueToPaste;
        }
    }

    onInputNumberChange(field: any, schedule: any): void {
        let value: number = field.value <= 0 ? 0 : field.value > 99 ? 99 : parseInt(field.value);
        field.value = value;
        schedule.scheduleMargin[field.name] = value;
    }

    goBack(): void {
        this._rootScope.goBackAndCheckForModifications(this.isFormDataChanged, this.schedulesPath);
    }

    getTotalTimePerDay(dayOfWeek: number, schedule: any): string {
        let hours = schedule.weekDaysSelection[this.getIndexDayInWeekDaysSelection(dayOfWeek, schedule)]?.totalTime.hours;
        let minutes = schedule.weekDaysSelection[this.getIndexDayInWeekDaysSelection(dayOfWeek, schedule)]?.totalTime.minutes;
        return hours + 'h ' + (minutes ? minutes + 'm' : '');
    }

    getTotalTimeBySchedule(schedule: any): string {
        let hours: number = 0;
        let minutes: number = 0;
        schedule.weekDaysSelection.forEach((day: any) => {
            hours += day.totalTime.hours;
            minutes += day.totalTime.minutes;
        });
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
        return hours + 'h ' + (minutes ? minutes + 'm' : '');
    }
}
