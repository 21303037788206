import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../../constants';

@Pipe({
    name: 'userStateIcon'
})
export class UserStateIconPipe implements PipeTransform {
    transform(user: any): string {
        let icon = 'icon ';

        switch (parseInt(user.state)) {
            case Constants.USER_STATES.ANONYMOUS:
                icon += ' icon-help-fill';
                break;
            case Constants.USER_STATES.PENDING_ACTIVATION:
                icon += ' icon-state-pending-activation';
                break;
            case Constants.USER_STATES.REGISTERED:
                icon = typeof user.lastAccessDate === 'undefined' || user.lastAccessDate ? '' : 'icon icon-state-wating-access';
                break;
            case Constants.USER_STATES.EXPIRED:
                icon += ' icon-state-expired';
                break;
            case Constants.USER_STATES.DISABLED:
                icon += ' icon-state-archived';
                break;
            case Constants.USER_STATES.EXPIRED_CREDENTIALS:
                icon += ' icon-state-locked yellow';
                break;
            case Constants.USER_STATES.LOCKED:
                icon += ' icon-state-locked red';
                break;
            default:
                break;
        }
        return icon;
    }
}
