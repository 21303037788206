<div class="main-container__content margin-top-20" style="min-width: 891px;">
    <div class="main-container--full-width">
        <div class="document-container">
            <div class="document-container__left">
                <div class="filter-tags-list">
                    <div
                        class="filter-tags-list__item margin-bottom-20"
                        [ngClass]="{'selected': datagridFilteringOptions.kind === DOCUMENT_KIND_ALL}"
                        (click)="onSelectDocumentKindFilter({id: DOCUMENT_KIND_ALL})">
                        <span class="icon icon-tag-fill-s margin-right-10"></span>
                        <span [textContent]="'documents.documentsKind.filterTagNames.all' | translate"></span>
                    </div>
                    @for (optionKind of documentsKindOptions | sort:'name'; track optionKind; let first = $first; let index = $index) {
                        <div
                            class="filter-tags-list__item margin-top-10"
                            (click)="onSelectDocumentKindFilter(optionKind)"
                            [ngClass]="{'selected': optionKind.id === datagridFilteringOptions.kind}">
                            <span class="icon icon-tag-fill-s margin-right-10" [ngStyle]="{'color': getDocumentTagHexColor(optionKind.color)}"></span>
                            <span class="truncate" [textContent]="optionKind.name"></span>
                        </div>
                    }
                    <div
                        class="filter-tags-list__item margin-top-10"
                        [ngClass]="{'selected': datagridFilteringOptions.kind === DOCUMENT_KIND_EMPTY}"
                        (click)="onSelectDocumentKindFilter({id: DOCUMENT_KIND_EMPTY})">
                        <span class="icon icon-tag-s margin-right-10 black-45"></span>
                        <span [textContent]="'documents.documentsKind.filterTagNames.other' | translate"></span>
                    </div>
                </div>
            </div>
            <!-- Master grid for document shipments -->
            <div class="document-container__right">
                <div class="filter-container align-items-start margin-top-5">
                    <!-- filter by year and month shipment date -->
                    <div class="flex direction-row align-items-center margin-left-10">
                        <span [translate]="'documents.list.filters.yearLabel'" class="margin-right-5"></span>
                        <kendo-dropdownlist
                            style="width: 100px"
                            [defaultItem]="itemAllYears"
                            [data]="years"
                            [(ngModel)]="selectedYears"
                            class="kendo-angular-dropdown margin-right-20"
                            valuePrimitive="true"
                            (valueChange)="onChangeYearFilter($event)"
                            [textField]="'name'"
                            valueField="value"
                            [popupSettings]="{ width: 'auto'}">
                            <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span [textContent]="dataItem.name" [ngClass]="dataItem.value ? 'featured-selection' : ''"></span>
                            </ng-template>
                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span class="padding-3" [textContent]="dataItem.name"></span>
                            </ng-template>
                        </kendo-dropdownlist>

                        <span [translate]="'documents.list.filters.monthLabel'" class="margin-right-5"></span>
                        <kendo-dropdownlist
                            style="width: fit-content"
                            [defaultItem]="itemAllMonths"
                            [data]="months"
                            [(ngModel)]="selectedMonths"
                            class="kendo-angular-dropdown"
                            valuePrimitive="true"
                            (valueChange)="onChangeMonthFilter($event)"
                            [textField]="'name'"
                            valueField="value"
                            [popupSettings]="{ width: 'auto'}">
                            <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                <span
                                    [textContent]="dataItem.name"
                                    style="text-transform: capitalize;"
                                    [ngClass]="dataItem.value ? 'featured-selection' : ''"></span>
                            </ng-template>
                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span class="capitalize padding-3" [textContent]="dataItem.name"></span>
                            </ng-template>
                        </kendo-dropdownlist>
                        @if (selectedYears || selectedMonths) {
                            <div kendoTooltip filter="button" position="top">
                                <button
                                    id="clear-date-filters"
                                    class="button button--small button--only-icon icon icon-18 icon-clear margin-left-10"
                                    style="width: 30px; height: 30px;"
                                    (click)="resetDateFilters()"
                                    [title]="'documents.list.filters.reset' | translate"></button>
                            </div>
                        }
                    </div>
                    <!-- filter by username, internal id, email and document name -->
                    <div class="search-filter-container align-items-center margin-bottom-5">
                        <input
                            type="text"
                            class="input input--search"
                            maxlength="1024"
                            [placeholder]="'documents.list.filters.searchPlaceholder' | translate"
                            [(ngModel)]="datagridFilteringOptions.name"
                            (input)="listItemsSearch()"
                            ngModelOptions="{ debounce: 200 }"
                            [ngClass]="datagridFilteringOptions.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'" />
                        <div class="icon icon-remove" (click)="clearSearchInput()"></div>
                    </div>
                </div>

                @if (documentsDatagrid?.data.length > 0) {
                    <kendo-grid
                        #documentsGrid
                        [data]="documentsDatagrid"
                        class="master-grid documents-grid"
                        (cellClick)="onCellClick($event)"
                        [pageable]="gridPagerSettings"
                        (pageChange)="onPageChange($event)"
                        [skip]="datagridPagingOptions.skip"
                        [pageSize]="datagridPagingOptions.size"
                        [navigable]="true"
                        [resizable]="false"
                        [selectable]="false"
                        [rowClass]="rowCallback"
                        [loading]="isSyncing">
                        <kendo-grid-messages
                            [detailExpand]="'Mostrar detalle'"
                            [detailCollapse]="'Ocultar detalle'"
                            [pagerItemsPerPage]="'items por página'"
                            [pagerOf]="'de'"
                            [pagerItems]="'ítems'">
                        </kendo-grid-messages>
                        <!-- Checkbox for select row-->
                        <kendo-grid-column width="60">
                            <ng-template kendoGridHeaderTemplate>
                                <label class="checkbox__container">
                                    <span
                                        class="checkbox icon icon-24"
                                        [ngClass]="toggleAllItemsSelect ? 'checkbox--on icon-checkbox-label-active': 'icon-checkbox-label'"></span>
                                    <!-- <span class="checkbox icon icon-24"
                  [ngClass]="toggleAllItemsSelect ? (selectedRows === datagridPagingOptions.size ? 'checkbox--on icon-checkbox-label-active' : 'checkbox--on icon-checkbox-semi') : (selectedRows > 0 ? 'checkbox--on icon-checkbox-semi' : 'icon-checkbox-label')" ></span> -->
                                    <input
                                        class="checkbox__input"
                                        type="checkbox"
                                        id="selectAllCheckboxId"
                                        [(ngModel)]="toggleAllItemsSelect"
                                        (click)="allRowsToggleSelectedProperty()" />
                                </label>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <label class="checkbox__container justify-content-center">
                                    <span
                                        class="checkbox icon icon-24 margin-0"
                                        [ngClass]="dataItem.selectedItem ? 'checkbox--on icon-checkbox-label-active': 'icon-checkbox-label'"></span>
                                    <input class="checkbox__input" type="checkbox" id="selectCheckbox" [(ngModel)]="dataItem.selectedItem" disabled />
                                </label>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Document -->
                        <kendo-grid-column
                            field="resource.nameWithExtension"
                            [headerStyle]="{'padding-block': '0.5em', 'width': '30%'}"
                            [style]="{'width': '30%'}">
                            <ng-template kendoGridHeaderTemplate>
                                @if (selectedRows === 0) {
                                    <div class="flex align-items-center">
                                        <span [textContent]="'model.users.documents.document' | translate"></span>
                                    </div>
                                }
                                @if (selectedRows > 0) {
                                    <div class="flex align-items-center gap-8" kendoTooltip filter="label" position="top">
                                        @if (false) {
                                            <label for="rename-docs" [title]="'documents.list.actions.label' | translate">
                                                <loading-button
                                                    [buttonId]="'rename-docs'"
                                                    [iconClass]="'icon-tag-s'"
                                                    name="rename-docs"
                                                    [classes]="'button button--small button--only-icon'"
                                                    (action)="relabelSelectedDocs()"
                                                    [isValid]="true">
                                                </loading-button>
                                            </label>
                                        }
                                        @if (false) {
                                            <label for="download-docs" [title]="'global.modal.download' | translate">
                                                <loading-button
                                                    [buttonId]="'download-docs'"
                                                    [iconClass]="'icon-download'"
                                                    name="download-docs"
                                                    [classes]="'button button--small button--only-icon'"
                                                    (action)="downloadSelectedDocs()"
                                                    [isValid]="true">
                                                </loading-button>
                                            </label>
                                        }
                                        <label for="delete-docs" [title]="'global.form.delete' | translate">
                                            <loading-button
                                                [buttonId]="'delete-docs'"
                                                [iconClass]="'icon-delete'"
                                                name="delete-docs"
                                                [classes]="'button button--small button--only-icon'"
                                                (action)="deleteSelectedDocs()"
                                                [isValid]="true">
                                            </loading-button>
                                        </label>
                                    </div>
                                }
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <img src="{{iconFromMime(dataItem.resource.mimetype)}}" class="margin-right-5" alt="" />
                                    <span class="text-sm black-60 truncate" [textContent]="dataItem.resource.name"></span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Employee data -->
                        <kendo-grid-column field="employee" [headerStyle]="{'width': '25%'}" [style]="{'width': '25%'}">
                            <ng-template kendoGridHeaderTemplate>
                                @if (selectedRows === 0) {
                                    <div class="flex align-items-center">
                                        <span [textContent]="'remoteSignature.grid.employee' | translate"></span>
                                    </div>
                                }
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <avatar
                                        [name]="dataItem.employee.name"
                                        [surname]="dataItem.employee.surname"
                                        [small]="true"
                                        [thumbnails]="{thumbnail_M: dataItem.employee.avatarThumbnailMUrl || dataItem.employee.avatarUrl, thumbnail_S: dataItem.employee.avatarThumbnailSUrl || dataItem.employee.avatarThumbnailMUrl}">
                                    </avatar>
                                    <span class="margin-left-8 truncate" [textContent]="dataItem.employee.fullName"></span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Document kind -->
                        <kendo-grid-column field="kind" [headerStyle]="{'width': '20%'}" [style]="{'width': '20%'}">
                            <ng-template kendoGridHeaderTemplate>
                                @if (selectedRows === 0) {
                                    <div class="flex align-items-center">
                                        <span [textContent]="'documents.list.label' | translate"></span>
                                    </div>
                                }
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div
                                    class="flex align-items-center document-label"
                                    [ngStyle]="{'background-color': getDocumentTagHexColor(dataItem.fileKind?.color)}">
                                    <span class="document-label__text" [textContent]="dataItem.fileKind?.name"></span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- Document shipment date -->
                        <kendo-grid-column field="privateDocumentDate" media="(min-width: 1240px)" [headerStyle]="{'width': '15%'}" [style]="{'width': '15%'}">
                            <ng-template kendoGridHeaderTemplate>
                                @if (selectedRows === 0) {
                                    <div class="flex align-items-center">
                                        <span [textContent]="'documents.list.shipmentDate' | translate"></span>
                                    </div>
                                }
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <span
                                        class="truncate"
                                        style="text-transform: lowercase;"
                                        [textContent]="dataItem.privateDocumentDate | date: 'dd/MM/yyyy'"></span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- CTA buttons column -->
                        <kendo-grid-command-column title="" [width]="144" [style]="{'text-align': 'end'}">
                            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                                @if (!dataItem.selectedItem) {
                                    <div class="td-on-hover">
                                        @if (dataItem.resource.mimetype == 'application/pdf') {
                                            <a class="k-button k-master-button" (click)="actionViewDocument(dataItem)">
                                                <span class="icon icon-eye-opened"></span>
                                            </a>
                                        }
                                        @if (dataItem.resource.mimetype !== 'application/pdf') {
                                            <a class="k-button k-master-button">
                                                <span style="height: 24px; width: 20px;"></span>
                                            </a>
                                        }
                                        <a id="menuMore" class="k-button k-master-button">
                                            <span class="icon icon-menu-more-1"></span>
                                        </a>
                                    </div>
                                }
                                <kendo-contextmenu
                                    #gridDropdownSubmenu
                                    [items]="getSubmenuOptions()"
                                    [showOn]="click"
                                    [target]="menuMore"
                                    [popupAnimate]="{type: 'expand', direction: 'down'}"
                                    (popupClose)="onSubmenuClose()"
                                    (select)="onSelectSubmenuOption($event.item)">
                                    <ng-template kendoMenuItemTemplate let-item="item">
                                        <span class="icon {{item.icon}} icon-20"></span>
                                        <span [translate]="item.text"></span>
                                    </ng-template>
                                </kendo-contextmenu>
                            </ng-template>
                        </kendo-grid-command-column>
                        <ng-template kendoGridNoRecordsTemplate></ng-template>
                        <!-- Custom Pager -->
                        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <article class="dui-grid-pager">
                                <kendo-pager-info></kendo-pager-info>
                                @if (documentsDatagrid.total > 10) {
                                    <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                                        <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                        <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                        <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                    </article>
                                    <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                                }
                            </article>
                        </ng-template>
                    </kendo-grid>
                }
                <ng-template kendoGridLoadingTemplate>
                    <div class="k-loading-color"></div>
                    <div class="loading-container">
                        <kendo-loader type="converging-spinner" size="large"></kendo-loader>
                    </div>
                </ng-template>

                <!-- Skeleton documents grid -->
                @if (documentsDatagrid?.data.length == 0 && isSyncing) {
                    <div>
                        <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
                            <kendo-grid-column width="60">
                                <ng-template kendoGridHeaderTemplate>
                                    <label class="checkbox__container padding-left-2">
                                        <span class="checkbox icon icon-24 icon-checkbox-label"></span>
                                    </label>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <label class="checkbox__container justify-content-center">
                                        <kendo-skeleton
                                            shape="rectangle"
                                            animation="pulse"
                                            [width]="20"
                                            [height]="20"
                                            style="border-radius: 4px"></kendo-skeleton>
                                    </label>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column [headerStyle]="{'padding-block': '0.5em', 'width': '30%'}" [style]="{'width': '30%'}">
                                <ng-template kendoGridHeaderTemplate>
                                    <div class="flex align-items-center">
                                        <span class="padding-left-10" [textContent]="'model.users.documents.document' | translate"></span>
                                    </div>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="flex align-items-center">
                                        <kendo-skeleton
                                            shape="rectangle"
                                            animation="pulse"
                                            [width]="20"
                                            [height]="25"
                                            style="border-radius: 4px"
                                            class="margin-right-10"></kendo-skeleton>
                                        <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column [headerStyle]="{'width': '25%'}" [style]="{'width': '25%'}">
                                <ng-template kendoGridHeaderTemplate>
                                    <div class="flex align-items-center">
                                        <span [textContent]="'remoteSignature.grid.employee' | translate"></span>
                                    </div>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <div class="flex align-items-center">
                                        <kendo-skeleton shape="circle" animation="pulse" [width]="28" [height]="28"></kendo-skeleton>
                                        <kendo-skeleton
                                            shape="text"
                                            animation="pulse"
                                            [width]="250"
                                            [height]="20"
                                            style="border-radius: 4px"
                                            class="margin-left-10"></kendo-skeleton>
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column [headerStyle]="{'width': '20%'}" [style]="{'width': '20%'}">
                                <ng-template kendoGridHeaderTemplate>
                                    <div class="flex align-items-center">
                                        <span [textContent]="'documents.list.label' | translate"></span>
                                    </div>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <kendo-skeleton shape="text" animation="pulse" [width]="150" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column media="(min-width: 1240px)" [headerStyle]="{'width': '15%'}" [style]="{'width': '15%'}">
                                <ng-template kendoGridHeaderTemplate>
                                    <div class="flex align-items-center">
                                        <span [textContent]="'documents.list.shipmentDate' | translate"></span>
                                    </div>
                                </ng-template>
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-command-column title="" [width]="144" [style]="{'text-align': 'end'}"></kendo-grid-command-column>
                        </kendo-grid>
                    </div>
                }
                @if (documentsDatagrid?.data.length == 0 && !datagridFilteringOptions.name && !isSyncing) {
                    <no-results [imageTitle]="'users.documents.grid.noResultsTitle'"></no-results>
                }
                @if (documentsDatagrid?.data.length == 0 && datagridFilteringOptions.name && !isSyncing) {
                    <no-search-results></no-search-results>
                }
            </div>
        </div>
    </div>
</div>
