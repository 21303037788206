import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ExternalLinksService } from './external-links.service';
import { Constants } from '../../constants';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';

@Component({
    selector: 'external-links',
    templateUrl: 'external-links.component.html',
    styleUrls: ['./_external-link.scss']
})
export class ExternalLinksComponent implements OnInit {
    @Input() isPreviewVisible: boolean;
    @Output() isPreviewVisibleChange: EventEmitter<any> = new EventEmitter<any>();

    public isSyncing: boolean = false;
    public externalLinksAdviceStatus: boolean = false;
    public userSession = window['getUserSession']();
    public selectedLanguage = this.userSession.companyDefaultLanguage;

    public extLinksDatagrid: any = {
        data: [],
        total: 0
    };

    public dataSkeleton = [{}, {}, {}];
    public POPULATION: any;
    private updateLinkUrl: string = '/settings/my-company/external-link/update/';

    constructor(
        private externalLinksService: ExternalLinksService,
        private translate: TranslateService,
        private location: Location,
        private dialogService: DialogService,
        private notificationService: DuiNotificationsService
    ) {
        this.POPULATION = Constants.POPULATION;
    }

    ngOnInit() {
        this.getExternalLinksGridData();
        this.externalLinksAdviceStatus = JSON.parse(localStorage.getItem('externalLinksAdviceStatus')) === true;
    }

    getExternalLinksGridData() {
        this.isSyncing = true;
        this.externalLinksService.findAll().subscribe(
            (response: any) => {
                setTimeout(() => {
                    this.extLinksDatagrid.data = response;
                    this.extLinksDatagrid.total = response.length;
                    this.isSyncing = false;
                }, 100);
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    updateLinksOrderProperty(): void {
        this.extLinksDatagrid.data.forEach((link: any, index: number) => {
            link.order = index;
        });
    }

    onDroppedItem(event: CdkDragDrop<string[]>) {
        if (event.previousIndex === event.currentIndex) {
            return;
        }

        this.externalLinksService.reorderLink(this.extLinksDatagrid.data[event.previousIndex].id, event.currentIndex).subscribe(
            (response: any) => {
                // Update array for view
                moveItemInArray(this.extLinksDatagrid.data, event.previousIndex, event.currentIndex);
                // Update order property with array order
                this.updateLinksOrderProperty();
                // Show notification success
                this.notificationService.showSuccessNotification();
            },
            (error: any) => {
                // Show notification error
                this.getExternalLinksGridData();
            }
        );
    }

    updateExternalLink(linkId: number): void {
        this.location.go(this.updateLinkUrl + linkId);
    }

    deleteExternalLink(link: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'externalLinks.modal.delete.title',
            message: 'externalLinks.modal.delete.message',
            messageParam: { extLink: link.contents[this.selectedLanguage].name }
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;

                this.externalLinksService.delete(link.id).subscribe(
                    (onSuccess) => {
                        const message = this.translate.instant('externalLinks.modal.delete.success', { extLink: link.contents[this.selectedLanguage].name });
                        this.notificationService.showSuccessNotification(message);
                        this.getExternalLinksGridData();
                    },
                    (onError) => {}
                );
            }
        });
    }
    almostOneLinkIsSegmented(): boolean {
        let index = this.extLinksDatagrid?.data.findIndex((link: any) => {
            return link.populationKind === this.POPULATION.SEGMENTS;
        });
        return index >= 0;
    }

    isPreviewVisibleFn(visibility: boolean): boolean {
        this.isPreviewVisible = visibility;
        this.isPreviewVisibleChange.emit(this.isPreviewVisible);
        return this.isPreviewVisible;
    }

    sortExternalLinksDescending(): any[] {
        const sortProperty = 'order';
        return [...this.extLinksDatagrid.data].sort((a, b) => (a[sortProperty] < b[sortProperty] ? 1 : a[sortProperty] === b[sortProperty] ? 0 : -1));
    }

    hideAdviceAppMessage(): void {
        localStorage.setItem('externalLinksAdviceStatus', JSON.stringify(true));
        this.externalLinksAdviceStatus = true;
    }
}
