import { Component, EventEmitter, Input, Output, OnChanges, ViewChild, AfterViewInit, ElementRef, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { WorkplacesService } from '../workplaces/workplaces.service';
import { TranslateService } from '@ngx-translate/core';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { UtilsService } from '../../shared/services/utils.service';
import { isEqual, cloneDeep } from 'lodash';
import { latLng, tileLayer, icon, MapOptions, Map, Marker, IconOptions, MarkerOptions } from 'leaflet';

@Component({
    selector: 'workplace-location',
    templateUrl: './workplace-location.component.html',
    encapsulation: ViewEncapsulation.None
})
export class WorkplaceLocationComponent implements OnChanges, AfterViewInit {
    @Input() workplaceForm: any;
    @Input() workplaceId: number;
    @Output() workplaceFormChange: EventEmitter<any> = new EventEmitter<any>();

    private _rootScope: any;
    private initialDataForm: any = {};
    private workplaceMap: Map;
    private workplaceMarkerMap: Marker;
    public countryEmptyOption: any = { id: null, country: this.translate.instant('global.form.notSet') };
    public communityEmptyOption: any = { id: null, name: this.translate.instant('global.form.notSet') };

    public countriesData: Array<any> = [];
    public communitiesData: Array<any> = [];
    public isValidURLWebsite: boolean = true;
    public isValidEmail: boolean = true;

    public isFormDataValid: boolean;
    public isFormDataChanged: boolean = false;
    private workplacesPath: string = '/settings/my-company/workplaces';

    @ViewChild('staticMap') staticMap: ElementRef;

    constructor(
        private upgrade: UpgradeModule,
        private translate: TranslateService,
        private notificationService: DuiNotificationsService,
        private formUtilsService: FormUtilsService,
        private utilsService: UtilsService,
        private workplacesService: WorkplacesService
    ) {
        this._rootScope = this.upgrade.$injector.get('$rootScope');
    }

    ngAfterViewInit(): void {
        this.workplaceMapInitialize();
        this.workplaceMapUpdate();
        this.getCountriesData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workplaceForm && changes.workplaceForm.currentValue && changes.workplaceForm.previousValue !== changes.workplaceForm.currentValue) {
            this.workplaceMapUpdate();
            this.setInitialFormData();
            if (this.workplaceForm.country && this.workplaceForm.country.code === '34') {
                this.getCommunitiesData(this.workplaceForm.country.id);
            }
        }
    }

    // PRIVATE METHODS

    private setInitialFormData(): void {
        this.initialDataForm = cloneDeep(this.workplaceForm);
        this.workplaceFormChange.emit(this.workplaceForm);
        this.onChange();
    }

    private checkIfDataFormAreChanged(): void {
        this.isFormDataChanged = !isEqual(this.initialDataForm, this.workplaceForm);
    }

    private checkIfDataFormAreValid(): void {
        this.isFormDataValid = this.isValidURLWebsite && this.isValidEmail;
    }

    private workplaceMapInitialize(): void {
        const mapOptions: MapOptions = {
            layers: [
                tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                    maxZoom: 18,
                    attribution:
                        '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions" target="_blank">CARTO</a>',
                    subdomains: 'abcd',
                    tileSize: 256
                })
            ],
            center: latLng(40.749357547, -73.986190129),
            zoom: 11,
            zoomControl: false,
            touchZoom: false,
            boxZoom: false,
            dragging: false,
            doubleClickZoom: false,
            scrollWheelZoom: false,
            keyboard: false,
            tapHold: false
        };
        const iconMarkerOptions: IconOptions = {
            iconUrl: '/ng1/assets/img/location.svg',
            iconSize: [64, 64],
            iconAnchor: [32, 32]
        };
        const markerOptions: MarkerOptions = {
            icon: icon(iconMarkerOptions),
            opacity: 0
        };

        if (!this.workplaceMap) {
            this.workplaceMap = new Map(this.staticMap.nativeElement, mapOptions);
            if (!this.workplaceMarkerMap) {
                this.workplaceMarkerMap = new Marker(latLng(40.749357547, -73.986190129), markerOptions).addTo(this.workplaceMap);
            }
        }
    }

    private workplaceMapUpdate(): void {
        if (this.workplaceMap) {
            if (!this.workplaceForm.latitude && !this.workplaceForm.longitude) {
                this.workplaceMap.setView(latLng(40.749357547, -73.986190129), 11);
                this.workplaceMarkerMap.setOpacity(0);
            } else {
                this.workplaceMap.setView(
                    latLng(parseFloat(this.workplaceForm.latitude || 40.749357547), parseFloat(this.workplaceForm.longitude || -73.986190129)),
                    17
                );
                this.workplaceMarkerMap.setLatLng(latLng(parseFloat(this.workplaceForm.latitude || 0), parseFloat(this.workplaceForm.longitude || 0)));
                this.workplaceMarkerMap.setOpacity(1);
            }
        }
    }

    private getCountriesData(): void {
        this.workplacesService.getCountryCodes().subscribe((response: any) => {
            this.countriesData = response.sort((a: any, b: any) => {
                return a.country > b.country ? 1 : b.country > a.country ? -1 : 0;
            });
        });
    }

    private getCommunitiesData(countryId: number): void {
        this.workplacesService.getAutonomousRegions(countryId).subscribe((response: any) => {
            this.communitiesData = response;
        });
    }

    // PUBLIC METHODS

    workplaceFormSave(): void {
        this.workplacesService.update(this.workplaceId, this.workplaceForm).subscribe(
            (response: any) => {
                this.workplaceForm = response;
                this.setInitialFormData();
                this.formUtilsService.finishSubmitAction();

                this.notificationService.showSuccessNotification();
            },
            (onError: any) => {}
        );
    }

    onChange() {
        this.checkIfDataFormAreChanged();
        this.checkIfDataFormAreValid();
    }

    onChangeDirector(value: any) {
        this.workplaceForm.director = typeof value === 'undefined' ? null : value;
        this.onChange();
    }

    onChangeCountrySelection(): void {
        if (!this.workplaceForm.country.id) {
            this.workplaceForm.country = null;
            this.workplaceForm.autonomousRegion = null;
        } else if (this.workplaceForm.country && this.workplaceForm.country.code === '34') {
            this.getCommunitiesData(this.workplaceForm.country.id);
        }
        this.onChange();
    }

    onChangeCommunitySelection(): void {
        if (!this.workplaceForm.autonomousRegion.id) {
            this.workplaceForm.autonomousRegion = null;
        }
        this.onChange();
    }

    onChangeEmail(): void {
        this.isValidEmail = this.utilsService.validateEmail(this.workplaceForm.email);
        this.workplaceForm.email = !this.workplaceForm.email ? null : this.workplaceForm.email;
        this.onChange();
    }

    onChangeMapCoord(): void {
        this.workplaceMapUpdate();
        this.onChange();
    }

    onChangeWebsiteUrl() {
        let urlTested: any = this.validateUrl(this.workplaceForm.website);
        this.isValidURLWebsite = urlTested.valid;
        this.workplaceForm.website = !!urlTested.data ? urlTested.data : null;
        this.onChange();
    }

    validateUrl(dataUrl: string) {
        let isValidURL = true;
        if (dataUrl) {
            isValidURL = this.utilsService.validateURL(dataUrl);
            if (isValidURL) {
                dataUrl = this.utilsService.concatURLPrefixIfNotExists(dataUrl);
            }
            return { valid: isValidURL, data: dataUrl };
        }
        return { valid: isValidURL, data: dataUrl };
    }

    testUrlLink(url: string) {
        this.utilsService.showUrlLink(url);
    }

    goBack(): void {
        this._rootScope.goBackAndCheckForModifications(this.isFormDataChanged, this.workplacesPath);
    }
}
