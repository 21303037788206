import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';

@Pipe({
    name: 'roleFilter'
})
export class RoleFilterPipe implements PipeTransform {
    private roleMap = {
        [Constants.USER_ROLES.manager]: 'manager',
        [Constants.USER_ROLES.admin]: 'admin',
        [Constants.USER_ROLES.employeeManager]: 'employeeManager',
        [Constants.USER_ROLES.user]: 'user',
        [Constants.USER_ROLES.platformAdmin]: 'platformAdmin',
        [Constants.USER_ROLES.ftrayResponsible]: 'ftrayResponsible',
        [Constants.USER_ROLES.documentsManager]: 'documentsManager',
        [Constants.USER_ROLES.publisher]: 'publisher',
        [Constants.USER_ROLES.teamResponsible]: 'teamResponsible',
        [Constants.USER_ROLES.humanResources]: 'humanResources',
        [Constants.USER_ROLES.ftrayManager]: 'ftrayManager',
        [Constants.USER_ROLES.chatroomManager]: 'chatroomManager'
    };

    constructor(private translate: TranslateService) {}

    transform(role: string): string {
        const roleKey = this.roleMap[role];
        const userRole = roleKey ? `model.users.roles.${roleKey}.name` : null;

        return userRole ? this.translate.instant(userRole) : '';
    }
}
