import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { CategoriesService } from '../../shared/services/categories.service';

@Injectable({
    providedIn: 'root'
})
export class CategoriesTreeService {
    private selectedCategorySource = new BehaviorSubject<number>(null);
    public selectedCategory = this.selectedCategorySource.asObservable();

    private selectedCategoryPathSource = new BehaviorSubject<Array<any>>([]);
    public selectedCategoryPath = this.selectedCategoryPathSource.asObservable();

    public categories: Array<any> = [];

    constructor(private categoriesService: CategoriesService) {}

    private setCategoryAsSelected(categoryId: number) {
        this.selectedCategorySource.next(categoryId);
        if (!!window['reloadKnowledgeCenterGrid']) {
            window['reloadKnowledgeCenterGrid'](categoryId);
        }
        if (!!window['setSelectedCategoryInModal']) {
            window['setSelectedCategoryInModal'](categoryId);
        }
    }

    selectCategory(categoryId: number) {
        this.setCategoryAsSelected(categoryId);
        if (categoryId) {
            const result = [];
            for (let index = 0; index < this.categories.length; index++) {
                if (this.findNode(this.categories[index], categoryId, result)) {
                    this.selectedCategoryPathSource.next(result);
                    return true;
                }
            }
        } else {
            this.selectedCategoryPathSource.next(null);
        }
    }

    updateSelectedCategoryData(categoryId: number, categoryName: string) {
        const currentSelectedCategoryPath: Array<any> = this.getSelectedCategoryPath();
        for (let index = 0; index < currentSelectedCategoryPath.length; index++) {
            if (categoryId === currentSelectedCategoryPath[index].id) {
                currentSelectedCategoryPath[index].name = categoryName;
                return true;
            }
        }
    }

    private findNode(category: any, categoryId: number, result: Array<any>) {
        if (category.id === categoryId) {
            result.unshift({ id: category.id, name: category.name, color: category.color, contents: category.contents });
            return true;
        } else if (category.children && category.children.length > 0) {
            for (let index = 0; index < category.children.length; index++) {
                if (this.findNode(category.children[index], categoryId, result)) {
                    category.isOpened = true;
                    result.unshift({ id: category.id, name: category.name, color: category.color, contents: category.contents });
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    getSelectedCategoryPath() {
        return this.selectedCategoryPathSource.getValue();
    }

    clearSelectedCategoryPath() {
        this.setCategoryAsSelected(null);
        this.selectedCategoryPathSource.next([]);
    }

    closeAllCategories() {
        this.categories.forEach((category) => {
            category.isOpened = false;
        });
    }

    getKnowledgeCenterCategoriesTree() {
        return this.categoriesService.getKnowledgeCenterCategoriesHierarchy().pipe(
            map((response) => {
                this.categories = response;
                return response;
            }),
            catchError((error) => {
                return throwError(error);
            })
        );
    }

    private searchCategory(categoryId, categories) {
        for (let index = 0; index < categories.length; index++) {
            const category = categories[index];
            if (category.id === categoryId) {
                return category;
            } else {
                const childCategory = this.searchCategory(categoryId, category.children);
                if (childCategory) {
                    return childCategory;
                }
            }
        }
        return false;
    }

    removeLastChildFromCategory(parentId) {
        const category = this.searchCategory(parentId, this.categories);
        if (category.children && category.children.length > 0) {
            category.children.pop();
        } else {
            category.isOpened = false;
        }
    }

    removeChildFromCategory(categoryId, parentId) {
        const category = this.searchCategory(parentId, this.categories);
        for (let index = 0; index < category.children.length; index++) {
            if (category.children[index].id === categoryId) {
                category.children.splice(index, 1);
                return;
            }
        }
    }

    getCategoryParent(parentId: number) {
        return this.searchCategory(parentId, this.categories);
    }

    updateCategoriesTree() {
        this.getKnowledgeCenterCategoriesTree().subscribe(() => {});
    }
}
