<div class="main-container">
    <!-- Header -->
    <div class="main-container__header" style="min-width: 891px;">
        <div class="flex align-items-end justify-space-between width-100-percent">
            <div class="flex direction-column width-100-percent" spellcheck="false">
                <!-- Breadcrumbs -->
                <ul class="breadcrumb margin-bottom-0">
                    <li>
                        <a #goGlobalSettings id="go-global-settings" href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
                    </li>
                    <li>
                        <a #goWorkplaces id="go-workplaces" href="/settings/my-company/workplaces" [translate]="'timeoffSettings.tabs.workplaces'"></a>
                    </li>
                </ul>
                <!-- Workplace editable name -->
                <div class="editable-wrapper">
                    <h2
                        #workplaceName
                        class="icon icon-edit contenteditable editable-title"
                        [textContent]="workplaceForm.name"
                        ([ngModel])="workplaceForm.name"
                        [contentEditable]="true"
                        prevent-event="dragover drop"
                        maxlength="64"
                        (blur)="onWorkplaceNameBlur()"
                        (keydown)="onWorkplaceNameKeyDown($event)"
                        (paste)="onWorkplaceNamePaste($event)"></h2>
                    <span class="bottom-line"></span>
                </div>
            </div>
            <div class="action-buttons-container margin-left-20 text-right">
                <div class="flex align-items-center" kendoTooltip filter="button" position="top">
                    <!-- Add holidays to workplace -->
                    @if (selectedTab === WORKPLACES_TABS.HOLIDAYS) {
                        <loading-button
                            [buttonId]="'add-holidays-modal'"
                            [classes]="'button button--secondary button--small margin-bottom-20'"
                            [iconClass]="'icon-add'"
                            [text]="'workplaces.holidays.addHolidays'"
                            (action)="addHolidaysToWorkplace()"
                            [isValid]="true">
                        </loading-button>
                    }
                    <!-- Add employees to workplace -->
                    @if (selectedTab === WORKPLACES_TABS.EMPLOYEES) {
                        <loading-button
                            [buttonId]="'add-users-modal'"
                            [classes]="'button button--secondary button--small margin-bottom-20'"
                            [iconClass]="'icon-add'"
                            [text]="'schemes.employees.addEmployees'"
                            (action)="addEmployeesToWorkplace()"
                            [isValid]="true">
                        </loading-button>
                    }
                </div>
            </div>
        </div>
    </div>
    <!-- Content -->
    <div class="main-container__content">
        <div class="main-container--full-width">
            <!-- Navigation tabs -->
            <div class="flex align-items-end justify-space-between margin-bottom-20">
                <div class="tab-nav tab-nav--inline width-100-percent">
                    <span
                        id="location-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === WORKPLACES_TABS.LOCATION}"
                        [translate]="'workplaces.tabs.location'"
                        (click)="loadTabContent(WORKPLACES_TABS.LOCATION)">
                    </span>
                    <span
                        id="holidays-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === WORKPLACES_TABS.HOLIDAYS}"
                        [translate]="'workplaces.tabs.holidays'"
                        (click)="loadTabContent(WORKPLACES_TABS.HOLIDAYS)">
                    </span>
                    <span
                        id="employees-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === WORKPLACES_TABS.EMPLOYEES}"
                        [translate]="'workplaces.tabs.employees'"
                        (click)="loadTabContent(WORKPLACES_TABS.EMPLOYEES)">
                    </span>
                </div>
            </div>
            @if (selectedTab === WORKPLACES_TABS.LOCATION) {
                <workplace-location [workplaceId]="workplaceId" [(workplaceForm)]="workplaceForm"></workplace-location>
            }
            @if (selectedTab === WORKPLACES_TABS.HOLIDAYS) {
                <workplace-holidays [workplaceId]="workplaceId" [(addNewHoliday)]="addNewHoliday"></workplace-holidays>
            }
            @if (selectedTab === WORKPLACES_TABS.EMPLOYEES) {
                <workplace-employees [workplaceId]="workplaceId" [(employees)]="workplaceEmployees"></workplace-employees>
            }
        </div>
    </div>
</div>
