<div class="dialog-modal-header ">
    <div class="flex align-items-center">
        <img src="{{data.iconMimeType}}" class="margin-right-10" alt="" />
        <h3 [textContent]="data.resource.nameWithExtension"></h3>
    </div>
    <button type="button" class="button button--only-icon dialog-modal__button-close" (click)="onClose()">
        <span class="button__icon icon icon-close"></span>
    </button>
</div>
<div class="dialog-modal-body" style="height: 82vh">
    <ngx-extended-pdf-viewer
        [authorization]="true"
        [src]="data.resource.url"
        [zoom]="'100%'"
        [logLevel]="0"
        [showBorders]="false"
        [showHighlightEditor]="false"
        [showOpenFileButton]="false"
        [showRotateButton]="false"
        [showSecondaryToolbarButton]="false"
        [showUnverifiedSignatures]="true">
    </ngx-extended-pdf-viewer>
</div>
