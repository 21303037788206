import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: 'confirmation-dialog.html'
})
export class ConfirmationDialogComponent {
    public skipModalValue: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    private saveSkipModalSetting(): void {
        if (this.skipModalValue && this.data.skipModalAlias) {
            let skipModalSavedValue = localStorage.getItem('skipModal');
            let parsedSkipModalValue = !!skipModalSavedValue ? JSON.parse(skipModalSavedValue) : {};
            parsedSkipModalValue[this.data.skipModalAlias] = true;
            localStorage.setItem('skipModal', JSON.stringify(parsedSkipModalValue));
        }
    }

    onCancel(): void {
        this.saveSkipModalSetting();
        this.dialogRef.close();
    }

    onAccept() {
        this.saveSkipModalSetting();
        this.dialogRef.close({ accept: true });
    }
}
