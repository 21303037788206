import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TeamsService } from '../teams/teams.service';
import { Constants } from '../../constants';

@Component({
    templateUrl: './team-create-modal.component.html'
})
export class TeamCreateModalComponent implements AfterViewInit {
    private userSession: any;
    public isFormValid: boolean = false;
    public isNameDuplicated: boolean = false;
    public isHolidaysModuleActive: boolean;
    public teamForm: any = {
        name: '',
        responsible: {}
    };
    @ViewChild('teamName') teamNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<TeamCreateModalComponent>,
        private teamsService: TeamsService,
        private _elementRef: ElementRef
    ) {
        this.userSession = window['getUserSession']();
        this.isHolidaysModuleActive =
            !!this.userSession.activeModules[Constants.MODULES.HOLIDAYS] &&
            this.userSession.activeModules[Constants.MODULES.HOLIDAYS].state === Constants.MODULES_STATES.ENABLED;
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.teamNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        // this.teamNameRef.nativeElement.classList.remove('input--error');
        this.isFormValid = this.teamForm.name.length > 0;
    }

    onEnterPressed(): void {
        if (this.isFormValid) {
            const createChatBtn: HTMLElement = this._elementRef.nativeElement.querySelector('.button--secondary');
            createChatBtn.click();
        }
    }

    onAccept(): void {
        this.teamsService.create(this.teamForm).subscribe(
            (onSuccess: any) => {
                // Get the new team id, return it and close modal
                this.dialogRef.close({ success: onSuccess });
            },
            (onError: any) => {
                if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
                    this.isNameDuplicated = true;
                }
                this.teamNameRef.nativeElement.focus();
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }
}
