import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TimeoffSettingsService } from '../timeoff-settings/timeoff-settings.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Constants } from '../../constants';
import { TimeoffSchemaAbsencesModalComponent } from './timeoff-schema-absences-modal.component';

@Component({
    selector: 'schema-absences',
    templateUrl: './timeoff-schema-absences.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TimeoffSchemaAbsencesComponent implements OnChanges {
    @Input() schemaId: number;
    @Input() selectedLanguage: string;
    @Input() defaultLanguage: string;

    public ABSENCE_MOTIVE_GROUPS: any;
    public schemaAbsencesForm: any = {
        content: []
    };

    constructor(
        private timeoffSettingsService: TimeoffSettingsService,
        private notificationService: DuiNotificationsService,
        private dialog: MatDialog
    ) {
        this.ABSENCE_MOTIVE_GROUPS = Constants.ABSENCE_MOTIVE_GROUPS;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedLanguage && changes.selectedLanguage.currentValue && changes.selectedLanguage.firstChange) {
            this.getSchemaAbsencesMotives();
        }
    }

    // PRIVATE METHODS

    private getSchemaAbsencesMotives(): void {
        this.timeoffSettingsService.findSchemaAbsences(this.schemaId).subscribe(
            (response: any) => {
                this.schemaAbsencesForm.content = response;
            },
            (onError: any) => {}
        );
    }

    // PUBLIC METHODS

    updateAbsenceMotive(group: any, motive?: any): void {
        let editionLanguage: string = !motive ? this.defaultLanguage : this.selectedLanguage;
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            motive: motive,
            group: group,
            selectedLanguage: editionLanguage,
            defaultLanguage: this.defaultLanguage
        };
        dialogSettings.width = '900px';
        dialogSettings.position = { top: '6vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(TimeoffSchemaAbsencesModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.accept) {
                    const createOrUpdate: string = !!result?.motive.id ? 'updateAbsenceMotiveInSchema' : 'createAbsenceMotiveInSchema';

                    this.timeoffSettingsService[createOrUpdate](this.schemaId, group.id, result?.motive).subscribe(
                        (response: any) => {
                            this.getSchemaAbsencesMotives();
                            this.notificationService.showSuccessNotification();
                        },
                        (onError: any) => {}
                    );
                }
            });
    }

    toggleActiveMotive(group: any, motive: any): void {
        motive.enabled = !motive.enabled;
        this.timeoffSettingsService.updateMotiveStatus(this.schemaId, group.id, motive.id, motive.enabled).subscribe(
            (response: any) => {
                this.notificationService.showSuccessNotification();
            },
            (onError: any) => {}
        );
    }
}
