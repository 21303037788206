<header class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [translate]="'users.usageConditionsModal.title'"></h3>
</header>

<div class="modal-body wk-usage-conditions-modal">
    <p [innerHTML]="'users.usageConditionsModal.description' | translate"></p>
    <div class="form-group">
        <div class="checkbox margin-top-20" [ngClass]="{'active' : termsAndConditionsAccepted }">
            <label class="flex align-items-start">
                <input name="termsAndConditionsAccepted" type="checkbox" class="margin-top-3" [(ngModel)]="termsAndConditionsAccepted" required />
                <p class="margin-left-8">
                    <span [translate]="'users.usageConditionsModal.descriptionAcceptancePart1'"></span>
                    <a [href]="termsUrl" target="_blank" class="user-name" [translate]="'users.usageConditionsModal.termsAndConditions'"></a>
                    <span [translate]="'users.usageConditionsModal.descriptionAcceptancePart2'"></span>
                </p>
            </label>
        </div>
    </div>
</div>

<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'accept-wk-terms'"
        [classes]="'button--secondary'"
        [text]="'global.form.continue'"
        (action)="onAccept()"
        [isValid]="termsAndConditionsAccepted">
    </loading-button>
    <button class="button margin-left-10" (click)="onCancel()">
        <span class="button__label" [translate]="'global.modal.cancel'"></span>
    </button>
</div>
