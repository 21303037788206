import { Component, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { TeamsService } from './teams.service';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'teams',
    templateUrl: './teams.component.html'
})
export class TeamsComponent implements OnInit {
    public isSyncing: boolean = false;

    public teamsDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };
    public dataPagingOpts: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: ['lastModifiedDate,desc', 'name,asc']
    };
    public dataFilterOpts: any = {
        active: true,
        name: ''
    };
    public totalPages: number = 0;
    public dataSkeleton = [{}, {}, {}];
    public gridSort: SortDescriptor[];

    private clickedRowItem: any = '';

    @ViewChild('teamsGrid') teamsGrid: GridComponent;

    constructor(
        private teamsService: TeamsService,
        private location: Location,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this.getTeamsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    getTeamsGridData(pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;

        this.teamsService.findAll(pagingOptions, filterOptions).subscribe(
            (response) => {
                this.teamsDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    navigateToEditView(id: string): void {
        this.location.go('settings/my-company/teams/update/' + id);
    }

    clearSearchInput() {
        let updateDataGrid = this.dataFilterOpts.name.length >= 3;
        this.dataFilterOpts.name = '';
        if (updateDataGrid) {
            this.listItemsSearch();
        }
    }

    listItemsSearch() {
        if (this.dataFilterOpts.name.length >= 3 || this.dataFilterOpts.name.length === 0) {
            this.getTeamsGridData(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    getTeamsByIsActive(isActive: boolean) {
        this.dataFilterOpts.active = isActive;
        this.getTeamsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    updateTeam(item: any) {
        this.navigateToEditView(item.id);
    }

    archiveOrActivateTeam(item: any) {
        const dialogValues: DialogValueOptions = {
            title: item.active ? 'timeoff.teams.modal.archive.title' : 'timeoff.teams.modal.activate.title',
            message: item.active ? 'timeoff.teams.modal.archive.message' : 'timeoff.teams.modal.activate.message',
            messageParam: { teamName: item.name },
            acceptButtonText: item.active ? 'global.form.archive' : 'global.form.activate'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;

                let serviceToBeenCalled = '';
                serviceToBeenCalled = item.active ? 'archive' : 'activate';

                if (serviceToBeenCalled) {
                    this.teamsService[serviceToBeenCalled](item.id).subscribe(
                        (onSuccess) => {
                            const message = this.translate.instant(
                                item.active ? 'timeoff.teams.notification.archive.message' : 'timeoff.teams.notification.activate.message',
                                { teamName: item.name }
                            );
                            this.notificationService.showSuccessNotification(message);
                            this.getTeamsGridData(this.dataPagingOpts, this.dataFilterOpts);
                        },
                        (onError) => {}
                    );
                }
            }
        });
    }

    deleteTeam(item: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'timeoff.teams.modal.delete.title',
            message: 'timeoff.teams.modal.delete.message',
            messageParam: { teamName: item.name },
            acceptButtonText: 'global.form.delete'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;

                this.teamsService.delete(item.id).subscribe(
                    (onSuccess) => {
                        this.getTeamsGridData(this.dataPagingOpts, this.dataFilterOpts);
                        const message = this.translate.instant('timeoff.teams.notification.delete.message', { teamName: item.name });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => {}
                );
            }
        });
    }

    onSortChange(sort: SortDescriptor[]): void {
        //OK
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : ['lastModifiedDate,desc', 'name,asc'];
        this.getTeamsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / this.dataPagingOpts.size;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;
        this.getTeamsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
    }

    onDblClick(): void {
        if (this.clickedRowItem.id) {
            this.navigateToEditView(this.clickedRowItem.id);
        }
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }
}
