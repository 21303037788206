import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SegmentsService } from '../segments/segments.service';

@Component({
    templateUrl: './segment-create-modal.component.html'
})
export class SegmentCreateModalComponent implements AfterViewInit {
    public isFormValid: boolean = false;
    public isNameDuplicated: boolean = false;
    public segmentForm: any = {
        name: '',
        description: ''
    };
    @ViewChild('segmentName') segmentNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<SegmentCreateModalComponent>,
        private segmentsService: SegmentsService,
        private _elementRef: ElementRef
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.segmentNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        this.segmentNameRef.nativeElement.classList.remove('input--error');
        this.isFormValid = this.segmentForm.name.length > 0;
    }

    onEnterPressed(): void {
        if (this.isFormValid) {
            const createSegmentBtn: HTMLElement = this._elementRef.nativeElement.querySelector('.button--secondary');
            createSegmentBtn.click();
        }
    }

    onAccept(): void {
        this.segmentsService.create(this.segmentForm).subscribe(
            (onSuccess: any) => {
                // Get the new room id, return it and close modal
                this.dialogRef.close({ success: onSuccess });
            },
            (onError: any) => {
                if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
                    this.isNameDuplicated = true;
                }
                this.segmentNameRef.nativeElement.focus();
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }
}
