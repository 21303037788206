import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class DuiNotificationsService {
    constructor(
        private _toast: ToastrService,
        private translate: TranslateService
    ) {}

    showNotification(options: NotificationOptions) {
        this._toast[options.kind](options.message, options.title || '', {
            toastClass: 'ngx-toastr animated fadeInUp toast-' + options.kind,
            closeButton: !options.hideCloseButton,
            timeOut: options?.delay > 0 || options?.delay === 0 ? options.delay : Constants.TOAST_DEFAULTS.DELAY,
            extendedTimeOut: Constants.TOAST_DEFAULTS.DELAY,
            enableHtml: true,
            progressBar: options.showProgressBar,
            positionClass: options.position || 'toast-bottom-right',
            tapToDismiss: options.tapToDismiss || false,
            titleClass: options.titleClass || null,
            messageClass: options.messageClass || null
        });
    }

    showSuccessNotification(message?: string): void {
        const notificationOptions: NotificationOptions = {
            kind: 'success',
            message: message || this.translate.instant('global.messages.changesUpdated.success')
        };
        this.showNotification(notificationOptions);
    }

    showErrorNotification(message?: string) {
        const notificationOptions: NotificationOptions = {
            kind: 'error',
            message: message || this.translate.instant('global.messages.error.unknown')
        };
        this.showNotification(notificationOptions);
    }

    showDownloadNotification() {
        const message = this.translate.instant('global.messages.downloading');

        this._toast.info(message, '', {
            toastClass: 'ngx-toastr animated fadeInUp toast-download',
            closeButton: true,
            timeOut: 0,
            extendedTimeOut: Constants.TOAST_DEFAULTS.DELAY,
            enableHtml: true,
            progressBar: false,
            positionClass: 'toast-bottom-right',
            tapToDismiss: false,
            titleClass: null,
            messageClass: null
        });
    }

    closeAllNotifications(): void {
        this._toast.clear();
    }
}

export interface NotificationOptions {
    kind: 'success' | 'info' | 'error' | 'warning';
    title?: string;
    message: string;
    hideCloseButton?: boolean;
    delay?: number;
    showProgressBar?: boolean;
    classes?: Array<string>;
    position?:
        | 'toast-center-center'
        | 'toast-top-center'
        | 'toast-bottom-center'
        | 'toast-top-full-width'
        | 'toast-bottom-full-width'
        | 'toast-top-left'
        | 'toast-top-right'
        | 'toast-bottom-right'
        | 'toast-bottom-left';
    tapToDismiss?: boolean;
    titleClass?: string;
    messageClass?: string;
}
