import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { TeamsService } from '../teams/teams.service';

@Component({
    selector: 'team',
    templateUrl: './team.component.html',
    styleUrls: ['./_team.scss']
})
export class TeamComponent implements OnInit {
    public isTimeoffModuleActive: boolean;
    public teamForm: teamForm = {
        totalEmployees: 0
    };
    public teamEmployeesDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    public teamHasResponsible: boolean;
    private initialResponsible: any = null;
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };
    public isSyncing: boolean = false;
    public isFormSubmiting: boolean = false;
    public gridPagingOps: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: ['name,asc', 'surname,asc']
    };
    public gridFilteringOps: any = {
        name: ''
    };
    public gridSort: SortDescriptor[];
    public totalPages: number = 0;
    public dataSkeleton = [{}, {}, {}];

    private _route: any;
    private teamId: number;
    private userSession: any = window['getUserSession']();
    private EDITION_ALLOWED_KEYS: Array<string> = Constants.EDITION_ALLOWED_KEYS;
    private MODULES_STATES: any = Constants.MODULES_STATES;

    @ViewChild('teamName') teamNameRef: ElementRef;
    @ViewChild('teamDescription') teamDescriptionRef: ElementRef;

    constructor(
        private upgrade: UpgradeModule,
        private teamsService: TeamsService,
        private translate: TranslateService,
        private formUtilsService: FormUtilsService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private notificationService: DuiNotificationsService,
        private fileSaverService: FileSaverService
    ) {
        this._route = this.upgrade.$injector.get('$route');
        this.teamId = this._route.current.params.id;
        this.isTimeoffModuleActive =
            !!this.userSession &&
            !!this.userSession.activeModules.timeOff &&
            (this.userSession.activeModules.timeOff.state === this.MODULES_STATES.ENABLED ||
                this.userSession.activeModules.timeOff.state === this.MODULES_STATES.EMPLOYEE_DISABLED);
    }

    ngOnInit() {
        this.teamsService.findOne(this.teamId).subscribe(
            (response: any) => {
                this.teamForm = response;
                this.initialResponsible = response.responsible;
                this.teamHasResponsible = !!this.teamForm.responsible && !!this.teamForm.responsible.id;
            },
            (error: any) => {
                // Show notification error
            }
        );
        this.loadTeamEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    // PRIVATE METHODS

    private checkElementAndSave(element: string, newText: string): void {
        if (element === 'team-name') {
            if (!newText) {
                this.teamNameRef.nativeElement.textContent = this.teamForm.name;
                return;
            } else if (newText !== this.teamForm.name) {
                this.teamForm.name = newText;
                this.teamFormSave();
            }
        } else {
            if (newText !== this.teamForm.description) {
                this.teamForm.description = newText;
                this.teamFormSave();
            }
        }
    }

    private teamFormSave(): void {
        this.isFormSubmiting = true;
        this.teamsService.update(this.teamId, this.teamForm).subscribe(
            (response: any) => {
                this.initialResponsible = this.teamForm.responsible;
                this.teamHasResponsible = !!this.teamForm.responsible && !!this.teamForm.responsible.id;

                const notificationOptions: NotificationOptions = {
                    kind: 'success',
                    message: this.translate.instant('global.messages.changesUpdated.success')
                };
                this.notificationService.showNotification(notificationOptions);
                this.isFormSubmiting = false;
            },
            (respError: any) => {
                this.isFormSubmiting = false;
            }
        );
    }

    loadTeamEmployeesGridData(pagingOptions: any, filterOptions: any): void {
        this.isSyncing = true;

        this.teamsService.findAllEmployees(this.teamId, pagingOptions, filterOptions).subscribe(
            (response) => {
                this.teamEmployeesDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    // PUBLIC METHODS

    onElementBlur(event: any): void {
        this.checkElementAndSave(event.srcElement.getAttribute('name'), event.srcElement.textContent);
    }

    onElementKeyDown(event: any): void {
        let field = event.srcElement.getAttribute('name') === 'team-name' ? this.teamNameRef.nativeElement : this.teamDescriptionRef.nativeElement;
        const fieldMaxLength: number = event.srcElement.getAttribute('maxlength');

        if (field.textContent.length >= fieldMaxLength && this.EDITION_ALLOWED_KEYS.indexOf(event.code) === -1) {
            event.preventDefault();
            return;
        }
        if (event.code === 'Escape' || event.code === 'Enter') {
            if (event.code === 'Escape') {
                field.textContent = event.srcElement.getAttribute('name') === 'team-name' ? this.teamForm.name : this.teamForm.description;
            }
            field.blur();
        }
    }

    onElementPaste(event: any): void {
        event.preventDefault();
        let field = event.srcElement.getAttribute('name') === 'team-name' ? this.teamNameRef.nativeElement : this.teamDescriptionRef.nativeElement;
        const fieldMaxLength: number = event.srcElement.getAttribute('maxlength');

        let selectedText = window.getSelection().toString();
        let textToPaste = event.clipboardData.getData('text');
        if (selectedText) {
            field.textContent = field.textContent.replace(selectedText, textToPaste.substring(0, fieldMaxLength));
        } else {
            field.textContent += textToPaste.substring(0, fieldMaxLength - field.textContent.length);
        }
        this.checkElementAndSave(event.srcElement.getAttribute('name'), field.textContent);
    }

    setResponsibleOption(): void {
        if (!this.teamHasResponsible && !!this.teamForm.responsible) {
            this.teamForm.responsible = null;
            this.teamFormSave();
        }
    }

    onResponsibleSelect(): void {
        // If exist responsible and is diferent, save changes. If only delete responsible for another search, skip saving
        if (!!this.teamForm.responsible && JSON.stringify(this.teamForm.responsible) !== JSON.stringify(this.initialResponsible)) {
            this.teamFormSave();
        }
    }

    onResponsibleBlur(): void {
        // If responsible is empty and click outside, deleting responsible is assumed to be the desired action desired and the changes are saved
        this.teamForm.responsible = typeof this.teamForm.responsible === 'undefined' ? null : this.teamForm.responsible;
        if (JSON.stringify(this.teamForm.responsible) !== JSON.stringify(this.initialResponsible)) {
            this.teamFormSave();
        }
    }

    addEmployeesToTeam(): void {
        // get all the employees from team for pre-charge modal
        this.teamsService.findAllEmployees(this.teamId, { page: 0, size: 20000 }, null).subscribe(
            (response) => {
                const dialogSettings = new MatDialogConfig();
                dialogSettings.data = {
                    title: this.translate.instant('timeoff.teams.employees.modal.title'),
                    users: response.content
                };
                dialogSettings.width = '65vw';
                dialogSettings.position = { top: '4vh' };
                dialogSettings.panelClass = ['animated', 'slideInDown'];
                this.dialog
                    .open(ChatroomAdminsModalComponent, dialogSettings)
                    .afterClosed()
                    .subscribe((result) => {
                        this.formUtilsService.finishSubmitAction();
                        if (result?.arePendingChanges && result?.addedUsers) {
                            this.isSyncing = true;

                            this.teamsService.updateEmployees(this.teamId, result.addedUsers).subscribe(
                                () => {
                                    this.loadTeamEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
                                    const message = this.translate.instant('timeoff.teams.employees.notification.add.message');
                                    this.notificationService.showSuccessNotification(message);
                                },
                                (onError: any) => {
                                    this.isSyncing = false;
                                }
                            );
                        }
                    });
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    deleteEmployee(employee: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'schemes.employees.modal.delete.title',
            message: 'timeoff.teams.employees.modal.delete.message',
            messageParam: { fullName: employee.name + ' ' + employee.surname }
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result: any) => {
            if (result?.accept) {
                this.isSyncing = true;
                this.teamsService.deleteEmployee(this.teamId, employee.id).subscribe(
                    () => {
                        this.loadTeamEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
                        // Show success notification
                        const message = this.translate.instant('timeoff.teams.employees.notification.delete.message', {
                            fullName: employee.name + ' ' + employee.surname
                        });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError: any) => {
                        this.isSyncing = false;
                        // Show notification error
                    }
                );
            }
        });
    }

    downloadTeamReport() {
        this.teamsService.downloadTeamReport(this.teamId).subscribe((response: any) => {
            let fileName = 'Team-' + this.teamForm.name + '-' + new Date().toISOString().split('.')[0];
            fileName = fileName.replaceAll(':', '_');
            this.fileSaverService.save(response.body, fileName + '.xls');
            this.formUtilsService.finishSubmitAction();
        });
    }

    listItemsSearch(): void {
        if (this.gridFilteringOps.name.length >= 3 || this.gridFilteringOps.name.length === 0) {
            this.loadTeamEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
        }
    }

    clearSearchInput(): void {
        this.gridFilteringOps.name = '';
        this.loadTeamEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    skeletonRowCallback(row: RowClassArgs): any {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }

    public onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.gridPagingOps.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : ['name,asc', 'surname,asc'];
        this.loadTeamEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    public onPageChange(event: PageChangeEvent): void {
        this.gridPagingOps.page = event.skip / this.gridPagingOps.size;
        this.gridPagingOps.skip = event.skip;
        this.gridPagingOps.size = event.take;

        this.loadTeamEmployeesGridData(this.gridPagingOps, this.gridFilteringOps);
    }
}

type teamForm = {
    id?: number;
    active?: boolean;
    deletable?: boolean;
    name?: string;
    description?: string;
    responsible?: any;
    totalEmployees?: number;
};
