<header class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="data.modalTitle"></h3>
</header>
<section class="modal-body">
    <div class="flex justify-end margin-bottom-32">
        <language-selector
            [(selectedLanguage)]="selectedLanguage"
            [formContent]="motiveForm.contents"
            (formContentChange)="validateModel()"></language-selector>
    </div>
    <section class="margin-bottom-32">
        <h3 class="margin-bottom-16" [translate]="'motives.modal.appMotiveTitle'"></h3>
        <div class="group-form group-form--label-200">
            <div class="group-form__label">
                <span class="group-form__label__text margin-right-10" [translate]="'motives.modal.motive'"></span>
                <span class="group-form__label__help icon icon-help-fill" kendoTooltip [title]="'motives.modal.motiveTooltip' | translate"></span>
            </div>
            <div class="group-form__data">
                <input
                    #motiveInAppInput
                    id="motive-in-app-input"
                    class="input"
                    [(ngModel)]="motiveForm.contents[selectedLanguage].motiveInApp"
                    (ngModelChange)="onChange()"
                    (keydown)="modalSaveOnEnter($event)"
                    maxlength="256"
                    [placeholder]="'motives.modal.motivePlaceholder' | translate" />
                @if (motiveForm?.contents[selectedLanguage].motiveInApp?.length === 1) {
                    <span class="hint-message" [translate]="'users.validation.nameminlength'" [translateParams]="{amount: 2}"></span>
                }
            </div>
        </div>
    </section>
    <section class="margin-bottom-16">
        <h3 class="margin-bottom-16" [translate]="'motives.modal.backofficeMotiveTitle'"></h3>
        <div class="group-form group-form--label-200 margin-bottom-16">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'motives.modal.tag'"></span>
            </div>
            <div class="group-form__data">
                <input
                    #motiveInput
                    id="motive-input"
                    class="input max-width-220"
                    [(ngModel)]="motiveForm.contents[selectedLanguage].motive"
                    (ngModelChange)="onChange()"
                    (keydown)="modalSaveOnEnter($event)"
                    maxlength="128"
                    [placeholder]="'motives.modal.tagPlaceholder' | translate" />
                @if (motiveForm.contents[selectedLanguage].motive?.length === 1) {
                    <span class="hint-message" [translate]="'users.validation.nameminlength'" [translateParams]="{amount: 2}"></span>
                }
            </div>
        </div>
        <div class="group-form group-form--label-200 margin-bottom-0">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'model.category.color'"></span>
            </div>
            <div class="group-form__data">
                <div class="select-color">
                    <!-- falta clickout -->
                    <div
                        id="color-selector-toggle"
                        class="select-color__button"
                        [ngClass]="{'select-color--open' : colorSelectorActive}"
                        (click)="toggleColorSelector()">
                        @if (motiveForm.color) {
                            <span class="select-color__selected" [ngStyle]="{'background-color': motiveForm.color | numberColor}"></span>
                        }
                    </div>
                    @if (colorSelectorActive) {
                        <div class="select-color__options">
                            @for (color of availableColors; track $index) {
                                <span
                                    class="select-color__option"
                                    [ngStyle]="{'background-color': color | numberColor}"
                                    [ngClass]="{'select-color__option--selected': motiveForm.color == color}"
                                    (click)="selectColor(color)"></span>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </section>
</section>
<footer class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'create-motive'"
        [classes]="'button--secondary margin-right-10'"
        [text]="'global.modal.accept'"
        [isValid]="isFormDataChanged && isFormDataValid"
        (action)="onAccept()">
    </loading-button>
    <button type="button" id="modal-cancel-button" class="button" (click)="onCancel()">
        <span class="button__label" [translate]="'global.modal.cancel'"></span>
    </button>
</footer>
