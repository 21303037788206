import { Component, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SegmentsService } from './segments.service';
import { SegmentCreateModalComponent } from '../segment-create/segment-create-modal.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'segments',
    templateUrl: './segments.component.html',
    styleUrls: ['./_segments.scss']
})
export class SegmentsComponent implements OnInit {
    public isSyncing: boolean = false;

    public segmentsDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };
    public dataPagingOpts: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: ['lastModifiedDate,desc', 'name,asc']
    };
    public dataFilterOpts: any = {
        active: true,
        name: ''
    };
    public totalPages: number = 0;
    public dataSkeleton = [{}, {}, {}];
    public gridSort: SortDescriptor[];

    private clickedRowItem: any = '';
    private updateSegmentUrl: string = '/settings/users-groups/update/';

    @ViewChild('segmentsGrid') segmentsGrid: GridComponent;

    constructor(
        private segmentsService: SegmentsService,
        private location: Location,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        private dialogService: DialogService,
        public Dialog: MatDialog
    ) {}

    ngOnInit() {
        this.getSegmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    getSegmentsGridData(pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;
        this.segmentsService.findAll(pagingOptions, filterOptions).subscribe(
            (response) => {
                this.segmentsDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.getStatistics();
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    navigateToEditView(id: string): void {
        this.location.go(this.updateSegmentUrl + id);
    }

    clearSearchInput() {
        let updateDataGrid = this.dataFilterOpts.name.length >= 3;
        this.dataFilterOpts.name = '';
        if (updateDataGrid) {
            this.listItemsSearch();
        }
    }

    listItemsSearch() {
        if (this.dataFilterOpts.name.length >= 3 || this.dataFilterOpts.name.length === 0) {
            this.getSegmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    getSegmentsByIsActive(isActive: boolean) {
        this.dataFilterOpts.active = isActive;
        this.getSegmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    getStatistics(): void {
        this.segmentsService.statistics().subscribe((response) => {
            this.segmentsDatagrid.data.forEach((segment: any) => {
                let statistic = response.filter((item: any) => {
                    return item.segment === segment.id;
                });
                segment.employeeCount = statistic[0]?.totalUsers || 0;
            });
        });
    }

    refreshSegmentsDataGrid(): void {
        this.getStatistics();
        const message = this.translate.instant('global.messages.updatedData.text');
        this.notificationService.showSuccessNotification(message);
    }

    createSegment(): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.Dialog.open(SegmentCreateModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    // Get id segment from success and navigate to update view
                    this.navigateToEditView(result.success.id);
                }
            });
    }

    updateSegment(item: any) {
        this.navigateToEditView(item.id);
    }

    archiveOrActivateSegment(item: any) {
        const dialogValues: DialogValueOptions = {
            title: item.active ? 'segments.modal.archive.title' : 'segments.modal.activate.title',
            message: item.active ? 'segments.modal.archive.message' : 'segments.modal.activate.message',
            messageParam: { segmentName: item.name },
            acceptButtonText: item.active ? 'global.form.archive' : 'global.form.activate'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result: any) => {
            if (result?.accept) {
                this.isSyncing = true;

                let serviceToBeenCalled = '';
                serviceToBeenCalled = item.active ? 'archive' : 'activate';

                if (serviceToBeenCalled) {
                    this.segmentsService[serviceToBeenCalled](item.id).subscribe(
                        (onSuccess) => {
                            const message = this.translate.instant(
                                item.active ? 'segments.notification.archive.message' : 'segments.notification.activate.message',
                                { segmentName: item.name }
                            );
                            this.notificationService.showSuccessNotification(message);
                            this.getSegmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
                        },
                        (onError) => {}
                    );
                }
            }
        });
    }

    deleteSegment(item: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'segments.modal.delete.title',
            message: 'segments.modal.delete.message',
            messageParam: { segmentName: item.name },
            acceptButtonText: 'global.form.delete'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result: any) => {
            if (result?.accept) {
                this.isSyncing = true;

                this.segmentsService.delete(item.id).subscribe(
                    (onSuccess) => {
                        this.getSegmentsGridData(this.dataPagingOpts, this.dataFilterOpts);

                        const message = this.translate.instant('segments.notification.delete.message', { segmentName: item.name });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => {}
                );
            }
        });
    }

    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : ['lastModifiedDate,desc', 'name,asc'];
        this.getSegmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / this.dataPagingOpts.size;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;
        this.getSegmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
    }

    onDblClick(): void {
        if (this.clickedRowItem.id) {
            this.navigateToEditView(this.clickedRowItem.id);
        }
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }
}
