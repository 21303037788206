import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

export const ScopeProvider = {
    deps: ['$injector'],
    provide: '$scope',
    useFactory: (injector: Injector) => injector.get('$rootScope').$new()
};

@Directive({
    providers: [ScopeProvider],
    selector: 'question-list'
})
export class QuestionListDirective extends UpgradeComponent {
    @Input() question: any;
    @Input() linkedItems: any;
    @Input() isFirst: any;
    @Input() isLast: any;
    @Input() index: any;
    @Input() isTray: boolean;
    @Input() hasCover: boolean;
    @Input() questionnaireKind: any;
    @Input() showEndingElement: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('questionList', elementRef, injector);
    }
}
