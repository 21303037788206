import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class WorkplacesService {
    private apiUrl: string;
    private apiUrlWorkplaces: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.apiUrlWorkplaces = this.apiUrl + '/headquarters';
    }

    findAll(pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                active: filterOptions.active
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) {
            httpOptions.params['sort'] = pagerOptions.sort;
        }
        if (filterOptions?.name) {
            httpOptions.params['name'] = filterOptions.name;
        }

        return this.httpClient.get<any>(`${this.apiUrlWorkplaces}`, httpOptions);
    }

    findOne(id: number) {
        return this.httpClient.get<any>(`${this.apiUrlWorkplaces}/${id}`);
    }

    create(data: any) {
        return this.httpClient.post(`${this.apiUrlWorkplaces}`, data);
    }

    update(id: number, data: any) {
        return this.httpClient.put<any>(`${this.apiUrlWorkplaces}/${id}`, data);
    }

    delete(id: number) {
        return this.httpClient.delete<any>(`${this.apiUrlWorkplaces}/${id}`);
    }

    archive(id: number) {
        return this.httpClient.put(`${this.apiUrlWorkplaces}/${id}/archive`, null);
    }

    activate(id: number) {
        return this.httpClient.put(`${this.apiUrlWorkplaces}/${id}/activate`, null);
    }

    getCountryCodes() {
        return this.httpClient.get<any>(`${this.apiUrl}/country-codes`);
    }

    getAutonomousRegions(countryId: number) {
        return this.httpClient.get<any>(`${this.apiUrl}/locations/${countryId}/autonomous-regions`);
    }

    getHolidaysFromWorkplace(year: number, workplaceId: number) {
        return this.httpClient.get<any>(`${this.apiUrlWorkplaces}/${workplaceId}/public-holidays`, { params: { year: year } });
    }

    createHolidayInWorkplace(workplaceId: number, holiday: any) {
        return this.httpClient.post(`${this.apiUrlWorkplaces}/${workplaceId}/public-holidays`, holiday);
    }

    updateHolidayInWorkplace(workplaceId: number, holiday: any) {
        return this.httpClient.put(`${this.apiUrlWorkplaces}/${workplaceId}/public-holidays/${holiday.id}`, holiday);
    }

    deleteHolidayFromWorkplace(workplaceId: number, holidayId: number) {
        return this.httpClient.delete<any>(`${this.apiUrlWorkplaces}/${workplaceId}/public-holidays/${holidayId}`);
    }

    findAllEmployees(workplaceId: number, pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) {
            httpOptions.params['sort'] = pagerOptions.sort;
        }
        if (filterOptions?.name) {
            httpOptions.params['name'] = filterOptions.name;
        }

        return this.httpClient.get<any>(`${this.apiUrlWorkplaces}/${workplaceId}/employees`, httpOptions);
    }

    updateEmployees(workplaceId: number, employees: Array<any>) {
        let employeesIds: Array<any> = [...employees];
        employeesIds =
            employeesIds.length > 0
                ? employeesIds.map((employee: any) => {
                      return employee.hasOwnProperty('id') ? employee['id'] : employee['user'];
                  })
                : [];
        return this.httpClient.post<any>(`${this.apiUrlWorkplaces}/${workplaceId}/employees`, employeesIds);
    }

    deleteEmployee(workplaceId: number, employeeId: number) {
        return this.httpClient.delete<any>(`${this.apiUrlWorkplaces}/${workplaceId}/employees/${employeeId}`);
    }
}
