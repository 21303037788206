import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class DepartmentsService {
    private apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    findAll(pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                active: filterOptions.active
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) {
            httpOptions.params['sort'] = pagerOptions.sort;
        }
        if (filterOptions?.name) {
            httpOptions.params['name'] = filterOptions.name;
        }

        return this.httpClient.get<any>(`${this.apiUrl}/departments`, httpOptions);
    }

    findOne(id: number) {
        return this.httpClient.get<any>(`${this.apiUrl}/departments/${id}`);
    }

    create(data: any) {
        return this.httpClient.post(`${this.apiUrl}/departments`, data);
    }

    update(id: number, companyData: any) {
        return this.httpClient.put<any>(`${this.apiUrl}/departments/${id}`, companyData);
    }

    archive(id: number) {
        return this.httpClient.put(`${this.apiUrl}/departments/${id}/archive`, null);
    }

    activate(id: number) {
        return this.httpClient.put(`${this.apiUrl}/departments/${id}/activate`, null);
    }

    delete(id: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}/departments/${id}`);
    }

    getEmployees(id: number, pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) {
            httpOptions.params['sort'] = pagerOptions.sort;
        }
        if (filterOptions?.name) {
            httpOptions.params['name'] = filterOptions.name;
        }

        return this.httpClient.get<any>(`${this.apiUrl}/departments/${id}/employees`, httpOptions);
    }

    updateEmployees(id: number, users: Array<any>) {
        let usersIds: Array<any> = [...users];
        usersIds =
            usersIds.length > 0
                ? usersIds.map((user: any) => {
                      return user.hasOwnProperty('id') ? user['id'] : user['user'];
                  })
                : [];
        return this.httpClient.put<any>(`${this.apiUrl}/departments/${id}/employees`, usersIds);
    }

    deleteEmployee(id: number, user: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}/departments/${id}/employees/${user}`);
    }
}
