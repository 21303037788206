import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TimeTrackingService } from '../time-tracking/time-tracking.service';

@Component({
    templateUrl: './schedule-create-modal.component.html'
})
export class ScheduleCreateModalComponent implements AfterViewInit {
    public isFormValid: boolean = false;
    public isNameDuplicated: boolean = false;
    public scheduleForm: any = {
        name: ''
    };

    @ViewChild('scheduleName') scheduleNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<ScheduleCreateModalComponent>,
        private timeTrackingService: TimeTrackingService,
        private _elementRef: ElementRef
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.scheduleNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        this.isFormValid = this.scheduleForm.name.length > 0;
    }

    onEnterPressed(): void {
        if (this.isFormValid) {
            const createScheduleBtn: HTMLElement = this._elementRef.nativeElement.querySelector('.button--secondary');
            createScheduleBtn.click();
        }
    }

    onAccept(): void {
        this.timeTrackingService.create(this.scheduleForm).subscribe(
            (onSuccess: any) => {
                // Get the new schedule id, return it and close modal
                this.dialogRef.close({ success: onSuccess });
            },
            (onError: any) => {
                if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
                    this.isNameDuplicated = true;
                }
                this.scheduleNameRef.nativeElement.focus();
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }
}
