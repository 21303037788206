import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../../constants';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { UtilsService } from '../../shared/services/utils.service';
import { DocumentsService } from '../documents/documents.service';
import { NamingConventionModalComponent } from './naming-convention-modal.component';

declare var moment: any;

@Component({
    selector: 'import-documents',
    templateUrl: './import-documents.component.html',
    styleUrls: ['./_import-documents.component.scss']
})
export class ImportDocumentsComponent implements OnInit {
    private DOCUMENT_KIND = Constants.DOCUMENT_KIND;
    private MODULES = Constants.MODULES;
    private DOCUMENT_TRANSACTION_STATE = Constants.DOCUMENT_TRANSACTION_STATE;
    public UPLOAD_FILE_MODE = Constants.UPLOAD_FILE_MODE;

    public AFTER_N_DAYS: number = 2;
    public EXACT_DATE: number = 5;

    public showSelectForMultipleFiles: boolean = true;
    public showSelectForSingleFile: boolean = true;
    public showSignatureMoreOptions: boolean = false;
    public numFilesToUpload: number = 0;
    public singleFileNameToUpload: string = '';
    public expirationDateMin: any = moment().add(1, 'd');
    public expirationDateMax: any = moment().add(59, 'd');
    private userSession: any = window['getUserSession']();
    public isSignatureProductionMode = this.userSession.activeModules[this.MODULES.DOCUMENTS].productionMode;

    public uploadOptions: any;
    public uploadedFiles: File[] = [];

    public numConventions: number;
    public documentKindsOptions: any[] = [];

    private KIND_OTHER: number = 9;
    public isReplaceDisabled: boolean = false;

    public signatureAreasOptions: any;
    public stampAreasOptions: any;
    public stampListOptions: any;

    public emptyOption: any = { name: this.translate.instant('global.form.notSet'), id: null };

    @ViewChild('inputFile') inputFile: ElementRef;

    constructor(
        private location: Location,
        private translate: TranslateService,
        private dialogService: DialogService,
        private notificationService: DuiNotificationsService,
        private formUtilsService: FormUtilsService,
        private utilsService: UtilsService,
        private documentsService: DocumentsService,
        private namingConventionModal: MatDialog
    ) {
        this.uploadOptions = this.getDefaultUploadOptions();
    }

    ngOnInit() {
        this.documentsService.getDocumentKinds().subscribe(
            (response) => {
                this.documentKindsOptions = response;
                this.documentKindsOptions.push({
                    id: null,
                    alias: null,
                    color: null,
                    name: this.translate.instant('documents.documentsKind.filterTagNames.other'),
                    language: null
                });
            },
            (error) => {
                console.error('Error fetching document kinds', error);
            }
        );

        this.documentsService.getConventions().subscribe(
            (response) => {
                this.numConventions = response?.length || 0;
            },
            (error) => {
                console.error('Error fetching conventions', error);
            }
        );

        this.documentsService.getSignatureAreas(['ALL', 'SIGNATURE']).subscribe(
            (response) => {
                this.signatureAreasOptions = response;
            },
            (error) => {
                console.error('Error fetching signature areas', error);
            }
        );
    }

    //** METHODS */

    getDefaultUploadOptions() {
        const DEFAULT_REMINDER_DAYS: number = 2;
        const DEFAULT_EXPIRATION_DAYS: number = 30;

        return {
            mode: this.UPLOAD_FILE_MODE.SINGLE,
            kind: this.DOCUMENT_KIND.PAYROLL,
            setDocumentDate: false,
            documentsDate: moment(),
            remoteSignature: false,
            corporateSeal: false,
            title: this.translate.instant('remoteSignature.creation.shortMessagePlaceholder', { company: this.userSession.externalLoginBrand }),
            message: '',
            location: null,
            stamp: {
                id: null,
                addAfterEmployee: false
            },
            stampLocation: null,
            reminder: {
                reminderEnabled: false,
                reminderKind: this.AFTER_N_DAYS,
                reminderAmount: DEFAULT_REMINDER_DAYS
            },
            expiration: {
                expirationEnabled: false,
                expirationKind: this.AFTER_N_DAYS,
                expirationAmount: DEFAULT_EXPIRATION_DAYS,
                expirationDate: moment().add(DEFAULT_EXPIRATION_DAYS, 'd')
            },
            replaceExisting: false
        };
    }

    /**
     * Sets the file upload mode and resets the form data.
     *
     * @param {number} type - The type of file upload.
     *                        This value determines the file upload mode.
     */
    setUploadFileMode(type: number) {
        this.resetFormData();
        this.uploadOptions.mode = type;
    }

    /** DRAG AND DROP LOGIC */

    /**
     * Handles the selection of a single file.
     *
     * @param {any} selectedFile - The event object, which includes the selected file.
     */
    onSelectSingleFile(selectedFile: any): void {
        this.numFilesToUpload = this.uploadedFiles.length; // single file;
        this.singleFileNameToUpload = selectedFile.name;
        this.showSelectForSingleFile = false;
    }

    /**
     * Handles the selection of multiple files.
     *
     * @param {any} selectedFiles - The event object, which includes the selected files.
     */
    onSelectMultipleFiles(selectedFiles: any): void {
        this.numFilesToUpload = this.uploadedFiles.length;
        this.showSelectForMultipleFiles = false;
    }

    /**
     * Handles the dragging event.
     *
     * @param {DragEvent} event - The drag event object.
     */
    onDragging(event: DragEvent): void {
        event.preventDefault();
        const element = event.target as HTMLElement;
        const filesUploadElement = element.closest('.files-upload');

        if (filesUploadElement) {
            filesUploadElement.classList.add('new-drag-drop--dragging');
        }
    }

    /**
     * Handles the drag leave event.
     *
     * @param {DragEvent} event - The drag event object.
     */
    onDragLeave(event: DragEvent): void {
        event.preventDefault();
        const element = event.target as HTMLElement;
        const filesUploadElement = element.closest('.files-upload');

        if (filesUploadElement) {
            filesUploadElement.classList.remove('new-drag-drop--dragging');
        }
    }

    /**
     * Handles the drop event.
     *
     * @param {DragEvent} event - The drag event object.
     */
    onDrop(event: DragEvent): void {
        event.preventDefault();
        const element = event.target as HTMLElement;
        const filesUploadElement = element.closest('.files-upload');
        const inputFileElement = filesUploadElement.querySelector('input[type="file"]') as HTMLInputElement;

        if (filesUploadElement) {
            filesUploadElement.classList.remove('new-drag-drop--dragging');

            if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
                if (inputFileElement) {
                    this.uploadedFiles = Array.from(event.dataTransfer.files);

                    if (!!inputFileElement.multiple) {
                        this.onSelectMultipleFiles(event.dataTransfer.files);
                    } else {
                        this.onSelectSingleFile(event.dataTransfer.items[0].getAsFile());
                    }
                }
            }
        }
    }

    /**
     * Handles the change event of the file input element.
     *
     * @param {Event} event - The event object.
     */
    onInputFileChange(event: Event): void {
        const target = event.target as HTMLInputElement;
        this.uploadedFiles = Array.from(target.files);
        if (target.files) {
            if (target.multiple) {
                this.onSelectMultipleFiles(target.files);
            } else {
                this.onSelectSingleFile(target.files[0]);
            }
        }
    }

    openNamingConvention(): void {
        const modalSettings = new MatDialogConfig();
        modalSettings.width = '1000px';
        modalSettings.position = { top: '8vh' };
        modalSettings.panelClass = ['animated', 'slideInDown'];
        this.namingConventionModal.open(NamingConventionModalComponent, modalSettings);
    }

    showNewHireInfo(): void {
        const dialogValues: DialogValueOptions = {
            title: 'activeFeatures.signature.newHireModal.title',
            message: 'activeFeatures.signature.newHireModal.message',
            acceptButtonText: 'global.form.back',
            acceptButtonTextClass: ' ',
            hideCancelButton: true
        };
        this.dialogService.openConfirmationDialog(dialogValues);
    }

    setDocumentKind() {
        this.isReplaceDisabled = !this.uploadOptions.kind || this.uploadOptions.kind === this.KIND_OTHER;

        if (this.uploadOptions.mode === this.UPLOAD_FILE_MODE.SINGLE && this.isReplaceDisabled) {
            this.uploadOptions.replaceExisting = false;
        }
    }

    onAreaSelected() {
        if (!this.uploadOptions.remoteSignature || (this.uploadOptions.remoteSignature && !this.uploadOptions.location.id)) {
            this.uploadOptions.location = null;
        }
    }

    handleRemoteSignatureToggle() {
        this.isReplaceDisabled = this.uploadOptions.remoteSignature;
        this.uploadOptions.replaceExisting = false;
    }

    daysInputValidation(event) {
        if (!isNaN(parseInt(event.target.value)) && event.target.value.length > 0) {
            if (event.target.value.length > 2) {
                event.target.value = event.target.value.slice(0, 2);
            }
            if (event.target.value > 59) {
                event.target.value = 59;
            } else if (event.target.value < 1) {
                event.target.value = 1;
            }
        }
    }

    selectOnClick(event) {
        event.target.select();
    }

    checkIfAnyValidFileBeforeUpload(files: { size: number; validationErrors?: any }[]): boolean {
        return files?.some((file) => file.size > 0 && !file.validationErrors) || false;
    }

    getDocumentTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    onSuccess(response: any) {
        if (response) {
            if (response.state === this.DOCUMENT_TRANSACTION_STATE.SUCCESSFUL && response.errorEntries === 0 && response.successfulEntries > 0) {
                const message = this.translate.instant('documents.import.messageUploadSuccess', {
                    link: `${location.origin}/import-documents/history/${response.id}`
                });
                this.notificationService.showSuccessNotification(message);

                this.formUtilsService.finishSubmitAction();
                this.resetFormData();
            } else {
                this.navigateTo(`/import-documents/history/${response.id}`);
            }
        }
    }

    onError(response: any) {
        this.resetFormData();
    }

    resetFormData() {
        this.cleanFileInput();
        this.uploadOptions = this.getDefaultUploadOptions();
    }

    cleanFileInput() {
        this.showSelectForMultipleFiles = true;
        this.showSelectForSingleFile = true;
        this.numFilesToUpload = 0;
        this.singleFileNameToUpload = '';
        this.uploadedFiles = [];
        this.inputFile.nativeElement.value = '';
    }

    areUploadOptionsValid() {
        let isValidDocumentsDate = !this.uploadOptions.setDocumentDate || (this.uploadOptions.setDocumentDate && !!this.uploadOptions.documentsDate);
        let isValidShortTitle = !this.uploadOptions.remoteSignature || (this.uploadOptions.remoteSignature && !!this.uploadOptions.title);
        let isValidReminder =
            !this.uploadOptions.remoteSignature ||
            !this.uploadOptions.reminder.reminderEnabled ||
            (this.uploadOptions.reminder.reminderEnabled && !!this.uploadOptions.reminder.reminderAmount);
        let isValidExpiration =
            !this.uploadOptions.remoteSignature ||
            !this.uploadOptions.expiration.expirationEnabled ||
            (this.uploadOptions.expiration.expirationEnabled &&
                (this.uploadOptions.expiration.expirationKind === this.AFTER_N_DAYS
                    ? !!this.uploadOptions.expiration.expirationAmount
                    : !!this.uploadOptions.expiration.expirationDate));
        let isValidStamp = !this.uploadOptions.corporateSeal || (this.uploadOptions.corporateSeal && !!this.uploadOptions.stamp.id);

        return this.numFilesToUpload !== 0 && isValidDocumentsDate && isValidShortTitle && isValidReminder && isValidExpiration && isValidStamp;
    }

    parseUploadOptions() {
        this.uploadOptions.documentsDate = this.uploadOptions.setDocumentDate ? moment(this.uploadOptions.documentsDate).format('YYYY-MM-DD') : '';
    }

    uploadFiles() {
        const areValidFiles = this.checkIfAnyValidFileBeforeUpload(this.uploadedFiles);
        if (areValidFiles) {
            this.parseUploadOptions();
            this.documentsService.uploadDocumentsByMode(this.uploadOptions, this.uploadedFiles).subscribe(
                (response) => this.onSuccess(response),
                (response) => this.onError(response)
            );
        } else {
            const message = this.translate.instant('documents.import.importFormatError');
            this.notificationService.showErrorNotification(message);
            this.formUtilsService.finishSubmitAction();
        }
    }
}
