import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'preview-button',
    templateUrl: './preview-button.component.html'
})
export class PreviewButtonComponent {
    @Input() isOnlyIcon = false;
    @Input() isPreviewOpened = false;
    @Input() classes: string;
    @Output() isPreviewOpenedChange: EventEmitter<any> = new EventEmitter();

    constructor() {}

    openPreview(): void {
        this.isPreviewOpened = true;
        this.isPreviewOpenedChange.emit(this.isPreviewOpened);
    }
}
