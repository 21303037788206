import { InjectionToken } from '@angular/core';
export const GLOBAL_ANGULARJS_SERVICE = new InjectionToken<any>('GLOBAL_ANGULARJS_SERVICE');

export function globalAngularJSService(i) {
    return i.get('GlobalService');
}

export const globalAngularJSServiceProvider = {
    provide: GLOBAL_ANGULARJS_SERVICE,
    useFactory: globalAngularJSService,
    deps: ['$injector']
};
