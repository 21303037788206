import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Constants } from '../../constants';
import { SharedDataService } from '../../shared/services/shared-data.service';

@Component({
    selector: 'global-settings',
    templateUrl: './global-settings.component.html'
})
export class GlobalSettingsComponent {
    public companyBrand: any;
    public settingsMenu: Array<any>;
    private userSession: any;

    constructor(
        private _location: Location,
        private _sharedDataService: SharedDataService
    ) {
        this.userSession = window['getUserSession']();
        const userLoggedHasAdminRole = this.userSession.roles.indexOf(Constants.USER_ROLES.admin) !== -1;
        this.companyBrand = this.userSession.externalLoginBrand;

        this.settingsMenu = [
            {
                title: 'myCompany.title',
                description: 'myCompany.description',
                icon: 'icon-my-company',
                activity: '',
                url: userLoggedHasAdminRole ? '/settings/my-company/global' : '/settings/my-company/workplaces',
                access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.humanResources]
            },
            {
                title: 'global.navbar.timeoffRequests',
                description: 'timeoffSettings.description',
                icon: 'icon-calendar-large',
                activity: '4 esquemas',
                url: '/settings/timeoff/schemes',
                access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.humanResources],
                module: Constants.MODULES.HOLIDAYS
            },
            {
                title: 'timeTracking.featureTitle',
                description: 'timeTracking.description',
                icon: 'icon-time-record',
                activity: '',
                url: '/settings/time-tracking/schedules',
                access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.humanResources],
                module: Constants.MODULES.TIME_TRACKING
            },
            {
                title: 'global.navbar.usergroups',
                description: 'segments.description',
                icon: 'icon-user-groups',
                activity: '12 grupos',
                url: '/settings/users-groups',
                access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.publisher, Constants.USER_ROLES.employeeManager]
            },
            {
                title: 'global.navbar.feedbackTrays',
                description: 'conversations.description',
                icon: 'icon-inbox',
                activity: '6 bandejas',
                url: '/settings/conversations/motives',
                access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.ftrayManager],
                module: Constants.MODULES.CONVERSATIONS
            },
            {
                title: 'chatrooms.title',
                description: 'chatrooms.description',
                icon: 'icon-chat',
                activity: '6 salas',
                url: '/settings/chatrooms',
                access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.chatroomManager],
                module: Constants.MODULES.CHAT
            },
            {
                title: 'global.navbar.categories',
                description: 'categories.description',
                icon: 'icon-tags',
                activity: '9 categorías',
                url: '/settings/categories',
                access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.publisher]
            },
            {
                title: 'documents.title',
                description: 'documents.description',
                icon: 'icon-documents',
                activity: '',
                url: userLoggedHasAdminRole ? '/settings/documents/global' : '/settings/documents/conventions',
                access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.documentsManager]
            },
            {
                title: 'activeFeatures.title',
                description: 'activeFeatures.description',
                icon: 'icon-features',
                url: '/settings/active-features',
                access: [Constants.USER_ROLES.admin]
            }
        ];
    }

    isItemAvailable(item: any) {
        let isItemModuleActive = !item.module || window['checkIfModuleIsActiveForUserCompany'](item.module);
        return isItemModuleActive && this._sharedDataService.checkIfItemIsAvailableForLoggedUser(item);
    }

    navigateTo(url: string) {
        this._location.go(url);
    }
}
