import { Component, Input, OnInit } from '@angular/core';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'conversation-note-status-tag',
    templateUrl: './conversation-note-status-tag.component.html'
})
export class ConversationNoteStatusTagComponent implements OnInit {
    @Input() status: string;

    public CONVERSATION_NOTES: any;
    public statusTagText: string;

    constructor(private translate: TranslateService) {
        this.translate = translate;
    }

    ngOnInit() {
        this.CONVERSATION_NOTES = Constants.CONVERSATION_NOTES;
        const statusText = this.getStatusTagText();
        if (statusText) {
            this.statusTagText = this.translate.instant(statusText);
        }
    }

    private getStatusTagText() {
        let statusText: string;
        switch (this.status) {
            case this.CONVERSATION_NOTES.CLOSING:
                statusText = 'conversations.closedConversation';
                break;
            case this.CONVERSATION_NOTES.REOPENING:
                statusText = 'conversations.reopenedConversation';
                break;
            case this.CONVERSATION_NOTES.VALIDATION:
                statusText = 'conversations.validatedConversation';
                break;
            case this.CONVERSATION_NOTES.REJECTION:
                statusText = 'conversations.rejectedConversation';
                break;
            case this.CONVERSATION_NOTES.DISCARDED_VALIDATION:
                statusText = 'conversations.validationDiscarded';
                break;
        }
        return statusText;
    }
}
