import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Constants } from '../../constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './request-validation-modal.component.html'
})
export class RequestValidationModalComponent implements OnInit {
    public isFormValid: boolean = false;
    public validationForm: any = {
        validation: '',
        message: ''
    };

    public REQUEST_VALIDATION_STATE = Constants.REQUEST_VALIDATION_STATE;
    public attachedResources: Array<File> = [];

    @ViewChild('attachFile') private attachFileRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<RequestValidationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    private validateModel(): void {
        this.isFormValid = !!this.validationForm.validation;
    }

    selectValidationStatus(state: number): void {
        if (this.validationForm.validation !== state) {
            this.validationForm.validation = state;
            this.onChange();
        }
    }

    attacthResource(): void {
        this.attachFileRef.nativeElement.click();
    }

    onFileSelected(files: File): void {
        if (files) {
            this.attachedResources.push(files[0]);
            this.onChange();
        }
    }

    removeAttachedFile(index: number): void {
        if (this.attachedResources.length > index) {
            this.attachedResources.splice(index, 1);
        }
        this.onChange();
    }

    onChange(): void {
        this.validateModel();
    }

    onAccept(): void {
        this.dialogRef.close({ success: true, validationForm: this.validationForm, attachments: this.attachedResources });
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }
}
