import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({ providedIn: 'root' })
export class ActiveFeaturesService {
    public apiUrl: string;
    public companiesURL: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.companiesURL = `${this.apiUrl}/companies`;
    }

    getConfigurationModules(id: number) {
        return this.httpClient.get<any>(`${this.companiesURL}/${id}/configuration/modules`);
    }

    enableModule(companyId: number, moduleId: number) {
        return this.httpClient.put(`${this.companiesURL}/${companyId}/configuration/modules/${moduleId}/enable`, null);
    }

    disableModule(companyId: number, moduleId: number) {
        return this.httpClient.put(`${this.companiesURL}/${companyId}/configuration/modules/${moduleId}/disable`, null);
    }

    temporarilyDeactivateModule(companyId: number, moduleId: number) {
        return this.httpClient.put(`${this.companiesURL}/${companyId}/configuration/modules/${moduleId}/disableTemporarily`, null);
    }

    temporarilyActivateModule(companyId: number, moduleId: number) {
        return this.httpClient.put(`${this.companiesURL}/${companyId}/configuration/modules/${moduleId}/enableForEmployees`, null);
    }

    enableByDefault(companyId: number, moduleId: number) {
        return this.httpClient.put(`${this.companiesURL}/${companyId}/configuration/modules/${moduleId}/defaultEnabled`, null);
    }

    disableByDefault(companyId: number, moduleId: number) {
        return this.httpClient.put(`${this.companiesURL}/${companyId}/configuration/modules/${moduleId}/defaultDisabled`, null);
    }

    showSignature(companyId: number) {
        return this.httpClient.put(`${this.companiesURL}/${companyId}/configuration/remote-signature/showBackoffice`, null);
    }

    hideSignature(companyId: number) {
        return this.httpClient.put(`${this.companiesURL}/${companyId}/configuration/remote-signature/hideBackoffice`, null);
    }

    getRemoteSignatureStats() {
        return this.httpClient.get<any>(`${this.apiUrl}/remote-signatures/statistics`);
    }
}
