<div class="main-container">
    <header class="main-container__header">
        <nav class="flex direction-column" aria-label="breadcrumb">
            <ul class="breadcrumb">
                <li>
                    <a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
                </li>
            </ul>
            <h2 [translate]="'activeFeatures.title'"></h2>
        </nav>
    </header>

    <section class="main-container__content">
        <div class="main-container--medium-width padding-bottom-64">
            <section class="margin-top-40">
                <h3 class="margin-bottom-40" [translate]="'activeFeatures.optionalFeatures'"></h3>

                <!-- Knowledge Center -->
                <div class="group-form group-form--label-250 margin-bottom-40">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'global.navbar.knowledgecenter'"></span>
                    </div>
                    <div class="group-form__data group-form__data--inline">
                        <div class="button-bar margin-right-20">
                            <button
                                class="button"
                                type="button"
                                [ngClass]="{'button--selected': activeFeatures.knowledgeCenter.state !== MODULES_STATES.DISABLED}"
                                (click)="changeModuleStateByName(MODULES.KNOWLEDGE_CENTER, MODULES_STATES.ENABLED)">
                                <span class="button__label" [translate]="'activeFeatures.state.active'"></span>
                            </button>
                            <button
                                class="button"
                                type="button"
                                [ngClass]="{'button--selected': activeFeatures.knowledgeCenter.state == MODULES_STATES.DISABLED}"
                                (click)="changeModuleStateByName(MODULES.KNOWLEDGE_CENTER, MODULES_STATES.DISABLED)">
                                <span class="button__label" [translate]="'activeFeatures.state.disabled'"></span>
                            </button>
                        </div>
                        <toggle-input
                            [inputId]="'kcenterTemporarilyDeactivate'"
                            [label]="('activeFeatures.state.temporarilyDeactivate' | translate)"
                            (inputChanged)="toggleModuleStateByName(MODULES.KNOWLEDGE_CENTER)"
                            [isDisabled]="activeFeatures.knowledgeCenter.state == MODULES_STATES.DISABLED"
                            [(inputModel)]="activeFeatures.knowledgeCenter.temporarilyDeactivate">
                        </toggle-input>
                        <span
                            class="group-form__label__help icon icon-help-fill margin-left-10"
                            kendoTooltip
                            [title]="'activeFeatures.kcenterTooltip' | translate"></span>
                    </div>
                </div>

                <!-- conversations -->
                <div class="group-form group-form--label-250 margin-bottom-40">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'global.navbar.conversations'"></span>
                    </div>
                    <div class="group-form__data group-form__data--inline">
                        <div class="button-bar margin-right-20">
                            <button
                                class="button"
                                type="button"
                                [ngClass]="{'button--selected': activeFeatures.conversations.state !== MODULES_STATES.DISABLED}"
                                (click)="changeModuleStateByName(MODULES.CONVERSATIONS, MODULES_STATES.ENABLED)">
                                <span class="button__label" [translate]="'activeFeatures.state.active'"></span>
                            </button>
                            <button
                                class="button"
                                type="button"
                                [ngClass]="{'button--selected': activeFeatures.conversations.state == MODULES_STATES.DISABLED}"
                                (click)="changeModuleStateByName(MODULES.CONVERSATIONS, MODULES_STATES.DISABLED)">
                                <span class="button__label" [translate]="'activeFeatures.state.disabled'"></span>
                            </button>
                        </div>
                        <toggle-input
                            [inputId]="'conversationTemporarilyDeactivate'"
                            [label]="'activeFeatures.state.temporarilyDeactivate' | translate"
                            (inputChanged)="toggleModuleStateByName(MODULES.CONVERSATIONS)"
                            [isDisabled]="activeFeatures.conversations.state == MODULES_STATES.DISABLED"
                            [(inputModel)]="activeFeatures.conversations.temporarilyDeactivate">
                        </toggle-input>
                    </div>
                </div>

                <!-- Experiences -->
                @if (activeFeatures.experiences.state !== MODULES_STATES.DISABLED) {
                    <div class="group-form group-form--label-250 margin-bottom-40">
                        <div class="group-form__label">
                            <span class="group-form__label__text" [translate]="'global.navbar.experiences'"></span>
                        </div>
                        <div class="group-form__data group-form__data--inline">
                            <div class="button-bar margin-right-20">
                                <button
                                    class="button"
                                    type="button"
                                    [ngClass]="{'button--selected': activeFeatures.experiences.state !== MODULES_STATES.DISABLED}"
                                    (click)="changeModuleStateByName(MODULES.EXPERIENCES, MODULES_STATES.ENABLED)">
                                    <span class="button__label" [translate]="'activeFeatures.state.active'"></span>
                                </button>
                                <button
                                    class="button"
                                    type="button"
                                    [ngClass]="{'button--selected': activeFeatures.experiences.state == MODULES_STATES.DISABLED}"
                                    (click)="changeModuleStateByName(MODULES.EXPERIENCES, MODULES_STATES.DISABLED)">
                                    <span class="button__label" [translate]="'activeFeatures.state.disabled'"></span>
                                </button>
                            </div>
                            <toggle-input
                                [inputId]="'experiencesTemporarilyDeactivate'"
                                [label]="'activeFeatures.state.temporarilyDeactivate' | translate"
                                (inputChanged)="toggleModuleStateByName(MODULES.EXPERIENCES)"
                                [isDisabled]="activeFeatures.experiences.state == MODULES_STATES.DISABLED"
                                [(inputModel)]="activeFeatures.experiences.temporarilyDeactivate">
                            </toggle-input>
                        </div>
                    </div>
                }

                <!-- employees directory -->
                <div class="group-form group-form--label-250 margin-bottom-40">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'global.navbar.employeesDirectory'"></span>
                    </div>
                    <div class="group-form__data group-form__data--inline">
                        <div class="button-bar margin-right-20">
                            <button
                                class="button"
                                type="button"
                                [ngClass]="{'button--selected': activeFeatures.employeeDirectory.state !== MODULES_STATES.DISABLED}"
                                (click)="changeModuleStateByName(MODULES.DIRECTORY, MODULES_STATES.ENABLED)">
                                <span class="button__label" [translate]="'activeFeatures.state.active'"></span>
                            </button>
                            <button
                                class="button"
                                type="button"
                                [ngClass]="{'button--selected': activeFeatures.employeeDirectory.state == MODULES_STATES.DISABLED}"
                                (click)="changeModuleStateByName(MODULES.DIRECTORY, MODULES_STATES.DISABLED)">
                                <span class="button__label" [translate]="'activeFeatures.state.disabled'"></span>
                            </button>
                        </div>
                        <toggle-input
                            [inputId]="'employeesDirectoryTemporarilyDeactivate'"
                            [label]="'activeFeatures.state.temporarilyDeactivate' | translate"
                            (inputChanged)="toggleModuleStateByName(MODULES.DIRECTORY)"
                            [isDisabled]="activeFeatures.employeeDirectory.state == MODULES_STATES.DISABLED"
                            [(inputModel)]="activeFeatures.employeeDirectory.temporarilyDeactivate">
                        </toggle-input>
                    </div>
                </div>

                <!-- Webapp -->
                <div class="group-form group-form--label-250">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'global.navbar.webApp'" [translateParams]="{urlText: webappUrl}"></span>
                    </div>
                    <div class="group-form__data group-form__data--inline">
                        <div class="button-bar margin-right-20">
                            <button
                                class="button button--disabled"
                                type="button"
                                [ngClass]="{'button--secondary': activeFeatures.webapp.state == MODULES_STATES.ENABLED}">
                                <span class="button__label" [translate]="'activeFeatures.state.active'"></span>
                            </button>
                            <button
                                class="button button--disabled"
                                type="button"
                                [ngClass]="{'button--secondary': activeFeatures.webapp.state == MODULES_STATES.DISABLED}">
                                <span class="button__label" [translate]="'activeFeatures.state.disabled'"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Aditional features Contracted -->
            @if (activeFeatures && hasActiveContractFeatures) {
                <section class="margin-top-48">
                    <h3 class="margin-bottom-20" [translate]="'activeFeatures.additionalContractedFeatures'"></h3>
                    <!-- Time Tracking -->
                    @if (activeFeatures.timeTracking?.state !== MODULES_STATES.DISABLED) {
                        <div class="active-color-feature__container active-color-feature__container--opened margin-bottom-20">
                            <div class="active-color-feature active-color-feature--bluered-gradient">
                                <span class="active-color-feature__icon icon nucleo icon-time-record"></span>
                                <article class="active-color-feature__text-container">
                                    <h3 class="active-color-feature__title margin-bottom-4" [translate]="'timeTracking.featureTitle'"></h3>
                                    <p class="active-color-feature__subtitle" [translate]="'timeTracking.featureDescription'"></p>
                                </article>
                            </div>
                            <div class="active-color-feature__extra-container">
                                <div class="flex align-items-center margin-bottom-24">
                                    <span class="text-md black margin-right-10" [translate]="'activeFeatures.activeFeatureForNewUsers'"></span>
                                    <span
                                        class="group-form__label__help icon icon-help-fill margin-right-10"
                                        kendoTooltip
                                        [title]="'activeFeatures.newUsersTooltip' | translate">
                                    </span>
                                    <toggle-input
                                        [inputId]="'chatEnabledByDefault'"
                                        (inputChanged)="onToggleByDefault(activeFeatures.timeTracking.id, activeFeatures.timeTracking.enabledByDefault)"
                                        [(inputModel)]="activeFeatures.timeTracking.enabledByDefault">
                                    </toggle-input>
                                </div>
                                <div class="flex align-items-center margin-bottom-24">
                                    <span class="text-md black margin-right-20" [translate]="'activeFeatures.deactivateTemporally'"></span>
                                    <toggle-input
                                        [inputId]="'chatTemporarilyDeactivate'"
                                        (inputChanged)="toggleModuleStateByName(MODULES.TIME_TRACKING)"
                                        [(inputModel)]="activeFeatures.timeTracking.temporarilyDeactivate">
                                    </toggle-input>
                                </div>
                                <button type="button" (click)="navigateTo('/settings/time-tracking/schedules')" class="button button--small">
                                    <span class="button__label" [translate]="'activeFeatures.goToConfiguration'"></span>
                                </button>
                            </div>
                        </div>
                    }
                    <!-- Chat -->
                    @if (activeFeatures.chat?.state !== MODULES_STATES.DISABLED) {
                        <div class="active-color-feature__container active-color-feature__container--opened margin-bottom-20">
                            <section class="active-color-feature active-color-feature--green-gradient">
                                <span class="active-color-feature__icon icon nucleo icon-chat"></span>
                                <article class="active-color-feature__text-container">
                                    <h3 class="active-color-feature__title margin-bottom-4" [translate]="'chatrooms.title'"></h3>
                                    <p class="active-color-feature__subtitle" [translate]="'activeFeatures.chatDescription'"></p>
                                </article>
                            </section>
                            <div class="active-color-feature__extra-container">
                                <div class="flex align-items-center margin-bottom-24">
                                    <span class="text-md black margin-right-10" [translate]="'activeFeatures.activeFeatureForNewUsers'"></span>
                                    <span
                                        class="group-form__label__help icon icon-help-fill margin-right-10"
                                        kendoTooltip
                                        [title]="'activeFeatures.newUsersTooltip' | translate">
                                    </span>
                                    <toggle-input
                                        [inputId]="'chatEnabledByDefault'"
                                        (inputChanged)="onToggleByDefault(activeFeatures.chat.id, activeFeatures.chat.enabledByDefault)"
                                        [(inputModel)]="activeFeatures.chat.enabledByDefault">
                                    </toggle-input>
                                </div>
                                <div class="flex align-items-center margin-bottom-24">
                                    <span class="text-md black margin-right-20" [translate]="'activeFeatures.deactivateTemporally'"></span>
                                    <toggle-input
                                        [inputId]="'chatTemporarilyDeactivate'"
                                        (inputChanged)="toggleModuleStateByName(MODULES.CHAT)"
                                        [(inputModel)]="activeFeatures.chat.temporarilyDeactivate">
                                    </toggle-input>
                                </div>
                                <button type="button" (click)="navigateTo('/settings/chatrooms')" class="button button--small">
                                    <span class="button__label" [translate]="'activeFeatures.goToConfiguration'"></span>
                                </button>
                            </div>
                        </div>
                    }
                    <!-- TimeOff -->
                    @if (activeFeatures.timeOff?.state !== MODULES_STATES.DISABLED) {
                        <div class="active-color-feature__container active-color-feature__container--opened margin-bottom-20">
                            <section class="active-color-feature active-color-feature--orange-gradient">
                                <span class="active-color-feature__icon icon nucleo icon-calendar-large"></span>
                                <article class="active-color-feature__text-container">
                                    <h3 class="active-color-feature__title margin-bottom-4" [translate]="'activeFeatures.timeoffTitle'"></h3>
                                    <p class="active-color-feature__subtitle" [translate]="'activeFeatures.timeoffDescription'"></p>
                                </article>
                            </section>
                            <div class="active-color-feature__extra-container">
                                <div class="flex align-items-center margin-bottom-24">
                                    <span class="text-md black margin-right-10" [translate]="'activeFeatures.activeFeatureForNewUsers'"></span>
                                    <span
                                        class="group-form__label__help icon icon-help-fill margin-right-10"
                                        kendoTooltip
                                        [title]="'activeFeatures.newUsersTooltip' | translate">
                                    </span>
                                    <toggle-input
                                        [inputId]="'timeoffEnabledByDefault'"
                                        (inputChanged)="onToggleByDefault(activeFeatures.timeOff.id, activeFeatures.timeOff.enabledByDefault)"
                                        [(inputModel)]="activeFeatures.timeOff.enabledByDefault">
                                    </toggle-input>
                                </div>
                                <div class="flex align-items-center margin-bottom-24">
                                    <span class="text-md black margin-right-20" translate="activeFeatures.deactivateTemporally"></span>
                                    <toggle-input
                                        [inputId]="'timeoffTemporarilyDeactivate'"
                                        (inputChanged)="toggleModuleStateByName(MODULES.HOLIDAYS)"
                                        [(inputModel)]="activeFeatures.timeOff.temporarilyDeactivate">
                                    </toggle-input>
                                </div>
                                <button type="button" class="button button--small" (click)="navigateTo('/settings/timeoff/schemes')">
                                    <span class="button__label" [translate]="'activeFeatures.goToConfiguration'"></span>
                                </button>
                            </div>
                        </div>
                    }
                    <!-- Documents -->
                    @if (activeFeatures.documents?.state !== MODULES_STATES.DISABLED) {
                        <div class="active-color-feature__container active-color-feature__container--opened">
                            <section class="active-color-feature active-color-feature--blue-gradient">
                                <span class="active-color-feature__icon icon nucleo icon-documents"></span>
                                <article class="active-color-feature__text-container">
                                    <h3 class="active-color-feature__title margin-bottom-4" [translate]="'activeFeatures.employeeDocuments'"></h3>
                                    <p class="active-color-feature__subtitle" [translate]="'activeFeatures.employeeDocumentsDescription'"></p>
                                </article>
                            </section>
                            <div class="active-color-feature__extra-container">
                                <div class="flex align-items-center margin-bottom-24">
                                    <span class="text-md black margin-right-10" [translate]="'activeFeatures.activeFeatureForNewUsers'"></span>
                                    <span
                                        class="group-form__label__help icon icon-help-fill margin-right-10"
                                        kendoTooltip
                                        [title]="'activeFeatures.newUsersTooltip' | translate">
                                    </span>
                                    <toggle-input
                                        [inputId]="'documentsEnabledByDefault'"
                                        (inputChanged)="onToggleByDefault(activeFeatures.documents.id, activeFeatures.documents.enabledByDefault)"
                                        [(inputModel)]="activeFeatures.documents.enabledByDefault">
                                    </toggle-input>
                                </div>
                                <div class="flex align-items-center margin-bottom-24">
                                    <span class="text-md black margin-right-20" [translate]="'activeFeatures.deactivateTemporally'"></span>
                                    <toggle-input
                                        [inputId]="'documentsTemporarilyDeactivate'"
                                        (inputChanged)="toggleModuleStateByName(MODULES.DOCUMENTS)"
                                        [(inputModel)]="activeFeatures.documents.temporarilyDeactivate">
                                    </toggle-input>
                                </div>
                                <div class="flex align-items-center">
                                    <span class="text-md black margin-right-20" translate="activeFeatures.hideRemoteSignature"></span>
                                    <toggle-input
                                        [inputId]="'hide-signature'"
                                        (inputChanged)="onToggleHideSignature()"
                                        [(inputModel)]="activeFeatures.documents.backofficeHidden">
                                    </toggle-input>
                                </div>
                            </div>
                            <!-- Signature in pre-production Mode -->
                            @if (!activeFeatures.documents.productionMode) {
                                <div [ngClass]="{'disabled': activeFeatures.documents.backofficeHidden}">
                                    <div class="active-color-feature active-color-feature--signature">
                                        <span class="active-color-feature__icon icon nucleo icon-icon-signature"></span>
                                        <article class="active-color-feature__text-container">
                                            <h3 class="active-color-feature__title margin-bottom-4" [translate]="'activeFeatures.remoteSignature'"></h3>
                                            <p class="active-color-feature__subtitle" [translate]="'activeFeatures.remoteSignatureDescription'"></p>
                                        </article>
                                    </div>
                                    @if (!activeFeatures.documents.backofficeHidden) {
                                        <div class="active-color-feature__extra-container">
                                            <div class="flex align-items-center direction-column">
                                                <span class="signature-title margin-bottom-20" [translate]="'activeFeatures.signature.noCredits'"></span>
                                                <button class="button button--small" type="button" (click)="showNewHireInfo()">
                                                    <span class="button__label" [translate]="'activeFeatures.signature.newHiring'"></span>
                                                </button>
                                                <div class="margin-top-20">
                                                    <span [translate]="'activeFeatures.signature.trySimulation'"></span>
                                                    <a
                                                        href="https://support.dialenga.com/external/knowledge-base/article/91"
                                                        target="_blank"
                                                        class="margin-left-5"
                                                        [translate]="'activeFeatures.signature.knowMore'">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <!-- Signature in production Mode -->
                            @if (activeFeatures.documents.productionMode) {
                                <div [ngClass]="{'disabled': activeFeatures.documents.backofficeHidden}">
                                    <div class="active-color-feature active-color-feature--signature">
                                        <span class="active-color-feature__icon icon nucleo icon-icon-signature"></span>
                                        <article class="active-color-feature__text-container">
                                            <h3 class="active-color-feature__title margin-bottom-4" [translate]="'activeFeatures.remoteSignature'"></h3>
                                            <p class="active-color-feature__subtitle" [translate]="'activeFeatures.remoteSignatureDescription'"></p>
                                        </article>
                                    </div>
                                    @if (!activeFeatures.documents.backofficeHidden) {
                                        <div class="active-color-feature__extra-container">
                                            <div class="flex justify-space-between">
                                                <section class="active-color-feature__box-container">
                                                    <div class="flex align-items-center">
                                                        <span
                                                            class="text-md--bold black margin-right-10"
                                                            style="line-height: 16px"
                                                            [translate]="'activeFeatures.signature.availables'">
                                                        </span>
                                                        <span
                                                            class="group-form__label__help icon icon-help-fill"
                                                            kendoTooltip
                                                            [title]="'activeFeatures.signature.availablesTooltip' | translate">
                                                        </span>
                                                    </div>
                                                    <div
                                                        class="flex align-items-center justify-content-center width-100-percent margin-top-24"
                                                        style="line-height: 32px;">
                                                        <span
                                                            class="font-size-22 margin-right-10"
                                                            [ngClass]="{'advice-color': ((activeFeatures.documents.availableSignatures / activeFeatures.documents.totalSignatures) * 100) <= 10}"
                                                            [textContent]="activeFeatures.documents.availableSignatures | decimalLocalized">
                                                        </span>
                                                        <span
                                                            class="font-size-22 black-45"
                                                            [translate]="'activeFeatures.signature.totalSignatures'"
                                                            [translateParams]="{total: activeFeatures.documents.totalSignatures}">
                                                        </span>
                                                    </div>
                                                    @if (activeFeatures.documents.availableSignatures == 0) {
                                                        <div class="flex align-items-center direction-column width-100-percent margin-top-8">
                                                            <span
                                                                class="signature-title--alert margin-bottom-16"
                                                                [translate]="'activeFeatures.signature.spentBatch'">
                                                            </span>
                                                            <button type="button" class="button button--small" (click)="showNewHireInfo()">
                                                                <span class="button__label" [translate]="'activeFeatures.signature.howHireBatch'"></span>
                                                            </button>
                                                        </div>
                                                    }
                                                    @if (activeFeatures.documents.availableSignatures > 0) {
                                                        <span
                                                            class="text-sm black margin-top-24 font-size-12"
                                                            style="line-height: 16px"
                                                            [translate]="'activeFeatures.signature.expiration'"
                                                            [translateParams]="{day: documentsExpiredDate.day, month: documentsExpiredDate.month, year: documentsExpiredDate.year}">
                                                        </span>
                                                    }
                                                </section>

                                                <section class="active-color-feature__box-container">
                                                    <div class="flex align-items-center margin-bottom-8">
                                                        <span
                                                            class="text-md--bold black margin-right-10"
                                                            style="line-height: 16px"
                                                            [translate]="'activeFeatures.signature.expendedByMonth'">
                                                        </span>
                                                        <span
                                                            class="group-form__label__help icon icon-help-fill"
                                                            kendoTooltip
                                                            [title]="'activeFeatures.signature.expendedByMonthTooltip' | translate">
                                                        </span>
                                                    </div>
                                                    @if (signatureStats.available < signatureStats.total) {
                                                        <div class="flex justify-content-center align-items-center width-100-percent margin-bottom-2">
                                                            <span
                                                                class="active-color-feature__legend-serie active-color-feature__legend-serie--signed margin-right-5">
                                                            </span>
                                                            <span
                                                                class="text-sm black margin-right-10 font-size-12"
                                                                style="line-height: 16px"
                                                                [translate]="'activeFeatures.signature.serieLegendSigned'">
                                                            </span>
                                                            <span
                                                                class="active-color-feature__legend-serie active-color-feature__legend-serie--sent margin-right-5">
                                                            </span>
                                                            <span
                                                                class="text-sm black font-size-12"
                                                                style="line-height: 16px"
                                                                [translate]="'activeFeatures.signature.serieLegendSent'">
                                                            </span>
                                                        </div>
                                                    }
                                                    <div class="flex justify-content-center align-items-center width-100-percent height-100-percent">
                                                        @if (signatureStats.available < signatureStats.total) {
                                                            <kendo-chart
                                                                class="signature-stats-chart"
                                                                [chartArea]="{ width: 220, height: 82 }"
                                                                [panes]="{clip: false}"
                                                                style="border: none;">
                                                                <kendo-chart-series>
                                                                    <kendo-chart-series-defaults
                                                                        [stack]="true"
                                                                        [border]="{width: 0}"
                                                                        [highlight]="{ visible: false }"></kendo-chart-series-defaults>
                                                                    <kendo-chart-series-item
                                                                        [type]="'column'"
                                                                        [data]="signatureStatsData.consumedBySigned"
                                                                        [color]="'#092E38'"
                                                                        [gap]="1"
                                                                        field="value"
                                                                        [visual]="signedVisual"></kendo-chart-series-item>
                                                                    <kendo-chart-series-item
                                                                        [type]="'column'"
                                                                        [data]="signatureStatsData.consumedBySent"
                                                                        [color]="'#2D9CDB'"
                                                                        [gap]="1"
                                                                        field="value"
                                                                        [visual]="sentVisual"></kendo-chart-series-item>
                                                                    <kendo-chart-series-item
                                                                        [type]="'line'"
                                                                        [data]="signatureStatsData.average"
                                                                        [color]="'#2D9CDB'"
                                                                        [style]="'smooth'"
                                                                        [width]="1"
                                                                        [markers]="{ visible: false }"
                                                                        [highlight]="{ visible: false }"
                                                                        [tooltip]="{ visible: false }">
                                                                    </kendo-chart-series-item>
                                                                </kendo-chart-series>
                                                                <!-- AXIS OK -->
                                                                <kendo-chart-axis-defaults
                                                                    [line]="{ visible: false}"
                                                                    [majorGridLines]="{ visible: false}"></kendo-chart-axis-defaults>
                                                                <kendo-chart-category-axis>
                                                                    <kendo-chart-category-axis-item
                                                                        [categories]="signatureStatsData.category"
                                                                        [labels]="{ font: '10px Gotham', padding: { top: 0 } }"></kendo-chart-category-axis-item>
                                                                </kendo-chart-category-axis>
                                                                <kendo-chart-value-axis>
                                                                    <kendo-chart-value-axis-item [min]="-1.5" [visible]="false"></kendo-chart-value-axis-item>
                                                                </kendo-chart-value-axis>
                                                                <kendo-chart-tooltip [visible]="true" format="{0}"></kendo-chart-tooltip>
                                                            </kendo-chart>
                                                        }
                                                        @if (signatureStats.available == signatureStats.total) {
                                                            <span class="black-45 text-md--bold" [translate]="'activeFeatures.signature.noConsumption'"> </span>
                                                        }
                                                    </div>
                                                    @if (signatureStats.available < signatureStats.total) {
                                                        <div class="flex justify-content-center align-items-center width-100-percent margin-top-2">
                                                            <span class="active-color-feature__legend margin-right-5"></span>
                                                            <span
                                                                class="text-sm black font-size-12"
                                                                style="line-height: 16px"
                                                                [innerHTML]="'activeFeatures.signature.averageSpending' | translate: { amount: signatureStats.averageConsumption }">
                                                            </span>
                                                        </div>
                                                    }
                                                </section>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </section>
            }

            <!-- Aditional features NOT contracted -->
            @if (activeFeatures && hasDisabledContractFeatures) {
                <section class="margin-top-48">
                    <h3 class="margin-bottom-20" [translate]="'activeFeatures.additionalNotContractedFeatures'"></h3>
                    <div class="info-advice">
                        <p [translate]="'activeFeatures.contactForNewFeatures'"></p>
                    </div>
                    <!-- Time Tracking -->
                    @if (activeFeatures.timeTracking?.state == MODULES_STATES.DISABLED) {
                        <div class="active-color-feature__container margin-bottom-20">
                            <div class="active-color-feature active-color-feature--bluered-gradient">
                                <span class="active-color-feature__icon icon nucleo icon-time-record"></span>
                                <article class="active-color-feature__text-container">
                                    <h3 class="active-color-feature__title margin-bottom-4" [translate]="'timeTracking.featureTitle'"></h3>
                                    <p class="active-color-feature__subtitle" [translate]="'timeTracking.featureDescription'"></p>
                                </article>
                            </div>
                        </div>
                    }
                    <!-- Chat -->
                    @if (activeFeatures.chat?.state == MODULES_STATES.DISABLED) {
                        <div class="active-color-feature__container margin-bottom-20">
                            <div class="active-color-feature active-color-feature--green-gradient">
                                <span class="active-color-feature__icon icon nucleo icon-chat"></span>
                                <article class="active-color-feature__text-container">
                                    <h3 class="active-color-feature__title margin-bottom-4" [translate]="'chatrooms.title'"></h3>
                                    <p class="active-color-feature__subtitle" [translate]="'activeFeatures.chatDescription'"></p>
                                </article>
                            </div>
                        </div>
                    }
                    <!-- TimeOff -->
                    @if (activeFeatures.timeOff?.state == MODULES_STATES.DISABLED) {
                        <div class="active-color-feature__container margin-bottom-20">
                            <div class="active-color-feature active-color-feature--orange-gradient">
                                <span class="active-color-feature__icon icon nucleo icon-calendar-large"></span>
                                <article class="active-color-feature__text-container">
                                    <h3 class="active-color-feature__title margin-bottom-4" [translate]="'activeFeatures.timeoffTitle'"></h3>
                                    <p class="active-color-feature__subtitle" [translate]="'activeFeatures.timeoffDescription'"></p>
                                </article>
                            </div>
                        </div>
                    }
                    <!-- Documents -->
                    @if (activeFeatures.documents?.state == MODULES_STATES.DISABLED) {
                        <div class="active-color-feature__container">
                            <div class="active-color-feature active-color-feature--blue-gradient direction-column">
                                <div class="flex align-items-center width-100-percent">
                                    <span class="active-color-feature__icon icon nucleo icon-documents"></span>
                                    <article class="active-color-feature__text-container">
                                        <h3 class="active-color-feature__title margin-bottom-4" [translate]="'activeFeatures.employeeDocuments'"></h3>
                                        <p class="active-color-feature__subtitle" [translate]="'activeFeatures.employeeDocumentsDescription'"></p>
                                    </article>
                                </div>
                                <!-- Remote signature -->
                                <div class="flex align-items-center width-100-percent">
                                    <span class="active-color-feature__icon icon nucleo icon-icon-signature"></span>
                                    <article class="active-color-feature__text-container">
                                        <h3 class="active-color-feature__title margin-bottom-4" [translate]="'activeFeatures.remoteSignature'"></h3>
                                        <p class="active-color-feature__subtitle" [translate]="'activeFeatures.remoteSignatureDescription'"></p>
                                    </article>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            }
        </div>
    </section>
</div>
