<div class="documents-upload-container drag-drop" [ngClass]="{ 'documents-upload-container--active dragging': isDropActive}">
    <div class="documents-upload-container__content">
        @if (imageUpload) {
            <img class="normal-state-img documents-upload-container__icon" [src]="'/ng1/assets/img/upload-picture.svg'" alt="" />
            <img class="hover-state-img documents-upload-container__icon" [src]="'/ng1/assets/img/upload-picture-blue.svg'" alt="" />
            <span class="documents-upload-container__label margin-top-10" [textContent]="'message.dragimage' | translate"></span>
        }
        @if (!imageUpload) {
            <span
                class="documents-upload-container__icon icon icon-upload-documents"
                [ngClass]="{ 'documents-upload-container__icon--active': isDropActive}"></span>
            <span class="documents-upload-container__label margin-top-10" [translate]="'remoteSignature.creation.dragFiles'"></span>
        }
        <span class="documents-upload-container__label margin-top-5 margin-bottom-5" [translate]="'message.dragimageor'"></span>
    </div>
    <div>
        <label
            for="documentInput"
            class="button button--small documents-upload-container__button"
            [ngClass]="{ 'documents-upload-container__button--active': isDropActive}">
            <span class="button__label non-selectable" [translate]="'documents.import.attachFiles'"></span>
            @if (!imageUpload) {
                <input
                    id="documentInput"
                    type="file"
                    (change)="selectFiles($event.target.files)"
                    onclick="this.value = null"
                    accept="application/pdf"
                    multiple />
            }
            @if (imageUpload) {
                <input id="documentInput" type="file" (change)="selectFiles($event.target.files)" onclick="this.value = null" accept="image/*" />
            }
        </label>
    </div>
</div>
