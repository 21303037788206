<div class="main-container">
    <!-- Header -->
    <section class="main-container__header" style="min-width: 891px;">
        <div class="flex align-items-end justify-space-between width-100-percent">
            <div class="flex direction-column width-100-percent" spellcheck="false">
                <!-- Breadcrumbs -->
                <ul class="breadcrumb margin-bottom-0">
                    <li>
                        <a #goGlobalSettings id="go-global-settings" href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
                    </li>
                    <li>
                        <a #goTeams id="go-teams" href="/settings/my-company/teams" [translate]="'timeoff.tabs.teams'"></a>
                    </li>
                </ul>
                <!-- Team editable name -->
                <div class="editable-wrapper">
                    <h2
                        #teamName
                        id="team-name"
                        name="team-name"
                        class="icon icon-edit contenteditable editable-title"
                        [textContent]="teamForm.name"
                        ([ngModel])="teamForm.name"
                        [contentEditable]="true"
                        prevent-event="dragover drop"
                        maxlength="64"
                        (blur)="onElementBlur($event)"
                        (keydown)="onElementKeyDown($event)"
                        (paste)="onElementPaste($event)"
                        [ngClass]="{'disabled-no-pointer': isFormSubmiting}"
                        [spellcheck]="false"></h2>
                    <span class="bottom-line"></span>
                </div>
            </div>
            <div class="action-buttons-container margin-left-20 text-right">
                <div class="flex align-items-center">
                    <!-- Add employees to team -->
                    <loading-button
                        [buttonId]="'add-users-modal'"
                        [classes]="'button button--secondary button--small'"
                        [iconClass]="'icon-add'"
                        [text]="'schemes.employees.addEmployees'"
                        (action)="addEmployeesToTeam()"
                        [isValid]="true">
                    </loading-button>
                    <!-- Download team report -->
                    @if (isTimeoffModuleActive) {
                        <loading-button
                            [buttonId]="'download-report'"
                            [classes]="'button button--small margin-left-10'"
                            [iconClass]="'icon-export'"
                            [text]="'model.teams.downloadBalanceTeam'"
                            (action)="downloadTeamReport()"
                            [isValid]="teamEmployeesDatagrid?.total > 0">
                        </loading-button>
                    }
                </div>
            </div>
        </div>
    </section>
    <!-- Content -->
    <section class="main-container__content">
        <div class="main-container--full-width">
            <!-- Team editable description -->
            <div class="editable-wrapper">
                <p
                    #teamDescription
                    id="team-description"
                    name="team-description"
                    class="icon icon-edit contenteditable editable-title title"
                    [title]="'model.teams.descriptionplaceholder' | translate"
                    [textContent]="teamForm.description"
                    ([ngModel])="teamForm.description"
                    [contentEditable]="true"
                    prevent-event="dragover drop"
                    maxlength="128"
                    (blur)="onElementBlur($event)"
                    (keydown)="onElementKeyDown($event)"
                    (paste)="onElementPaste($event)"
                    [ngClass]="{'disabled-no-pointer': isFormSubmiting}"
                    [spellcheck]="false"></p>
            </div>
            <!-- Team responsible -->
            <div class="group-form group-form--label-250 margin-bottom-0">
                <div class="group-form__label">
                    <span
                        id="label-team-leader"
                        class="group-form__label__text"
                        [translate]="!isTimeoffModuleActive ? 'timeoffRequests.responsible' : 'timeoff.teams.approvalResponsible'"></span>
                </div>
                <div class="group-form__data ">
                    <fieldset class="radio-button-container margin-top-5 padding-top-2">
                        <input
                            type="radio"
                            name="withoutLeader"
                            id="withoutLeader"
                            class="sr-only"
                            [value]="false"
                            [(ngModel)]="teamHasResponsible"
                            (ngModelChange)="setResponsibleOption()" />

                        <label for="withoutLeader" class="radio-button__item" [ngClass]="{'radio-button__item--selected': !teamHasResponsible}">
                            <span class="radio-button__item__icon icon" [ngClass]="!teamHasResponsible ? 'icon-circle-selected' : 'icon-circle'"> </span>
                            <span class="radio-button__item__label" [translate]="'timeoff.teams.optionNoResponsible'"></span>
                            @if (isTimeoffModuleActive) {
                                <span class="radio-button__item__label margin-left-5" [translate]="'timeoff.teams.optionNoResponsibleTimeOff'"></span>
                            }
                        </label>

                        <input
                            type="radio"
                            name="withLeader"
                            id="withLeader"
                            class="sr-only"
                            [value]="true"
                            [(ngModel)]="teamHasResponsible"
                            (ngModelChange)="setResponsibleOption()" />

                        <label for="withLeader" class="radio-button__item" [ngClass]="{'radio-button__item--selected': teamHasResponsible}">
                            <span class="radio-button__item__icon icon" [ngClass]="teamHasResponsible ? 'icon-circle-selected' : 'icon-circle'"> </span>
                            <span class="radio-button__item__label" [translate]="'timeoff.teams.optionResponsible'"></span>
                        </label>
                    </fieldset>
                </div>
            </div>
            @if (teamHasResponsible) {
                <div class="group-form group-form--label-250">
                    <div class="group-form__label">
                        <span class="group-form__label__text"></span>
                    </div>
                    <div class="group-form__data">
                        <user-dropdown
                            class="width-350 margin-left-30"
                            [(inputModel)]="teamForm.responsible"
                            (onBlur)="onResponsibleBlur()"
                            (onSelection)="onResponsibleSelect()"></user-dropdown>
                    </div>
                </div>
            }
            <!-- --- -->
            <div class="flex align-items-start justify-space-between margin-top-20 margin-bottom-10">
                <div class="group-form group-form--label-100">
                    <div class="group-form__label">
                        <span class="group-form__label__text font-size-16" [translate]="'model.schemes.employees'"></span>
                    </div>
                    <div class="group-form__data ">
                        <div class="blue-counter">
                            <span class="blue-counter__label" [textContent]="teamEmployeesDatagrid?.total"></span>
                        </div>
                    </div>
                </div>
                <!-- filter by username, internal id, email and document name -->
                @if (teamEmployeesDatagrid.total > 0 || (teamEmployeesDatagrid.total === 0 && !!gridFilteringOps.name)) {
                    <div class="search-filter-container align-items-center margin-bottom-5">
                        <input
                            type="text"
                            class="input input--search"
                            maxlength="1024"
                            [placeholder]="'documents.list.filters.searchPlaceholder' | translate"
                            [(ngModel)]="gridFilteringOps.name"
                            (input)="listItemsSearch()"
                            ngModelOptions="{ debounce: 200 }"
                            [ngClass]="gridFilteringOps.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'" />
                        <div class="icon icon-remove" (click)="clearSearchInput()"></div>
                    </div>
                }
            </div>
            <!-- Team employees grid -->
            @if (teamEmployeesDatagrid?.data.length > 0) {
                <kendo-grid
                    #teamEmployeesGrid
                    [data]="teamEmployeesDatagrid"
                    class="master-grid"
                    [sortable]="true"
                    [sort]="gridSort"
                    (sortChange)="onSortChange($event)"
                    [resizable]="false"
                    [selectable]="false"
                    [navigable]="true"
                    [loading]="isSyncing"
                    [pageable]="true"
                    (pageChange)="onPageChange($event)"
                    [skip]="gridPagingOps.skip"
                    [pageSize]="gridPagingOps.size">
                    <kendo-grid-messages
                        [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                        [pagerOf]="'global.lists.pagerOf' | translate"
                        [pagerItems]="'global.lists.pagerItems' | translate">
                    </kendo-grid-messages>

                    <!-- Employee avatar -->
                    <kendo-grid-column [width]="76" class="cursor-pointer" [headerClass]="'padding-0'" [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <avatar
                                    [name]="dataItem.name"
                                    [surname]="dataItem.surname"
                                    [small]="false"
                                    [thumbnails]="{thumbnail_M: dataItem.avatarThumbnailMUrl || dataItem.avatarUrl, thumbnail_S: dataItem.avatarThumbnailSUrl || dataItem.avatarThumbnailMUrl}">
                                </avatar>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Employee name and surname -->
                    <kendo-grid-column class="cursor-default" [sortable]="false" [headerStyle]="{'pointer-events': 'none'}" [headerClass]="'padding-left-12'">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center cursor-pointer user-select-none">
                                <span [translate]="'users.name'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span class="truncate" [textContent]="dataItem.name + ' ' + dataItem.surname"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Employee username -->
                    <kendo-grid-column field="username" class="cursor-pointer" [sortable]="true">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center cursor-pointer user-select-none">
                                <span [translate]="'users.username'"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span class="truncate" [textContent]="dataItem.username"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- CTA buttons column -->
                    <kendo-grid-command-column title="" [style]="{ 'text-align': 'end' }" class="cursor-pointer">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                            <div class="td-on-hover">
                                <a id="delete-user-btn" class="k-button k-master-button" (click)="deleteEmployee(dataItem)">
                                    <span class="icon icon-delete"></span>
                                </a>
                            </div>
                        </ng-template>
                    </kendo-grid-command-column>
                    <!-- Custom Pager -->
                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <article class="dui-grid-pager">
                            <kendo-pager-info></kendo-pager-info>
                            @if (teamEmployeesDatagrid.total > gridPagingOps.size) {
                                <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                </article>
                                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                            }
                        </article>
                    </ng-template>
                </kendo-grid>
            }
            <!-- Skeleton team employees grid -->
            @if (teamEmployeesDatagrid?.data.length == 0 && isSyncing) {
                <div>
                    <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
                        <kendo-grid-column [width]="76">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="circle" animation="pulse" [width]="40" [height]="40"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column [headerStyle]="{'padding': '0.5em 0.9em'}">
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [textContent]="'users.name' | translate"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex align-items-center">
                                    <kendo-skeleton shape="text" animation="pulse" [width]="200" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column>
                            <ng-template kendoGridHeaderTemplate>
                                <div class="flex align-items-center">
                                    <span [textContent]="'users.username' | translate"></span>
                                </div>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <kendo-skeleton shape="text" animation="pulse" [width]="250" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-command-column title=""></kendo-grid-command-column>
                    </kendo-grid>
                </div>
            }
            @if (teamEmployeesDatagrid?.data.length == 0 && !gridFilteringOps.name && !isSyncing) {
                <no-results [imageTitle]="'schemes.employees.noResultsTitle'" [description]="'schemes.employees.noResultsText'"> </no-results>
            }
            @if (teamEmployeesDatagrid?.data.length == 0 && gridFilteringOps.name && !isSyncing) {
                <no-search-results></no-search-results>
            }
        </div>
    </section>
</div>
