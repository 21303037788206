import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SharedDataService } from '../../../shared/services/shared-data.service';
import { ArrayUtilsService } from '../../../shared/services/array-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogValueOptions } from 'src/ng2/app/shared/services/dialog.service';

@Component({
    selector: 'language-selector',
    templateUrl: 'language-selector.component.html',
    styleUrls: ['./_language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
    @Input() selectedLanguage: any;
    @Input() formContent: any;
    @Input() answers: any[] = [];
    @Input() validationFunction: any;
    @Output() selectedLanguageChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() formContentChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() publicationLanguagesUpdate: EventEmitter<any> = new EventEmitter<any>();

    @HostListener('document:click', ['$event'])
    clickout(event: any) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isDropDownOpened = false;
        }
    }
    public addedLanguagesInitialized: boolean;
    private userSession: any;
    public defaultLanguage: string;
    public isDropDownOpened: boolean;
    public availableLanguages: any[];

    constructor(
        private arrayUtilsService: ArrayUtilsService,
        public Dialog: MatDialog,
        private dialogService: DialogService,
        private sharedDataService: SharedDataService,
        private translate: TranslateService,
        private elementRef: ElementRef
    ) {
        this.addedLanguagesInitialized = false;
        this.userSession = window['getUserSession']();
        this.defaultLanguage = this.userSession.companyDefaultLanguage;
        this.isDropDownOpened = false;
        this.availableLanguages = [];
    }

    ngOnInit() {
        this.setLanguage(this.userSession.companyDefaultLanguage);
    }

    setLanguage(language) {
        this.selectedLanguage = language;
        this.selectedLanguageChange.emit(this.selectedLanguage);
    }

    isLanguageAdded(language) {
        return !!language.added;
    }

    removeLanguage(language: any): void {
        // Delete content of the language we want to remove
        delete this.formContent[language.name];

        // Check if content is a question and has any answer associated to the language we want to remove
        // If so, delete all of them
        if (this.answers && this.answers.length > 0) {
            for (const answer of this.answers) {
                if (answer.contents && answer.contents[language.name]) {
                    delete answer.contents[language.name];
                }
            }
        }
    }

    setLanguageAsRemoved(language: any) {
        if (this.selectedLanguage === language.name) {
            this.selectedLanguage = this.userSession.companyDefaultLanguage;
            this.selectedLanguageChange.emit(this.selectedLanguage);
        }
        if (this.validationFunction) {
            this.validationFunction();
        }
        language.added = false;
        // If language removed has no longer setted in company, remove from availableLanguages
        if (this.userSession.companyLanguages.indexOf(language.name) === -1) {
            var langIndex = this.availableLanguages.findIndex((lang) => {
                return lang.name == language.name;
            });
            if (langIndex !== -1) {
                this.availableLanguages.splice(langIndex, 1);
            }
        }
    }

    toggleLanguage(language: any) {
        if (!language.added) {
            // Add language
            language.added = true;
            this.formContent[language.name] = {};
            this.formContentChange.emit(this.formContent);
            this.setLanguage(language.name);
        } else {
            // Remove language
            if (this.formContent[language.name] && this.arrayUtilsService.objectHasAnyValue(this.formContent[language.name])) {
                // If language has content, confirm action
                const dialogValues: DialogValueOptions = {
                    title: 'language.modal.remove.title',
                    message: 'language.modal.remove.message'
                };

                this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
                    if (result?.accept) {
                        this.removeLanguage(language);
                        this.setLanguageAsRemoved(language);
                        this.formContentChange.emit(this.formContent);
                    }
                });
            } else {
                delete this.formContent[language.name];
                this.setLanguageAsRemoved(language);
                this.formContentChange.emit(this.formContent);
            }
        }
        this.isDropDownOpened = false;
    }

    toggleSelector() {
        this.isDropDownOpened = !this.isDropDownOpened;
    }

    initializeAvailableLanguages() {
        let totalLanguages = this.userSession.companyLanguages.concat(Object.keys(this.formContent));

        this.availableLanguages = totalLanguages
            .filter((language: string) => language !== this.userSession.companyDefaultLanguage)
            .map((language: string) => ({ name: language, translatedName: this.translate.instant('global.language.' + language), added: false }));

        this.availableLanguages = this.arrayUtilsService.removeDuplicates(this.availableLanguages, 'name');
        this.availableLanguages.sort((a, b) => {
            return a.translatedName > b.translatedName ? 1 : b.translatedName > a.translatedName ? -1 : 0;
        });
    }

    addLanguages(languages: string[]): void {
        this.availableLanguages.forEach((availableLanguage) => {
            availableLanguage.added = languages.includes(availableLanguage.name);
        });
        this.addedLanguagesInitialized = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedLanguage && this.validationFunction) {
            this.validationFunction();
        }

        if (changes.formContent) {
            const newValue = changes.formContent.currentValue;
            const oldValue = changes.formContent.previousValue;

            const oldValueKeys = oldValue ? Object.keys(oldValue) : [];
            const newValueKeys = newValue ? Object.keys(newValue) : [];

            if ((oldValueKeys.length === 0 || !this.addedLanguagesInitialized) && newValueKeys.length > 0) {
                this.initializeAvailableLanguages();
                this.addLanguages(newValueKeys);
                let language = this.sharedDataService.getMainContentLanguage(newValue);
                // If content does not have company default language, set language of first content
                if (language !== this.userSession.companyDefaultLanguage) {
                    language =
                        this.availableLanguages[
                            this.availableLanguages.findIndex(function (lang) {
                                return lang.added;
                            })
                        ].name;
                }
                this.setLanguage(language);
            } else if (oldValueKeys.length !== newValueKeys.length) {
                this.addLanguages(newValueKeys);
                this.publicationLanguagesUpdate.emit({ addedLanguages: newValueKeys });
            } else if (newValueKeys.length === oldValueKeys.length) {
                this.addLanguages(newValueKeys);
            }
        }
    }
}
