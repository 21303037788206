import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { WorkplacesService } from '../workplaces/workplaces.service';
import { TranslateService } from '@ngx-translate/core';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { WorkplaceHolidayModalComponent } from './workplace-holiday-modal.component';

declare var moment: any;

@Component({
    selector: 'workplace-holidays',
    templateUrl: './workplace-holidays.component.html',
    encapsulation: ViewEncapsulation.None
})
export class WorkplaceHolidaysComponent implements OnInit, OnChanges {
    @Input() workplaceId: number;
    @Input() addNewHoliday: boolean;
    @Output() addNewHolidayChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public years: Array<number> = [];
    public selectedYear: number;
    public workplaceHolidays: Array<any> = [];
    public isSyncing: boolean = false;

    constructor(
        private workplaceService: WorkplacesService,
        private notificationService: DuiNotificationsService,
        private formUtilsService: FormUtilsService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private dialogService: DialogService
    ) {}

    ngOnInit(): void {
        this.initializeAvailableYears();
        this.selectedYear = this.years[0];
        this.getWorkplaceHolidays(this.selectedYear);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.addNewHoliday && changes.addNewHoliday.currentValue !== changes.addNewHoliday.previousValue && !changes.addNewHoliday.firstChange) {
            if (changes.addNewHoliday.currentValue) {
                this.openHolidayEditionModal();
                this.formUtilsService.finishSubmitAction();
                this.addNewHolidayChange.emit(false);
            }
        }
    }

    // PRIVATE METHODS

    private initializeAvailableYears(): void {
        const actualYear = new Date().getFullYear();
        for (var index = 0; index <= 10; index++) {
            this.years.push(actualYear + index);
        }
    }

    private getWorkplaceHolidays(year: number): void {
        this.isSyncing = true;
        this.workplaceService.getHolidaysFromWorkplace(year, this.workplaceId).subscribe(
            (response: any) => {
                this.workplaceHolidays = response;
                this.isSyncing = false;
            },
            (onError: any) => {
                this.isSyncing = false;
            }
        );
    }

    // PUBLIC METHODS

    onYearSelection(): void {
        this.getWorkplaceHolidays(this.selectedYear);
    }

    getFormattedDate(holiday: any): string {
        var monthName = moment.months(holiday.month - 1);
        return this.translate.instant('global.form.formattedDateShort', { day: holiday.day, month: monthName });
    }

    openHolidayEditionModal(holiday?: any): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            modalTitle: this.translate.instant(!!holiday ? 'workplaces.holidays.modal.edit.title' : 'workplaces.holidays.addHolidays'),
            holiday: holiday || { id: null, name: '', day: 1, month: 1, year: this.selectedYear }
        };
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '6vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(WorkplaceHolidayModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.accept) {
                    const createOrUpdate: string = !!result?.holiday.id ? 'updateHolidayInWorkplace' : 'createHolidayInWorkplace';
                    this.workplaceService[createOrUpdate](this.workplaceId, result?.holiday).subscribe(
                        (response: any) => {
                            this.getWorkplaceHolidays(this.selectedYear);
                            const message = this.translate.instant(
                                !result?.holiday.id ? 'workplaces.holidays.notification.add.message' : 'workplaces.holidays.notification.edit.message'
                            );
                            this.notificationService.showSuccessNotification(message);
                        },
                        (onError: any) => {}
                    );
                }
            });
    }

    openHolidayDeletionModal(holiday: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'workplaces.holidays.modal.delete.title',
            message: 'workplaces.holidays.modal.delete.message',
            messageParam: { holidayName: holiday.name },
            acceptButtonText: 'global.form.delete'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result: any) => {
            if (result?.accept) {
                this.workplaceService.deleteHolidayFromWorkplace(this.workplaceId, holiday.id).subscribe(
                    (onSuccess: any) => {
                        this.getWorkplaceHolidays(this.selectedYear);
                        const message = this.translate.instant('workplaces.holidays.notification.delete.message', { name: holiday.name });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError: any) => {}
                );
            }
        });
    }
}
