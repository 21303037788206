<section class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="modalTitle"></h3>
</section>
<section class="modal-body">
    <div class="group-form group-form--label-200">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'workplaces.holidays.modal.edit.holidayName'"></span>
        </div>
        <div class="group-form__data">
            <input
                #holidayName
                id="holiday-name"
                class="input"
                [(ngModel)]="holidayForm.name"
                (ngModelChange)="onChange()"
                maxlength="64"
                placeholder="{{'workplaces.holidays.modal.edit.holidayNamePlaceholder' | translate}}" />
        </div>
    </div>
    <div class="group-form group-form--label-200 margin-bottom-0">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'workplaces.holidays.modal.edit.day'"></span>
        </div>
        <div class="group-form__data">
            <kendo-dropdownlist
                id="select-day"
                [data]="days"
                [(ngModel)]="holidayForm.day"
                class="dui-dropdown align-right margin-right-10 height-36 width-60"
                valuePrimitive="true"
                (valueChange)="onChange()"
                [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span
                        data-cy="day-option"
                        class="padding-3"
                        [textContent]="dataItem"
                        [ngClass]="{'itemDisabled': (dataItem > 28 && holidayForm.month == '2') || 
                            (dataItem > 30 && [4,6,9,11].includes(holidayForm.month))}">
                    </span>
                </ng-template>
            </kendo-dropdownlist>

            <kendo-dropdownlist
                id="select-month"
                [data]="months"
                [(ngModel)]="holidayForm.month"
                class="dui-dropdown margin-right-10 height-36 width-160"
                valuePrimitive="true"
                (valueChange)="onChange()"
                [textField]="'name'"
                valueField="value"
                [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                <ng-template kendoDropDownListValueTemplate let-dataItem>
                    @if (dataItem) {
                        <span class="capitalize padding-left-4" [textContent]="dataItem.name"></span>
                    }
                </ng-template>
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span
                        data-cy="month-option"
                        class="padding-3 margin-left-5 capitalize"
                        [textContent]="dataItem.name"
                        [ngClass]="{'itemDisabled': (dataItem.value == '2' && holidayForm.day > 28) || 
                            ([4,6,9,11].includes(dataItem.value) && holidayForm.day > 30)}"></span>
                </ng-template>
            </kendo-dropdownlist>
        </div>
    </div>
</section>
<section class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'holiday-modal-accept-button'"
        [classes]="'button button--secondary margin-right-10'"
        [text]="'global.form.save'"
        (action)="onAccept()"
        [isValid]="isFormDataChanged && isFormDataValid"></loading-button>
    <button type="button" class="button" (click)="onCancel()">
        <span class="button__label" [translate]="'global.modal.cancel'"></span>
    </button>
</section>
