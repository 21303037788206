import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CategoryService {
    public apiUrl: string;
    public categoryUrl: string;
    public availableColors: { [key: string]: number };

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.categoryUrl = `${this.apiUrl}/categories`;

        this.availableColors = {
            redDark: 10362398,
            orangeDark: 13916194,
            yellowDark: 12487702,
            greenDark: 2198821,
            blueDark: 31872,
            secondaryBlueDark: 739233,
            purpleDark: 7809446,
            pinkDark: 10687892,
            greyDark: 4936020,
            redBase: 13839915,
            orangeBase: 15628583,
            yellowBase: 15908385,
            greenBase: 5095769,
            blueBase: 374214,
            secondaryBlueBase: 2654180,
            purpleBase: 10375914,
            pinkBase: 14901206,
            greyBase: 7893368,
            redLight: 16734286,
            orangeLight: 16753475,
            yellowLight: 16765501,
            greenLight: 7199352,
            blueLight: 1626340,
            secondaryBlueLight: 3059702,
            purpleLight: 11760868,
            pinkLight: 15636463,
            greyLight: 10725031,
            redLightest: 16744833,
            orangeLightest: 16760687,
            yellowLightest: 15653731,
            greenLightest: 10151842,
            blueLightest: 6481395,
            secondaryBlueLightest: 7586806,
            purpleLightest: 12889335,
            pinkLightest: 16167659,
            greyLightest: 12435393
        };
    }

    private getCategoryAsFormData(data: { category: any; images?: { image: Blob; name: string }[] }): FormData {
        const fd = new FormData();
        fd.append('category', new Blob([JSON.stringify(data.category)], { type: 'application/json' }));
        if (data.images) {
            data.images.forEach((imageData) => {
                fd.append('image', imageData.image, imageData.name);
            });
        }
        return fd;
    }

    findOne(id: number) {
        return this.httpClient.get<any>(`${this.categoryUrl}/${id}`);
    }

    update(data: { category: any; images?: { image: Blob; name: string }[] }): Observable<any> {
        const formData = this.getCategoryAsFormData(data);
        return this.httpClient.post<any>(`${this.categoryUrl}/${data.category.id}`, formData);
    }

    create(data: { category: any; images?: { image: Blob; name: string }[] }): Observable<any> {
        const formData = this.getCategoryAsFormData(data);
        return this.httpClient.post<any>(`${this.categoryUrl}`, formData);
    }
}
