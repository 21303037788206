<dui-popover [onlyOnClick]="true" class="dui-popover-signature-area">
    <ng-content popoverItem></ng-content>
    <div class="dui-popover-container">
        @for (signatureArea of signatureAreasList; track signatureArea) {
            <div
                class="dui-popover-container__item"
                (click)="selectSignatureArea(signatureArea)"
                [ngClass]="{ 'dui-popover-container__item--selected': area?.id === signatureArea.id }">
                <div class="dui-popover-container__item__icon">
                    <span class="icon" [ngClass]="signatureArea.anchor ? 'icon-anchor' : 'icon-signature-zone-s'"></span>
                </div>
                <div class="dui-popover-container__item__value">
                    <span class="non-selectable" [textContent]="signatureArea.name"></span>
                </div>
            </div>
        }
    </div>
</dui-popover>
