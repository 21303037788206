<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="data.title"></h3>
</div>
<div class="modal-body padding-24">
    <section class="records-container">
        <div class="records-container__header">
            <span [translate]="'timeTracking.detail.entry'"></span>
            <span [translate]="'timeTracking.detail.departure'"></span>
        </div>
        @for (record of recordTimeForm.records; track record; let last = $last; let idx = $index) {
            <div class="records-container__item">
                <input
                    #startTime
                    type="time"
                    class="input"
                    [(ngModel)]="record.startTime"
                    placeholder="00:00"
                    name="startTime"
                    [ngClass]="{'input--error': record.invalid}"
                    (ngModelChange)="onChangeTime($event, record, startTime)"
                    (copy)="onCopyTime($event, startTime)"
                    (paste)="onPasteTime($event, record, startTime)" />
                <input
                    #endTime
                    type="time"
                    class="input"
                    [(ngModel)]="record.endTime"
                    placeholder="00:00"
                    name="endTime"
                    [ngClass]="{'input--error': record.invalid}"
                    (ngModelChange)="onChangeTime($event, record, endTime)"
                    (copy)="onCopyTime($event, endTime)"
                    (paste)="onPasteTime($event, record, endTime)" />

                <kendo-dropdownlist
                    [defaultItem]=""
                    [data]="recordKinds"
                    [(ngModel)]="record.kind"
                    [textField]="'name'"
                    valueField="id"
                    [disabled]="recordKindsDisabled"
                    class="dui-dropdown margin-right-10"
                    [ngClass]="{'kinds-disabled': recordKindsDisabled}"
                    [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                    <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        @if (!recordKindsDisabled) {
                            <span
                                class="label-kind"
                                [textContent]="dataItem?.name"
                                [ngStyle]="{'background-color': utils.decimalToHex(dataItem?.color)}"></span>
                        } @else {
                            <span class="label-kind" [textContent]="'Tipos desactivados'" [ngStyle]="{'background-color': utils.decimalToHex(12961221)}"></span>
                        }
                    </ng-template>
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span class="padding-3 margin-left-5" [textContent]="dataItem?.name"></span>
                    </ng-template>
                </kendo-dropdownlist>
                @if (recordTimeForm.records.length > 1 && !isEditionMode) {
                    <span class="icon icon-24 icon-delete black-30 cursor-pointer" (click)="deleteInterval(idx)"></span>
                }
            </div>
        }
    </section>
    @if (oneValidRecordExist() && !isEditionMode) {
        <section class="info-color cursor-pointer non-selectable flex align-items-center margin-top-24" (click)="addInterval()" style="width: fit-content">
            <span class="icon icon-16 icon-add margin-right-8"></span>
            <span [translate]="'timeTracking.detail.addInterval'"></span>
        </section>
    }
    @if (formHasErrors) {
        <div class="info-advice danger margin-bottom-0 margin-top-24 width-100-percent" style="border-radius: 3px;">
            <span [textContent]="localizedError"></span>
        </div>
    }
</div>
<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'create-chatroom'"
        [classes]="'button--secondary margin-right-10'"
        [text]="'global.modal.accept'"
        (action)="onAccept()"
        [isValid]="isFormValid">
    </loading-button>
    <button id="cancel-chatroom" type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>
