import { Pipe, PipeTransform } from '@angular/core';

/**
 * @ngdoc pipe
 * @name hoursMinutes
 * @description Parse and return any input time (seconds, minutes, hours) in hours(h) minutes(m), optional show arithmetic sign if time input is a operation result
 * @param {inputKind: string, showSign: boolean} inputKind input format: hours, minutes, seconds
 * @param {showSign: boolean} showSign boolean for add arithmetic sign (- or +)
 * @returns {string} Parsed result in {hours}h {minutes}m
 */

import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'hoursMinutes'
})
export class HoursMinutesTimePipe implements PipeTransform {
    constructor(private translate: TranslateService) {}
    transform(field: any, inputFormat: any): string {
        if (!inputFormat || !field) {
            return !field ? this.translate.instant('global.form.hoursSymbol', { value: 0 }) : '';
        }
        const isFieldNegative = field < 0;
        if (isFieldNegative) {
            field = field * -1;
        }

        let hours: number = 0;
        let minutes: number = 0;
        let result: string = isFieldNegative ? '-' : inputFormat.showSign ? '+' : '';

        switch (inputFormat.inputKind) {
            case 'seconds':
                hours = Math.floor(field / 3600);
                minutes = Math.floor((field % 3600) / 60);
                break;
            case 'minutes':
                hours = Math.floor(field / 60);
                minutes = Math.floor(field % 60);
                break;
            case 'hours':
                hours = Math.floor(field / 1);
                minutes = Math.round((field % 1) * 60);
                break;
            default:
                break;
        }
        if (inputFormat.large) {
            return (result +=
                (hours > 0 ? hours + ' ' + this.translate.instant('global.form.timeInterval.hoursPlural', { hours: hours }) + ' ' : '') +
                (minutes > 0 ? minutes + ' ' + this.translate.instant('global.form.timeInterval.minutesPlural', { minutes: minutes }) : ''));
        } else {
            return (result +=
                (hours > 0 ? this.translate.instant('global.form.hoursSymbol', { value: hours }) + ' ' : '') +
                (minutes > 0 ? this.translate.instant('global.form.minutesSymbol', { value: minutes }) : ''));
        }
    }
}
