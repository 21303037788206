import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class MyCompanyService {
    public myCompanyApiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.myCompanyApiUrl = Constants.apiUrl + '/global-configuration';
    }

    getTimezones(filterOptions: any) {
        const httpOptions = {
            params: {
                sort: 'timezone,asc'
            },
            reportProgress: true
        };
        if (filterOptions) {
            httpOptions.params['timezone'] = filterOptions;
        }

        return this.httpClient.get<any>(`${this.myCompanyApiUrl}/timezones`, httpOptions);
    }

    myCompanyGlobalSettings() {
        return this.httpClient.get<any>(`${this.myCompanyApiUrl}`);
    }

    setAvailableLanguages(data: any) {
        return this.httpClient.put(`${this.myCompanyApiUrl}/available-languages`, { availableLanguages: data });
    }

    setDefaultLanguage(language: string) {
        const httpOptions = {
            params: {
                defaultLanguage: language
            }
        };
        return this.httpClient.put(`${this.myCompanyApiUrl}/default-language`, null, httpOptions);
    }

    setTimezone(data: any) {
        return this.httpClient.put(`${this.myCompanyApiUrl}/timezone`, data);
    }

    setCommentsState(state: number) {
        const httpOptions = {
            params: {
                commentsDefault: state
            }
        };
        return this.httpClient.put(`${this.myCompanyApiUrl}/comments-default`, null, httpOptions);
    }

    setSharingState(state: number) {
        const httpOptions = {
            params: {
                sharingDefault: state
            }
        };
        return this.httpClient.put(`${this.myCompanyApiUrl}/sharing-default`, null, httpOptions);
    }

    setRatingState(state: number) {
        const httpOptions = {
            params: {
                ratingDefault: state
            }
        };
        return this.httpClient.put(`${this.myCompanyApiUrl}/rating-default`, null, httpOptions);
    }

    setRatingKind(kind: number) {
        const httpOptions = {
            params: {
                ratingKind: kind
            }
        };
        return this.httpClient.put(`${this.myCompanyApiUrl}/rating-kind`, null, httpOptions);
    }
}
