import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class DocumentsService {
    public apiUrl: string;
    public UPLOAD_FILE_MODE: any;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.UPLOAD_FILE_MODE = Constants.UPLOAD_FILE_MODE;
    }

    findAll(pagerOptions, filterOptions) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                sort: pagerOptions.sort || ''
            },
            reportProgress: true
        };
        if (filterOptions?.name) {
            httpOptions.params['employeeName'] = filterOptions.name;
        }
        if (filterOptions?.kind) {
            httpOptions.params['fileKind'] = filterOptions.kind;
        }
        if (filterOptions?.year) {
            httpOptions.params['year'] = filterOptions.year;
        }
        if (filterOptions?.month) {
            httpOptions.params['month'] = filterOptions.month;
        }
        return this.httpClient.get<any>(`${this.apiUrl}/documents`, httpOptions);
    }

    deleteDocuments(ids: any[]) {
        const httpOptions = {
            params: {
                ids: ids
            },
            reportProgress: true
        };

        return this.httpClient.delete<any>(`${this.apiUrl}/documents`, httpOptions);
    }

    downloadDocument(url: string): any {
        return this.httpClient.get(url, { withCredentials: true, observe: 'response', responseType: 'blob' });
    }

    getSignatureAreas(availability: any[]) {
        const httpOptions = {
            params: {
                availability: availability
            }
        };
        return this.httpClient.get<any>(`${this.apiUrl}/remote-signatures/locations/my-company`, httpOptions);
    }

    getCorporateStamps() {
        return this.httpClient.get<any>(`${this.apiUrl}/remote-signatures/certs/my-company`);
    }

    getDocumentKinds() {
        return this.httpClient.get<any>(`${this.apiUrl}/file-kinds/allowed`);
    }

    getConventions() {
        return this.httpClient.get<any>(`${this.apiUrl}/documents/conventions`);
    }

    uploadDocumentsByMode(uploadOptions: any, files: File[]): Observable<any> {
        const fd = new FormData();
        const resources = uploadOptions.mode === this.UPLOAD_FILE_MODE.MULTIPLE ? 'files' : 'file';
        const uploadUrl = uploadOptions.mode === this.UPLOAD_FILE_MODE.MULTIPLE ? '/upload' : '/upload/document';

        fd.append('options', new Blob([JSON.stringify(uploadOptions)], { type: 'application/json' }));

        files.forEach((file) => {
            fd.append(resources, file, file.name);
        });

        return this.httpClient.post(`${this.apiUrl}/documents/${uploadUrl}`, fd);
    }
}
