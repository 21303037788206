<div class="main-container__content">
    <div class="main-container--small-width">
        <h3 class="margin-top-20 margin-bottom-30" [translate]="'workplaces.generalInformation'"></h3>
        <!-- Workplace responsible -->
        <div class="group-form group-form--label-250 margin-bottom-30">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'workplaces.responsible'"></span>
            </div>
            <div class="group-form__data">
                <user-dropdown class="min-width-100-percent" [(inputModel)]="workplaceForm.director" (onSelection)="onChangeDirector($event)"></user-dropdown>
            </div>
        </div>
        <!-- Workplace description -->
        <div class="group-form group-form--label-250">
            <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" position="top">
                <span class="group-form__label__text" [translate]="'company.shortDescription'"></span>
                <span class="group-form__label__help icon icon-help-fill margin-left-8" [title]="'workplaces.descriptionTooltip' | translate"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <dialenga-textarea
                    class="width-100-percent new-rich-editor"
                    textareaId="short-description"
                    [(inputModel)]="workplaceForm.description"
                    (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder"
                    [isRichFormat]="true"
                    [maxLength]="{ amount: 240, warningAmount: 220 }">
                </dialenga-textarea>
                <span class="padding-top-5" [translate]="'global.validation.maxlengthChars'" [translateParams]="{amount: 240}"></span>
            </div>
        </div>
        <h3 class="margin-top-30 margin-bottom-30" [translate]="'contactInfo.title'"></h3>
        <!-- Workplace country -->
        <div class="group-form group-form--label-250">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'workplaces.location.country'"></span>
            </div>
            <div class="group-form__data">
                <kendo-dropdownlist
                    id="country-select"
                    class="dui-dropdown width-100-percent min-width-300"
                    [data]="countriesData"
                    [defaultItem]="countryEmptyOption"
                    [(ngModel)]="workplaceForm.country"
                    [textField]="'country'"
                    [valueField]="'id'"
                    (valueChange)="onChangeCountrySelection()"
                    [popupSettings]="{ popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                    <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                        @if (!!dataItem) {
                            <span [innerHTML]="dataItem.country"></span>
                        }
                    </ng-template>
                    <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <span class="country-option" [innerHTML]="dataItem.country"></span>
                    </ng-template>
                </kendo-dropdownlist>
            </div>
        </div>
        <!-- Workplace autonomus community -->
        @if (workplaceForm.country?.code === '34') {
            <div class="group-form group-form--label-250">
                <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'workplaces.location.autonomousCommunity'"></span>
                </div>
                <div class="group-form__data">
                    <kendo-dropdownlist
                        id="community-select"
                        class="dui-dropdown width-100-percent min-width-300"
                        [data]="communitiesData"
                        [defaultItem]="communityEmptyOption"
                        [(ngModel)]="workplaceForm.autonomousRegion"
                        [defaultItem]=""
                        [textField]="'name'"
                        [valueField]="'id'"
                        (valueChange)="onChangeCommunitySelection()"
                        [popupSettings]="{ popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                        <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>
                        <ng-template kendoDropDownListValueTemplate let-dataItem>
                            @if (!!dataItem) {
                                <span [innerHTML]="dataItem.name"></span>
                            }
                        </ng-template>
                        <ng-template kendoDropDownListItemTemplate let-dataItem>
                            <span [innerHTML]="dataItem.name"></span>
                        </ng-template>
                    </kendo-dropdownlist>
                </div>
            </div>
        }
        <!-- Workplace address -->
        <div class="group-form group-form--label-250">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'workplaces.location.address'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <dialenga-textarea
                    class="width-100-percent new-rich-editor"
                    textareaId="workplace-address"
                    [(inputModel)]="workplaceForm.address"
                    (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder"
                    [isRichFormat]="true"
                    [maxLength]="{ amount: 240, warningAmount: 220 }">
                </dialenga-textarea>
            </div>
        </div>
        <!-- Workplace map -->
        <div class="group-form group-form--label-250 padding-top-16">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.mainOffice'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <div class="flex align-items-center justify-space-between width-100-percent">
                    <div class="flex align-items-center">
                        <span class="group-form__label__text margin-right-10" [translate]="'contactInfo.labels.latitud'"></span>
                        <input
                            id="latitude"
                            class="input max-width-150"
                            type="number"
                            step="any"
                            [(ngModel)]="workplaceForm.latitude"
                            (ngModelChange)="onChangeMapCoord()"
                            maxlength="20"
                            placeholder="{{'contactInfo.placeHolders.latitude' | translate}}"
                            autocomplete="off" />
                    </div>
                    <div class="flex align-items-center">
                        <span class="group-form__label__text margin-right-10" [translate]="'contactInfo.labels.longitud'"></span>
                        <input
                            id="longitude"
                            class="input max-width-150"
                            type="number"
                            step="any"
                            [(ngModel)]="workplaceForm.longitude"
                            (ngModelChange)="onChangeMapCoord()"
                            maxlength="20"
                            placeholder="{{'contactInfo.placeHolders.longitude' | translate}}"
                            autocomplete="off" />
                    </div>
                </div>
                <div #staticMap id="staticMap" class="margin-top-10 width-100-percent" style="height: 300px; border: 1px solid lightgrey"></div>
            </div>
        </div>
        <!-- Workplace business hours -->
        <div class="group-form group-form--label-250">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.businessHours'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <dialenga-textarea
                    class="width-100-percent new-rich-editor"
                    textareaId="workplace-business-hours"
                    [(inputModel)]="workplaceForm.businessHours"
                    (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder"
                    [isRichFormat]="true"
                    [maxLength]="{ amount: 240, warningAmount: 220 }">
                </dialenga-textarea>
            </div>
        </div>
        <!-- Workplace website url -->
        <div class="group-form group-form--label-250 padding-top-16">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.website'"></span>
            </div>
            <div class="group-form__data group-form__data--column relative">
                <input
                    id="workplace-website"
                    class="input width-100-percent padding-right-40"
                    [(ngModel)]="workplaceForm.website"
                    (ngModelChange)="onChangeWebsiteUrl()"
                    maxlength="256"
                    placeholder="{{'contactInfo.placeHolders.website' | translate}}"
                    autocomplete="off"
                    [ngClass]="{'input--error': !isValidURLWebsite && !!workplaceForm.website}" />

                <div class="input-icon-right" kendoTooltip filter="span.icon-external-link">
                    <button
                        id="test-url-button"
                        class="button button--only-icon button--link"
                        [disabled]="!workplaceForm.website || !isValidURLWebsite"
                        (click)="testUrlLink(workplaceForm.website)">
                        <span class="button__icon button__icon--lg icon icon-external-link" [title]="'message.urlTest' | translate"></span>
                    </button>
                </div>
                @if (!isValidURLWebsite && !!workplaceForm.website) {
                    <div class="margin-top-10">
                        <label class="danger" [translate]="'global.form.urlWrong'"></label>
                    </div>
                }
            </div>
        </div>
        <!-- Workplace email contact -->
        <div class="group-form group-form--label-250">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'users.email'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <input
                    id="workplace-email"
                    data-cy="e-mail"
                    class="input"
                    [(ngModel)]="workplaceForm.email"
                    email
                    autocomplete="off"
                    maxlength="255"
                    [ngClass]="{'input--error': !isValidEmail && !!workplaceForm.email}"
                    (ngModelChange)="onChangeEmail()"
                    [placeholder]="'users.email' | translate" />

                @if (!isValidEmail && !!workplaceForm.email) {
                    <div class="margin-top-10">
                        <label class="danger" [translate]="'users.validation.emailformat'"></label>
                    </div>
                }
            </div>
        </div>
        <!-- Workplace phone contact -->
        <div class="group-form group-form--label-250">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'users.phone'"></span>
            </div>
            <div class="group-form__data group-form__data--inline flex">
                <input
                    id="phoneNumber"
                    data-cy="phone-number"
                    class="input width-50-percent"
                    [(ngModel)]="workplaceForm.phoneNumber"
                    (ngModelChange)="onChange()"
                    maxlength="20"
                    placeholder="(+34) 915 555 555"
                    autocomplete="off" />
            </div>
        </div>
        <h3 class="margin-top-40 margin-bottom-30" [translate]="'contactInfo.meansOfTransport'"></h3>
        <!-- Underground stations -->
        <div class="group-form group-form--label-250">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.undergrounds'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <dialenga-textarea
                    class="width-100-percent new-rich-editor"
                    textareaId="workplace-underground"
                    [(inputModel)]="workplaceForm.undergrounds"
                    (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder"
                    [isRichFormat]="true"
                    [maxLength]="{ amount: 240, warningAmount: 220 }">
                </dialenga-textarea>
            </div>
        </div>
        <!-- Bus stations -->
        <div class="group-form group-form--label-250 margin-top-30">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.busStations'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <dialenga-textarea
                    class="width-100-percent new-rich-editor"
                    textareaId="workplace-buses"
                    [(inputModel)]="workplaceForm.buses"
                    (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder"
                    [isRichFormat]="true"
                    [maxLength]="{ amount: 240, warningAmount: 220 }">
                </dialenga-textarea>
            </div>
        </div>
        <!-- Train stations -->
        <div class="group-form group-form--label-250 margin-top-30">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.trainStations'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <dialenga-textarea
                    class="width-100-percent new-rich-editor"
                    textareaId="workplace-trains"
                    [(inputModel)]="workplaceForm.trains"
                    (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder"
                    [isRichFormat]="true"
                    [maxLength]="{ amount: 240, warningAmount: 220 }">
                </dialenga-textarea>
            </div>
        </div>
        <!-- Parkings -->
        <div class="group-form group-form--label-250 margin-top-30">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.parkings'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <dialenga-textarea
                    class="width-100-percent new-rich-editor"
                    textareaId="workplace-parkings"
                    [(inputModel)]="workplaceForm.parkings"
                    (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder"
                    [isRichFormat]="true"
                    [maxLength]="{ amount: 240, warningAmount: 220 }">
                </dialenga-textarea>
            </div>
        </div>
        <!-- Electric Spots -->
        <div class="group-form group-form--label-250 margin-top-30">
            <div class="group-form__label">
                <span class="group-form__label__text" [translate]="'contactInfo.labels.electricSpots'"></span>
            </div>
            <div class="group-form__data group-form__data--column">
                <dialenga-textarea
                    class="width-100-percent new-rich-editor"
                    textareaId="workplace-electricSpots"
                    [(inputModel)]="workplaceForm.electricSpots"
                    (inputChanged)="onChange()"
                    placeholder="message.captionPlaceholder"
                    [isRichFormat]="true"
                    [maxLength]="{ amount: 240, warningAmount: 220 }">
                </dialenga-textarea>
            </div>
        </div>
        <div class="group-form group-form--label-250">
            <div class="group-form__label"></div>
            <div class="group-form__data">
                <feedback-action-button
                    [buttonId]="'save-workplace-button'"
                    [classes]="'button--secondary'"
                    [text]="'global.form.save'"
                    (action)="workplaceFormSave()"
                    [isValid]="isFormDataChanged && isFormDataValid">
                </feedback-action-button>
                <loading-button [buttonId]="'go-back'" [classes]="'button margin-left-10'" [text]="'global.form.cancel'" (action)="goBack()" [isValid]="true">
                </loading-button>
            </div>
        </div>
    </div>
</div>
