<div class="main-container main-container--with-preview">
    <!-- HEAD OK -->
    <div class="main-container__head">
        <div class="flex align-items-end justify-space-between margin-top-20">
            <div class="flex direction-column" spellcheck="false">
                <ul class="breadcrumb">
                    <li>
                        <a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
                    </li>
                    <li>
                        <a href="/settings/categories" [translate]="'global.navbar.categories'"></a>
                    </li>
                </ul>
                <h2 [translate]="categoryId ? 'model.category.editCategory' : 'model.category.newCategory'"></h2>
            </div>
            <div class="flex align-items-end">
                <button class="button button--small preview-launcher" (click)="openCategoryPreview()">
                    <span class="button__icon icon icon-eye-opened"></span>
                    <span class="button__label" [translate]="'message.preview.preview'"></span>
                </button>
            </div>
        </div>
    </div>

    <div class="main-container__content">
        <div class="main-container--small-width">
            <div class="flex justify-end margin-bottom-20">
                <language-selector
                    class="margin-top-6"
                    [(selectedLanguage)]="selectedLanguage"
                    [(formContent)]="categoryForm.category.contents"
                    [validationFunction]="validateModel()"></language-selector>
            </div>

            <div class="notification-box">
                <span class="notification-box__text" [translate]="'categories.advice'"></span>
            </div>

            <!-- Seleccionar disponibilidad OK -->
            <div class="group-form group-form--label-200">
                <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'model.category.availableIn'"></span>
                </div>
                <div class="group-form__data group-form__data--inline">
                    <div
                        class="button-square"
                        [ngClass]="{'button-square--selected': categoryForm.category.availability == CATEGORY_AVAILABILITY.ALL}"
                        (click)="selectCategoryAvailability(CATEGORY_AVAILABILITY.ALL)">
                        <span class="button-square__icon icon icon-globe"></span>
                        <span class="button-square__label" [translate]="'categories.availableInAll'"></span>
                    </div>
                    <div class="button-square-group margin-left-20">
                        <div
                            class="button-square"
                            [ngClass]="{'button-square--selected': categoryForm.category.availability == CATEGORY_AVAILABILITY.TIMELINE}"
                            (click)="selectCategoryAvailability(CATEGORY_AVAILABILITY.TIMELINE)">
                            <span class="button-square__icon icon icon-default-message"></span>
                            <span class="button-square__label" [translate]="'categories.availableInInbox'"></span>
                        </div>
                        <div
                            class="button-square"
                            [ngClass]="{'button-square--selected': categoryForm.category.availability == CATEGORY_AVAILABILITY.KNOWLEDGE_CENTER}"
                            (click)="selectCategoryAvailability(CATEGORY_AVAILABILITY.KNOWLEDGE_CENTER)">
                            <span class="button-square__icon icon icon-menu-knowledge"></span>
                            <span class="button-square__label" [translate]="'knowledgecenter.title'"></span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Añadir nombre OK -->
            <div class="group-form group-form--label-200 margin-bottom-0 min-height-70">
                <div class="group-form__label">
                    <span class="group-form__label__text margin-right-10" [translate]="'model.category.name'"></span>
                    @if (selectedLanguage) {
                        <span class="group-form__label__text group-form__label__text--sm" [translate]="'global.languageBrackets.' + selectedLanguage"></span>
                    }
                </div>
                <div class="group-form__data group-form__data--column align-items-end">
                    <input
                        type="text"
                        id="categoryName"
                        name="categoryName"
                        class="input"
                        [(ngModel)]="categoryForm.category.contents[selectedLanguage].name"
                        (ngModelChange)="onChange()"
                        maxlength="36"
                        [placeholder]="'model.category.nameplaceholder' | translate" />

                    @if (categoryForm.category.contents[selectedLanguage].name && categoryForm.category?.contents[selectedLanguage].name.length > 21) {
                        <p class="dui__message__text dui__message__text--align-right warning">
                            <span
                                [translate]="'global.form.remainingCharsNg'"
                                [translateParams]="{current: categoryForm.category?.contents[selectedLanguage].name.length, total: 36}"
                                style="font-size: 12px"></span>
                        </p>
                    }
                </div>
            </div>

            <!-- Añadir subtitulo OK -->
            <div class="group-form group-form--label-200 margin-bottom-0 min-height-70">
                <div class="group-form__label">
                    <span class="group-form__label__text margin-right-10" [translate]="'model.category.subtitle'"></span>
                    @if (selectedLanguage) {
                        <span class="group-form__label__text group-form__label__text--sm" [translate]="'global.languageBrackets.' + selectedLanguage"></span>
                    }
                </div>
                <div class="group-form__data group-form__data--column align-items-end">
                    <input
                        type="text"
                        id="categorySubtitle"
                        name="categorySubtitle"
                        class="input"
                        [(ngModel)]="categoryForm.category.contents[selectedLanguage].subtitle"
                        (ngModelChange)="onChange()"
                        maxlength="32"
                        [placeholder]="'model.category.subtitleplaceholder' | translate" />

                    @if (categoryForm.category.contents[selectedLanguage].subtitle && categoryForm.category.contents[selectedLanguage].subtitle.length > 21) {
                        <p class="dui__message__text dui__message__text--align-right warning">
                            <span
                                [translate]="'global.form.remainingCharsNg'"
                                [translateParams]="{current: categoryForm.category?.contents[selectedLanguage].subtitle.length, total: 32}"
                                style="font-size: 12px"></span>
                        </p>
                    }
                </div>
            </div>

            <!-- Seleccionar color OK, falta clickout -->
            <div class="group-form group-form--label-200">
                <div class="group-form__label">
                    <span class="group-form__label__text" [translate]="'model.category.color'"></span>
                </div>
                <div class="group-form__data">
                    <div class="select-color">
                        <!-- falta clickout -->
                        <div class="select-color__button" [ngClass]="{'select-color--open' : colorSelectorActive}" (click)="toggleColorSelector()">
                            @if (categoryForm.category.color) {
                                <span class="select-color__selected" [ngStyle]="{'background-color': categoryForm.category.color | numberColor}"></span>
                            }
                        </div>
                        @if (colorSelectorActive) {
                            <div class="select-color__options">
                                @for (color of availableColors; track $index) {
                                    <span
                                        class="select-color__option"
                                        [ngStyle]="{'background-color': color | numberColor}"
                                        [ngClass]="{'select-color__option--selected': categoryForm.category.color == color}"
                                        (click)="selectColor(color)"></span>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div class="group-form group-form--label-200">
                <div class="group-form__label" kendoTooltip filter=".icon-help-fill" [tooltipTemplate]="imageCropperTootltipTemplate">
                    <ng-template #imageCropperTootltipTemplate let-anchor>
                        <span [innerHTML]="'message.imagetooltipNg' | translate:IMAGE_CROPPER_CATEGORY"></span>
                    </ng-template>
                    <span class="group-form__label__text margin-right-10" [translate]="'model.message.image'"></span>
                    <span class="group-form__label__text group-form__label__help icon icon-help-fill"></span>
                    <span class="group-form__label__text group-form__label__text--optional" [translate]="'global.form.optional'"></span>
                </div>
                <div class="group-form__data">
                    <div class="flex align-items-end  width-100-percent">
                        <image-uploader
                            class="category-image width-100-percent"
                            [(image)]="categoryForm.images"
                            [(imageUrl)]="categoryForm.category.imageURL"
                            [(croppedImageSrc)]="categoryForm.croppedImageSrc"
                            [cropOptions]="IMAGE_CROPPER_CATEGORY"
                            [roundedCanvas]="false"
                            [canvasHeight]="'197px'"
                            [acceptedFormats]="'image/jpeg, image/jpg, image/png, image/webp'"
                            [hideSearchBtn]="false"
                            [showTransformImage]="true"
                            [gifsAllowed]="false"
                            [picsAllowed]="true"
                            [uploadAllowed]="false"
                            [insertInEditor]="false"
                            [isCategoryImg]="true"
                            (onChanges)="onChange()">
                        </image-uploader>
                    </div>
                </div>
            </div>

            <div class="group-form group-form--label-200">
                <div class="group-form__label">
                    <span class="group-form__label__text"></span>
                </div>
                <div class="group-form__data">
                    <feedback-action-button
                        [classes]="'button--secondary'"
                        [text]="'global.form.save'"
                        [tooltipText]="disabledTooltipText"
                        (action)="categoryFormSave()"
                        [isValid]="isValid"></feedback-action-button>
                </div>
            </div>
        </div>
    </div>

    <category-preview-device
        [(isOpened)]="isCategoryPreviewOpened"
        [category]="categoryForm.category"
        [availableIn]="categoryForm.category.availability"
        [language]="selectedLanguage"
        [image]="categoryForm.croppedImageSrc"
        [color]="categoryForm.category.color | numberColor"></category-preview-device>
</div>
