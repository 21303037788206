import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class ChatroomsService {
    public apiUrl: string;
    private CHATROOM_USERS_SYNCHRO_KIND: any;
    private SEGMENTATION_KIND: any;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.CHATROOM_USERS_SYNCHRO_KIND = Constants.CHATROOM_USERS_SYNCHRO_KIND;
        this.SEGMENTATION_KIND = Constants.SEGMENTATION_KIND;
    }

    findAll(pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                archived: filterOptions.archived
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) {
            httpOptions.params['sort'] = pagerOptions.sort;
        }
        if (filterOptions?.name) {
            httpOptions.params['name'] = filterOptions.name;
        }

        return this.httpClient.get<any>(`${this.apiUrl}/chat-rooms`, httpOptions);
    }

    findOne(id: number) {
        return this.httpClient.get<any>(`${this.apiUrl}/chat-rooms/${id}`);
    }

    createChatroom(data: any) {
        return this.httpClient.post(`${this.apiUrl}/chat-rooms`, data);
    }

    updateChatroom(id: number, chatRoom: any, images: Array<any>) {
        let chatData: any = { ...chatRoom };
        chatData.admins =
            chatData.admins.length > 0
                ? chatData.admins.map((user: any) => {
                      return user.hasOwnProperty('id') ? user['id'] : user['user'];
                  })
                : [];
        if (chatData.synchronizationKind === this.CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS) {
            chatData.segmentationKind = chatData.segmentationKind !== null ? chatData.segmentationKind : this.SEGMENTATION_KIND.UNION;
        } else {
            chatData.segments = [];
        }
        const formData = new FormData();
        formData.append('chatRoom', new Blob([JSON.stringify(chatData)], { type: 'application/json' }));
        let index: number;
        if (images && images.length > 0) {
            for (index = 0; index < images.length; index++) {
                let filename = images[index].name;
                if (!!images[index].extension && !filename.endsWith('.' + images[index].extension)) {
                    filename += '.' + images[index].extension;
                }
                formData.append('image', images[index].image, filename);
            }
        }
        return this.httpClient.post<any>(`${this.apiUrl}/chat-rooms/${id}`, formData);
    }

    archiveChat(id: number) {
        return this.httpClient.put(`${this.apiUrl}/chat-rooms/${id}/archive`, null);
    }

    activateChat(id: number) {
        return this.httpClient.put(`${this.apiUrl}/chat-rooms/${id}/activate`, null);
    }

    deactivateChat(id: number) {
        return this.httpClient.put(`${this.apiUrl}/chat-rooms/${id}/deactivate`, null);
    }

    deleteChat(id: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}/chat-rooms/${id}`);
    }

    findAllParticipants(id: number, pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) {
            httpOptions.params['sort'] = pagerOptions.sort;
        }
        if (filterOptions?.name) {
            httpOptions.params['name'] = filterOptions.name;
        }

        return this.httpClient.get<any>(`${this.apiUrl}/chat-rooms/${id}/participants/members`, httpOptions);
    }

    updateChatParticipants(id: number, users: Array<any>) {
        let usersIds: Array<any> = [...users];
        usersIds =
            usersIds.length > 0
                ? usersIds.map((user: any) => {
                      return user.hasOwnProperty('id') ? user['id'] : user['user'];
                  })
                : [];
        return this.httpClient.put<any>(`${this.apiUrl}/chat-rooms/${id}/participants/members`, usersIds);
    }

    deleteChatParticipants(chatId: number, users: Array<any>) {
        const httpOptions = {
            params: {
                participants: users
            },
            reportProgress: true
        };
        return this.httpClient.delete<any>(`${this.apiUrl}/chat-rooms/${chatId}/participants/members`, httpOptions);
    }
}
