<div class="dui-cropper-container" [ngClass]="{'cropper-ready': isCropperReady}">
    <image-cropper
        #imageCropper
        [imageChangedEvent]="sourceImage"
        [maintainAspectRatio]="mustMaintainAspectRatio()"
        [aspectRatio]="cropperOptions.ASPECT_RATIO == 'none' ? 1 / 1 : cropperOptions.ASPECT_RATIO"
        [cropperMinWidth]="cropperOptions.MINIMUM_WIDTH || 0"
        [cropperMinHeight]="cropperOptions.MINIMUM_HEIGHT || 0"
        [roundCropper]="roundedCropper || false"
        [style.display]="showCropper ? null : 'none'"
        [imageBase64]="sourceImage"
        format="png"
        [transform]="transform"
        [backgroundColor]="'#fff'"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        [resizeToWidth]="resizeToWidth || 0"
        [resizeToHeight]="resizeToHeight || 0"
        (cropperReady)="isCropperReady = true">
    </image-cropper>
</div>
