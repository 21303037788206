import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'normalizeUnicode'
})
export class NormalizeUnicodePipe implements PipeTransform {
    transform(text: string): string {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}
