// roles-filter.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { RoleFilterPipe } from './role-filter.pipe'; // Importar el pipe

@Pipe({
    name: 'rolesFilter'
})
export class RolesFilterPipe implements PipeTransform {
    constructor(private roleFilterPipe: RoleFilterPipe) {} // Inyectar el pipe

    private compareRoles(roleA: string, roleB: string): number {
        return ('' + roleA).localeCompare(roleB, undefined, { sensitivity: 'base' });
    }

    transform(roles: any[], separator: string = ', '): string {
        const userRoles = roles
            .filter((role) => role.name !== 'ROLE_USER')
            .map((role) => this.roleFilterPipe.transform(role.name)) // Usar el pipe inyectado
            .sort(this.compareRoles);

        return userRoles.join(separator);
    }
}
