import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DuiPopoverComponent } from '../../shared/components/dui-popover/dui-popover.component';
import { DocumentsService } from '../documents/documents.service';

@Component({
    selector: 'signature-area-popover',
    templateUrl: './signature-area-popover.component.html',
    styleUrls: ['./_signature-area-popover.component.scss']
})
export class SignatureAreaPopoverComponent implements OnInit {
    @Input() area?: any;
    @Output() onAreaSelected: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

    @ViewChild(DuiPopoverComponent) duiPopover: DuiPopoverComponent;

    public signatureAreasList: Array<any>;

    constructor(private documentsService: DocumentsService) {}

    ngOnInit() {
        this.documentsService.getSignatureAreas(['ALL', 'SIGNATURE']).subscribe((response) => {
            this.signatureAreasList = response;
        });
    }

    selectSignatureArea(area: any) {
        this.area = area;
        this.onAreaSelected.emit(area);
        this.duiPopover.hidePopover();
    }
}
