import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService {
    public apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    private getEntityAsFormData(entity: string, entityAlias: string, images: Array<any>): any {
        let fd = new FormData();
        let content = typeof entity !== 'string' ? JSON.stringify(entity) : entity;

        fd.append(entityAlias, new Blob([content], { type: 'application/json' }));
        images.forEach((image: any) => {
            fd.append('image', image.image, image.name);
        });
        return fd;
    }

    findAll(pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10,
                active: filterOptions.active
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) {
            httpOptions.params['sort'] = pagerOptions.sort;
        }
        if (filterOptions?.name) {
            httpOptions.params['name'] = filterOptions.name;
        }

        return this.httpClient.get<any>(`${this.apiUrl}/subsidiary-companies`, httpOptions);
    }

    findOne(id: number) {
        return this.httpClient.get<any>(`${this.apiUrl}/subsidiary-companies/${id}`);
    }

    create(data: any) {
        return this.httpClient.post(`${this.apiUrl}/subsidiary-companies`, data);
    }

    update(id: number, companyData: any, companyImage?: Array<any>) {
        var fd = this.getEntityAsFormData(companyData, 'company', companyImage);
        return this.httpClient.post<any>(`${this.apiUrl}/subsidiary-companies/${id}`, fd);
    }

    archive(id: number) {
        return this.httpClient.put(`${this.apiUrl}/subsidiary-companies/${id}/archive`, null);
    }

    activate(id: number) {
        return this.httpClient.put(`${this.apiUrl}/subsidiary-companies/${id}/activate`, null);
    }

    delete(id: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}/subsidiary-companies/${id}`);
    }

    findAllEmployees(id: number, pagerOptions: any, filterOptions: any) {
        const httpOptions = {
            params: {
                page: pagerOptions.page || 0,
                size: pagerOptions.size || 10
            },
            reportProgress: true
        };
        if (pagerOptions?.sort) {
            httpOptions.params['sort'] = pagerOptions.sort;
        }
        if (filterOptions?.name) {
            httpOptions.params['name'] = filterOptions.name;
        }

        return this.httpClient.get<any>(`${this.apiUrl}/subsidiary-companies/${id}/employees`, httpOptions);
    }

    updateEmployees(id: number, employees: Array<any>) {
        let employeesIds: Array<any> = [...employees];
        employeesIds =
            employeesIds.length > 0
                ? employeesIds.map((employee: any) => {
                      return employee.hasOwnProperty('id') ? employee['id'] : employee['user'];
                  })
                : [];
        return this.httpClient.put<any>(`${this.apiUrl}/subsidiary-companies/${id}/employees`, employeesIds);
    }

    deleteEmployee(companyId: number, employeeId: number) {
        return this.httpClient.delete<any>(`${this.apiUrl}/subsidiary-companies/${companyId}/employees/${employeeId}`);
    }
}
