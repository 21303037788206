<section class="main-container">
    <header class="main-container__header">
        <h2 [translate]="'users.title'"></h2>
        <div class="flex align-items-center gap-8">
            <loading-button
                [classes]="'button--secondary button--small'"
                [iconClass]="'icon-add'"
                [text]="'users.new'"
                [isValid]="true"
                (action)="navigateTo('/user/create')">
            </loading-button>
            @if (a3Configuration?.enabled) {
                <button
                    id="sync-external-users"
                    class="button button--small"
                    kendoTooltip
                    [title]="'users.syncTooltip' | translate"
                    (click)="syncExternalUsers()">
                    <span class="button__icon icon icon-sync"></span>
                    <span [translate]="'users.sync'"></span>
                </button>
            }
            <loading-button
                [classes]="'button button--small button--only-icon'"
                [iconClass]="'icon-upload'"
                [isValid]="true"
                kendoTooltip
                [title]="'users.importcsv' | translate"
                (action)="navigateTo('/users/import-users')">
            </loading-button>
            <button (click)="getExportCSVUrl()" class="button button--small button--only-icon" kendoTooltip [title]="'users.exportcsv' | translate">
                <span class="button__icon icon icon-export"></span>
            </button>
        </div>
    </header>
    <section class="main-container__content margin-top-32">
        <div class="main-container--full-width">
            <section class="users-filters margin-bottom-16">
                <!-- Filters by Entity -->
                <div class="users-filters__left-column">
                    <!-- Company -->
                    @if (filterCompaniesOptions?.length > 1) {
                        <section class="users-filters__filter-item">
                            @if (usersFilterOptions.companies.length > 0) {
                                <p [translate]="'users.company'"></p>
                            }
                            <dui-select-multiple-soft
                                [availableOptions]="filterCompaniesOptions"
                                [(inputModel)]="usersFilterOptions.companies"
                                [placeholder]="'users.company' | translate"
                                [showClearBtn]="false"
                                textFieldName="name"
                                valueFieldName="id"
                                popupClass="max-width-300"
                                summaryTagFrom="2"
                                (inputChanged)="onEntityFilterChanged()">
                            </dui-select-multiple-soft>
                        </section>
                    }
                    <!-- Workplace -->
                    <section class="users-filters__filter-item">
                        @if (usersFilterOptions.workplaces.length > 0) {
                            <p [translate]="'workplaces.grid.searchPlaceholder'"></p>
                        }
                        <dui-select-multiple-soft
                            [availableOptions]="filterWorkplacesOptions"
                            [(inputModel)]="usersFilterOptions.workplaces"
                            [placeholder]="'workplaces.grid.searchPlaceholder' | translate"
                            textFieldName="name"
                            valueFieldName="id"
                            popupClass="max-width-300"
                            summaryTagFrom="2"
                            (inputChanged)="onEntityFilterChanged()">
                        </dui-select-multiple-soft>
                    </section>
                    <!-- Department -->
                    <section class="users-filters__filter-item">
                        @if (usersFilterOptions.departments.length > 0) {
                            <p [translate]="'users.department'"></p>
                        }
                        <dui-select-multiple-soft
                            [availableOptions]="filterDepartmentsOptions"
                            [(inputModel)]="usersFilterOptions.departments"
                            [placeholder]="'users.department' | translate"
                            textFieldName="name"
                            valueFieldName="id"
                            popupClass="max-width-300"
                            summaryTagFrom="2"
                            (inputChanged)="onEntityFilterChanged()">
                        </dui-select-multiple-soft>
                    </section>
                    <!-- Team -->
                    <section class="users-filters__filter-item">
                        @if (usersFilterOptions.teams.length > 0) {
                            <p [translate]="'users.team'"></p>
                        }
                        <dui-select-multiple-soft
                            [availableOptions]="filterTeamsOptions"
                            [(inputModel)]="usersFilterOptions.teams"
                            [placeholder]="'users.team' | translate"
                            textFieldName="name"
                            valueFieldName="id"
                            popupClass="max-width-300"
                            summaryTagFrom="2"
                            (inputChanged)="onEntityFilterChanged()">
                        </dui-select-multiple-soft>
                    </section>
                    <!-- Rol -->
                    <section class="users-filters__filter-item">
                        @if (usersFilterOptions.roles.length > 0) {
                            <p [translate]="'users.shortRole'"></p>
                        }
                        <dui-select-multiple-soft
                            [availableOptions]="filterRolesOptions"
                            [(inputModel)]="usersFilterOptions.roles"
                            [placeholder]="'users.shortRole' | translate"
                            textFieldName="name"
                            valueFieldName="id"
                            popupClass="max-width-300"
                            summaryTagFrom="2"
                            (inputChanged)="onEntityFilterChanged()">
                        </dui-select-multiple-soft>
                    </section>
                </div>
                <!-- Search filter -->
                <div class="users-filters__right-column">
                    <!-- Search by keyword filter -->
                    <div class="search-grow-container align-items-center" [ngClass]="{'opened': inputSearchOpened}">
                        <input
                            #searchUsersInput
                            id="search-users"
                            type="text"
                            maxlength="1024"
                            [placeholder]="'users.search' | translate"
                            [(ngModel)]="usersFilterOptions.name"
                            (input)="searchUsers(false)"
                            ngModelOptions="{ debounce: 200 }"
                            [disabled]="!usersDataGrid.total && !usersFilterOptions.name && !isSyncing"
                            (blur)="!usersFilterOptions.name && inputSearchOpened = !inputSearchOpened"
                            autocomplete="off" />
                        @if (inputSearchOpened && !!usersFilterOptions.name) {
                            <span class="icon icon-20 icon-remove black60" (click)="searchUsers(true)"></span>
                        }
                        @if (!inputSearchOpened || (inputSearchOpened && !usersFilterOptions.name)) {
                            <span
                                id="search-users-icon"
                                class="icon icon-20 icon-search black60"
                                (click)="openInputSearch()"
                                [ngClass]="{'disabled-events': inputSearchOpened || !usersDataGrid.total, 'disabled': !usersDataGrid.total}">
                            </span>
                        }
                    </div>
                    <!-- Filters by user states -->
                    <dui-popover [onlyOnClick]="true" class="popover-user-states align-self-center">
                        <ng-container popoverItem>
                            <span id="icon-state-filter" class="block margin-auto icon icon-20 cursor-pointer" [ngClass]="selectedState.icon"></span>
                        </ng-container>
                        <div class="popover-user-states__filters">
                            @for (userState of filterStatesOptions; track $index) {
                                <section
                                    class="popover-user-states__item"
                                    (click)="onStateFilterChanged(userState)"
                                    [ngClass]="{'disabled-events': userState.id === selectedState.id }">
                                    <span class="icon icon-24" [ngClass]="$index > 0 ? userState.icon: ''"></span>
                                    <span [textContent]="userState.name"></span>
                                    <span class="icon icon-16 black-45" [ngClass]="{'icon-accept': userState.id === selectedState.id}"></span>
                                </section>
                                @if ($index === 1 || $index === 6) {
                                    <div class="separator"></div>
                                }
                            }
                        </div>
                    </dui-popover>
                </div>
            </section>

            @if (usersDataGrid?.data.length > 0) {
                <kendo-grid
                    #usersGrid
                    id="users-grid"
                    class="master-grid user-select-none margin-bottom-40 org-grid"
                    [data]="usersDataGrid"
                    [sortable]="true"
                    [sort]="gridSort"
                    (sortChange)="onSortChange($event)"
                    (cellClick)="onCellClick($event)"
                    (dblclick)="onDblClick()"
                    [pageable]="gridPagerSettings"
                    [pageSize]="dataPagingOpts.size"
                    (pageChange)="onPageChange($event)"
                    [skip]="dataPagingOpts.skip"
                    [navigable]="true"
                    [resizable]="false"
                    [selectable]="false"
                    [loading]="isSyncing">
                    <kendo-grid-column field="state" title="" [width]="58" headerClass="cursor-pointer">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span
                                class="icon"
                                [ngClass]="userStateIconFilter(dataItem.state, dataItem.lastAccessDate)"
                                kendoTooltip
                                [title]="userStateTooltipFilter(dataItem.state, dataItem.lastAccessDate)">
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="avatar" title="" [width]="72" [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <avatar
                                [name]="dataItem.name"
                                [surname]="dataItem.surname"
                                [thumbnails]="{ thumbnail_S: dataItem.avatarThumbnailSUrl, thumbnail_M: dataItem.avatarThumbnailMUrl }"
                                [state]="dataItem.state"
                                [access]="dataItem.lastAccessDate">
                            </avatar>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="name" [title]="'users.name' | translate" [width]="192" headerClass="cursor-pointer">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                @if (!!dataItem.confidentialProfile) {
                                    <span
                                        class="icon icon-confidential black-60 margin-right-4 icon-22"
                                        kendoTooltip
                                        [title]="'users.confidential.title' | translate">
                                    </span>
                                }
                                <span class="truncate" [textContent]="dataItem.name"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="surname" [title]="'users.surname' | translate" headerClass="cursor-pointer">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="truncate" [textContent]="dataItem.surname"></span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="rolesNames" [title]="'model.users.backofficeProfile' | translate" [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span
                                class="truncate"
                                [textContent]="dataItem.roles | rolesFilter"
                                filter="span"
                                offset="8"
                                kendoTooltip
                                [tooltipTemplate]="rolesTooltipTemplate">
                            </span>

                            <ng-template #rolesTooltipTemplate>
                                @for (role of dataItem.roles; track $index) {
                                    @if (role.name !== 'ROLE_USER') {
                                        <span class="block" [textContent]="role.name | roleFilter"></span>
                                    }
                                }
                            </ng-template>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-command-column [width]="192" [style]="{ 'text-align': 'end', 'padding-right': '24px' }">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                            <div class="flex justify-end td-on-hover">
                                @if (dataItem.state !== USER_STATES.PENDING_ACTIVATION) {
                                    <button
                                        type="button"
                                        class="grid-action-button"
                                        kendoTooltip
                                        filter="button"
                                        [tooltipTemplate]="infoTooltipTemplate"
                                        (click)="navigateToEditView(dataItem.id)">
                                        <span class="icon icon-info icon-24"></span>
                                    </button>

                                    <ng-template #infoTooltipTemplate>
                                        <p class="tooltip-row">
                                            <span [translate]="'model.users.lastAccessDate'"></span>
                                            <span
                                                [textContent]="dataItem.lastAccessDate ? (dataItem.lastAccessDate | date:'dd/MM/yyyy') : '- -'"
                                                [ngClass]="{'empty': !dataItem.lastAccessDate}">
                                            </span>
                                        </p>
                                        <p class="tooltip-row">
                                            <span [translate]="'model.users.joinDate'"></span>
                                            <span [textContent]="dataItem.joinDate | date:'dd/MM/yyyy'"></span>
                                        </p>
                                    </ng-template>
                                }

                                @if (
                                    dataItem.state === USER_STATES.PENDING_ACTIVATION || (dataItem.state === USER_STATES.REGISTERED && !dataItem.lastAccessDate)
                                ) {
                                    <button
                                        type="button"
                                        class="grid-action-button"
                                        [ngClass]="{ 'disabled': !dataItem.email && !dataItem.phoneNumber }"
                                        kendoTooltip
                                        [title]="!dataItem.email && !dataItem.phoneNumber ? ('global.form.sendActivationLocked' | translate) : ('global.form.sendActivation' | translate)"
                                        (click)="sendActivationEMail(dataItem)">
                                        <span
                                            class="icon icon-22"
                                            [ngClass]="!dataItem.email && !dataItem.phoneNumber ? 'icon-send-locked' : 'icon-send'"></span>
                                    </button>
                                }

                                <button
                                    type="button"
                                    class="grid-action-button"
                                    kendoTooltip
                                    [title]="'global.form.edit' | translate"
                                    (click)="navigateToEditView(dataItem.id)">
                                    <span class="icon icon-22 icon-edit"></span>
                                </button>

                                <button
                                    type="button"
                                    class="grid-action-button"
                                    kendoTooltip
                                    [title]="dataItem.state === USER_STATES.DISABLED ? ('users.reactivate' | translate) : ('global.form.archive' | translate)"
                                    (click)="toggleUserActivationState(dataItem)">
                                    <span class="icon icon-22" [ngClass]="dataItem.state === USER_STATES.DISABLED ? 'icon-unarchive' : 'icon-archive'"></span>
                                </button>
                            </div>
                        </ng-template>
                    </kendo-grid-command-column>

                    <kendo-grid-messages
                        [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                        [pagerOf]="'global.lists.pagerOf' | translate"
                        [pagerItems]="'global.lists.pagerItems' | translate">
                    </kendo-grid-messages>

                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <article class="dui-grid-pager">
                            <kendo-pager-info></kendo-pager-info>
                            @if (usersDataGrid.total > 10) {
                                @if (totalPages > 1) {
                                    <article class="dui-grid-pager__numbers-wrap">
                                        <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                        <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                        <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                    </article>
                                }
                                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                            }
                        </article>
                    </ng-template>
                </kendo-grid>
            }

            @if (usersDataGrid?.data.length == 0 && isSyncing) {
                <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid org-grid" [rowClass]="skeletonRowCallback">
                    <kendo-grid-column field="state" title="" [width]="58">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <kendo-skeleton shape="rectangle" animation="pulse" [width]="20" [height]="20" style="border-radius: 4px;"></kendo-skeleton>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="avatar" title="" [width]="72">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <kendo-skeleton shape="circle" animation="pulse" [width]="40" [height]="40"></kendo-skeleton>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="name" [title]="'users.name' | translate" [width]="192">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <kendo-skeleton shape="text" animation="pulse" [width]="160" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="surname" [title]="'users.surname' | translate">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <kendo-skeleton shape="text" animation="pulse" [width]="272" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="rolesNames" [title]="'model.users.backofficeProfile' | translate">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <kendo-skeleton shape="text" animation="pulse" [width]="500" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-command-column [width]="192"></kendo-grid-command-column>
                </kendo-grid>
            }

            @if (usersDataGrid.total == 0 && !usersFilterOptions.name && !isSyncing) {
                <no-results
                    [imageTitle]="'users.noResultsTitle'"
                    [description]="selectedState.id !== STATE_FILTER.ARCHIVED ? 'users.noResultsText' : 'users.noResultsArchiveText'">
                </no-results>
            }
            @if (usersDataGrid.total == 0 && usersFilterOptions.name && !isSyncing) {
                <no-search-results></no-search-results>
            }
        </div>
    </section>
</section>
