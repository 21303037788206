@if (!isValid) {
    <disabled-button [buttonId]="buttonId" [classes]="classes" [iconClass]="iconClass" [text]="text" [tooltipText]="tooltipText"></disabled-button>
}

@if (isValid) {
    <loading-button
        [buttonId]="buttonId"
        [classes]="classes"
        [iconClass]="iconClass"
        [text]="text"
        (action)="submitForm()"
        [isValid]="isValid"></loading-button>
}
