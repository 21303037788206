import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FeedbackTraysService } from './feedback-trays.service';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';

@Component({
    templateUrl: './feedback-tray-clone-modal.component.html',
    styleUrls: ['./_feedback-tray-clone-modal.component.scss']
})
export class FeedbackTrayCloneModalComponent implements OnInit {
    hasChanges: boolean;
    isFormValid: boolean;
    nameAlreadyExist: boolean;
    selectedTray: any;

    cloneTrayForm: TrayForm;
    initialForm: TrayForm;

    @ViewChild('trayNameInApp') trayNameInApp: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<FeedbackTrayCloneModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private feedbackTraysService: FeedbackTraysService
    ) {
        this.hasChanges = false;
        this.isFormValid = false;
        this.nameAlreadyExist = false;
        this.selectedTray = this.data.selectedTray;

        const copyPrefix = this.translate.instant('feedbackTray.clone.copyText');

        this.cloneTrayForm = {
            nameInApp: copyPrefix + this.selectedTray.name,
            initials: '',
            alias: !!this.selectedTray.alias ? (copyPrefix + this.selectedTray.alias).substring(0, 64) : '',
            copyResponsible: false,
            copyQuestionnaire: false,
            copyRecipients: false,
            hasQuestionnaire: !!this.selectedTray.questionnaire && !!this.selectedTray.questionnaire.id
        };

        this.initialForm = { ...this.cloneTrayForm };
    }

    ngOnInit() {
        setTimeout(() => this.trayNameInApp.nativeElement.focus(), 200);
    }

    onChangeName() {
        if (this.nameAlreadyExist) {
            this.nameAlreadyExist = false;
        }

        this.validateModel();
    }

    onChange() {
        this.validateModel();
    }

    onAccept() {
        this.feedbackTraysService.duplicateTray(this.selectedTray.id, this.cloneTrayForm).subscribe(
            () => {
                this.dialogRef.close({ success: true });
            },
            (onError: any) => {
                if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
                    this.nameAlreadyExist = true;
                }

                this.trayNameInApp.nativeElement.focus();
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({ cancel: true });
    }

    validateModel() {
        this.detectFormDataChanges();
        this.checkFormDataValidity();
    }

    private detectFormDataChanges(): void {
        this.hasChanges = !isEqual(this.cloneTrayForm, this.initialForm);
    }

    private checkFormDataValidity(): void {
        const hasNameInApp = !!this.cloneTrayForm.nameInApp;
        const hasInitials = !!this.cloneTrayForm.initials;

        this.isFormValid = hasNameInApp && hasInitials;
    }
}

type TrayForm = {
    nameInApp: any;
    initials: string;
    alias: any;
    copyResponsible: boolean;
    copyQuestionnaire: boolean;
    copyRecipients: boolean;
    hasQuestionnaire: any;
};
