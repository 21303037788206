import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'dialenga-input-text',
    templateUrl: './dialenga-input-text.component.html'
})
export class DialengaInputTextComponent implements OnInit, OnChanges {
    @Input() isDisabled?: false;
    @Input() isReadOnly?: false;
    @Input() inputId: string;
    @Input() placeholder?: string = 'global.form.notSet';
    @Input() required?: { state: boolean; errorMessage: string };
    @Input() minLength?: { amount: number; errorMessage: string };
    @Input() maxLength?: { amount: number; warningAmount?: number };
    @Input() pattern?: { model: string; errorMessage: string };
    @Input() inputModel: string;
    @Input() lengthError: boolean;
    @Input() cancelledChanges: boolean;
    @Input() isAllwaysActive?: boolean;
    @Output() inputChanged: EventEmitter<any> = new EventEmitter();
    @Output() onFocus?: EventEmitter<boolean> = new EventEmitter();
    @Output() inputModelChange: EventEmitter<string> = new EventEmitter<string>();

    constructor(private translate: TranslateService) {
        this.translate = translate;
    }
    private lastInputModel: string;
    public isValid = true;
    public validRequired = true;
    public validMinLength = true;
    public validMaxLength = true;
    public validPattern = true;
    public errorMessage: string;
    public expPattern: any;
    public isInputModelAlreadyEdited = false;
    public inputOnFocus = false;

    public warningMessage: string;
    public colorWarningMessage: number;
    public isWarningMessageActive = false;

    public MESSAGE_COLOR = {
        WARNING: 1,
        DANGER: 2
    };

    ngOnInit() {
        this.lastInputModel = this.inputModel;
        if (this.pattern) {
            if (this.pattern.model === 'email') {
                this.expPattern = new RegExp(
                    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
                );
            }
            if (this.pattern.model === 'phone') {
                this.expPattern = new RegExp(/^[0-9 ]*$/);
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.inputModel) {
            this.lastInputModel = changes.inputModel.currentValue;
        }
    }

    modelValueChanged(): void {
        if (this.isValid && this.inputModel !== this.lastInputModel) {
            this.inputModelChange.emit(this.inputModel);
            this.inputChanged.emit(null);
        } else {
            this.inputModel = this.lastInputModel;
            this.checkValidations();
        }
        this.lastInputModel = this.inputModel;
    }

    checkValidations(): void {
        let errorMessageKey: string;

        if (this.required?.state) {
            this.validRequired = this.inputModel !== '';
            errorMessageKey = !this.validRequired ? this.required.errorMessage : '';
        }

        if (this.minLength?.amount && this.validRequired) {
            if (this.inputModel?.length > 0) {
                this.validMinLength = this.inputModel.length >= this.minLength.amount;
                errorMessageKey = !this.validMinLength ? this.minLength.errorMessage : '';
            } else {
                this.validMinLength = true;
            }
        }
        if (this.maxLength?.amount && this.maxLength?.warningAmount && this.validMinLength && this.validRequired) {
            if (this.inputModel) {
                this.isWarningMessageActive = this.inputModel.length >= this.maxLength.warningAmount;
                if (this.inputModel.length >= this.maxLength.warningAmount && this.inputModel.length < this.maxLength.amount) {
                    this.isWarningMessageActive = true;
                    this.colorWarningMessage = this.MESSAGE_COLOR.WARNING;
                } else if (this.inputModel.length === this.maxLength.amount) {
                    this.isWarningMessageActive = true;
                    this.colorWarningMessage = this.MESSAGE_COLOR.DANGER;
                }
            }
        }
        if (this.pattern && this.inputModel !== '' && this.validMinLength && this.validMaxLength) {
            this.validPattern = this.expPattern.test(this.inputModel) || this.inputModel === undefined;
            errorMessageKey = !this.validPattern ? this.pattern.errorMessage : '';
        }

        this.isValid = this.validRequired && this.validMinLength && this.validMaxLength && this.validPattern;
        if (errorMessageKey) {
            this.errorMessage = this.translate.instant(errorMessageKey, { amount: this.minLength?.amount });
        }
        if (this.isWarningMessageActive) {
            this.warningMessage = this.translate.instant('global.validation.warningAmount', {
                inputLength: this.inputModel.length,
                totalLength: this.maxLength.amount
            });
        }
    }

    modelValueIsChanging(): void {
        this.inputModel = this.inputModel.trim();
        this.checkValidations();
    }

    modelValueOnFocus(): void {
        this.inputOnFocus = true;
        if (this.onFocus) {
            this.onFocus.emit(true);
        }
    }

    checkCancelledChanges(): void {
        // if cancel from confirmation modal
        if (this.cancelledChanges) {
            this.lastInputModel = '';
        }
    }

    modelValueOnFocusOut(): void {
        this.inputOnFocus = false;
        if (this.onFocus) {
            this.onFocus.emit(false);
        }
        this.isWarningMessageActive = false;
        if (!this.isInputModelAlreadyEdited) {
            this.checkCancelledChanges();
            this.modelValueChanged();
        }
        this.isInputModelAlreadyEdited = false;
    }

    modelValueCancel(): void {
        this.isInputModelAlreadyEdited = true;
        this.inputModel = this.lastInputModel;
        this.modelValueChanged();
    }

    modelValueAccept(): void {
        this.isInputModelAlreadyEdited = true;
        this.checkCancelledChanges();
        this.modelValueChanged();
    }

    onKeyPressed(event: any): void {
        if (event.keyCode === 13) {
            this.modelValueAccept();
        } else if (event.keyCode === 27) {
            this.modelValueCancel();
        }
        event.srcElement.blur();
    }
}
