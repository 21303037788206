import { Subscription } from 'rxjs';
import { Constants } from './../../constants';
import { AfterViewInit, Component, HostListener, Inject, Input, OnInit, OnDestroy } from '@angular/core';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { SharedDataService } from '../../shared/services/shared-data.service';
import { UpgradeModule } from '@angular/upgrade/static';

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html'
})
export class SidemenuComponent implements OnInit, AfterViewInit, OnDestroy {
    private initialSidemenuItems: any = [
        {
            title: 'global.navbar.home',
            url: '/',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.manager, Constants.USER_ROLES.publisher],
            icon: 'icon icon-menu-dashboard',
            activeRoutes: ['/']
        },
        {
            title: 'global.navbar.messages',
            url: '/messages',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.manager, Constants.USER_ROLES.publisher],
            icon: 'icon icon-menu-messages',
            activeRoutes: [
                '/messages',
                '/messages/create',
                '/messages/:tabName/update/:id',
                '/messages/:tabName/update/:id',
                '/messages/:tabName/update/:id',
                '/messages/statistics/:id',
                '/messages/view/:id'
            ]
        },
        {
            title: 'global.navbar.questionnaires',
            url: '/questionnaires',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.manager, Constants.USER_ROLES.publisher],
            icon: 'icon icon-menu-dialogs',
            activeRoutes: [
                '/questionnaires',
                '/questionnaires/edit/:id',
                '/questionnaires/recipients/:id',
                '/questionnaires/preview/:id',
                '/questionnaires/:id/questions/:questionId?',
                '/questionnaires/:id/endings/:questionId?',
                '/questionnaires/statistics/:id',
                '/questionnaires/view/:id'
            ]
        },
        {
            title: 'global.navbar.knowledgecenter',
            url: '/knowledge-center',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.manager, Constants.USER_ROLES.publisher],
            icon: 'icon icon-menu-knowledge',
            activeRoutes: [
                '/knowledge-center',
                '/knowledge-center/create',
                '/knowledge-center/:tabName/update/:id',
                '/knowledge-center/:tabName/update/:id',
                '/knowledge-center/:tabName/update/:id',
                '/knowledge-center/statistics/:id',
                '/knowledge-center/view/:id'
            ],
            module: Constants.MODULES.KNOWLEDGE_CENTER
        },
        {
            title: 'global.navbar.experiences',
            url: '/experiences',
            access: [Constants.USER_ROLES.admin],
            icon: 'icon icon-menu-experiences',
            activeRoutes: ['/experiences'],
            module: Constants.MODULES.EXPERIENCES
        },
        {
            title: 'global.navbar.conversations',
            url: '/requests',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.ftrayResponsible],
            icon: 'icon icon-menu-feedback-inboxes',
            activeRoutes: ['/requests'],
            module: Constants.MODULES.CONVERSATIONS
        },
        {
            title: 'global.navbar.timeoffRequests',
            url: '/timeoff/requests',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.humanResources],
            icon: 'icon icon-menu-vacations',
            activeRoutes: ['/timeoff/:tabName'],
            module: Constants.MODULES.HOLIDAYS
        },
        {
            title: 'global.navbar.timeTracking',
            url: '/time-tracking',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.humanResources],
            icon: 'icon icon-menu-time-tracking',
            activeRoutes: ['/time-tracking'],
            module: Constants.MODULES.TIME_TRACKING
        },
        {
            title: 'global.navbar.documents',
            url: '/documents',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.documentsManager],
            icon: 'icon icon-menu-documents',
            activeRoutes: ['/documents/:tabName?', '/import-documents', '/import-documents/history', '/import-documents/history/:id'],
            module: Constants.MODULES.DOCUMENTS
        },
        {
            title: 'global.navbar.users',
            url: '/users',
            access: [Constants.USER_ROLES.admin, Constants.USER_ROLES.employeeManager],
            icon: 'icon icon-group',
            activeRoutes: ['/users', '/user/create', '/user/:tabName/update/:id', '/users/import-users']
        },
        {
            title: 'global.navbar.statistics',
            url: '/globalstatistics',
            access: [Constants.USER_ROLES.admin],
            icon: 'icon icon-menu-statistics',
            activeRoutes: ['/globalstatistics']
        },
        {
            id: 'menu-settings',
            title: 'global.navbar.globalSettings',
            url: '/global-settings',
            access: [
                Constants.USER_ROLES.admin,
                Constants.USER_ROLES.publisher,
                Constants.USER_ROLES.humanResources,
                Constants.USER_ROLES.employeeManager,
                Constants.USER_ROLES.ftrayManager,
                Constants.USER_ROLES.documentsManager,
                Constants.USER_ROLES.chatroomManager
            ],
            icon: 'icon icon-menu-settings',
            activeRoutes: [
                '/global-settings',
                '/settings/my-company/:tabName',
                '/settings/my-company/company/:tabName/update/:id',
                '/settings/my-company/workplace/:tabName/update/:id',
                '/settings/my-company/teams/update/:id',
                '/settings/timeoff/:tabName',
                '/settings/timeoff/scheme/:tabName/update/:id',
                '/settings/users-groups',
                '/settings/users-groups/update/:id',
                '/settings/conversations/:tabName',
                '/settings/conversations/feedback-trays/create',
                '/settings/conversations/feedback-trays/:tabName/:id',
                '/settings/conversations/feedback-trays/questionnaire/update/:id',
                '/settings/conversations/feedback-trays/questionnaire/:id/questions/:questionId?',
                '/settings/conversations/feedback-trays/questionnaire/:id/endings/:endingId?',
                '/settings/categories',
                '/settings/categories/create',
                '/settings/categories/update/:id',
                '/settings/active-features',
                '/settings/contact-info',
                '/settings/documents/:tabName',
                '/settings/chatrooms',
                '/settings/chatroom/:tabName/update/:id',
                '/settings/time-tracking/:tabName',
                '/settings/time-tracking/:tabName/update/:id'
            ],
            class: 'the-menu__item--bottom'
        }
    ];

    public sidemenuItems;
    public _rootScope;
    public isSubmenuVisible = false;
    public isMobileSize = false;
    public categoryContainerState;
    public CATEGORY_CONTAINER_STATE = Constants.CATEGORY_CONTAINER_STATE;
    public menuLinks = [];
    public submenuLinks = [];
    public isSideMenuMoreItemVisible = false;
    private finishModulesUpdateSubscription: Subscription;

    constructor(
        private upgrade: UpgradeModule,
        private sharedDataService: SharedDataService,
        private formUtilsService: FormUtilsService
    ) {}

    ngOnInit() {
        this._rootScope = this.upgrade.$injector.get('$rootScope');
        this.categoryContainerState = Constants.CATEGORY_CONTAINER_STATE.CLOSED;
        this.finishModulesUpdateSubscription = this.formUtilsService.finishModulesUpdateEmitter$.subscribe(() => {
            this.initializeSidemenu();
        });
    }

    ngAfterViewInit() {
        this.initializeSidemenu();
    }

    ngOnDestroy() {
        if (this.finishModulesUpdateSubscription) {
            this.finishModulesUpdateSubscription.unsubscribe();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.onViewResize();
    }

    checkAvailableItems() {
        this.sidemenuItems = [];
        this.initialSidemenuItems.forEach((item) => {
            if (!item.module || window['checkIfModuleIsActiveForUserCompany'](item.module)) {
                item.isAccessible = this.sharedDataService.checkIfItemIsAvailableForLoggedUser(item);
                this.sidemenuItems.push(item);
            }
        });
    }

    getMenuActiveItems() {
        const menuHeight = document.getElementById('sidemenu').clientHeight;
        const horizontalScrollbarHeight = window.innerHeight - document.documentElement.clientHeight;
        const sidemenuMarginSize = 114;
        const sidemenuItemHeight = 44;
        const numItemsMenuActive = Math.floor((menuHeight - sidemenuMarginSize - horizontalScrollbarHeight) / sidemenuItemHeight);
        const minNumItemsMenuActive = 5;
        return Math.max(numItemsMenuActive, minNumItemsMenuActive);
    }

    setItemsMenus() {
        this.menuLinks = [...this.sidemenuItems];

        const menuActiveItems = this.getMenuActiveItems();
        this.submenuLinks = this.menuLinks.splice(menuActiveItems, this.menuLinks.length - menuActiveItems);

        this.isSideMenuMoreItemVisible = this.submenuLinks.length > 0;
    }

    initializeSidemenu() {
        this.checkAvailableItems();
        this.setItemsMenus();
        this._rootScope.inmobile = false;
        this._rootScope.openedMenuMobileSize = false;
        this.isMobileSize = window['innerWidth'] < 1100;
    }

    onViewResize() {
        this.setItemsMenus();
        this.isMobileSize = window['innerWidth'] < 1100;
        if (!this._rootScope.inmobile) {
            if (this.isMobileSize) {
                this._rootScope.isSidemenuVisible = !!this._rootScope.openedMenuMobileSize;
            } else {
                this._rootScope.isSidemenuVisible = true;
                this._rootScope.openedMenuMobileSize = false;
            }
        } else {
            this._rootScope.isSidemenuVisible = false;
        }
    }

    trackByFn(index: number, item: any): number {
        return index;
    }

    openSidemenu() {
        this._rootScope.isSidemenuVisible = true;
        this._rootScope.inmobile = false;
        this._rootScope.openedMenuMobileSize = !!this.isMobileSize;
        // localStorage.setItem('isSidemenuVisibleLS', this._rootScope.isSidemenuVisible);
        // localStorage.setItem('inmobileLS', this._rootScope.inmobile);
        // localStorage.setItem('openedMenuMobileSizeLS', this._rootScope.openedMenuMobileSize);
    }

    private movementMenuFn() {
        this._rootScope.movementMenu = false;
        setTimeout(() => {
            this._rootScope.movementMenu = true;
        }, 400);
    }

    closeSidemenu() {
        this._rootScope.isSidemenuVisible = false;
        this._rootScope.inmobile = true;
        this._rootScope.openedMenuMobileSize = false;
        this.movementMenuFn();
        // localStorage.setItem('isSidemenuVisibleLS', this._rootScope.isSidemenuVisible);
        // localStorage.setItem('inmobileLS', this._rootScope.inmobile);
        // localStorage.setItem('openedMenuMobileSizeLS', this._rootScope.openedMenuMobileSize);
    }

    checkActive(link: any) {
        if (!this._rootScope.currentRoute) {
            return false;
        }
        const currentPath = this._rootScope.currentRoute.originalPath;
        if (currentPath && link.activeRoutes) {
            return link.activeRoutes.indexOf(currentPath) > -1;
        } else {
            return false;
        }
    }

    checkActiveMenuMore() {
        if (!this._rootScope.currentRoute) {
            return false;
        }
        const currentPath = this._rootScope.currentRoute.originalPath;
        let isMenuMoreActive = false;
        for (let index = 0; index < this.submenuLinks.length; index++) {
            const item = this.submenuLinks[index];
            if (currentPath && item.activeRoutes && item.activeRoutes.indexOf(currentPath) > -1) {
                isMenuMoreActive = true;
                break;
            }
        }
        return isMenuMoreActive;
    }

    toggleSubmenuVisibility() {
        this.isSubmenuVisible = !this.isSubmenuVisible;
    }

    redirectTo(url: string) {
        this.isSubmenuVisible = false;
        window['redirectToUrl'](url);
    }
}
