import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { UsersService } from '../../services/users.service';

@Component({
    selector: 'user-dropdown',
    templateUrl: './user-dropdown.component.html'
})
export class UserDropdownComponent implements OnChanges {
    @Input() inputModel: any;
    @Output() inputModelChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() onSelection: EventEmitter<string> = new EventEmitter<string>();
    @Output() onBlur: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('availableUsersCombo') public availableUsersCombo: ComboBoxComponent;

    public userFilter: string;
    public availableUsers: Array<any> = [];

    constructor(private usersService: UsersService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.inputModel && changes.inputModel.currentValue && this.availableUsers.length === 0) {
            this.getEmployees(this.inputModel.fullName);
        }
    }

    getEmployees(filterValue?: string): void {
        this.userFilter = filterValue;
        if (filterValue && filterValue.length >= 3) {
            this.usersService.getEmployees(filterValue).subscribe(
                (response: any) => {
                    this.availableUsers = response.content;
                },
                (error: any) => {
                    console.error(error);
                }
            );
        } else {
            this.availableUsersCombo?.toggle(false);
        }
    }

    onEmployeeSelected(value: any): void {
        this.inputModelChange.emit(value);
        if (!!this.onSelection) {
            this.onSelection.emit(value);
        }
    }

    onElementBlur(value: any): void {
        this.onBlur.emit(value);
    }
}
