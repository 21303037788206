import { Component, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: 'wk-usage-conditions-modal.component.html'
})
export class WKUsageConditionsModalComponent implements AfterViewInit {
    termsAndConditionsAccepted: boolean;
    termsUrl: string;

    private userSession: any = window['getUserSession']();

    constructor(public dialogRef: MatDialogRef<WKUsageConditionsModalComponent>) {
        this.termsAndConditionsAccepted = false;
        this.termsUrl = this.setTermsLanguageUrl('/terms-and-conditions');
    }

    ngAfterViewInit() {}

    onAccept() {
        if (this.termsAndConditionsAccepted) {
            this.dialogRef.close({ success: true });
        } else {
            this.dialogRef.close({ cancelled: true });
        }
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }

    setTermsLanguageUrl(url: string): string {
        const allowedLanguages = ['en', 'fr'];
        const language = this.userSession?.language;
        if (allowedLanguages.includes(language)) {
            url = `${url}-${language}`;
        }
        return url;
    }
}
