import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WorkplaceCreateModalComponent } from '../workplace-create/workplace-create-modal.component';
import { DepartmentCreateModalComponent } from '../department-create/department-create-modal.component';
import { TeamCreateModalComponent } from '../team-create/team-create-modal.component';
import { CompanyCreateModalComponent } from '../company-create/company-create-modal.component';
import { Constants } from '../../constants';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'my-company',
    templateUrl: './my-company.component.html'
})
export class MyCompanyComponent implements OnInit {
    public MY_COMPANY_TABS: any;
    public selectedTab: number;
    private _route: any;
    public userSession: any;

    public userLoggedHasAdminRole: boolean;
    public isPreviewVisible: boolean = false;

    constructor(
        private location: Location,
        private upgrade: UpgradeModule,
        private titleService: Title,
        private translate: TranslateService,
        public dialog: MatDialog
    ) {
        this.MY_COMPANY_TABS = Constants.MY_COMPANY_TABS;
        this._route = this.upgrade.$injector.get('$route');
        this.selectedTab = this._route.current.locals.selectedTab;
        this.userSession = window['getUserSession']();

        this.userLoggedHasAdminRole = this.userSession.roles.indexOf(Constants.USER_ROLES.admin) !== -1;
    }

    ngOnInit() {}

    navigateTo(url: string) {
        this.location.go(url);
    }

    loadTabContent(tab: number) {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            switch (tab) {
                case this.MY_COMPANY_TABS.GLOBAL:
                    this.titleService.setTitle(this.translate.instant('myCompany.title'));
                    this._route.updateParams({ tabName: 'global' });
                    break;
                case this.MY_COMPANY_TABS.COMPANIES:
                    this.titleService.setTitle(this.translate.instant('subsidiaries.title'));
                    this._route.updateParams({ tabName: 'companies' });
                    break;
                case this.MY_COMPANY_TABS.WORKPLACES:
                    this.titleService.setTitle(this.translate.instant('workplaces.title'));
                    this._route.updateParams({ tabName: 'workplaces' });
                    break;
                case this.MY_COMPANY_TABS.DEPARTMENTS:
                    this.titleService.setTitle(this.translate.instant('departments.title'));
                    this._route.updateParams({ tabName: 'departments' });
                    break;
                case this.MY_COMPANY_TABS.TEAMS:
                    this.titleService.setTitle(this.translate.instant('timeoff.teams.title'));
                    this._route.updateParams({ tabName: 'teams' });
                    break;
                case this.MY_COMPANY_TABS.EXTERNAL_LINKS:
                    this.titleService.setTitle(this.translate.instant('externalLinks.title'));
                    this._route.updateParams({ tabName: 'external-links' });
                    break;
                default:
                    break;
            }
        }
    }

    createCompany() {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(CompanyCreateModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    // Get the new company id from success and navigate to update view
                    this.location.go('settings/my-company/company/general/update/' + result.success.id);
                }
            });
    }

    createWorkplace() {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(WorkplaceCreateModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    // Get id workplace from success and navigate to update view
                    this.location.go('settings/my-company/workplace/location/update/' + result.success.id);
                }
            });
    }

    createDepartment() {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(DepartmentCreateModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    this.location.go('settings/my-company/department/update/' + result.success.id);
                }
            });
    }

    createTeam() {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(TeamCreateModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    // Get id team from success and navigate to update view
                    this.location.go('settings/my-company/teams/update/' + result.success.id);
                }
            });
    }

    createExternalLink() {
        this.location.go('settings/my-company/external-link/create');
    }

    isPreviewVisibleFn(visibility: boolean): boolean {
        this.isPreviewVisible = visibility;
        return this.isPreviewVisible;
    }
}
