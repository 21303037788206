import { Component, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { DepartmentsService } from './departments.service';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'departments',
    templateUrl: './departments.component.html'
})
export class DepartmentsComponent implements OnInit {
    public isSyncing: boolean = false;

    public departmentsDatagrid: GridDataResult = {
        data: [],
        total: 0
    };

    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };

    public dataPagingOpts: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: ['name,asc']
    };

    public dataFilterOpts: any = {
        active: true,
        name: ''
    };

    public totalPages: number = 0;
    public dataSkeleton = [{}, {}, {}];
    public gridSort: SortDescriptor[];

    private clickedRowItem: any = '';

    @ViewChild('departmentsGrid') departmentsGrid: GridComponent;

    constructor(
        private departmentsService: DepartmentsService,
        private location: Location,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        private dialogService: DialogService
    ) {}

    ngOnInit() {
        this.getDepartmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    //** Grid related functions **//

    getDepartmentsGridData(pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;

        this.departmentsService.findAll(pagingOptions, filterOptions).subscribe(
            (response) => {
                this.departmentsDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    onSortChange(sort: SortDescriptor[]): void {
        //OK
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : ['lastModifiedDate,desc', 'name,asc'];
        this.getDepartmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / this.dataPagingOpts.size;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;
        this.getDepartmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
    }

    onDblClick(): void {
        if (this.clickedRowItem.id) {
            this.navigateToEditView(this.clickedRowItem.id);
        }
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }

    //** Search input Functions **//

    clearSearchInput() {
        let updateDataGrid = this.dataFilterOpts.name.length >= 3;
        this.dataFilterOpts.name = '';
        if (updateDataGrid) {
            this.listItemsSearch();
        }
    }

    listItemsSearch() {
        if (this.dataFilterOpts.name.length >= 3 || this.dataFilterOpts.name.length === 0) {
            this.getDepartmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    //** Filter Functions **//

    getDepartmentsByIsActive(isActive: boolean) {
        this.dataFilterOpts.active = isActive;
        this.getDepartmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    //** Row Action Functions **//

    updateDepartment(item: any) {
        this.navigateToEditView(item.id);
    }

    archiveOrActivateDepartment(item: any) {
        const dialogValues: DialogValueOptions = {
            title: item.active ? 'departments.modal.archive.title' : 'departments.modal.activate.title',
            message: item.active ? 'departments.modal.archive.message' : 'departments.modal.activate.message',
            messageParam: { departmentName: item.name },
            acceptButtonText: item.active ? 'global.form.archive' : 'global.form.activate'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;

                let serviceToBeenCalled = '';
                serviceToBeenCalled = item.active ? 'archive' : 'activate';

                if (serviceToBeenCalled) {
                    this.departmentsService[serviceToBeenCalled](item.id).subscribe(
                        (onSuccess) => {
                            const message = this.translate.instant(
                                item.active ? 'departments.notification.archive.message' : 'departments.notification.activate.message',
                                { workplaceName: item.name }
                            );
                            this.notificationService.showSuccessNotification(message);
                            this.getDepartmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
                        },
                        (onError) => {}
                    );
                }
            }
        });
    }

    deleteDepartment(item: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'departments.modal.delete.title',
            message: 'departments.modal.delete.message',
            messageParam: { departmentName: item.name },
            acceptButtonText: 'global.form.delete'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;

                this.departmentsService.delete(item.id).subscribe(
                    (onSuccess) => {
                        this.getDepartmentsGridData(this.dataPagingOpts, this.dataFilterOpts);
                        const message = this.translate.instant('departments.notification.delete.message', { departmentName: item.name });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => {}
                );
            }
        });
    }

    navigateToEditView(id: string): void {
        this.location.go('settings/my-company/department/update/' + id);
    }
}
