<div class="main-container__content">
    <div class="main-container--normal-width">
        <div class="notification-box margin-bottom-20">
            <span class="notification-box__text" [translate]="'workplaces.holidays.advice'"></span>
        </div>
        <div class="flex align-items-center margin-top-40 margin-bottom-20">
            <h3 class="margin-bottom-0" [translate]="'workplaces.holidays.title'"></h3>
            <div class="blue-counter margin-left-10">
                <span class="blue-counter__label" [textContent]="workplaceHolidays.length"></span>
            </div>
            <div class="filters-buttons-bar-container margin-left-20 justify-self-end">
                <kendo-dropdownlist
                    #yearFilter
                    id="year-filter"
                    data-cy="year-filter"
                    [data]="years"
                    [(ngModel)]="selectedYear"
                    class="kendo-angular-dropdown min-width-100"
                    (valueChange)="onYearSelection()"
                    [popupSettings]="{ width: 'auto'}">
                    <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>
                </kendo-dropdownlist>
            </div>
        </div>
        <!-- Holidays list -->
        @if (workplaceHolidays.length > 0 && !isSyncing) {
            <section class="table">
                <div class="table__body" id="workplace-holidays-table">
                    @for (holiday of workplaceHolidays | sort: 'month'; track $index) {
                        <div class="table__body__row">
                            <div class="table__body__cell" (dblclick)="openHolidayEditionModal(holiday)">
                                <span class="table__body__content table__body__content--truncate black-85" [textContent]="holiday.name"></span>
                            </div>
                            <div class="table__body__cell max-width-200" (dblclick)="openHolidayEditionModal(holiday)">
                                <span class="table__body__content table__body__content--truncate" [textContent]="getFormattedDate(holiday)"></span>
                            </div>
                            <div class="table__body__cell table__body__cell--icons max-width-150">
                                <div class="table__body__content table__body__content--icons visible-on-hover">
                                    <span
                                        class="table__body__icon icon icon-edit"
                                        (click)="openHolidayEditionModal(holiday)"
                                        (dblclick)="$event.stopImmediatePropagation(); $event.stopPropagation()"></span>
                                    <span
                                        class="table__body__icon icon icon-delete"
                                        (click)="openHolidayDeletionModal(holiday)"
                                        (dblclick)="$event.stopImmediatePropagation(); $event.stopPropagation()"></span>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </section>
        }
        <!-- Skeleton -->
        @if (workplaceHolidays.length == 0 && isSyncing) {
            @for (item of [1,2,3]; track $index) {
                <div class="table__body__row" [ngClass]="{'opacity-50': $index === 1, 'opacity-30': $index === 2}">
                    <div class="table__body__cell">
                        <kendo-skeleton shape="text" animation="pulse" [width]="300" [height]="20" class="border-radius-4"></kendo-skeleton>
                    </div>
                    <div class="table__body__cell max-width-200">
                        <kendo-skeleton shape="text" animation="pulse" [width]="100" [height]="20" class="border-radius-4"></kendo-skeleton>
                    </div>
                    <div class="table__body__cell table__body__cell--icons max-width-150"></div>
                </div>
            }
        }
        <!-- No data feedback -->
        @if (workplaceHolidays.length == 0 && !isSyncing) {
            <div class="margin-bottom-100">
                <no-results [imageTitle]="'workplaces.holidays.noResultsTitle'" [description]="'workplaces.holidays.noResultsText'"></no-results>
            </div>
        }
    </div>
</div>
