<section class="main-container__content">
    <div class="main-container--full-width">
        <div class="flex justify-space-between margin-top-4 margin-bottom-24" [style.minHeight]="'32px'">
            <div class="label-filters align-self-end">
                <button
                    type="button"
                    id="active-motives"
                    [ngClass]="{'selected': stateIsActive}"
                    (click)="getMotivesByIsActive(true)"
                    [translate]="'global.listFilters.active'"></button>
                <button
                    type="button"
                    id="archived-motives"
                    [ngClass]="{'selected': !stateIsActive}"
                    (click)="getMotivesByIsActive(false)"
                    [translate]="'global.listFilters.archived'"></button>
            </div>
            @if (motivesDataGrid.total || inputSearchField || isSyncing) {
                <article class="search-filter-container align-items-center">
                    <input
                        type="text"
                        id="motive-search-input"
                        class="input input--search"
                        [ngClass]="inputSearchField.length > 0 ? 'showClearBtn' : 'hideClearBtn'"
                        maxlength="1024"
                        [placeholder]="'motives.modal.motive' | translate"
                        [(ngModel)]="inputSearchField"
                        (input)="updateSearch()" />
                    <span class="icon icon-remove" (click)="updateSearch(true)"></span>
                </article>
            }
        </div>

        @if (motivesDataGrid.data.length > 0) {
            <kendo-grid
                #motivesGrid
                id="motives-grid"
                class="dialenga-grid margin-bottom-40 user-select-none"
                [data]="motivesDataGrid"
                [sortable]="true"
                [sort]="gridSort"
                (sortChange)="onSortChange($event)"
                (cellClick)="onCellClick($event)"
                (dblclick)="onDblClick($event)"
                [pageable]="gridPagerSettings"
                [pageSize]="dataPagingOpts.size"
                (pageChange)="onPageChange($event)"
                [skip]="dataPagingOpts.skip"
                [navigable]="true"
                [resizable]="false"
                [selectable]="false"
                [loading]="isSyncing">
                <kendo-grid-column field="contents.motive" [title]="'motives.motiveTag' | translate" [width]="300" headerClass="cursor-pointer">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center">
                            @if (isDefaultLanguageHidden(dataItem.availableLanguages)) {
                                <span
                                    class="icon-left-vertical-center icon icon-alert"
                                    kendoTooltip
                                    [title]="'language.contentTooltip.noDefaultLanguage' | translate"></span>
                            }
                            <span class="reason-tag truncate" [style.--motive-bg]="decimalToHex(dataItem.color)" [textContent]="dataItem.motive"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="contents.motiveInApp" [title]="'motives.motiveInApp' | translate" headerClass="cursor-pointer">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="truncate" [textContent]="dataItem.motiveInApp"></span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-command-column [width]="136" [style]="{ 'text-align': 'end' }">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                        <div class="dialenga-grid__actions" kendoTooltip filter="button">
                            <button
                                type="button"
                                data-cy="edit-button"
                                class="dialenga-grid__action-button"
                                [title]="'global.form.edit' | translate"
                                (click)="editMotive(dataItem.id)">
                                <span class="icon icon-edit icon-24"></span>
                            </button>
                            @if (dataItem.deletable) {
                                <button
                                    type="button"
                                    data-cy="delete-button"
                                    class="dialenga-grid__action-button"
                                    [title]="'global.form.delete' | translate"
                                    (click)="deleteMotive(dataItem)">
                                    <span class="icon icon-delete icon-24"></span>
                                </button>
                            }
                            @if (!dataItem.deletable && !dataItem.archived) {
                                <button
                                    type="button"
                                    data-cy="archive-button"
                                    class="dialenga-grid__action-button"
                                    [title]="'global.form.archive' | translate"
                                    (click)="archiveMotive(dataItem)">
                                    <span class="icon icon-archive icon-24"></span>
                                </button>
                            }
                            @if (dataItem.archived) {
                                <button
                                    type="button"
                                    data-cy="activate-button"
                                    class="dialenga-grid__action-button"
                                    [title]="'global.form.activate' | translate"
                                    (click)="activateMotive(dataItem)">
                                    <span class="icon icon-unarchive icon-24"></span>
                                </button>
                            }
                        </div>
                    </ng-template>
                </kendo-grid-command-column>

                <kendo-grid-messages
                    [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                    [pagerOf]="'global.lists.pagerOf' | translate"
                    [pagerItems]="'global.lists.pagerItems' | translate">
                </kendo-grid-messages>

                <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                    <article class="dui-grid-pager">
                        <kendo-pager-info></kendo-pager-info>
                        @if (motivesDataGrid.total > 10) {
                            @if (totalPages > 1) {
                                <article class="dui-grid-pager__numbers-wrap">
                                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                </article>
                            }
                            <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                        }
                    </article>
                </ng-template>
            </kendo-grid>
        }

        @if (motivesDataGrid.data.length == 0 && isSyncing) {
            <kendo-grid [kendoGridBinding]="dataSkeleton" class="dialenga-grid" [rowClass]="skeletonRowCallback">
                <kendo-grid-column field="contents.motive" [title]="'motives.motiveTag' | translate" [width]="300">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-skeleton shape="text" animation="pulse" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="contents.motiveInApp" [title]="'motives.motiveInApp' | translate">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-skeleton shape="text" animation="pulse" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-command-column [width]="136"></kendo-grid-command-column>
            </kendo-grid>
        }

        @if (motivesDataGrid.total == 0 && !inputSearchField && !isSyncing) {
            <no-results [imageTitle]="'motives.noResultsTitle'" [description]="!stateIsActive ? 'motives.noResultsText' : 'motives.noResultsArchiveText'">
            </no-results>
        }
        @if (motivesDataGrid.total == 0 && inputSearchField && !isSyncing) {
            <no-search-results></no-search-results>
        }
    </div>
</section>
