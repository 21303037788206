<div class="main-container">
    <div class="main-container__header" style="min-width: 891px;">
        <div class="flex direction-column width-100-percent" spellcheck="false">
            <ul class="breadcrumb">
                <li>
                    <a href="/global-settings" [textContent]="'global.navbar.globalSettings' | translate"></a>
                </li>
            </ul>
            <h2 [translate]="'documents.title'"></h2>
        </div>
    </div>
    <div class="tabs-language-container" style="min-width: 891px;">
        <div class="tab-nav tab-nav--inline" style="width:100%" kendoTooltip filter="span" position="top">
            <!-- Global tab -->
            @if (userLoggedHasAdminRole) {
                <span
                    id="docs-global-tab"
                    data-cy="docs-global-tab"
                    class="tab-nav__item"
                    [ngClass]="{'tab-nav__item--selected': selectedTab === DOCUMENT_TABS.GLOBAL}"
                    [translate]="'myCompany.tabs.global'"
                    (click)="loadTabContent(DOCUMENT_TABS.GLOBAL)">
                </span>
            } @else {
                <span
                    id="docs-global-tab"
                    data-cy="docs-global-tab"
                    class="tab-nav__item tab-nav__item--disabled"
                    [translate]="'myCompany.tabs.global'"
                    [title]="'users.tabs.noValidRolesForAccess' | translate">
                </span>
            }
            <!-- Conventions tab -->
            <span
                id="docs-conventions-tab"
                data-cy="docs-conventions-tab"
                class="tab-nav__item"
                [ngClass]="{'tab-nav__item--selected': selectedTab === DOCUMENT_TABS.CONVENTIONS}"
                [translate]="'documents.nameConvention.title'"
                (click)="loadTabContent(DOCUMENT_TABS.CONVENTIONS)">
            </span>
        </div>
    </div>
    @if (selectedTab === DOCUMENT_TABS.GLOBAL && userLoggedHasAdminRole) {
        <div class="margin-top-20" style="min-width: 891px;">
            <document-settings-global></document-settings-global>
        </div>
    }
    @if (selectedTab === DOCUMENT_TABS.CONVENTIONS) {
        <div class="margin-top-20" style="min-width: 891px;">
            <document-settings-conventions></document-settings-conventions>
        </div>
    }
</div>
