<div class="main-container">
    <section class="main-container__header">
        <article class="flex direction-column" spellcheck="false">
            <ul class="breadcrumb">
                <li>
                    <a href="/global-settings" [translate]="'global.navbar.globalSettings'"></a>
                </li>
            </ul>
            <h2 [translate]="'timeoffSettings.title'"></h2>
        </article>
        <div class="flex justify-self-end">
            <!-- New schema button -->
            <div *ngIf="selectedTab === TIMEOFF_SETTINGS_TABS.SCHEMAS">
                <button #newSchemeBtn id="new-schema-btn" class="button button--secondary button--small" (click)="openNewSchemaModal()">
                    <span class="button__icon icon icon-add"></span>
                    <span class="button__label" [translate]="'schemes.newSchema'"></span>
                </button>
            </div>
        </div>
    </section>
    <section class="main-container__content">
        <div class="main-container--full-width">
            <!-- Timeoff settings navigation tabs -->
            <div class="tabs-language-container">
                <div class="tab-nav tab-nav--inline" style="width:100%">
                    <span
                        #schemasTab
                        id="schemas-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === TIMEOFF_SETTINGS_TABS.SCHEMAS}"
                        [translate]="'timeoffSettings.tabs.schemes'"
                        (click)="loadTabContent(TIMEOFF_SETTINGS_TABS.SCHEMAS)"></span>
                    <span
                        #globalTab
                        id="global-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === TIMEOFF_SETTINGS_TABS.GLOBAL}"
                        [translate]="'timeoff.tabs.global'"
                        (click)="loadTabContent(TIMEOFF_SETTINGS_TABS.GLOBAL)"></span>
                </div>
            </div>
        </div>
    </section>
    <section class="margin-top-20" style="min-width: 891px;">
        @if (selectedTab === TIMEOFF_SETTINGS_TABS.SCHEMAS) {
            <schemas-list></schemas-list>
        }
        @if (selectedTab === TIMEOFF_SETTINGS_TABS.GLOBAL) {
            <timeoff-settings-global></timeoff-settings-global>
        }
    </section>
</div>
