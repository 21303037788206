import { Component, Inject, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormUtilsService } from '../../services/form-utils.service';
import { DuiNotificationsService } from '../../../shared/services/dui-notifications.service';
import { TimeTrackingService } from '../../../components/time-tracking/time-tracking.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    templateUrl: './time-record-edit-modal.component.html',
    styleUrls: ['./_time-record-edit-modal.scss']
})
export class TimeRecordEditModalComponent implements OnInit, AfterViewInit {
    public isFormValid: boolean = true;
    public formHasErrors: boolean = false;
    public localizedError: string = '';

    public recordTimeForm: any = {
        date: this.data.recordDate,
        records: []
    };
    public recordKinds: Array<any> = this.data.recordKinds;
    public recordKindsDisabled: boolean = this.data.recordKinds.length === 0;
    public isEditionMode: boolean = this.data.editionMode;

    @ViewChild('startTime') startTimeRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<TimeRecordEditModalComponent>,
        private formUtilsService: FormUtilsService,
        private notificationService: DuiNotificationsService,
        public utils: UtilsService,
        private timeTrackingService: TimeTrackingService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.data.dayRecords.forEach((item: any) => this.recordTimeForm.records.push(Object.assign({}, item)));

        if (this.recordTimeForm?.records.length === 0 && !this.isEditionMode) {
            this.addInterval();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.startTimeRef.nativeElement.focus(), 200);
    }

    ngOnInit() {
        this.onChanges();
    }

    private getDefaultRecordKind(): any {
        let kind: any = this.recordKinds.find((kind: any) => {
            if (kind.id === 1) {
                return kind;
            }
        });
        return kind;
    }

    private checkIfDataFormAreValid(): boolean {
        /// Cambiarlo, está mal
        let valid = false;
        this.recordTimeForm.records.forEach((record: any) => {
            valid = !!record.startTime && !!record.endTime && (record.startTime != '00:00' || record.endTime != '00:00');
        });
        return valid;
    }

    private parseRecordsData(form: any): any {
        form.records.forEach((record: any) => {
            record.kindId = record.kind?.id || null;
        });
        return form;
    }

    private onChanges() {
        this.isFormValid = this.checkIfDataFormAreValid();
    }

    oneValidRecordExist(): boolean {
        let validRecord: boolean = false;
        validRecord = this.recordTimeForm.records.find((record: any) => {
            return !!record.startTime && !!record.endTime && (record.startTime != '00:00' || record.endTime != '00:00');
        });
        return validRecord;
    }

    onChangeTime(event: any, record: any, element: any) {
        // Not exist event if keypressed is supr or backspace
        if (!event) {
            element.value = '00:00';
            record[element.name] = '00:00';
        }
        this.onChanges();
    }

    onCopyTime(event: any, element: any) {
        event.preventDefault();
        event.clipboardData.setData('text/plain', element.value);
        this.onChanges();
    }

    onPasteTime(event: any, record: any, element: any) {
        event.preventDefault();
        const valueToPaste = event.clipboardData.getData('text');

        if (new RegExp('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$').test(valueToPaste)) {
            element.value = valueToPaste;
            record[element.name] = valueToPaste;
        }
        this.onChanges();
    }

    addInterval(): void {
        this.recordTimeForm.records.push({
            id: null,
            recordDate: this.data.recordDate,
            startTime: '00:00',
            endTime: '00:00',
            kind: this.recordKindsDisabled ? null : this.getDefaultRecordKind(),
            valid: true
        });
        this.onChanges();
    }

    deleteInterval(idx: number): void {
        this.recordTimeForm.records.splice(idx, 1);
        this.onChanges();
    }

    onAccept(): void {
        const serviceMethod = this.isEditionMode ? 'updateUserDayRecord' : 'createUserDayRecords';

        this.timeTrackingService[serviceMethod](this.data.userId, this.parseRecordsData(this.recordTimeForm)).subscribe(
            (response: any) => {
                this.formHasErrors = false;
                this.localizedError = '';
                this.dialogRef.close({ success: true });
            },
            (error: any) => {
                this.formUtilsService.finishSubmitAction();

                if (error.status === 422) {
                    this.formHasErrors = true;
                    this.localizedError = error.error.localizedError;
                } else {
                    this.notificationService.showErrorNotification();
                }
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }
}
