<div class="main-container">
    <div class="main-container__head">
        <div class="flex align-items-center justify-space-between margin-top-20 margin-bottom-20">
            <div class="flex direction-column">
                <ul class="breadcrumb">
                    <li>
                        <a href="/documents" [translate]="'global.navbar.documents'"></a>
                    </li>
                    <li>
                        <a href="/import-documents/history" [translate]="'global.navbar.documents-history'"></a>
                    </li>
                </ul>
                <h2 [translate]="'documents.history.detail.title'"></h2>
            </div>
            <div class="flex align-items-center">
                <button type="button" id="new-submit" class="button button--small button--secondary" (click)="navigateTo('/import-documents')">
                    <span class="button__icon icon icon-add"></span>
                    <span class="button__label" [translate]="'documents.import.newShipment'"></span>
                </button>
                <button class="button button--small margin-left-20" (click)="exportAsExcel()">
                    <span class="button__icon icon icon-export"></span>
                    <span class="button__label" [translate]="'documents.import.exportErrors'"></span>
                </button>
            </div>
        </div>
    </div>
    @if (numResults >= 0) {
        <div class="margin-top-40">
            <section class="margin-bottom-48">
                <div class="group-form group-form--label-200">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'documents.import.detail.state'"></span>
                    </div>
                    <div class="group-form__data">
                        @if (
                            (transactions.errorEntries === 0 && transactions.successfulEntries > 0) || transactions.state === DOCUMENT_TRANSACTION_STATE.SUCCESSFUL
                        ) {
                            <article class="document-submission-status" data-status="success">
                                <span class="icon icon-tick-bold icon-16 white"></span>
                                <p id="file-successful" class="document-submission-status__label" [translate]="'documents.import.detail.completed'"></p>
                            </article>
                        }
                        @if (
                            (transactions.errorEntries > 0 && transactions.successfulEntries > 0) || transactions.state === DOCUMENT_TRANSACTION_STATE.SOME_ERRORS
                        ) {
                            <div class="document-submission-status" data-status="with-errors">
                                <span class="icon icon-tick-bold icon-16 white"></span>
                                <p id="file-with-errors" class="document-submission-status__label" [translate]="'documents.import.detail.sendedWithErrors'"></p>
                            </div>
                        }
                        @if (
                            (transactions.errorEntries > 0 && transactions.successfulEntries === 0) || transactions.state === DOCUMENT_TRANSACTION_STATE.FAILURE
                        ) {
                            <article class="document-submission-status" data-status="failure">
                                <p id="file-not-sent" class="document-submission-status__label" [translate]="'documents.import.detail.notSended'"></p>
                            </article>
                        }
                    </div>
                </div>
                @if (transactions.kind) {
                    <div class="group-form group-form--label-200">
                        <div class="group-form__label">
                            <span class="group-form__label__text" [translate]="'documents.import.detail.type'"></span>
                        </div>
                        <div class="group-form__data">
                            <span
                                class="icon icon-16 margin-right-8"
                                [ngClass]="{'icon-tag-s':transactionKind.id === 5,'icon-tag-fill-s':transactionKind.id !== 5}"
                                [ngStyle]="{ color: transactionKind.color }"></span>
                            <span [textContent]="transactionKind.text"></span>
                        </div>
                    </div>
                }
                <div class="group-form group-form--label-200">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'documents.import.detail.sendingDate'"></span>
                    </div>
                    <div class="group-form__data">
                        <span>{{ transactions.transactionDate | date:'dd/MM/yyyy HH:mm' }}</span>
                    </div>
                </div>
                <div class="group-form group-form--label-200">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'documents.import.detail.sendedBy'"></span>
                    </div>
                    <div class="group-form__data">
                        <avatar
                            [name]="transactions.user?.name"
                            [surname]="transactions.user?.surname"
                            [small]="true"
                            [thumbnails]="{thumbnail_M: transactions.user?.avatarThumbnailMUrl || transactions.user?.avatarUrl, thumbnail_S: transactions.user?.avatarThumbnailSUrl || transactions.user?.avatarThumbnailMUrl}">
                        </avatar>
                        <span id="sentBy" class="margin-left-8" [textContent]="transactions.user?.fullName"></span>
                    </div>
                </div>
                <div class="group-form group-form--label-200">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'documents.import.detail.originDocs'"></span>
                    </div>
                    <div class="group-form__data">
                        <span
                            [translate]="'documents.import.detail.sentOriginDocs'"
                            [translateParams]="{docs: transactions.errorEntries + transactions.successfulEntries}"></span>
                    </div>
                </div>
            </section>
            <section>
                <h3 class="transaction-detail__subheading margin-bottom-24" [translate]="'documents.import.detail.processedDocs'"></h3>
                <div class="group-form group-form--label-200">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'documents.import.detail.satisfactory'"></span>
                    </div>
                    <div class="group-form__data">
                        <span id="successful-files" class="strong" [textContent]="transactions.successfulEntries"></span>
                    </div>
                </div>
                @if (!!transactions.replacedEntries) {
                    <div class="group-form group-form--label-200">
                        <div class="group-form__label">
                            <span class="group-form__label__text" [translate]="'documents.import.detail.replaced'"></span>
                        </div>
                        <div class="group-form__data">
                            <span id="successful-files" class="strong" [textContent]="transactions.replacedEntries"></span>
                        </div>
                    </div>
                }
                @if (transactions.errorEntries > 0) {
                    <div class="group-form group-form--label-200">
                        <div class="group-form__label">
                            <span class="group-form__label__text danger-color" [translate]="'documents.import.detail.notSended'"></span>
                        </div>
                        <div class="group-form__data">
                            <span id="error-files" class="strong danger-color" [textContent]="transactions.errorEntries"></span>
                        </div>
                    </div>
                }
            </section>
        </div>
    }

    <!-- grid -->
    @if (numResults > 0) {
        <div class="margin-top-48 margin-bottom-56">
            <section>
                <h3 class="margin-bottom-24" [translate]="'documents.import.detail.details'"></h3>
                <kendo-grid
                    #errorsGrid
                    id="results-grid"
                    class="master-grid master-grid--transaction-detail"
                    [kendoGridBinding]="errorsDataGrid"
                    [pageable]="true"
                    [pageSize]="PAGE_SIZE"
                    [sortable]="true"
                    [sort]="gridSort"
                    (sortChange)="onSortChange($event)">
                    <kendo-grid-column field="filename" [width]="400" [sortable]="true" [title]="'documents.import.resultsGridFilename' | translate">
                        <ng-template kendoGridHeaderTemplate>
                            <span [translate]="'documents.import.resultsGridFilename'" class="cursor-pointer user-select-none"></span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="truncate" [textContent]="dataItem.filename" [title]="dataItem.filename"></span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="error" [title]="'documents.import.resultsGridError' | translate">
                        <ng-template kendoGridHeaderTemplate>
                            <span [translate]="'documents.import.resultsGridError'" class="cursor-pointer user-select-none"></span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span class="truncate" [textContent]="dataItem.error" [title]="dataItem.error"></span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-messages
                        [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                        [pagerOf]="'global.lists.pagerOf' | translate"
                        [pagerItems]="'global.lists.pagerItems' | translate">
                    </kendo-grid-messages>
                    <kendo-grid-excel fileName="load-documents-errors.xlsx" filterable="true"></kendo-grid-excel>
                    <!-- Custom Pager -->
                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <article class="dui-grid-pager">
                            <kendo-pager-info></kendo-pager-info>
                            @if (errorsDataGrid.length > 10) {
                                <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                </article>
                                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                            }
                        </article>
                    </ng-template>
                </kendo-grid>
            </section>
        </div>
    }
</div>
