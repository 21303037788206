<div class="dialog-modal-header" style="min-height: 72px;">
    <div class="flex align-items-center">
        <h3 [textContent]="mainTitle" style="font-size: 22px"></h3>
    </div>
    <button type="button" class="button button--only-icon dialog-modal__button-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
</div>
<div class="dialog-modal-body text-center" style="min-height: 300px; padding: 0; position: relative;">
    <kendo-scrollview
        #whatsNew
        [data]="data.features"
        width="100%"
        height="300px"
        class="k-scrollview--whatsnew"
        [animate]="true"
        [arrows]="false"
        [pageable]="data.features.length > 1"
        [endless]="data.features.length > 1"
        (itemChanged)="onItemChanged()">
        <ng-template let-item="item">
            <div class="feature-container">
                <div
                    class="feature-container__col feature-container__col--left"
                    [ngStyle]="{'background': 'linear-gradient(133.11deg, ' + item.color_primary + ' -1.51%, ' + item.color_secondary + ' 100%)'}">
                    @if (!!item.tag) {
                        <span
                            class="margin-top-16 font-size-10"
                            [textContent]="item.tag"
                            style="background-color: #04736F; border-radius: 2px; color: white; padding: 2px 6px; font-family: 'Inter'; font-weight: 600; letter-spacing: 2px;"></span>
                    }
                    <span class="feature-container__title margin-top-20" [textContent]="item.featureTitle"></span>
                    <span class="feature-container__description" [innerHTML]="item.featureDescription"></span>
                    @if (item.url) {
                        <a
                            href="{{item.url}}"
                            class="button feature-container__button margin-top-10 margin-bottom-40"
                            [textContent]="item.featureTextButton"
                            target="_blank"></a>
                    }
                    @if (!item.url) {
                        <div class="margin-top-10 margin-bottom-20" style="min-height: 32px; width: 100%;"></div>
                    }
                </div>
                <div class="feature-container__col feature-container__col--bg" [ngStyle]="{'background-image': 'url(' + item.image + ')'}"></div>
            </div>
        </ng-template>
    </kendo-scrollview>
    @if (data.features.length > 1) {
        <kendo-progressbar class="feature-container__progress" [animation]="progressAnimation" [value]="progressValue" [label]="{visible: false}">
        </kendo-progressbar>
    }
</div>
<div class="modal-footer flex justify-end align-items-center" style="min-height: 84px;">
    <button id="confirmation-modal-cancel-button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.form.viewed' | translate"></span>
    </button>
</div>
