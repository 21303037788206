import { Component, Input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'avatar-popover',
    templateUrl: './avatar-popover.component.html',
    styleUrls: ['./_avatar-popover.component.scss']
})
export class AvatarPopoverComponent {
    @Input() nationalId: string;
    @Input() username: string;
    @Input() internalId: string;
    @Input() employee: any;
    @Input() largeMode?: boolean;

    public isCopyIconVisible: any = {
        nationalId: false,
        username: false,
        internalId: false
    };

    constructor(
        private clipboard: Clipboard,
        private translate: TranslateService
    ) {
        this.translate = translate;
    }

    showCopyIcon(value: string): void {
        this.isCopyIconVisible[value] = true;
    }

    hideCopyIcon(value: string): void {
        this.isCopyIconVisible[value] = false;
    }

    copyToClipboard(value: string): void {
        const isCopySuccessful = this.clipboard.copy(value);
        if (isCopySuccessful && window['notifySuccess']) {
            window['notifySuccess'](this.translate.instant('500.copiedToClipboard'), 3000);
        }
    }
}
