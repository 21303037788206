<section class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 id="internal-link-title" class="modal-title" [translate]="'internalLinks.title'"></h3>
</section>

<section class="modal-body">
    <!-- Input for search contents -->
    <section class="search-content">
        <p class="black-85 margin-bottom-10" [translate]="'internalLinks.description'"></p>
        <article class="relative">
            <input
                #contentSearch
                id="contentSearch"
                type="text"
                class="input"
                [(ngModel)]="contentSearchText"
                (input)="onSearch()"
                [placeholder]="'segments.searchPlaceholder' | translate"
                autocomplete="off"
                [ngModelOptions]="{ debounce: 1000 }" />
            <span class="icon icon-remove" (click)="onClearSearchInput()"></span>
        </article>
        @if (contentSearchText.length < 3) {
            <p class="margin-top-4 margin-bottom-0 font-size-13 black-45" [translate]="'internalLinks.searchInfoLabel'"></p>
        }
    </section>

    <!-- Total results of search and filter content buttons -->
    @if (showFilters) {
        <section class="search-content__filter margin-top-32">
            <p
                class="black-85 margin-bottom-16"
                [translate]="'global.form.nResults'"
                [translateParams]="{number: internalLinks.totalElements ? internalLinks.totalElements : 0}"></p>
            <div class="flex align-items-center">
                @for (filterBtn of filterButtons; track filterBtn) {
                    <button
                        [id]="filterBtn.id"
                        class="button button--small"
                        (click)="onSelectFilter(filterBtn)"
                        [ngClass]="{'button--selected': filterBtn.value === selectedFilter.value}">
                        <span class="button__label font-size-12" [textContent]="filterBtn.text"></span>
                    </button>
                }
            </div>
        </section>
    }

    @if (internalLinks.totalElements > 0 && contentSearchText.length >= 3) {
        <!-- Result list for select content -->
        <section class="search-content__container scroll7 margin-top-16">
            @for (content of internalLinks.content; track content) {
                <article class="rel-card-h" (click)="onSelectContent(content)" [ngClass]="{'selected': content.selected}">
                    <span class="rel-card-h__type-icon icon icon-24" [ngClass]="getContentIconClass(content.kind, !!content.gallerySize)"></span>
                    <div class="rel-card-h__data">
                        <span class="rel-card-h__title margin-bottom-6" [innerHTML]="content.title"></span>
                        <span
                            class="rel-card-h__category truncate"
                            [textContent]="content.category.name"
                            [ngStyle]="{'background-color': utilsService.getCategoryColor(content.category)}"></span>
                        <span class="rel-card-h__date margin-top-6" [textContent]="getTimeElapsedFrom(content.date)"></span>
                    </div>
                    @if (content.imageURL) {
                        <figure class="content-image" [style.--content-image-background]="'url(' + content.imageURL + ')'">
                            @if (content.gallerySize !== 0) {
                                <figcaption class="content-kind-badge content-kind-badge--md">
                                    <span [textContent]="'+' + (content.gallerySize - 1)"></span>
                                </figcaption>
                            }
                            @if (content.kind === CONTENT_KIND.SURVEY) {
                                @if (content.anonymousSurvey) {
                                    <span class="icon icon-heisenberg anonymous-badge anonymous-badge--md"></span>
                                }
                                <figcaption class="content-kind-badge content-kind-badge--md">
                                    <span class="icon icon-questionnaire"></span>
                                </figcaption>
                            }
                        </figure>
                    } @else {
                        @if (content.youtubeURL) {
                            <figure class="content-image" [style.--content-image-background]="'url(' + getVideoImgPreview(content.youtubeURL) + ')'">
                                <figcaption class="content-kind-badge content-kind-badge--md">
                                    <span class="icon icon-play"></span>
                                </figcaption>
                            </figure>
                        } @else {
                            <figure
                                class="content-image"
                                data-is-category
                                [style.--content-image-background]="content.category.imageURL ? 'url(' + content.category.imageURL + ')' : null"
                                [style.--content-image-overlay-color]="utilsService.getCategoryColor(content.category)">
                                @if (content.kind === CONTENT_KIND.SURVEY) {
                                    @if (content.anonymousSurvey) {
                                        <span class="icon icon-heisenberg anonymous-badge anonymous-badge--md"></span>
                                    }
                                    <figcaption class="content-kind-badge content-kind-badge--md">
                                        <span class="icon icon-questionnaire"></span>
                                    </figcaption>
                                }
                            </figure>
                        }
                    }
                </article>
            }
        </section>
    }

    @if (internalLinks.totalElements === 0 && contentSearchText.length >= 3) {
        <section class="search-content__no-matches margin-top-16">
            <div class="text-center">
                <img src="/ng1/assets/img/dialenguin-no-matches.svg" />
                <p class="margin-top-16" [translate]="'internalLinks.noMatchesTitle'"></p>
                <p class="margin-top-8" [translate]="'internalLinks.noMatchesText'"></p>
            </div>
        </section>
    }
</section>

<section class="modal-footer flex justify-end" style="border-top: none">
    <loading-button
        [buttonId]="'validate-conversation-button'"
        [classes]="'button button--secondary margin-right-10'"
        (action)="onAccept()"
        [isValid]="isFormValid"
        [text]="'global.modal.accept'"></loading-button>
    <button type="button" class="button" (click)="onCancel()">
        <span class="button__label" [translate]="'global.modal.cancel'"></span>
    </button>
</section>
