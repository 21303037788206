<div class="main-container__content">
    <div class="main-container--normal-width">
        <div class="flex flex-wrap justify-space-between">
            <!-- Schema data form  -->
            <section class="padding-top-48">
                <!-- How calculate requested days (working / calendar days) -->
                <div class="group-form group-form--label-300">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'model.schemes.computingModeDays'"></span>
                    </div>
                    <div class="group-form__data">
                        <div class="button-bar button-bar--small">
                            <button
                                data-cy="working-days"
                                class="button"
                                type="button"
                                (click)="setSchemaKindDays(SCHEMA_KIND_DAYS.WORKING)"
                                [ngClass]="{'button--selected': schemaForm?.timeOffSchema.daysKind === SCHEMA_KIND_DAYS.WORKING}">
                                <span class="button__label" [translate]="'model.schemes.workingDays'"></span>
                            </button>
                            <button
                                data-cy="calendar-days"
                                class="button"
                                type="button"
                                (click)="setSchemaKindDays(SCHEMA_KIND_DAYS.CALENDAR)"
                                [ngClass]="{'button--selected': schemaForm?.timeOffSchema.daysKind === SCHEMA_KIND_DAYS.CALENDAR}">
                                <span class="button__label" [translate]="'model.schemes.calendarDays'"></span>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- Select working days if schema kind days mode is working -->
                @if (schemaForm?.timeOffSchema.daysKind === SCHEMA_KIND_DAYS.WORKING) {
                    <div class="group-form group-form--label-300">
                        <div class="group-form__label">
                            <span class="group-form__label__text" [translate]="'model.schemes.workingDays'"></span>
                        </div>
                        <div class="group-form__data">
                            <ul class="circles-list" kendoTooltip filter="li">
                                @for (day of workingDaysSelection; track day) {
                                    <li
                                        class="circles-list__item"
                                        [ngClass]="{'circles-list__item--active' : schemaForm?.timeOffSchema.workDays[day.schemaField]}"
                                        (click)="setSchemaWorkingDays(day)"
                                        [textContent]="day.name"
                                        [title]="'global.form.weekDaysName' | translate: {day: day.dayOfWeek}"></li>
                                }
                            </ul>
                        </div>
                    </div>
                }
                <!-- Holidays per year (amount) -->
                <div class="group-form group-form--label-300">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'model.schemes.holidaysDaysPerYear'"></span>
                    </div>
                    <div class="group-form__data group-form__data--inline">
                        <input
                            #holydaysPerYear
                            name="holidaysPerYear"
                            type="number"
                            class="input text-right max-width-60"
                            inputmode="numeric"
                            [(ngModel)]="schemaForm?.timeOffSchema.annualLeaveDays"
                            (blur)="onChange()"
                            [min]="0"
                            [max]="99"
                            (ngModelChange)="onInputNumberChange(holydaysPerYear)" />
                        <span
                            class="margin-left-10"
                            [translate]="(schemaForm?.timeOffSchema.daysKind === SCHEMA_KIND_DAYS.WORKING) ? 'model.schemes.workingDays' : 'model.schemes.calendarDays'">
                        </span>
                    </div>
                </div>
                <!-- Accrual period start date -->
                <div class="group-form group-form--label-300">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'model.schemes.startDateVestingPeriod'"></span>
                    </div>
                    <div class="group-form__data group-form__data--inline">
                        <kendo-dropdownlist
                            [defaultItem]="''"
                            [data]="days"
                            [(ngModel)]="schemaForm?.timeOffSchema.startBearDay"
                            [disabled]="true"
                            class="dui-dropdown align-right margin-right-10 height-36 width-60"
                            valuePrimitive="true"
                            (valueChange)="onChange()"
                            [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                            <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span
                                    class="padding-3"
                                    [textContent]="dataItem"
                                    [ngClass]="{'hidden': (dataItem > 28 && schemaForm.timeOffSchema.startBearMonth == '2') || 
                                    (dataItem > 30 && [4,6,9,11].includes(schemaForm.timeOffSchema.startBearMonth))}">
                                </span>
                            </ng-template>
                        </kendo-dropdownlist>

                        <kendo-dropdownlist
                            [defaultItem]="''"
                            [data]="months"
                            [(ngModel)]="schemaForm?.timeOffSchema.startBearMonth"
                            [disabled]="true"
                            class="dui-dropdown margin-right-10 height-36 width-160"
                            valuePrimitive="true"
                            (valueChange)="onChange()"
                            [textField]="'name'"
                            valueField="value"
                            [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                            <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                @if (dataItem) {
                                    <span class="capitalize padding-left-4" [textContent]="dataItem.name"></span>
                                }
                            </ng-template>
                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                <span
                                    class="padding-3 margin-left-5 capitalize"
                                    [textContent]="dataItem.name"
                                    [ngClass]="{'hidden': (dataItem.value == '2' && schemaForm.timeOffSchema.startBearDay > 28) || 
                                    ([4,6,9,11].includes(dataItem.value) && schemaForm.timeOffSchema.startBearDay > 30)}"></span>
                            </ng-template>
                        </kendo-dropdownlist>
                    </div>
                </div>
                <!-- Allow request unearned days -->
                <div class="group-form group-form--label-300">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'model.schemes.allowRequestUnearnedDays'"></span>
                    </div>
                    <div class="group-form__data">
                        <toggle-input
                            id="allowBearDays"
                            [inputId]="'allowBearDays'"
                            [(inputModel)]="schemaForm?.timeOffSchema.allowBearRequests"
                            (inputChanged)="onChangeAllowBearDays()">
                        </toggle-input>
                    </div>
                </div>
                @if (schemaForm?.timeOffSchema.allowBearRequests) {
                    <div class="group-form group-form--label-300">
                        <div class="group-form__label group-form__label--end" kendoTooltip filter="span.icon-help-fill" offset="8">
                            <span class="group-form__label__text margin-right-10" [translate]="'model.schemes.maxVestingDays'"></span>
                            <span class="group-form__label__help icon icon-help-fill k-group" [title]="'schemes.boxes.bearDaysAllowed' | translate"></span>
                        </div>
                        <div class="group-form__data group-form__data--inline">
                            <input
                                #bearDaysAllowed
                                name="bearDaysAllowed"
                                type="number"
                                class="input text-right max-width-60"
                                inputmode="numeric"
                                [(ngModel)]="schemaForm?.timeOffSchema.bearDaysAllowed"
                                (blur)="onChange()"
                                [min]="0"
                                [max]="99"
                                (ngModelChange)="onInputNumberChange(bearDaysAllowed)" />
                            <span class="margin-left-10" [translate]="'model.schemes.days'"></span>
                        </div>
                    </div>
                }
                <!-- Days pending consumption title -->
                <div class="flex align-items-center margin-top-48" kendoTooltip filter="span.icon-help-fill" offset="8">
                    <h3 class="margin-bottom-0" [translate]="'model.schemes.pendingDays'"></h3>
                    <span
                        class="group-form__label__help icon icon-help-fill margin-left-10 k-group"
                        [title]="'schemes.boxes.allowPendingDays' | translate"></span>
                </div>
                <!-- Include days pending consumption toggle -->
                <div class="group-form group-form--label-300 margin-top-24">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'model.schemes.includeDaysToBeConsumed'"></span>
                    </div>
                    <div class="group-form__data group-form__data--inline">
                        <toggle-input
                            id="allowPendingDays"
                            [inputId]="'allowPendingDays'"
                            [(inputModel)]="schemaForm?.timeOffSchema.allowPendingDays"
                            (inputChanged)="onChangeAllowPendingDays()">
                        </toggle-input>
                    </div>
                </div>
                @if (schemaForm?.timeOffSchema.allowPendingDays) {
                    <!-- Amount pending days allowed -->
                    <div class="group-form group-form--label-300">
                        <div class="group-form__label group-form__label--end" kendoTooltip filter="span.icon-help-fill" offset="8">
                            <span class="group-form__label__text margin-right-10" [translate]="'model.schemes.limitDaysPreviousPeriod'"></span>
                            <span class="group-form__label__help icon icon-help-fill k-group" [title]="'schemes.boxes.pendingDaysAllowed' | translate"></span>
                        </div>
                        <div class="group-form__data group-form__data--inline">
                            <input
                                #pendingDaysAllowed
                                name="pendingDaysAllowed"
                                type="number"
                                class="input text-right width-60"
                                inputmode="numeric"
                                [(ngModel)]="schemaForm?.timeOffSchema.pendingDaysAllowed"
                                (blur)="onChange()"
                                [min]="0"
                                [max]="99"
                                (ngModelChange)="onInputNumberChange(pendingDaysAllowed)" />
                        </div>
                    </div>
                    <!-- Date limit for pending days -->
                    <div class="group-form group-form--label-300">
                        <div class="group-form__label group-form__label--end">
                            <span class="group-form__label__text" [translate]="'model.schemes.deadlineDaysPreviousPeriod'"></span>
                        </div>
                        <div data-cy="date-limit-pending-days-control" class="group-form__data group-form__data--inline">
                            <kendo-dropdownlist
                                [defaultItem]="''"
                                [data]="days"
                                [(ngModel)]="schemaForm?.timeOffSchema.limitPendingDay"
                                class="dui-dropdown align-right margin-right-10 height-36 width-60"
                                valuePrimitive="true"
                                (valueChange)="onChange()"
                                [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <span
                                        class="padding-3"
                                        [textContent]="dataItem"
                                        [ngClass]="{'hidden': (dataItem > 28 && schemaForm.timeOffSchema.limitPendingMonth == '2') || 
                                        (dataItem > 30 && [4,6,9,11].includes(schemaForm.timeOffSchema.limitPendingMonth))}">
                                    </span>
                                </ng-template>
                            </kendo-dropdownlist>

                            <kendo-dropdownlist
                                [defaultItem]="''"
                                [data]="months"
                                [(ngModel)]="schemaForm?.timeOffSchema.limitPendingMonth"
                                class="dui-dropdown margin-right-10 height-36 width-160"
                                valuePrimitive="true"
                                (valueChange)="onChange()"
                                [textField]="'name'"
                                valueField="value"
                                [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    @if (dataItem) {
                                        <span class="capitalize padding-left-4" [textContent]="dataItem.name"></span>
                                    }
                                </ng-template>
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <span
                                        class="padding-3 margin-left-5 capitalize"
                                        [textContent]="dataItem.name"
                                        [ngClass]="{'hidden': (dataItem.value == '2' && schemaForm.timeOffSchema.limitPendingDay > 28) || 
                                        ([4,6,9,11].includes(dataItem.value) && schemaForm.timeOffSchema.limitPendingDay > 30)}"></span>
                                </ng-template>
                            </kendo-dropdownlist>
                        </div>
                    </div>
                }
                <!-- Days of free disposal title (personal days) -->
                <div class="flex align-items-center relative" kendoTooltip filter="span.icon-help-fill" offset="8">
                    <h3 class="margin-bottom-0" [translate]="'model.schemes.daysOfFreeDisposal'"></h3>
                    <span
                        class="group-form__label__help icon icon-help-fill margin-left-10 k-group"
                        [title]="'schemes.boxes.freeDisposalTooltip' | translate"></span>
                </div>
                <!-- Personal days allowed toggle -->
                <div class="group-form group-form--label-300 margin-top-24">
                    <div class="group-form__label">
                        <span class="group-form__label__text" [translate]="'model.schemes.enableDaysOfFreeDisposal'"></span>
                    </div>
                    <div class="group-form__data">
                        <toggle-input
                            id="enableDaysOfFreeDisposal"
                            [inputId]="'enableDaysOfFreeDisposal'"
                            [(inputModel)]="schemaForm?.timeOffSchema.allowPersonalDays"
                            (inputChanged)="onChangeAllowPersonalDays()">
                        </toggle-input>
                    </div>
                </div>
                <!-- Personal days amount -->
                @if (schemaForm?.timeOffSchema.allowPersonalDays) {
                    <div class="group-form group-form--label-300">
                        <div class="group-form__label group-form__label--end">
                            <span class="group-form__label__text" [translate]="'model.schemes.basicBalance'"></span>
                        </div>
                        <div class="group-form__data">
                            <input
                                #personalDaysAmount
                                name="personalDaysAmount"
                                type="number"
                                class="input text-right width-60"
                                inputmode="numeric"
                                [(ngModel)]="schemaForm?.timeOffSchema.personalDaysAllowed"
                                (blur)="onChange()"
                                [min]="0"
                                [max]="99"
                                (ngModelChange)="onInputNumberChange(personalDaysAmount)" />
                        </div>
                    </div>
                }
                <!-- Additional options title -->
                <div class="flex align-items-center relative">
                    <h3 class="margin-bottom-0" [translate]="'model.schemes.additionalOptions'"></h3>
                </div>
                <!-- Show notice message toggle -->
                <div class="group-form group-form--label-300 margin-top-24">
                    <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="8">
                        <span class="group-form__label__text margin-right-10" [translate]="'model.schemes.noticeContents'"></span>
                        <span class="group-form__label__help icon icon-help-fill k-group" [title]="'schemes.boxes.noticeContentsTooltip' | translate"></span>
                    </div>
                    <div class="group-form__data">
                        <toggle-input
                            id="showNoticeContents"
                            [inputId]="'showNoticeContents'"
                            [(inputModel)]="schemaForm?.timeOffSchema.showNoticeContents"
                            (inputChanged)="onChangeShowNoticeContents()">
                        </toggle-input>
                    </div>
                </div>
                @if (schemaForm?.timeOffSchema.showNoticeContents) {
                    <div class="group-form group-form--label-300">
                        <div class="group-form__label group-form__label--end">
                            <div class="group-form__label"></div>
                            <span class="group-form__label__text padding-top-16" [translate]="'model.schemes.noticeContentsLabel'"></span>
                        </div>
                        <div class="group-form__data flex direction-column">
                            <div class="flex justify-end padding-bottom-2">
                                <language-selector
                                    [(selectedLanguage)]="selectedLanguage"
                                    [formContent]="schemaForm?.timeOffSchema.noticeContents"
                                    (formContentChange)="onChange()">
                                </language-selector>
                            </div>
                            <div class="width-100-percent">
                                <textarea
                                    id="noticeContents"
                                    maxlength="256"
                                    class="textarea"
                                    (ngModelChange)="onChange()"
                                    placeholder="{{'message.captionPlaceholder' | translate}}"
                                    [(ngModel)]="schemaForm.timeOffSchema.noticeContents[selectedLanguage].notice">
                                </textarea>
                            </div>
                        </div>
                    </div>
                }
                <!-- Form action buttons -->
                <div class="group-form group-form--label-300 margin-top-48 margin-bottom-100">
                    <div class="group-form__label"></div>
                    <div class="group-form__data group-form__data--inline">
                        <feedback-action-button
                            [buttonId]="'save-schema-button'"
                            [classes]="'button--secondary margin-right-10'"
                            [text]="'global.form.save'"
                            [tooltipText]="'formValidation.noChangesAvailable'"
                            (action)="scheduleFormSave()"
                            [isValid]="isFormDataChanged && isFormDataValid">
                        </feedback-action-button>
                        <loading-button [buttonId]="'go-back'" [text]="'global.form.cancel'" (action)="goBack()" [isValid]="true"></loading-button>
                    </div>
                </div>
            </section>

            <!-- Nuevo diseño Periodos -->
            <section class="period-list__container">
                <div class="period-list ">
                    <h3 [translate]="'model.schemes.activePeriods'"></h3>
                    <!-- Current period -->
                    <div class="period-list__item margin-top-10">
                        <span class="period-list__year" [textContent]="schemaPeriods.currentPeriod.period"></span>
                        <div
                            class="state-tag period-list__state"
                            [ngClass]="schemaForm?.currentPeriod?.periodState === PERIOD_STATES.ENABLED ? 'state-tag--enabled' : 'state-tag--disabled'">
                            <span
                                class="state-tag__label"
                                [translate]="schemaForm?.currentPeriod?.periodState === PERIOD_STATES.ENABLED ? 'model.schemes.enabledPeriod' : 'model.schemes.disabledPeriod'">
                            </span>
                        </div>
                        <!-- @if (false) {
                        <label class="period-list__toggle toggle__container margin-left-10" [ngClass]="{'disabled': true}">
                            <div class="toggle toggle--small"
                                [ngClass]="{'toggle--active': schemaForm?.currentPeriod?.periodState === PERIOD_STATES.ENABLED}">
                                <span class="toggle__indicator"></span>
                            </div>
                            <input class="toggle__input" id="current-period-toggle" name="currentPeriodToggle"
                                type="checkbox" [(ngModel)]="schemaPeriods.currentPeriod.enabled" [disabled]="true" />
                        </label>
                        } -->
                    </div>
                    <!-- Next period -->
                    <div class="period-list__item">
                        <span class="period-list__year" [textContent]="schemaPeriods.nextPeriod.period"></span>
                        <div
                            class="state-tag period-list__state"
                            [ngClass]="schemaForm?.nextPeriod?.periodState === PERIOD_STATES.ENABLED ? 'state-tag--enabled' : 'state-tag--disabled'">
                            <span
                                class="state-tag__label"
                                [translate]="schemaForm?.nextPeriod?.periodState === PERIOD_STATES.ENABLED ? 'model.schemes.enabledPeriod' : 'model.schemes.disabledPeriod'">
                            </span>
                        </div>
                        <!-- @if (false) {
                        <label class="period-list__toggle toggle__container margin-left-10">
                            <div class="toggle toggle--small"
                                [ngClass]="{'toggle--active': schemaForm?.nextPeriod?.periodState === PERIOD_STATES.ENABLED}">
                                <span class="toggle__indicator"></span>
                            </div>
                            <input class="toggle__input" id="next-period-toggle" name="nextPeriodToggle" type="checkbox"
                                [(ngModel)]="schemaPeriods.nextPeriod.enabled" (click)="toggleActiveNextPeriod()" />
                        </label>
                        } -->
                    </div>
                </div>
                <!-- REMOVED IN OLDER VERSION -->
                <!-- <div ng-if="false">
                    <div id="bloque-01"
                        ng-if="vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.nextPeriodConfigKind === vm.SCHEME_PERIOD_ACTIVATION_KIND.MANUAL">
                        <p class="text--sm black-60 margin-top-20" data-translate="model.schemes.setAutomaticActivation"
                            data-translate-values="{nextPeriod: vm.periods.nextPeriod.period}"></p>
                        <a href="" class="link--blue" data-ng-click="vm.setAutomaticActivationModal()"
                            data-translate="model.schemes.setAutomaticKind"></a>
                    </div>
                    <div id="bloque-03" class="flex margin-top-20"
                        ng-if="vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.nextPeriodConfigKind === vm.SCHEME_PERIOD_ACTIVATION_KIND.AUTOMATIC && 
                                                                    vm.schemaForm.schema.nextPeriod.periodState === vm.PERIOD_STATES.DISABLED">
                        <span class="icon icon-warning-circle-fill icon-24 warning min-width-40 flex justify-center"></span>
                        <span class="text-sm black-60 padding-top-3" data-translate="model.schemes.manualActivationRequired"
                            data-translate-values="{nextPeriod: vm.periods.nextPeriod.period}"></span>
                    </div>
                    <div id="bloque-02" class="margin-top-20"
                        ng-if="vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.nextPeriodConfigKind === vm.SCHEME_PERIOD_ACTIVATION_KIND.AUTOMATIC">
                        <div class="flex align-items-center">
                            <span
                                class="icon icon-state-scheduled icon-32 state-scheduled-color width-40 flex justify-center"></span>
                            <span class="text-sm--bold" data-translate="model.schemes.automaticModeActive"></span>
                        </div>
                        <div class="flex direction-column margin-left-40"
                            ng-if="vm.schemaForm.schema.timeOffSchema.nextPeriodConfig.nextPeriodConfigKind === vm.SCHEME_PERIOD_ACTIVATION_KIND.AUTOMATIC">
                            <span class="text-sm black-60" data-translate="model.schemes.nextPeriodsActivation"
                                data-translate-values="{nextCalendarDateEnabled: vm.automaticPeriodEnabled}"></span>
                            <a href="" class="link--blue margin-top-10" data-ng-click="vm.cancelAutomaticActivation()"
                                data-translate="model.schemes.cancelAutomaticKind"></a>
                        </div>
                    </div>
                </div> -->
            </section>
        </div>
    </div>
</div>
