import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../constants';
import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    public apiUrl: string;
    public PAGE_SIZE: number;
    public USER_STATES_FILTER: any;
    public STATE_FILTER: any;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.PAGE_SIZE = Constants.PAGE_SIZE;
        this.USER_STATES_FILTER = Constants.USER_STATES_FILTER;
        this.STATE_FILTER = {
            AVAILABLES: 1,
            ARCHIVED: 2,
            ACTIVES: 3,
            PENDING_ACTIVATION: 4,
            ACTIVES_PENDING_ACCESS: 5,
            LOCKED: 6
        };
    }

    findOne(id: number): Observable<any> {
        return this.httpClient.get<any>(this.apiUrl + '/users/' + id);
    }

    findAll(pagerOptions: any, filterOptions: any) {
        let params = new HttpParams()
            .set('page', pagerOptions.page || 0)
            .set('size', pagerOptions.size || this.PAGE_SIZE)
            .set('sort', pagerOptions.sort || 'joinDate,desc');

        if (filterOptions?.state) {
            filterOptions.state.forEach((state: string) => {
                params = params.append('state', state);
            });
        }

        if (filterOptions?.name) {
            params = params.append('name', filterOptions.name);
        }

        if (filterOptions?.companies) {
            filterOptions.companies.forEach((subsidiary: string) => {
                params = params.append('subsidiaryCompany', subsidiary);
            });
        }

        if (filterOptions?.workplaces) {
            filterOptions.workplaces.forEach((hq: string) => {
                params = params.append('headquarter', hq);
            });
        }

        if (filterOptions?.departments) {
            filterOptions.departments.forEach((department: string) => {
                params = params.append('department', department);
            });
        }

        if (filterOptions?.teams) {
            filterOptions.teams.forEach((team: string) => {
                params = params.append('team', team);
            });
        }

        if (filterOptions?.roles) {
            filterOptions.roles.forEach((role: string) => {
                params = params.append('roles.name', role);
            });
        }

        const httpOptions = {
            params: params,
            reportProgress: true
        };

        return this.httpClient.get<any>(`${this.apiUrl}/users`, httpOptions);
    }

    activate(id: number) {
        return this.httpClient.put<any>(`${this.apiUrl}/users/${id}/activate`, null);
    }

    deactivate(id: number) {
        return this.httpClient.put<any>(`${this.apiUrl}/users/${id}/deactivate`, null);
    }

    downloadUsersCsv(filterOptions: any) {
        let params = new HttpParams();

        const appendParams = (key: string, values: string | string[]) => {
            if (Array.isArray(values)) {
                values.forEach((value) => {
                    params = params.append(key, value);
                });
            } else {
                params = params.append(key, values);
            }
        };

        const filterMappings = {
            state: 'state',
            name: 'name',
            companies: 'subsidiaryCompany',
            workplaces: 'headquarter',
            departments: 'department',
            teams: 'team',
            roles: 'roles.name'
        };

        Object.keys(filterMappings).forEach((key) => {
            if (filterOptions[key] && filterOptions[key].length > 0) {
                appendParams(filterMappings[key], filterOptions[key]);
            }
        });

        const httpOptions: any = {
            withCredentials: true,
            observe: 'response',
            responseType: 'blob',
            params: params
        };

        return this.httpClient.get<any>(this.apiUrl + '/users/export-csv', httpOptions);
    }

    getEmployees(employeeFilter?: string): Observable<any> {
        let url = this.apiUrl + '/users/employees?';
        if (!!employeeFilter) {
            url += 'name=' + employeeFilter.toLowerCase() + '&';
        }
        url +=
            'state=' + this.USER_STATES_FILTER.REGISTERED + '&state=' + this.USER_STATES_FILTER.PENDING_ACTIVATION + '&state=' + this.USER_STATES_FILTER.LOCKED;

        return this.httpClient.get<any>(url).pipe(
            map((response) => {
                if (response.content.length > 0) {
                    response.content.forEach((user) => {
                        user.fullName = user.name + ' ' + user.surname;
                    });
                }
                return response;
            })
        );
    }

    getCountryCodes(): Observable<any> {
        return this.httpClient.get<any>(this.apiUrl + '/country-codes');
    }

    getCompanies(): Observable<any> {
        return this.httpClient.get<any>(`${this.apiUrl}/subsidiary-companies/active`);
    }

    getWorkplaces(): Observable<any> {
        return this.httpClient.get<any>(`${this.apiUrl}/headquarters/active`);
    }

    getDepartments(): Observable<any> {
        return this.httpClient.get<any>(`${this.apiUrl}/departments/active`);
    }

    getTeams(): Observable<any> {
        return this.httpClient.get<any>(`${this.apiUrl}/timeoff/teams/active`);
    }

    updatePhoneNumber(userId, countryCode, phoneNumber): Observable<any> {
        return this.httpClient.put<any>(`${this.apiUrl}/users/${userId}/phone`, { code: countryCode, number: phoneNumber });
    }

    sendActivationEmail(id: number) {
        return this.httpClient.get<any>(`${this.apiUrl}/users/${id}/resend-activation`);
    }

    getUsersRolesOptions() {
        return [
            { id: Constants.USER_ROLES.admin, nameKey: 'model.users.roles.admin.name' },
            { id: Constants.USER_ROLES.publisher, nameKey: 'model.users.roles.publisher.shortName' },
            { id: Constants.USER_ROLES.manager, nameKey: 'model.users.roles.manager.shortName' },
            { id: Constants.USER_ROLES.employeeManager, nameKey: 'model.users.roles.employeeManager.name' },
            { id: Constants.USER_ROLES.humanResources, nameKey: 'model.users.roles.humanResources.shortName' },
            { id: Constants.USER_ROLES.documentsManager, nameKey: 'model.users.roles.documentsManager.shortName' },
            { id: Constants.USER_ROLES.ftrayManager, nameKey: 'model.users.roles.ftrayManager.shortName' },
            { id: Constants.USER_ROLES.chatroomManager, nameKey: 'model.users.roles.chatroomManager.name' }
        ];
    }

    getUsersStatesOptions() {
        return [
            {
                value: this.STATE_FILTER.AVAILABLES,
                text: 'users.titleactive',
                icon: 'icon-filter-lines black-45'
            },
            {
                value: this.STATE_FILTER.ARCHIVED,
                text: 'users.titleinactive',
                icon: 'icon-archive black-45'
            },
            {
                value: this.STATE_FILTER.ACTIVES,
                text: 'global.userstates.stateFilters.actives',
                icon: 'icon-ok-fill approved-color-soft'
            },
            {
                value: this.STATE_FILTER.PENDING_ACTIVATION,
                text: 'global.userstates.stateFilters.pendingactivation',
                icon: 'icon-state-pending-activation black-45'
            },
            {
                value: this.STATE_FILTER.ACTIVES_PENDING_ACCESS,
                text: 'global.userstates.stateFilters.activesPendingAccess',
                icon: 'icon-state-wating-access black-45'
            },
            {
                value: this.STATE_FILTER.LOCKED,
                text: 'global.userstates.stateFilters.blocked',
                icon: 'icon-state-locked advice-color'
            }
        ];
    }
}
