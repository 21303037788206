<section class="main-container">
    <header class="main-container__head">
        <div class="flex align-items-center justify-space-between margin-top-20 margin-bottom-40">
            <div class="flex direction-column">
                <ul class="breadcrumb">
                    <li>
                        <a href="/documents" [translate]="'global.navbar.documents'"></a>
                    </li>
                </ul>
                <h2 [translate]="'documents.history.title'"></h2>
            </div>
        </div>
    </header>
    <section class="main-container__content margin-bottom-32">
        <div class="main-container--full-width">
            @if (documentsHistoryData?.data.length > 0) {
                <kendo-grid
                    #documentsHistoryGrid
                    id="kendo-grid"
                    class="master-grid user-select-none"
                    [data]="documentsHistoryData"
                    [sortable]="true"
                    [sort]="gridSort"
                    (sortChange)="onSortChange($event)"
                    [pageable]="gridPagerSettings"
                    (pageChange)="onPageChange($event)"
                    [skip]="gridPagingOptions.skip"
                    [pageSize]="gridPagingOptions.size"
                    [navigable]="true"
                    [resizable]="false"
                    [selectable]="false"
                    (cellClick)="onCellClick($event)"
                    (dblclick)="onDoubleClick()">
                    <kendo-grid-column field="state" title="" class="padding-0 relative" [width]="6" [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="submission-state" [ngClass]="getSubmissionDocumentsClass(dataItem.state)"></div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="transactionKind" title="" [width]="64" [sortable]="false">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <span class="black-60 icon icon-32" [ngClass]="getDocumentKindClass(dataItem.kind)"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="transactionDate" [title]="'documents.history.date' | translate" [width]="176">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span
                                class="black-60"
                                [style]="{'white-space':'nowrap'}"
                                [textContent]="dataItem.transactionDate | date: 'dd/MM/yyyy HH:mm'"></span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="successfulEntries" class="text-center" [width]="100">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="block text-center width-100-percent" [translate]="'documents.history.successfulEntries'"></span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            @if (dataItem.successfulEntries > 0) {
                                <span class="font-medium black-60">{{ dataItem.successfulEntries }}</span>
                            }
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="errorEntries" class="text-center" [width]="120">
                        <ng-template kendoGridHeaderTemplate>
                            <span class="block text-center width-100-percent" [translate]="'documents.history.errorEntries'"></span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            @if (dataItem.errorEntries > 0) {
                                <span
                                    class="font-medium counter-error-files"
                                    [ngClass]="getErrorEntriesClass(dataItem.state)"
                                    [textContent]="dataItem.errorEntries"></span>
                            }
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="user" [title]="'documents.history.user' | translate">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <avatar
                                    [name]="dataItem.user.name"
                                    [surname]="dataItem.user.surname"
                                    [small]="true"
                                    [thumbnails]="{thumbnail_M: dataItem.user.avatarThumbnailMUrl || dataItem.user.avatarUrl, thumbnail_S: dataItem.user.avatarThumbnailSUrl || dataItem.user.avatarThumbnailMUrl}"></avatar>
                                <span class="truncate margin-left-8 black-60" [textContent]="dataItem.user.fullName"></span>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-command-column title="" width="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex justify-end td-on-hover" kendoTooltip filter="button.k-grid-show-button" offset="0">
                                <button
                                    type="button"
                                    class="k-button k-grid-show-button"
                                    [title]="'documents.history.showDetailTooltip' | translate"
                                    (click)="redirectToDetailView(dataItem.id)">
                                    <span class="icon icon-eye-opened"></span>
                                </button>
                            </div>
                        </ng-template>
                    </kendo-grid-command-column>
                    <kendo-grid-messages
                        [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                        [pagerOf]="'global.lists.pagerOf' | translate"
                        [pagerItems]="'global.lists.pagerItems' | translate"></kendo-grid-messages>
                    <!-- Custom Pager -->
                    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                        <article class="dui-grid-pager">
                            <kendo-pager-info></kendo-pager-info>
                            @if (documentsHistoryData.total > 10) {
                                <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                </article>
                                <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                            }
                        </article>
                    </ng-template>
                </kendo-grid>
            }

            @if (documentsHistoryData.total === 0 && !isSyncing) {
                <no-results [imageTitle]="'documents.history.noResultsTitle'" [description]="'documents.history.noResultsText'"></no-results>
            }
        </div>
    </section>
</section>
