<div class="main-container">
    <div class="main-container__header" style="min-width: 891px;">
        <div class="flex direction-column" spellcheck="false">
            <h2 [translate]="'documents.title'"></h2>
        </div>
        <div class="flex align-items-center" kendoTooltip filter="#history-button" [tooltipTemplate]="tooltipActionBtn" position="top">
            <!-- <div *ngIf="selectedTab === DOCUMENTS_TABS.ALL_DOCUMENTS"> -->
            <loading-button
                [classes]="'button--secondary button--small margin-right-10'"
                [iconClass]="'icon-add'"
                (action)="navigateTo('/import-documents')"
                [text]="'documents.sendDocuments'"
                [isValid]="true">
            </loading-button>
            <!-- </div> -->
            <!-- <div *ngIf="selectedTab === DOCUMENTS_TABS.REMOTE_SIGNATURE && !isSignatureTabHidden"> -->
            <loading-button
                [classes]="'button--secondary button--small margin-right-10'"
                [iconClass]="'icon-add'"
                (action)="navigateTo('/remote-signature/create')"
                [text]="'global.navbar.signature'"
                [isValid]="true">
            </loading-button>
            <!-- </div> -->
            <loading-button
                id="history-button"
                [classes]="'button button--small button--only-icon'"
                [iconClass]="'icon-18 icon-history'"
                (action)="navigateTo('/import-documents/history')"
                [isValid]="true">
            </loading-button>
            <ng-template #tooltipActionBtn>
                <div class="flex" style="z-index: 2000 !important; min-width: 130px; flex-wrap: nowrap;">
                    <span style="width: 100%;" [translate]="'documents.history.title'"></span>
                </div>
            </ng-template>
        </div>
    </div>
    @if (!isSignatureTabHidden) {
        <div class="tabs-language-container" style="min-width: 891px;">
            <div class="tab-nav tab-nav--inline" style="width:100%">
                <!-- All documents tab -->
                <span
                    id="company-tab"
                    class="tab-nav__item"
                    [ngClass]="{'tab-nav__item--selected': selectedTab === DOCUMENTS_TABS.ALL_DOCUMENTS}"
                    [translate]="'documents.allDocuments'"
                    (click)="loadTabContent(DOCUMENTS_TABS.ALL_DOCUMENTS)">
                </span>
                <!-- Remote signature tab -->
                <span
                    id="profile-tab"
                    class="tab-nav__item"
                    [ngClass]="{'tab-nav__item--selected': selectedTab === DOCUMENTS_TABS.REMOTE_SIGNATURE}"
                    [translate]="'documents.remoteSignatureShipments'"
                    (click)="loadTabContent(DOCUMENTS_TABS.REMOTE_SIGNATURE)">
                </span>
            </div>
        </div>
    }
    @if (selectedTab === DOCUMENTS_TABS.ALL_DOCUMENTS) {
        <div style="min-width: 891px;">
            <documents-list></documents-list>
        </div>
    }
    @if (selectedTab === DOCUMENTS_TABS.REMOTE_SIGNATURE && !isSignatureTabHidden) {
        <div style="min-width: 891px;">
            <remote-signature></remote-signature>
        </div>
    }
</div>
