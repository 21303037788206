<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="'timeTracking.newSchedule' | translate"></h3>
</div>

<div class="modal-body">
    <!-- Schedule name -->
    <div class="group-form group-form--label-150">
        <div class="group-form__label">
            <span class="group-form__label__text" [textContent]="'timeTracking.schedules.modal.name' | translate"></span>
        </div>
        <div class="group-form__data">
            <input
                #scheduleName
                id="scheduleName"
                name="scheduleName"
                data-cy="schedule-name"
                type="text"
                class="input"
                [ngClass]="{'input--error': isNameDuplicated}"
                maxlength="64"
                [(ngModel)]="scheduleForm.name"
                (ngModelChange)="onChange()"
                (keyup)="isNameDuplicated = false"
                (keyup.enter)="onEnterPressed()"
                [placeholder]="'timeTracking.schedules.modal.namePlaceholder' | translate" />
        </div>
    </div>
</div>

<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'schedule-create'"
        [classes]="'button--secondary margin-right-10'"
        [text]="'timeTracking.schedules.modal.createSchedule'"
        (action)="onAccept()"
        [isValid]="isFormValid">
    </loading-button>
    <button id="schedule-cancel" data-cy="schedule-cancel" type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>
