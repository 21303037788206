import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { ChatroomAdminsModalComponent } from '../chatroom/chatroom-admins-modal.component';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { SegmentsService } from '../segments/segments.service';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
    selector: 'segment',
    templateUrl: './segment.component.html'
})
export class SegmentComponent implements OnInit {
    private _route: any;
    private segmentListUrl: string = '/settings/users-groups';
    private segmentId: number;
    private lastSegmentName: string;

    public segmentForm: any = {};
    public segmentUsersDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };
    public gridPagingOps: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: 'user.name,asc'
    };
    public gridFilteringOps: any = {
        name: ''
    };
    public gridSort: SortDescriptor[];
    public totalPages: number = 0;

    public dataSkeleton = [{}, {}, {}];
    public isSyncing: boolean = false;

    @ViewChild('segmentName') segmentNameRef: ElementRef;
    @ViewChild('segmentDescription') segmentDescRef: ElementRef;
    @ViewChild('segmentUsersGrid') segmentUsersGrid: GridComponent;

    constructor(
        private location: Location,
        private upgrade: UpgradeModule,
        private segmentsService: SegmentsService,
        private translate: TranslateService,
        public dialogService: DialogService,
        public formUtilsService: FormUtilsService,
        private Dialog: MatDialog,
        private notificationService: DuiNotificationsService
    ) {
        this._route = this.upgrade.$injector.get('$route');
        this.segmentId = this._route.current.params.id;
    }

    ngOnInit() {
        this.isSyncing = true;

        this.segmentsService.findOne(this.segmentId).subscribe(
            (response: any) => {
                this.segmentForm = response;
                this.lastSegmentName = response.name;
                this.loadSegmentUsersGridData(this.gridPagingOps, this.gridFilteringOps);
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    private loadSegmentUsersGridData(pagingOptions: any, filterOptions: any): void {
        this.isSyncing = true;

        this.segmentsService.findAllUsersFromSegment(this.segmentId, pagingOptions, filterOptions).subscribe(
            (response) => {
                this.segmentUsersDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    private checkSegmentNameAndSave(newName: string): void {
        if (!newName) {
            this.segmentNameRef.nativeElement.textContent = this.segmentForm.name;
            return;
        } else if (newName !== this.segmentForm.name) {
            this.segmentForm.name = this.segmentNameRef.nativeElement.textContent;
            this.segmentFormSave();
        }
    }

    private checkSegmentDescriptionAndSave(newDescription: string): void {
        if (newDescription !== this.segmentForm.description) {
            this.segmentForm.description = newDescription;
            this.segmentFormSave();
        }
    }

    private segmentFormSave(): void {
        this.segmentsService.update(this.segmentId, this.segmentForm).subscribe(
            (response: any) => {
                this.segmentForm = response;
                this.lastSegmentName = response.name;

                this.notificationService.showSuccessNotification();
            },
            (onError: any) => {
                if (onError.status === 422 && onError.error.localizedError) {
                    this.segmentNameRef.nativeElement.focus();
                    // Reset segment name to last name
                    this.segmentForm.name = this.lastSegmentName;
                    this.segmentNameRef.nativeElement.textContent = this.lastSegmentName;
                    // Select the name
                    let range = document.createRange();
                    range.selectNodeContents(this.segmentNameRef.nativeElement);
                    let sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);
                }
            }
        );
    }

    onSegmentNameInputEvent(event: any) {
        if (event.type === 'keydown') {
            event.preventDefault();
            if (event.code === 'Escape') {
                this.segmentNameRef.nativeElement.textContent = this.segmentForm.name;
            }
            this.segmentNameRef.nativeElement.blur();
        }
        this.checkSegmentNameAndSave(this.segmentNameRef.nativeElement.textContent);
    }

    onSegmentNameInputPaste(event: any): void {
        event.preventDefault();

        var selectedText = window.getSelection().toString();
        if (selectedText) {
            this.segmentNameRef.nativeElement.textContent = this.segmentNameRef.nativeElement.textContent.replace(
                selectedText,
                event.clipboardData.getData('text')
            );
        } else {
            this.segmentNameRef.nativeElement.textContent += event.clipboardData.getData('text');
        }
        this.checkSegmentNameAndSave(this.segmentNameRef.nativeElement.textContent);
    }

    onSegmentDescriptionInputEvent(event: any) {
        if (event.type === 'keydown') {
            event.preventDefault();
            if (event.code === 'Escape') {
                this.segmentDescRef.nativeElement.textContent = this.segmentForm.description;
            }
            this.segmentDescRef.nativeElement.blur();
        }
        this.checkSegmentDescriptionAndSave(this.segmentDescRef.nativeElement.textContent);
    }

    onSegmentDescriptionInputPaste(event: any): void {
        event.preventDefault();

        var selectedText = window.getSelection().toString();
        if (selectedText) {
            this.segmentDescRef.nativeElement.textContent = this.segmentDescRef.nativeElement.textContent.replace(
                selectedText,
                event.clipboardData.getData('text')
            );
        } else {
            this.segmentDescRef.nativeElement.textContent += event.clipboardData.getData('text');
        }
        this.checkSegmentDescriptionAndSave(this.segmentDescRef.nativeElement.textContent);
    }

    navigateTo(url: string): void {
        if (url == 'back') {
            this.location.back();
        } else {
            this.location.go(url);
        }
        // this.location.go(url);
    }

    archiveOrActivateSegment(): void {
        const dialogValues: DialogValueOptions = {
            title: this.segmentForm.active ? 'segments.modal.archive.title' : 'segments.modal.activate.title',
            message: this.segmentForm.active ? 'segments.modal.archive.message' : 'segments.modal.activate.message',
            messageParam: { segmentName: this.segmentForm.name },
            acceptButtonText: this.segmentForm.active ? 'global.form.archive' : 'global.form.activate'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                let serviceToBeenCalled = this.segmentForm.active ? 'archive' : 'activate';

                this.segmentsService[serviceToBeenCalled](this.segmentId).subscribe(
                    (onSuccess) => {
                        // Go back to segments list
                        this.navigateTo(this.segmentListUrl);

                        const message = this.translate.instant(
                            this.segmentForm.active ? 'segments.notification.archive.message' : 'segments.notification.activate.message',
                            { segmentName: this.segmentForm.name }
                        );
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => {}
                );
            } else {
                this.formUtilsService.finishSubmitAction();
            }
        });
    }

    deleteSegment(): void {
        const dialogValues: DialogValueOptions = {
            title: 'segments.modal.delete.title',
            message: 'segments.modal.delete.message',
            messageParam: { segmentName: this.segmentForm.name }
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.segmentsService.delete(this.segmentId).subscribe(
                    (onSuccess) => {
                        // Go back to segments list
                        this.navigateTo(this.segmentListUrl);

                        const message = this.translate.instant('segments.notification.delete.message', { segmentName: this.segmentForm.name });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => {}
                );
            } else {
                this.formUtilsService.finishSubmitAction();
            }
        });
    }

    manageSegmentUsers(): void {
        this.segmentsService
            .findAllUsersFromSegment(
                this.segmentId,
                {
                    page: 0,
                    size: 5000,
                    skip: 0,
                    sort: 'user.name,asc'
                },
                this.gridFilteringOps
            )
            .subscribe((response) => {
                const dialogSettings = new MatDialogConfig();
                dialogSettings.data = {
                    title: this.translate.instant('segments.segmentedUsers.title'),
                    users: response.content
                };
                dialogSettings.width = '65vw';
                dialogSettings.position = { top: '4vh' };
                dialogSettings.panelClass = ['animated', 'slideInDown'];
                this.manageSegmentUsersData(dialogSettings);
            });
    }

    manageSegmentUsersData(settings): void {
        this.Dialog.open(ChatroomAdminsModalComponent, settings)
            .afterClosed()
            .subscribe((result) => {
                this.formUtilsService.finishSubmitAction();

                if (result?.arePendingChanges && result?.addedUsers) {
                    this.isSyncing = true;
                    this.segmentsService.updateSegmentUsers(this.segmentId, result.addedUsers).subscribe(
                        (onSuccess) => {
                            this.loadSegmentUsersGridData(this.gridPagingOps, this.gridFilteringOps);

                            this.notificationService.showSuccessNotification();
                        },
                        (onError) => {
                            this.isSyncing = false;
                        }
                    );
                }
            });
    }

    deleteUserFromSegment(user: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'segments.modal.deleteSegmentedUser.title',
            message: 'segments.modal.deleteSegmentedUser.message',
            messageParam: { name: user.name, surname: user.surname }
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;
                this.segmentsService.deleteUserFromSegment(this.segmentId, user.id).subscribe(
                    (response) => {
                        this.loadSegmentUsersGridData(this.gridPagingOps, this.gridFilteringOps);

                        const message = this.translate.instant('segments.modal.deleteSegmentedUser.success', { name: user.name, surname: user.surname });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError: any) => {}
                );
            }
        });
    }

    listItemsSearch(): void {
        if (this.gridFilteringOps.name.length >= 3 || this.gridFilteringOps.name.length === 0) {
            this.loadSegmentUsersGridData(this.gridPagingOps, this.gridFilteringOps);
        }
    }

    clearSearchInput(): void {
        this.gridFilteringOps.name = '';
        this.loadSegmentUsersGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }

    public onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.gridPagingOps.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : 'user.name,asc';
        this.loadSegmentUsersGridData(this.gridPagingOps, this.gridFilteringOps);
    }

    public onPageChange(event: PageChangeEvent): void {
        this.gridPagingOps.page = event.skip / this.gridPagingOps.size;
        this.gridPagingOps.skip = event.skip;
        this.gridPagingOps.size = event.take;

        this.loadSegmentUsersGridData(this.gridPagingOps, this.gridFilteringOps);
    }
}
