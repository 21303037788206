<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="data.title"></h3>
</div>
<div class="modal-body padding-24">
    <!-- Date range -->
    <div class="group-form group-form--label-150 margin-bottom-8">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'users.holidays.export.modal.period'"></span>
        </div>
        <div class="group-form__data">
            <span [translate]="'timeTracking.list.modal.downloadRange'" [translateParams]="{start: startDate, end: endDate}"></span>
        </div>
    </div>
    <!-- Company -->
    <div class="group-form group-form--label-150 margin-bottom-8">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'users.company'"></span>
        </div>
        <div class="group-form__data">
            @if (!!data.filter.subsidiaryCompany?.id) {
                <span [textContent]="data.filter.subsidiaryCompany?.name"></span>
            }
            @if (!data.filter.subsidiaryCompany?.id) {
                <span [translate]="'timeoff.requests.kindFilters.all'"></span>
            }
        </div>
    </div>
    <!-- Headquarter -->
    <div class="group-form group-form--label-150 margin-bottom-4">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'workplaces.grid.searchPlaceholder'"></span>
        </div>
        <div class="group-form__data">
            @if (!!data.filter.headquarter?.id) {
                <span [textContent]="data.filter.headquarter?.name"></span>
            }
            @if (!data.filter.headquarter?.id) {
                <span [translate]="'global.messages.stateFilters.all'"></span>
            }
        </div>
    </div>
    <!-- Department -->
    <div class="group-form group-form--label-150 margin-bottom-4">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'users.department'"></span>
        </div>
        <div class="group-form__data">
            @if (!!data.filter.department?.id) {
                <span [textContent]="data.filter.department?.name"></span>
            }
            @if (!data.filter.department?.id) {
                <span [translate]="'global.messages.stateFilters.all'"></span>
            }
        </div>
    </div>
    <!-- Team -->
    <div class="group-form group-form--label-150 margin-bottom-24">
        <div class="group-form__label">
            <span class="group-form__label__text" [translate]="'users.team'"></span>
        </div>
        <div class="group-form__data">
            @if (!!data.filter.team?.id) {
                <span [textContent]="data.filter.team?.name"></span>
            }
            @if (!data.filter.team?.id) {
                <span [translate]="'global.messages.stateFilters.all'"></span>
            }
        </div>
    </div>
    <!-- Download detail records -->
    <div class="flex direction-column padding-top-24" style="border-top: 1px solid #e5e5e5">
        <span class="bold" [translate]="'feedbackTray.groupTitle.options'"></span>

        <label class="checkbox__container padding-top-8">
            <span class="checkbox icon" [ngClass]="includeDetail ? 'checkbox--on icon-checkbox-label-active': 'icon-checkbox-label'"></span>
            <div class="flex direction-column">
                <span class="checkbox__label checkbox__label" [translate]="'timeTracking.list.modal.includeDetail'"></span>
            </div>
            <input class="checkbox__input" type="checkbox" [(ngModel)]="includeDetail" />
        </label>
    </div>
</div>
<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'init-download'"
        [classes]="'button--secondary margin-right-10'"
        [text]="'global.modal.accept'"
        (action)="onAccept()"
        [isValid]="true">
    </loading-button>
    <button id="cancel-download" type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>
