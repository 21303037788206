import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { UpgradeModule } from '@angular/upgrade/static';
import { Constants } from '../../constants';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeoffSchemaCreateModalComponent } from '../timeoff-schema-create/timeoff-schema-create-modal.component';

@Component({
    selector: 'timeoff-settings',
    templateUrl: './timeoff-settings.component.html'
})
export class TimeoffSettingsComponent implements OnInit {
    public TIMEOFF_SETTINGS_TABS: any;
    public selectedTab: number;
    private _route: any;
    private schemaUpdateUrl: string = 'settings/timeoff/scheme/general/update/';

    constructor(
        private upgrade: UpgradeModule,
        private titleService: Title,
        private translate: TranslateService,
        private dialog: MatDialog,
        private location: Location
    ) {
        this.TIMEOFF_SETTINGS_TABS = Constants.TIMEOFF_SETTINGS_TABS;
        this._route = this.upgrade.$injector.get('$route');
        this.selectedTab = this._route.current.locals.selectedTab;
    }

    ngOnInit() {}

    // PUBLIC METHODS

    loadTabContent(tab: number): void {
        if (this.selectedTab !== tab) {
            this.selectedTab = tab;
            if (tab === this.TIMEOFF_SETTINGS_TABS.SCHEMAS) {
                this.titleService.setTitle(this.translate.instant('timeoffSettings.tabs.schemes'));
                this._route.updateParams({ tabName: 'schemes' });
            } else if (tab === this.TIMEOFF_SETTINGS_TABS.GLOBAL) {
                this.titleService.setTitle(this.translate.instant('timeoff.tabs.global'));
                this._route.updateParams({ tabName: 'global' });
            }
        }
    }

    openNewSchemaModal(): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(TimeoffSchemaCreateModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    this.location.go(this.schemaUpdateUrl + result.newSchema.timeOffSchema.id);
                }
            });
    }
}
