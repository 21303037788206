import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'publication-related-link',
    templateUrl: './related-link.component.html'
})
export class RelatedLinkComponent implements OnInit, OnChanges {
    public isRelatedLinkEnabled = false;
    public testButtonTooltip: string;

    @Input() selectedLanguage: string;
    @Input() message: any;
    @Input() isValidUrl: boolean;
    @Output() inputChanged: EventEmitter<any> = new EventEmitter();
    @Output() openLink: EventEmitter<any> = new EventEmitter();

    constructor(
        private formUtilsService: FormUtilsService,
        private translate: TranslateService
    ) {
        this.translate = translate;
    }

    ngOnInit(): void {
        this.testButtonTooltip = this.translate.instant('message.urlTest');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.message && changes.message.currentValue && changes.message.currentValue.contents[this.selectedLanguage]) {
            this.isRelatedLinkEnabled = !!(
                this.message.contents[this.selectedLanguage].moreInfoLabel || this.message.contents[this.selectedLanguage].externalURL
            );
        }
    }

    modelValueChanged(): void {
        if (this.message.contents[this.selectedLanguage]?.externalURL) {
            this.message.contents[this.selectedLanguage].externalURL = this.message.contents[this.selectedLanguage].externalURL.trim();
        }
        this.inputChanged.emit(null);
    }

    clearInputsValuesForAllLanguages(): void {
        const languages = Object.keys(this.message.contents);
        languages.forEach((language) => {
            this.message.contents[language].moreInfoLabel = null;
            this.message.contents[language].externalURL = null;
        });
    }

    setCheckboxValue(value: boolean): void {
        this.formUtilsService.confirmCheckboxDeactivation('relatedLinkEnabled', value);
        this.modelValueChanged();
    }

    isRelatedLinkNotEmptyInOtherLanguages(): boolean {
        const languages = Object.keys(this.message.contents);
        for (let index = 0; index < languages.length; index++) {
            if (
                languages[index] !== this.selectedLanguage &&
                (this.message.contents[languages[index]].moreInfoLabel || this.message.contents[languages[index]].externalURL)
            ) {
                return true;
            }
        }
        return false;
    }

    confirmRelatedLinkCheckboxDeactivation(): void {
        if (window['showConfirmationModal']) {
            const askForConfirmation = Object.keys(this.message.contents).length > 1 && this.isRelatedLinkNotEmptyInOtherLanguages();
            if (askForConfirmation) {
                const confirmationModalParams = {
                    title: 'message.confirmExternalUrlDeletionTitle',
                    message: 'message.confirmExternalUrlDeletionDescription',
                    accept: () => {
                        this.setCheckboxValue(false);
                    },
                    cancel: () => {
                        this.setCheckboxValue(true);
                    }
                };
                window['showConfirmationModal'](confirmationModalParams);
            } else {
                this.setCheckboxValue(false);
            }
        }
    }

    relatedLinkValueChanged(): void {
        this.modelValueChanged();
        if (!this.isRelatedLinkEnabled) {
            this.clearInputsValuesForAllLanguages();
        } else if (!this.message.contents[this.selectedLanguage]) {
            this.message.contents[this.selectedLanguage] = {};
        }
    }

    showUrlLink(): void {
        if (this.message.contents[this.selectedLanguage].externalURL && this.isValidUrl) {
            this.openLink.emit(null);
        }
    }
}
