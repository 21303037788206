import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Constants } from '../../constants';

@Injectable({ providedIn: 'root' })
export class WKAuthService {
    private apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    promptWKLoginForm(clientId: string): void {
        const popupHeight = 700;
        const popupWidth = 550;
        const popupTop = window.screen.height / 2 - popupHeight / 2;
        const popupLeft = window.screen.width / 2 - popupWidth / 2;

        const popupSettings = `width=${popupWidth}, height=${popupHeight}, top=${popupTop > 0 ? popupTop : 0}, left=${popupLeft > 0 ? popupLeft : 0}`;

        const successAuthUrl = encodeURIComponent(`${location.origin}/wkauth-success`);

        const wkExternalLoginUrl = `https://login.wolterskluwercloud.com/auth/core/connect/authorize?client_id=${clientId}&response_type=code+id_token&redirect_uri=${successAuthUrl}&scope=WK.ES.A3EquipoContex+IDInfo+openid+offline_access&state=state&nonce=0925b077-d730-41c9-a550-39b8ad5bbd28`;

        window.open(wkExternalLoginUrl, 'newwindow', popupSettings);
    }

    getA3Configuration(): Observable<any> {
        const userSession = window['getUserSession']();

        if (!userSession.companyId) {
            return throwError('No company ID found');
        }

        return this.httpClient.get<any>(`${this.apiUrl}/companies/${userSession.companyId}/configuration/a3config`);
    }

    acceptA3ConfigurationTerms() {
        const userSession = window['getUserSession']();

        if (!userSession.companyId) {
            return throwError('No company ID found');
        }

        return this.httpClient.put(`${this.apiUrl}/companies/${userSession.companyId}/configuration/a3config/acceptTerms`, null);
    }
}
