<div class="main-container__content">
    <div class="main-container--small-width margin-top-10">
        <!-- Chatroom identification -->
        <p class="bold margin-bottom-20" [textContent]="'chatrooms.groupTitle.identification' | translate"></p>
        <!-- name -->
        <div class="group-form group-form--label-220">
            <div class="group-form__label">
                <span class="group-form__label__text margin-right-10" [textContent]="'chatrooms.chatName' | translate"></span>
            </div>
            <div class="group-form__data direction-column align-items-start">
                <input
                    id="chat-name"
                    class="input"
                    [(ngModel)]="chatroomForm.name"
                    (ngModelChange)="onChangeChatName()"
                    maxlength="64"
                    [ngClass]="{'input--error': isChatNameDuplicated}" />
                @if (isChatNameDuplicated) {
                    <span class="margin-top-10 danger-color" [textContent]="'chatrooms.duplicatedChatName' | translate"></span>
                }
            </div>
        </div>
        <!-- description -->
        <div class="group-form group-form--label-220">
            <div class="group-form__label">
                <span class="group-form__label__text" [textContent]="'model.segment.description' | translate"></span>
                <span class="group-form__label__text group-form__label__text--optional" [textContent]="'global.form.optional' | translate"></span>
            </div>
            <div class="group-form__data">
                <textarea
                    id="chatroomDescription"
                    class="textarea-autogrow"
                    [(ngModel)]="chatroomForm.description"
                    (ngModelChange)="onChange()"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    [placeholder]="'chatrooms.modal.create.descriptionplaceholder' | translate"
                    maxlength="160">
                </textarea>
            </div>
        </div>
        <!-- image -->
        <div class="group-form group-form--label-220">
            <div class="group-form__label">
                <span class="group-form__label__text" [textContent]="'model.message.image' | translate"></span>
                <span class="group-form__label__text group-form__label__text--optional" [textContent]="'global.form.optional' | translate"></span>
            </div>
            <div class="group-form__data">
                <div class="flex align-items-end width-100-percent">
                    <image-uploader
                        class="width-100-percent"
                        [(image)]="chatroomForm.image"
                        [(imageUrl)]="chatroomForm.avatarUrl"
                        [(croppedImageSrc)]="chatroomForm.croppedImageSrc"
                        [cropOptions]="IMAGE_CROPPER_CHATROOM"
                        [roundedCanvas]="true"
                        [gifsAllowed]="false"
                        [picsAllowed]="true"
                        [uploadAllowed]="false"
                        [insertInEditor]="false"
                        (onChanges)="onChange()">
                    </image-uploader>
                </div>
            </div>
        </div>
        <!-- Chatroom admins -->
        <p class="bold margin-top-40 margin-bottom-20" [textContent]="'chatrooms.groupTitle.chatAdmins' | translate"></p>

        <div class="group-form group-form--label-220">
            <div class="group-form__label" kendoTooltip filter="span.icon-help-fill" offset="10">
                <span class="group-form__label__text margin-right-10" [textContent]="'global.navbar.users' | translate"></span>
                <span class="group-form__label__help icon icon-help-fill" [title]="'chatrooms.update.adminsTooltip' | translate"></span>
            </div>

            <div class="group-form__data group-form__data--column margin-top-2">
                @if (chatroomForm.admins?.length > 0 && chatroomForm.admins?.length < 4) {
                    @for (admin of chatroomForm.admins; track admin) {
                        <div [ngClass]="chatroomForm.admins.length - 1 === $index ? 'margin-bottom-20' : 'margin-bottom-10'">
                            <user-avatar [small]="true" [user]="admin"></user-avatar>
                        </div>
                    }
                }
                @if (chatroomForm.admins?.length > 0 && chatroomForm.admins?.length > 3) {
                    <div class="conflicts-avatar-list margin-right-10 margin-bottom-10" kendoTooltip filter="div.avatar" position="top">
                        @for (admin of chatroomForm.admins | slice:0:4; track admin; let i = $index) {
                            <avatar
                                [name]="admin.name"
                                [surname]="admin.surname"
                                [title]="admin.name + ' ' + admin.surname"
                                [thumbnails]="{thumbnail_M: admin.avatarThumbnailMUrl || admin.avatarUrl, thumbnail_S: admin.avatarThumbnailSUrl || admin.avatarThumbnailMUrl}">
                            </avatar>
                        }
                        @if (chatroomForm.admins?.length > 4) {
                            <span class="avatar conflicts-avatar-list__more">
                                <span class="avatar__label">+{{chatroomForm.admins.length - 4}}</span>
                            </span>
                        }
                    </div>
                }

                <button id="add-admins" class="button button--small" style="padding: 0px 25px" (click)="addAdminsToChat()">
                    <span class="button__label" [textContent]="'global.form.select' | translate"></span>
                </button>
            </div>
        </div>
        <!-- Chatroom Options -->
        <p class="bold margin-top-40 margin-bottom-20" [textContent]="'feedbackTray.groupTitle.options' | translate"></p>
        <!-- chat active -->
        @if (chatroomForm.state !== CHATROOM_STATES.ARCHIVED) {
            <div class="group-form group-form--label-220">
                <div class="group-form__label">
                    <span class="group-form__label__text" [textContent]="'chatrooms.update.activeChat' | translate"></span>
                </div>
                @if (chatroomForm.state !== CHATROOM_STATES.ARCHIVED) {
                    <div class="group-form__data">
                        <label class="toggle__container">
                            <div class="toggle" [ngClass]="{'toggle--active': chatroomForm.state === CHATROOM_STATES.ACTIVE}">
                                <span class="toggle__tick icon nucleo icon-accept-bold"></span>
                                <span class="toggle__indicator"></span>
                            </div>
                            <input
                                id="activeChat"
                                class="toggle__input"
                                type="checkbox"
                                (value)="chatroomForm.state"
                                (click)="changeActivateState()"
                                (change)="onChange()" />
                        </label>
                    </div>
                }
            </div>
        }
        <!-- private chat -->
        <div class="group-form group-form--label-220">
            <div class="group-form__label">
                <span class="group-form__label__text" [textContent]="'chatrooms.modal.create.privateRoom' | translate"></span>
            </div>
            <div class="group-form__data group-form__data--column padding-top-10">
                <toggle-input id="exclusive-room" [inputId]="'exclusive-room'" (change)="onChange()" [(inputModel)]="chatroomForm.exclusive"> </toggle-input>
                <!-- Participants selection kind for private chatroom -->
                @if (chatroomForm.exclusive) {
                    <span class="margin-top-24 black-60" [translate]="'chatrooms.update.roomSynchroSettings.title'"></span>
                    <section class="radio-button-container radio-button-container--column margin-top-5">
                        <div
                            class="radio-button__item"
                            [ngClass]="{'radio-button__item--selected' : chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL}"
                            (click)="chatroomForm.synchronizationKind = CHATROOM_USERS_SYNCHRO_KIND.MANUAL; onChange()">
                            <span
                                class="radio-button__item__icon icon "
                                [ngClass]="chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <span class="radio-button__item__label" [translate]="'chatrooms.update.roomSynchroSettings.manual'"></span>
                        </div>
                        <span
                            class="margin-left-25 padding-left-2 margin-top-minus-10 text-xs"
                            [ngClass]="chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.MANUAL ? 'black' : 'black45'"
                            [translate]="'chatrooms.update.roomSynchroSettings.manualInfo'">
                        </span>
                        <div
                            class="radio-button__item margin-top-10"
                            [ngClass]="{'radio-button__item--selected' : chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS}"
                            (click)="chatroomForm.synchronizationKind = CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS; onChange()">
                            <span
                                class="radio-button__item__icon icon "
                                [ngClass]="chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS ? 'icon-circle-selected' : 'icon-circle'"></span>
                            <span class="radio-button__item__label" [translate]="'chatrooms.update.roomSynchroSettings.bySegments'"></span>
                        </div>
                        <span
                            class="margin-left-25 padding-left-2 margin-top-minus-10 text-xs"
                            [ngClass]="chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS ? 'black' : 'black45'"
                            [translate]="'chatrooms.update.roomSynchroSettings.bySegmentsInfo'">
                        </span>
                    </section>
                    @if (chatroomForm.synchronizationKind === CHATROOM_USERS_SYNCHRO_KIND.SEGMENTS) {
                        <div class="margin-left-25">
                            <button id="add-segments" class="button button--small margin-top-20" style="padding: 0px 25px" (click)="addSegments()">
                                <span class="button__label" [textContent]="'chatrooms.update.synchroBtnText' | translate"></span>
                            </button>
                            @if (chatroomForm.segments.length > 0) {
                                <div class="margin-top-10">
                                    <div class="flex align-items-center">
                                        <span [translate]="'chatrooms.update.roomSynchroSettings.segmentationKind'"></span>
                                        <span
                                            class="icon icon-24 margin-left-5"
                                            [ngClass]="chatroomForm.segmentationKind === SEGMENTATION_KIND.UNION ? 'icon-union' : 'icon-intersect'"></span>
                                        <span
                                            class="margin-left-5 bold"
                                            [translate]="'global.segmentation.kind'"
                                            [translateParams]="{kind: chatroomForm.segmentationKind}"></span>
                                    </div>
                                    <div class="flex flex-wrap margin-top-10">
                                        @for (segment of chatroomForm.segments; track segment) {
                                            <div class="group-form__data--tags max-width-225">
                                                <span class="max-width-200 truncate" [textContent]="segment.name"></span>
                                                <span class="icon icon-clear-fill-s" (click)="removeSegmentFromChatroom($index)"></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                }
            </div>
        </div>
        <!-- Action buttons -->
        <div class="group-form group-form--label-220 margin-top-24 margin-bottom-40">
            <div class="group-form__label">
                <!-- <loading-button [buttonId]="'chatrooms-back-button'"
          [classes]="'button button--only-icon'" [iconClass]="'icon-back'" (action)="goBack()"
          [isValid]="true">
        </loading-button> -->
            </div>
            <div class="group-form__data group-form__data--inline">
                <feedback-action-button
                    [buttonId]="'save-btn'"
                    [classes]="'button--secondary'"
                    [text]="'global.form.save'"
                    [tooltipText]="'disabledTooltipText'"
                    (action)="chatroomFormSave()"
                    [isValid]="isFormDataValid && isFormDataChanged">
                </feedback-action-button>
                <feedback-action-button
                    [buttonId]="'cancel-btn'"
                    [classes]="'button--ghost margin-left-10'"
                    [text]="'global.form.cancel'"
                    (action)="goBack()"
                    [isValid]="true">
                </feedback-action-button>
            </div>
        </div>
    </div>
</div>
