<label class="toggle__container" [ngClass]="{'toggle__container--disabled': isDisabled, 'toggle__container--active' : inputModel}">
    @if (inputModel !== null) {
        <div class="toggle" [ngClass]="{'toggle--active': inputModel, 'toggle--small': isSmall}">
            <!-- <span class="toggle__text toggle__text--off" [translate]="'global.toggle.off'"></span>
      <span class="toggle__text toggle__text--on" [translate]="'global.toggle.on'"></span> -->
            @if (!isSmall) {
                <span class="toggle__tick icon nucleo icon-accept-bold"></span>
            }
            <span class="toggle__indicator"></span>
        </div>
    }
    @if (label) {
        <span class="toggle__label">{{label}}</span>
    }

    <input
        class="toggle__input"
        [id]="inputId"
        [name]="inputId"
        type="checkbox"
        [(ngModel)]="inputModel"
        (ngModelChange)="modelValueChanged()"
        [disabled]="isDisabled" />
</label>
