<div class="relative">
    <div class="mentioned-popover" [class.mentioned-popover--active]="isActive" [ngStyle]="{'top': popoverTop, 'left': popoverLeft}">
        @if (!userData.id && isActive) {
            <div class="mentioned-popover__skeleton">
                <div class="flex align-items-center">
                    <kendo-skeleton shape="circle" animation="pulse" [width]="28" [height]="28"></kendo-skeleton>
                    <kendo-skeleton
                        shape="text"
                        animation="pulse"
                        [width]="150"
                        [height]="20"
                        style="border-radius: 4px"
                        class="margin-left-10"></kendo-skeleton>
                </div>
                <kendo-skeleton shape="text" animation="pulse" [width]="120" [height]="12" style="border-radius: 4px" class="margin-left-40"></kendo-skeleton>
            </div>
        }
        @if (userData?.id && isActive) {
            <div class="flex direction-column">
                <div class="mentioned-popover__header">
                    <user-avatar [small]="false" [user]="userData" [showUsername]="true"></user-avatar>
                </div>
                <div class="mentioned-popover__footer">
                    <button class="button button--small" (click)="goMentionedProfile()">
                        <span class="button__label" [translate]="'users.linkToUser'"></span>
                    </button>
                </div>
            </div>
        }
    </div>
    <span class="mentioned" (click)="showMentionedInfo(this)">&#64;<ng-content></ng-content></span>
</div>
