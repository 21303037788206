import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { FileSaverService } from 'ngx-filesaver';
import { TranslateService } from '@ngx-translate/core';

import { Constants } from '../../constants';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { UtilsService } from '../../shared/services/utils.service';
import { CategoriesService } from '../../shared/services/categories.service';

@Component({
    selector: 'categories',
    templateUrl: 'categories.component.html',
    styleUrls: ['_categories.component.scss']
})
export class CategoriesComponent implements OnInit {
    public userSession = window['getUserSession']();
    public defaultLanguage = this.userSession.companyDefaultLanguage;

    public openDatePopover: boolean = false;

    public isSyncing: boolean = false;

    public categoriesDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };

    public dataPagingOpts: any = {
        page: 0,
        size: Constants.PAGE_SIZE,
        skip: 0,
        sort: ['lastModifiedDate,desc', 'publicationGroupingContents.name,asc']
    };

    public dataFilterOpts: any = {
        active: true,
        name: ''
    };
    public totalPages: number = 0;
    public dataSkeleton = [{}, {}, {}];
    public gridSort: SortDescriptor[];

    private clickedRowItem: any = '';

    @ViewChild('categoriesGrid') categoriesGrid: GridComponent;

    constructor(
        private location: Location,
        private fileSaverService: FileSaverService,
        private notificationService: DuiNotificationsService,
        private dialogService: DialogService,
        private utilsService: UtilsService,
        private translate: TranslateService,
        private categoriesService: CategoriesService
    ) {}

    ngOnInit() {
        this.getCategoriesGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    private getCategoriesGridData(pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;
        // this.documentsService.findAll(pagingOptions, filterOptions).subscribe(
        this.categoriesService.findAllCategories(pagingOptions, filterOptions).subscribe(
            (response) => {
                this.categoriesDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
            }
        );
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    navigateToEditView(id: string): void {
        this.location.go('/settings/categories/update/' + id);
    }

    downloadCSV(event): void {
        this.categoriesService.exportCategoriesCSV(event.startDate, event.endDate).subscribe((response: any) => {
            let currentDate = new Date().toISOString().split('.')[0];
            currentDate = currentDate.replaceAll(':', '_');
            const fileName = `categories-statistics-${currentDate}.csv`;
            this.fileSaverService.save(response.body, fileName);
            this.openDatePopover = false;
        });
    }

    cancelDownload(event): void {
        this.openDatePopover = false;
    }

    getCategoriesByIsActive(): void {
        this.dataPagingOpts.page = 0;
        this.dataPagingOpts.skip = 0;
        this.getCategoriesGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    listItemsSearch() {
        if (this.dataFilterOpts.name.length >= 3 || this.dataFilterOpts.name.length === 0) {
            this.getCategoriesGridData(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    clearSearchInput() {
        let updateDataGrid = this.dataFilterOpts.name.length >= 3;
        this.dataFilterOpts.name = '';
        if (updateDataGrid) {
            this.listItemsSearch();
        }
    }

    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.dataPagingOpts.sort =
            sort.length > 0 && sort[0].dir !== undefined
                ? sort[0].field + ',' + sort[0].dir
                : ['lastModifiedDate,desc', 'publicationGroupingContents.name,asc'];
        this.getCategoriesGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / this.dataPagingOpts.size;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;
        this.getCategoriesGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
    }

    onDblClick(): void {
        if (this.clickedRowItem.id) {
            this.navigateToEditView(this.clickedRowItem.id);
        }
    }

    isVisible(availableLanguages: string[]): boolean {
        return !availableLanguages.includes(this.defaultLanguage);
    }

    getCategoryTagHexColor(color: any): string {
        return this.utilsService.decimalToHex(color);
    }

    editCategory(item: any) {
        this.navigateToEditView(item.id);
    }

    deleteCategory(item: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'categories.modal.delete.title',
            message: 'categories.modal.delete.message',
            messageParam: { categoryName: item.name },
            acceptButtonText: 'global.form.delete'
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;
                this.categoriesService.deleteCategory(item.id).subscribe(
                    (onSuccess) => {
                        this.getCategoriesGridData(this.dataPagingOpts, this.dataFilterOpts);

                        const message = this.translate.instant('categories.notification.delete.message', { categoryName: item.name });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => this.notificationService.showErrorNotification()
                );
            }
        });
    }

    archiveCategory(item: any) {
        const dialogValues: DialogValueOptions = {
            title: 'categories.modal.archive.title',
            message: 'categories.modal.archive.message',
            messageParam: { categoryName: item.name },
            acceptButtonText: 'global.form.archive'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;
                this.categoriesService.archiveCategory(item.id).subscribe(
                    (onSuccess) => {
                        this.getCategoriesGridData(this.dataPagingOpts, this.dataFilterOpts);

                        const message = this.translate.instant('categories.notification.archive.message', { categoryName: item.name });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => this.notificationService.showErrorNotification()
                );
            }
        });
    }

    activateCategory(item: any) {
        const dialogValues: DialogValueOptions = {
            title: 'categories.modal.activate.title',
            message: 'categories.modal.activate.message',
            messageParam: { categoryName: item.name },
            acceptButtonText: 'global.form.activate'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;
                this.categoriesService.activateCategory(item.id).subscribe(
                    (onSuccess) => {
                        this.getCategoriesGridData(this.dataPagingOpts, this.dataFilterOpts);

                        const message = this.translate.instant('categories.notification.activate.message', { categoryName: item.name });
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => this.notificationService.showErrorNotification()
                );
            }
        });
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }
}
