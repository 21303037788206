!function (e, t) {
  "object" == typeof exports && "object" == typeof module ? module.exports = t(require("string-template-parser"), require("core-js/es7/object")) : "function" == typeof define && define.amd ? define("ngx-translate-parser-plural-select", ["string-template-parser", "core-js/es7/object"], t) : "object" == typeof exports ? exports["ngx-translate-parser-plural-select"] = t(require("string-template-parser"), require("core-js/es7/object")) : e["ngx-translate-parser-plural-select"] = t(e["string-template-parser"], e["core-js/es7/object"]);
}(this, function (e, t) {
  return function (e) {
    function t(n) {
      if (r[n]) return r[n].exports;
      var o = r[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return e[n].call(o.exports, o, o.exports, t), o.l = !0, o.exports;
    }
    var r = {};
    return t.m = e, t.c = r, t.d = function (e, r, n) {
      t.o(e, r) || Object.defineProperty(e, r, {
        configurable: !1,
        enumerable: !0,
        get: n
      });
    }, t.n = function (e) {
      var r = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return t.d(r, "a", r), r;
    }, t.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, t.p = "", t(t.s = 0);
  }([function (e, t, r) {
    e.exports = r(1);
  }, function (e, t, r) {
    "use strict";

    var n = this && this.__extends || function () {
        var e = Object.setPrototypeOf || {
          __proto__: []
        } instanceof Array && function (e, t) {
          e.__proto__ = t;
        } || function (e, t) {
          for (var r in t) t.hasOwnProperty(r) && (e[r] = t[r]);
        };
        return function (t, r) {
          function n() {
            this.constructor = t;
          }
          e(t, r), t.prototype = null === r ? Object.create(r) : (n.prototype = r.prototype, new n());
        };
      }(),
      o = this && this.__assign || Object.assign || function (e) {
        for (var t, r = 1, n = arguments.length; r < n; r++) {
          t = arguments[r];
          for (var o in t) Object.prototype.hasOwnProperty.call(t, o) && (e[o] = t[o]);
        }
        return e;
      };
    Object.defineProperty(t, "__esModule", {
      value: !0
    });
    var i = r(2);
    r(3);
    var u = function (e) {
        return "{" === e.charAt(0);
      },
      a = function (e, t) {
        for (var r = 1, n = 1; n > 0 && r < e.length;) "\\" !== e[r] ? ("{" === e[r] && n++, "}" === e[r] && n--, r++) : r += 2;
        var o = e.substr(0, r);
        return t(r), o;
      },
      s = i.parseStringTemplateGenerator({
        VARIABLE_START: /^{\s*/,
        VARIABLE_END: /^\s*}/,
        PIPE_START: /^\s*,\s*/,
        PIPE_PARAMETER_START: /^\s*,\s*/,
        QUOTED_STRING_IN_PARAMETER_TEST: u,
        QUOTED_STRING_IN_PARAMETER_GET_AND_ADVANCE: a
      }),
      c = i.parseStringTemplateGenerator({
        VARIABLE_START: /^{/,
        VARIABLE_END: /^}/,
        PIPE_START: /^$/,
        PIPE_PARAMETER_START: /^$/,
        QUOTED_STRING_TEST: u,
        QUOTED_STRING_GET_AND_ADVANCE: a,
        QUOTED_STRING_IN_PARAMETER_TEST: u,
        QUOTED_STRING_IN_PARAMETER_GET_AND_ADVANCE: a
      }),
      p = {
        plural: function (e, t, r) {
          t = t.map(function (e) {
            return e.trim();
          }).slice(0, t.length - 1);
          var n = t.reduce(function (e, t, n) {
              return o({}, e, (i = {}, i["=" === t.charAt(0) ? t.slice(1).trim() : t.trim()] = r[n], i));
              var i;
            }, {}),
            i = +e;
          if (n.hasOwnProperty(i.toString())) return n[e];
          if (i === i) {
            var u = {
              0: ["zero"],
              1: ["one"],
              2: ["two", "few"],
              3: ["three", "few"],
              4: ["four", "few"],
              5: ["five", "many"],
              6: ["six", "many"],
              7: ["seven", "many"],
              8: ["eight", "many"],
              9: ["nine", "many"]
            };
            return n.hasOwnProperty(e) ? n[e] : (i >= 10 && i < 20 ? ["many"] : u[i % 10] || ["many"]).concat(i >= 20 ? ["many"] : [], ["other"]).filter(function (e) {
              return n.hasOwnProperty(e);
            }).map(function (e) {
              return n[e];
            })[0] || "";
          }
          return null == n.other ? "" : n.other;
        },
        select: function (e, t, r) {
          t = t.map(function (e) {
            return e.trim();
          }).slice(0, t.length - 1);
          var n = t.reduce(function (e, t, n) {
            return o({}, e, (i = {}, i[t.trim()] = r[n], i));
            var i;
          }, {});
          return null == n[e] ? "" : n[e];
        }
      },
      l = function () {
        function e(e) {
          this.additionalPipes = e, this.pipeEntries = [], this.pipeEntries = Object.entries(o({}, e || {}, p));
        }
        return e.prototype.getPipes = function (e) {
          var t = this;
          return this.pipeEntries.map(function (r) {
            var n = r[0],
              o = r[1];
            return i = {}, i[n] = function (r, n) {
              var i = n[0],
                u = c(i),
                a = u.literals,
                s = u.variables.map(function (r) {
                  return t.interpolate(r.name, e);
                });
              return o(r, a, s);
            }, i;
            var i;
          }).reduce(function (e, t) {
            return o({}, e, t);
          }, {});
        }, e.prototype.interpolate = function (e, t) {
          var r = this;
          return "function" == typeof e ? e(t) : i.evaluateParsedString(s(e), t, this.getPipes(t), function (e) {
            return r.getValue(t, e);
          });
        }, e.prototype.getValue = function (e, t) {
          var r = t && t.split(".") || [];
          t = "";
          do {
            t += r.shift(), null == e || null == e[t] || "object" != typeof e[t] && r.length ? r.length ? t += "." : e = void 0 : (e = e[t], t = "");
          } while (r.length);
          return e;
        }, e;
      }();
    t.TranslateICUCustomParser = l;
    var f = function (e) {
      function t() {
        return e.call(this, {}) || this;
      }
      return n(t, e), t;
    }(l);
    t.TranslateICUParser = f;
  }, function (e, t) {
    e.exports = require("string-template-parser");
  }, function (e, t) {
    e.exports = require("core-js/es7/object");
  }]);
});
