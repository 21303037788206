<div class="main-container">
    <div class="main-container__content" style="min-width: 891px;">
        <div class="main-container--medium-width">
            <div class="flex align-items-center justify-space-between margin-top-20 margin-bottom-40">
                <div class="flex direction-column" spellcheck="false">
                    <ul class="breadcrumb">
                        <li>
                            <a href="/users" [translate]="'users.title'"></a>
                        </li>
                    </ul>
                    <h2 [translate]="'usersImport.title'"></h2>
                </div>
                <div class="flex align-items-center"></div>
            </div>

            <!-- Load users data file to import -->
            <div class="group-form margin-top-20">
                <div class="group-form__label">
                    <span class="section-number margin-right-10" [ngClass]="{'selected': loadCsvStepOneEnabled}">1</span>
                    <span
                        class="group-form__label__text text-md--bold"
                        [translate]="'usersImport.loadUsersFile'"
                        [ngClass]="{'disabled': !loadCsvStepOneEnabled, 'black': loadCsvStepOneEnabled}"></span>
                </div>
            </div>
            @if (loadCsvStepOneEnabled) {
                <div>
                    <div class="group-form margin-left-40">
                        <div class="template-download-box" style="padding-right: 20px">
                            <div>
                                <span class="text" [translate]="'usersImport.boxInfo.text'"></span>
                                <a
                                    href="https://support.dialenga.com/external/knowledge-base/article/22"
                                    class="margin-left-5 strong"
                                    [translate]="'usersImport.boxInfo.helpCenter'"
                                    target="_blank"></a>
                            </div>
                            <div>
                                <a href="{{downloadTemplateUrl}}" class="button button--small" target="_self">
                                    <span class="button__icon icon icon-download"></span>
                                    <span class="button__label" [translate]="'usersImport.boxInfo.btn'"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="group-form margin-left-40 margin-right-20">
                        <div class="notification-box ">
                            <span class="notification-box__text" [translate]="'usersImport.boxInfo.archiveManagementMode'"></span>
                        </div>
                    </div>
                    <div class="group-form group-form--label-220 margin-left-40 margin-right-20" [ngClass]="{'white-cover': isValidatingCsvFile}">
                        <div class="group-form__label">
                            <span class="group-form__label__text" [translate]="'usersImport.csvFile'"></span>
                        </div>
                        <div class="group-form__data">
                            @if (!csvToUpload) {
                                <label for="csvFile" class="button button--small button--load-csv">
                                    <span class="button__label" [translate]="'documents.import.attachFiles'"></span>
                                    <input id="csvFile" type="file" (change)="handleFileInput($event.target.files)" accept=".csv, text/csv" />
                                </label>
                            }
                            @if (csvToUpload) {
                                <div>
                                    <a href="" class="attachment hover-disabled">
                                        <span class="attachment__icon icon icon-attachment"></span>
                                        <span class="attachment__filename" [textContent]="csvToUpload.name"></span>
                                    </a>
                                    <span
                                        class="attachment__icon attachment__icon--remove icon nucleo icon-remove-fill margin-left-5"
                                        (click)="removeCsvFileSelected()"></span>
                                </div>
                            }
                        </div>
                    </div>
                    <div class="group-form group-form--label-220 margin-left-40 margin-right-20" [ngClass]="{'white-cover': isValidatingCsvFile}">
                        <div class="group-form__label">
                            <span class="group-form__label__text" [translate]="'usersImport.archiveManagement'"></span>
                        </div>
                        <div class="group-form__data">
                            <div class="radio-button-container radio-button-container--column margin-top-5">
                                <div
                                    class="radio-button__item"
                                    [ngClass]="{'margin-bottom-0': !userImportForm.allowUsersArchiving}"
                                    [ngClass]="{'radio-button__item--selected' : !userImportForm.allowUsersArchiving}"
                                    (click)="userImportForm.allowUsersArchiving = !userImportForm.allowUsersArchiving">
                                    <span
                                        class="radio-button__item__icon icon "
                                        [ngClass]="!userImportForm.allowUsersArchiving ? 'icon-circle-selected' : 'icon-circle'"></span>
                                    <span class="radio-button__item__label" [translate]="'usersImport.skipFiled'"></span>
                                </div>
                                @if (!userImportForm.allowUsersArchiving) {
                                    <span class="margin-bottom-10 margin-left-25 padding-left-2" [translate]="'usersImport.skipFiledTextInfo'"></span>
                                }
                                <div
                                    class="radio-button__item"
                                    [ngClass]="{'margin-bottom-0': userImportForm.allowUsersArchiving}"
                                    [ngClass]="{'radio-button__item--selected' : userImportForm.allowUsersArchiving}"
                                    (click)="userImportForm.allowUsersArchiving = !userImportForm.allowUsersArchiving">
                                    <span
                                        class="radio-button__item__icon icon "
                                        [ngClass]="userImportForm.allowUsersArchiving ? 'icon-circle-selected' : 'icon-circle'"></span>
                                    <span class="radio-button__item__label" [translate]="'usersImport.processFiled'"></span>
                                </div>
                                @if (userImportForm.allowUsersArchiving) {
                                    <span class="margin-bottom-10 margin-left-25 padding-left-2" [translate]="'usersImport.processFiledTextInfo'"></span>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="group-form group-form--label-220 margin-left-40 margin-right-20">
                        <div class="group-form__label"></div>
                        <div class="group-form__data">
                            <loading-button
                                buttonId="get-import-resume"
                                classes="button--secondary button--icon-to-right icon-right"
                                [iconClass]="nextButtonOptions.icon"
                                [text]="nextButtonOptions.text"
                                (action)="getImportResume()"
                                [isValid]="csvToUpload">
                            </loading-button>
                        </div>
                    </div>
                </div>
            }
            <!-- Resume and information -->
            <div class="group-form margin-top-20 margin-bottom-10">
                <div class="group-form__label">
                    <span class="section-number margin-right-10" [ngClass]="{'selected': loadCsvStepTwoEnabled}">2</span>
                    <span
                        class="group-form__label__text text-md--bold"
                        [translate]="'usersImport.resumeAndConfirmation'"
                        [ngClass]="{'disabled': !loadCsvStepTwoEnabled, 'black': loadCsvStepTwoEnabled}"></span>
                </div>
            </div>
            @if (loadCsvStepTwoEnabled) {
                <div class="group-form">
                    <span class="group-form__label__text margin-left-30 padding-left-5" [innerHTML]="'usersImport.importActionsDetected' | translate"></span>
                    <div class="group-form margin-top-20 width-90-percent justify-content-center">
                        <section class="import-resume-row margin-bottom-20" [ngClass]="{ 'import-resume-row--errors': resumeActions.omittedEmployees > 0 }">
                            <!-- New employees -->
                            <article class="import-resume-box">
                                <span class="import-resume-box__number" [textContent]="resumeActions.newEmployees"></span>
                                <span
                                    class="import-resume-box__text truncate"
                                    [translate]="resumeActions.newEmployees === 1 ? 'users.newuser' : 'usersImport.newUsers'"></span>
                            </article>
                            <!-- Reactivated employees -->
                            <article class="import-resume-box">
                                <span class="import-resume-box__number" [textContent]="resumeActions.reactivatedEmployees"></span>
                                <span class="import-resume-box__text truncate" [translate]="'usersImport.toReactivate'"></span>
                            </article>
                            <!-- Updated employees -->
                            <article class="import-resume-box">
                                <span class="import-resume-box__number" [textContent]="resumeActions.updatedEmployees"></span>
                                <span
                                    class="import-resume-box__text truncate"
                                    [translate]="resumeActions.updatedEmployees === 1 ? 'usersImport.foundUser' : 'usersImport.foundUsers'"></span>
                            </article>
                            <!-- Employees to archive -->
                            <article class="import-resume-box" [ngClass]="{'import-resume-box--disabled': !userImportForm.allowUsersArchiving}">
                                @if (userImportForm.allowUsersArchiving && resumeActions.deactivatedEmployees) {
                                    <div class="flex direction-column justify-space-between align-items-center height-100-percent">
                                        <span class="import-resume-box__number" [textContent]="resumeActions.deactivatedEmployees"></span>
                                        <div class="flex direction-row align-items-center">
                                            <span class="icon icon-16 icon-archive margin-right-5 black-45"></span>
                                            <span class="import-resume-box__text truncate" [translate]="'usersImport.usersToFile'"></span>
                                        </div>
                                    </div>
                                }
                                @if (!userImportForm.allowUsersArchiving || !resumeActions.deactivatedEmployees) {
                                    <div>
                                        <span class="import-resume-box__text--disabled" [translate]="'usersImport.skippedUsersFile'"></span>
                                    </div>
                                }
                            </article>
                            <!-- Employees to omit -->
                            @if (resumeActions.omittedEmployees > 0) {
                                <article class="import-resume-box import-resume-box--error">
                                    <span class="import-resume-box__number" [textContent]="resumeActions.omittedEmployees"></span>
                                    <span class="import-resume-box__text truncate" [translate]="'usersImport.usersWithErrors'"></span>
                                </article>
                            }
                        </section>
                        <div class="flex direction-column width-76-percent">
                            @if (resumeActions.issues && resumeActions.issues.length > 0) {
                                <div
                                    class="notification-box notification-box--warning padding-right-20 min-height-75 direction-row justify-space-between align-items-center">
                                    <div id="label-errors-info" class="flex align-items-baseline">
                                        <span class="icon icon-24 icon-warning warning margin-right-10 align-self-center"></span>
                                        <span
                                            class="notification-box__text margin-right-5"
                                            [translate]="resumeActions.issues.length === 1 ? 'usersImport.errors.errorDetected' : 'usersImport.errors.errorsDetected'"></span>
                                        <span
                                            class="notification-box__text notification-box__text--enhanced margin-right-5"
                                            [textContent]="resumeActions.issues.length"></span>
                                        <span
                                            class="notification-box__text"
                                            [translate]="resumeActions.issues.length === 1 ? 'usersImport.errors.issueInto' : 'usersImport.errors.issuesInto'"></span>
                                    </div>
                                    <section id="cta-buttons-log">
                                        <button class="button button--small secondary-color" type="button" (click)="showErrorsLog()">
                                            <span class="button__icon icon icon-eye-opened"></span>
                                            <span class="button__label" [translate]="'usersImport.showIssues'"></span>
                                        </button>
                                        <button
                                            class="button button--small button--only-icon secondary-color margin-right-20"
                                            type="button"
                                            (click)="downloadErrorsLog()">
                                            <span class="icon icon-download"></span>
                                        </button>
                                    </section>
                                </div>
                            }
                            <div class="notification-box padding-right-20 min-height-75 direction-column justify-content-center">
                                @if (resumeActions.issues && resumeActions.issues.length > 0) {
                                    <span class="notification-box__text margin-bottom-5" [innerHTML]="'usersImport.whenApplyingChanges' | translate"></span>
                                }
                                <span class="notification-box__text" [translate]="'usersImport.willReceiveAnEmail'"></span>
                            </div>
                            <div class="group-form">
                                <span [innerHTML]="'usersImport.sendActivationEmails' | translate"></span>
                                <toggle-input
                                    class="margin-left-10"
                                    [inputId]="'send-activate-email'"
                                    [(inputModel)]="userImportForm.sendActivateEmails"
                                    [isDisabled]="resumeActions.newEmployees == 0">
                                </toggle-input>
                            </div>
                            <div class="flex justify-content-center">
                                <loading-button
                                    [text]="'usersImport.applyChanges'"
                                    class="margin-right-10"
                                    [buttonId]="'send-message'"
                                    [classes]="'button--cta width-100-percent'"
                                    (action)="processUsersImport()"
                                    [isValid]="true">
                                </loading-button>
                                <button class="button button--link margin-left-20" type="button" (click)="discardImport()">
                                    <span class="button__label" [translate]="'usersImport.discardImport'"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
</div>
