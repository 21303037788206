import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from '../../constants';

@Injectable({ providedIn: 'root' })
export class DocumentsHistoryService {
    private apiUrl: string;
    private TRANSACTIONS_API: string;
    private USER_DOCUMENTS_API: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
        this.TRANSACTIONS_API = `${this.apiUrl}/documents/transactions`;
        this.USER_DOCUMENTS_API = `${this.apiUrl}/documents/employees`;
    }

    findAll(options: Options): Observable<any> {
        const { page = 0, size = Constants.PAGE_SIZE, sort = undefined } = options;
        const params = { page, size, sort };
        return this.httpClient.get<any>(this.TRANSACTIONS_API, { params });
    }

    findOne(transactionId: number): Observable<any> {
        return this.httpClient.get<any>(`${this.TRANSACTIONS_API}/${transactionId}`);
    }

    findAllUserDocuments(options: Options, id: number): Observable<any> {
        const { page = 0, size = Constants.PAGE_SIZE, sort = undefined } = options;
        const params = { page, size, sort };
        return this.httpClient.get<any>(`${this.USER_DOCUMENTS_API}/${id}`, { params });
    }

    deleteUserDocument(userId: number, documentId: number): Observable<any> {
        const url = `${this.USER_DOCUMENTS_API}/${userId}/document/${documentId}`;
        return this.httpClient.delete<any>(url);
    }
}

interface Options {
    page?: number;
    size?: number;
    sort?: string;
}
