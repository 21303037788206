<div class="main-container main-container--with-signature-preview direction-column">
    <div class="main-container__header">
        <div class="flex justify-space-between align-items-center width-100-percent">
            <div class="flex align-items-center">
                <loading-button
                    [buttonId]="'remote-signature-creation-back-button'"
                    [classes]="'button button--small button--only-icon'"
                    [iconClass]="'icon-back'"
                    (action)="navigateTo('back')"
                    [isValid]="true">
                </loading-button>
                <h3 class="margin-left-15 font-size-22 min-width-fit-content" [translate]="'remoteSignature.detail.title'"></h3>
            </div>
            <div class="flex align-items-center" kendoTooltip filter="button" position="top" offset="5">
                <!-- Pending -->
                <!-- <loading-button [classes]="'button button--small button--only-icon margin-right-10'" [iconClass]="'icon-18 icon-send-reminder'"
        (action)="''" [isValid]="true">
      </loading-button> -->
                @if (batchData.cancellable) {
                <button
                    id="cancel-batch-btn"
                    class="button button--cancel button--small"
                    (click)="cancelBatch()"
                    [title]="'remoteSignature.tooltips.cancelSingleBatch' | translate">
                    <span class="button__icon icon icon-18 icon-clear-fill-s"></span>
                    <span class="button__label" [textContent]="'global.form.cancel' | translate"></span>
                </button>
                }
            </div>
        </div>
    </div>
    <div class="main-container__content">
        <div class="main-container--full-width">
            <div class="batch-header">
                <div class="batch-header__block">
                    <span class="batch-header__block--title margin-bottom-10" [translate]="'remoteSignature.creation.signer'"></span>
                    @if (batchData.employee) {
                    <user-avatar [small]="true" [user]="batchData.employee" [showUsername]="true"> </user-avatar>
                    }
                </div>
                <div class="batch-header__block">
                    <span class="batch-header__block--title margin-bottom-10" [translate]="'remoteSignature.detail.progress'"></span>
                    @if (batchData.state == SIGNATURE_BATCH_STATES.SENT || batchData.state == SIGNATURE_BATCH_STATES.IN_PROGRESS) {
                    <div class="batch-progress">
                        <div class="batch-progress__row">
                            <div class="batch-progress__line-graph">
                                @if (batchData) {
                                <div class="batch-progress__line-graph__bar" [ngStyle]="{'max-width': finishedDocsPercent()}"></div>
                                }
                            </div>
                            <span class="batch-progress__number margin-left-5" [textContent]="getFinishedDocsCount()"></span>
                            <span class="margin-left-5" [translate]="'users.holidays.modal.fromDays'"></span>
                            <span class="batch-progress__number" [textContent]="batchData.documentsCount"></span>
                        </div>
                    </div>
                    } @if (batchData.state == SIGNATURE_BATCH_STATES.FINISHED || batchData.state == SIGNATURE_BATCH_STATES.EXPIRED || batchData.state ==
                    SIGNATURE_BATCH_STATES.CANCELLED) {
                    <div>
                        <div class="flex align-items-center signature-state margin-top-10" [ngClass]="getBatchStateColor(batchData?.state)">
                            <span class="signature-state__label" [textContent]="getBatchStateTextLabel(batchData?.state)"></span>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <h3 class="margin-top-20 padding-top-20 margin-bottom-20" [translate]="'remoteSignature.detail.docsInBatch'"></h3>

            @if (batchDocumentsDatagrid?.length > 0) {
            <kendo-grid
                #batchDetail
                class="master-grid"
                [data]="batchDocumentsDatagrid"
                [navigable]="true"
                [resizable]="false"
                [selectable]="{ checkboxOnly: false, mode: 'single' }"
                kendoGridSelectBy
                [(selectedKeys)]="selectedRow"
                (selectionChange)="onSelectDocument($event)"
                (cellClick)="onCellClick()">
                <!-- Document -->
                <kendo-grid-column field="resource.nameWithExtension" width="25%" [headerStyle]="{'padding': '0.5em 0.6em'}" [style]="{'cursor': 'pointer'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center">
                            <span class="padding-left-10" [textContent]="'model.users.documents.document' | translate"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center" kendoTooltip filter="div" position="top">
                            <div
                                class="batch-document-icon"
                                [ngStyle]="{'background-color': getDocumentTagHexColor(dataItem.fileKind?.color)}"
                                [ngClass]="{'batch-document-icon--empty': !dataItem.fileKind}"
                                [title]="dataItem.fileKind ? dataItem.fileKind.name : 'documents.documentsKind.filterTagNames.other' | translate">
                                <span class="icon icon-24 icon-doc-signed" [ngClass]="{'white-color': dataItem.fileKind}"></span>
                            </div>
                            <span class="text-sm black-60 truncate margin-left-10" [textContent]="dataItem.resource.nameWithExtension"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- Document state -->
                <kendo-grid-column field="kind" width="20%" [style]="{'cursor': 'pointer'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center">
                            <span [textContent]="'remoteSignature.grid.progress' | translate"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center" kendoTooltip filter="span" position="top">
                            <div class="document-state" [ngClass]="getDocumentStateBgColor(dataItem.state)">
                                <span class="document-state__text" [textContent]="getDocumentLabelText(dataItem.state)"></span>
                            </div>
                            @if (dataItem.state == DOCUMENT_STATES.REJECTED) {
                            <span
                                class="icon icon-16 icon-comment-fill margin-left-10 advice-color"
                                [title]="dataItem.rejectionReason? ('conversations.motiveLabel' | translate) + ' ' + dataItem.rejectionReason : ''">
                            </span>
                            }
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- CTA buttons column -->
                <kendo-grid-command-column title="" width="13%" [style]="{ 'text-align': 'end' }">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        @if ((!dataItem.disabledByFileKind && !isConfidentialProfile) || userLoggedHasAdminRole) {
                        <div class="td-on-hover" kendoTooltip filter="a" position="top">
                            @if (dataItem.resource.mimetype == 'application/pdf') {
                            <a
                                class="k-button k-master-button k-grid-ignore-click"
                                (click)="actionViewDocument(dataItem)"
                                [title]="'global.form.view' | translate">
                                <span class="icon icon-eye-opened"></span>
                            </a>
                            } @if (dataItem.resource.mimetype !== 'application/pdf') {
                            <a class="k-button k-master-button k-grid-ignore-click">
                                <span style="height: 24px; width: 20px;"></span>
                            </a>
                            }
                            <a
                                class="k-button k-master-button k-grid-ignore-click"
                                (click)="actionDownloadDocument(dataItem)"
                                [title]="'global.modal.download' | translate">
                                <span class="icon icon-download"></span>
                            </a>
                            @if (dataItem.state == DOCUMENT_STATES.SIGNED) {
                            <a
                                class="k-button k-master-button k-grid-ignore-click"
                                [title]="'remoteSignature.evidenceReport' | translate"
                                (click)="actionDownloadEvidenceReport(dataItem)">
                                <span class="icon icon-stamped-doc"></span>
                            </a>
                            }
                            <!-- <a class="k-button k-master-button k-grid-ignore-click" (click)="actionDeleteDocument()">
                <span class="icon icon-delete"></span>
              </a> -->
                        </div>
                        }
                    </ng-template>
                </kendo-grid-command-column>
            </kendo-grid>
            }
        </div>
    </div>
    <!-- Remote signature batch preview -->
    <div class="signature-batch-preview">
        <div class="signature-batch-preview__container scroll7">
            <!-- Summary preview -->
            <div class="signature-batch-preview__container--summary" [ngClass]="{'visible': !showTracking}">
                <h3 class="margin-top-10" [translate]="'remoteSignature.detail.summary.title'"></h3>
                <div class="summary-data-block">
                    <div class="summary-data-block__item margin-bottom-30">
                        <span class="summary-data-block__item__label margin-bottom-10" [translate]="'remoteSignature.detail.summary.shipmentDate'"></span>
                        <span class="summary-data-block__item__text" [textContent]="batchData.batchDate | date: 'dd/MM/yyyy HH:mm'"></span>
                    </div>
                    <div class="summary-data-block__item">
                        <span class="summary-data-block__item__label margin-bottom-10" [translate]="'remoteSignature.detail.summary.signerPhone'"></span>
                        <span class="summary-data-block__item__text" [textContent]="batchData.employee?.phoneNumber?.number"></span>
                    </div>
                </div>
                <div class="summary-data-block">
                    @if (batchData.reminder?.reminderEnabled) {
                    <div class="summary-data-block__item margin-bottom-30">
                        <span class="summary-data-block__item__label margin-bottom-10" [translate]="'remoteSignature.detail.summary.reminder'"></span>
                        <div class="flex align-items-center">
                            <span class="summary-data-block__item__text" [translate]="'remoteSignature.detail.summary.after'"></span>
                            <span class="summary-data-block__item__text margin-left-5" [textContent]="batchData.reminder?.reminderAmount"></span>
                            <span class="summary-data-block__item__text margin-left-5" [translate]="'model.schemes.days'"></span>
                        </div>
                    </div>
                    }
                    <div class="summary-data-block__item">
                        <span class="summary-data-block__item__label margin-bottom-10" [translate]="'remoteSignature.detail.summary.expirationDate'"></span>
                        <span class="summary-data-block__item__text" [textContent]="batchData.expiration?.expirationDate | date: 'dd/MM/yyyy'"></span>
                    </div>
                </div>
                <div class="summary-data-block summary-data-block--no-border">
                    <div class="summary-data-block__item margin-bottom-30">
                        <span class="summary-data-block__item__label margin-bottom-10" [translate]="'documents.import.detail.sendedBy'"></span>
                        <div class="flex align-items-center">
                            <avatar
                                [name]="batchData.sender?.name"
                                [surname]="batchData.sender?.surname"
                                [small]="true"
                                [thumbnails]="{thumbnail_M: batchData.sender?.avatarThumbnailMUrl || batchData.sender?.avatarUrl, thumbnail_S: batchData.sender?.avatarThumbnailSUrl || batchData.sender?.avatarThumbnailMUrl}">
                            </avatar>
                            <span
                                class="summary-data-block__item__text margin-left-10"
                                [textContent]="batchData.sender?.name + ' ' + batchData.sender?.surname"></span>
                        </div>
                    </div>
                    <div class="summary-data-block__item margin-bottom-30">
                        <span class="summary-data-block__item__label margin-bottom-10" [translate]="'remoteSignature.detail.summary.subject'"></span>
                        <span class="summary-data-block__item__text" [innerHTML]="batchData.title"></span>
                    </div>
                    <div class="summary-data-block__item">
                        <span class="summary-data-block__item__label margin-bottom-10" [translate]="'model.message.text'"></span>
                        <span class="summary-data-block__item__text" [innerHTML]="batchData.message"></span>
                    </div>
                </div>
            </div>
            <!-- Tracking preview -->
            <div class="signature-batch-preview__container--tracking" [ngClass]="{'visible': showTracking}">
                <div class="flex align-items-center margin-top-10">
                    <span class="icon icon-24 icon-back margin-right-10 cursor-pointer" (click)="onCellClick()"></span>
                    <h3 [translate]="'statistics.activity.title'"></h3>
                </div>
                @if (!isFileLogLoaded) {
                <div class="tracking-data-block margin-top-10">
                    <div class="flex align-items-center margin-left-25 margin-bottom-10">
                        <kendo-skeleton shape="circle" animation="pulse" [width]="20" [height]="20" class="black-10"></kendo-skeleton>
                        <kendo-skeleton
                            shape="text"
                            animation="pulse"
                            [width]="145"
                            [height]="16"
                            style="border-radius: 4px"
                            class="margin-left-10 black-10"></kendo-skeleton>
                    </div>
                    <div class="flex align-items-center margin-left-25 margin-bottom-10">
                        <kendo-skeleton
                            shape="text"
                            animation="pulse"
                            [width]="105"
                            [height]="12"
                            style="border-radius: 4px"
                            class="margin-left-30 black-5 padding-left-10"></kendo-skeleton>
                    </div>
                </div>
                } @if (isFileLogLoaded) {
                <div class="tracking-data-block margin-top-10">
                    @if (selectedFileLog.length > 0) { @for (item of selectedFileLog; track item; let index = $index; let last = $last) {
                    <div>
                        <!-- Document first trace / document sent / trace state is setted in 0 -->
                        @if (!item.state) {
                        <div class="tracking-data-block__item tracking-data-block__item--first">
                            <div class="flex align-items-center margin-bottom-5">
                                <span class="icon icon-16 icon-send-fill flex align-items-center margin-left-5"></span>
                                <span
                                    class="tracking-data-block__item__message margin-left-5 padding-left-3 truncate"
                                    [textContent]="item.message"
                                    [title]="item.message"></span>
                            </div>
                            <span class="tracking-data-block__item__text margin-bottom-5" [textContent]="item.timestamp | date: 'dd/MM/yyyy HH:mm'"></span>
                        </div>
                        }
                        <!-- Document traces / trace state is setted to 1 (UNSIGNED)-->
                        @if (item.state == DOCUMENT_STATES.UNSIGNED) {
                        <div class="tracking-data-block__item" kendoTooltip filter="span" position="top" tooltipWidth="250">
                            <div class="flex align-items-center margin-bottom-5 padding-top-20">
                                <span class="tracking-item-dot"></span>
                                <span
                                    class="tracking-data-block__item__message margin-left-15 truncate"
                                    [textContent]="item.message"
                                    [title]="item.message"></span>
                            </div>
                            <span class="tracking-data-block__item__text margin-bottom-5" [textContent]="item.timestamp | date: 'dd/MM/yyyy HH:mm'"></span>
                            @if (item.ip) {
                            <span class="tracking-data-block__item__text margin-bottom-10" [textContent]="'IP ' + item.ip"></span>
                            }
                        </div>
                        }
                        <!-- Document last trace / finished / trace state is setted to 2 (SIGNED) | 3 (REJECTED) | 4 (EXPIRED) | 5 (CANCELLED) -->
                        @if (!!item.state && item.state !== DOCUMENT_STATES.UNSIGNED) {
                        <div
                            class="tracking-data-block__item tracking-data-block__item--last-finished"
                            kendoTooltip
                            filter="span"
                            position="top"
                            tooltipWidth="250">
                            <div class="flex align-items-center margin-top-15">
                                <span
                                    class="icon icon-28"
                                    [ngClass]="item.state == DOCUMENT_STATES.SIGNED ? 'icon-ok-fill success' : item.state == DOCUMENT_STATES.EXPIRED ? 'icon-remove-fill secondary-color' : 'icon-remove-fill advice-color'"></span>
                                <span
                                    class="tracking-data-block__item__message margin-left-5 truncate"
                                    [textContent]="item.message"
                                    [title]="item.message"></span>
                            </div>
                            <span class="tracking-data-block__item__text margin-bottom-5" [textContent]="item.timestamp | date: 'dd/MM/yyyy HH:mm'"></span>
                            @if (item.ip) {
                            <span class="tracking-data-block__item__text" [textContent]="'IP ' + item.ip"></span>
                            }
                        </div>
                        }
                        <!-- Document last trace / in Progress / trace state is 0 (first) or 1 (UNSIGNED) and last -->
                        @if ((!item.state || item.state == DOCUMENT_STATES.UNSIGNED) && last) {
                        <div class="tracking-data-block__item tracking-data-block__item--last-inProgress">
                            <span
                                class="tracking-data-block__item--last-inProgress__message margin-left-30 truncate"
                                [translate]="'remoteSignature.detail.tracking.inProgress'"></span>
                        </div>
                        }
                    </div>
                    } } @if (selectedFileLog.length === 0) {
                    <div class="tracking-data-block__item tracking-data-block__item--first">
                        <div class="flex align-items-center margin-bottom-5">
                            <span class="icon icon-16 icon-send-fill flex align-items-center margin-left-5"></span>
                            <span
                                class="tracking-data-block__item__message margin-left-5 padding-left-3 truncate"
                                [translate]="'remoteSignature.detail.tracking.documentSent'"></span>
                        </div>
                        <span class="tracking-data-block__item__text margin-bottom-5" [textContent]="batchData.batchDate | date: 'dd/MM/yyyy HH:mm'"></span>
                    </div>
                    <div class="tracking-data-block__item tracking-data-block__item--expired">
                        <div class="flex align-items-center margin-bottom-5">
                            <span class="icon icon-16 icon-timer-s flex align-items-center justify-content-center margin-left-5"></span>
                            <span
                                class="tracking-data-block__item__message margin-left-5 padding-left-3 truncate"
                                [translate]="'remoteSignature.detail.tracking.documentExpired'"></span>
                        </div>
                        <span
                            class="tracking-data-block__item__text margin-bottom-5"
                            [textContent]="batchData.expiration.expirationDate | date: 'dd/MM/yyyy HH:mm'"></span>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
    </div>
</div>
