<div class="main-container main-container--full-width main-container--no-main-header">
    <div class="main-container__header main-container__header--no-main-header">
        <div class="flex justify-space-between align-items-center width-100-percent">
            <div class="flex align-items-center">
                <loading-button
                    [buttonId]="'remote-signature-creation-back-button'"
                    [classes]="'button button--small button--only-icon'"
                    [iconClass]="'icon-back'"
                    (action)="goBack()"
                    [isValid]="!isCreationActive">
                </loading-button>
                <h3 class="margin-left-15" [translate]="'remoteSignature.creation.title'"></h3>
            </div>
            <div class="flex">
                <loading-button
                    id="upload-files"
                    [buttonId]="'send-remote-signature-documents'"
                    [classes]="'button button--cta'"
                    [text]="'global.form.send'"
                    (action)="sendRemoteSignatureDocuments()"
                    [isValid]="isFormValid()">
                </loading-button>
            </div>
        </div>
    </div>

    <div class="main-container__content" [ngClass]="{ 'white-cover' : isCreationActive }">
        <div
            class="main-container__content--left-panel flex justify-content-center"
            [ngClass]="{'main-container__content--left-panel-empty': (!documents || documents.length === 0)}">
            @if ((!documents || documents.length === 0)) {
                <div class="flex align-items-center">
                    <documents-upload (addDocuments)="addDocuments($event)"></documents-upload>
                </div>
            } @else {
                <div class="documents-viewer">
                    <div class="documents-viewer__list documents-viewer__list--border">
                        <div class="documents-viewer__header">
                            <span class="documents-viewer__title" [translate]="'remoteSignature.creation.documentsListTitle'"></span>
                        </div>
                        <div class="documents-viewer__content">
                            @for (document of documents; track document; let i = $index) {
                                <div
                                    class="documents-viewer__list-item"
                                    [ngClass]="{ 'documents-viewer__list-item--selected': i === selectedDocumentIndex }"
                                    (click)="selectDocument(i)">
                                    <div
                                        class="documents-viewer__list-item-icon-container"
                                        kendoTooltip
                                        filter=".documents-viewer__list-item-badge"
                                        position="top">
                                        <span
                                            class="documents-viewer__list-item-icon icon icon-document-xl"
                                            [ngClass]="{ 'documents-viewer__list-item-icon--tagged': !!document.fileKind && !!document.fileKind.id }"
                                            [ngStyle]="!!document.fileKind?.color && { 'color': (document.fileKind.color | numberColor) }">
                                            <span class="documents-viewer__list-item-icon-fill icon icon-signature-document"></span>
                                            @if (!!document.fileKind && !!document.fileKind.id) {
                                                <span class="documents-viewer__list-item-icon-shine icon icon-document-xl"></span>
                                            }
                                            <!-- Document info badge -->
                                            @if (!document.location && !toggleCorporateSeal) {
                                                <span
                                                    class="documents-viewer__list-item-badge icon icon-question-s"
                                                    [title]="('remoteSignature.creation.badgeTooltip.noSignerArea' | translate)">
                                                </span>
                                            }
                                            @if (!document.location && !!toggleCorporateSeal) {
                                                <span
                                                    class="documents-viewer__list-item-badge icon icon-question-s"
                                                    [title]="!!document.stampLocation ? ('remoteSignature.creation.badgeTooltip.justStampArea' | translate) : ('remoteSignature.creation.badgeTooltip.noAreasAtAll' | translate)">
                                                </span>
                                            }
                                            @if (!!document.location && !toggleCorporateSeal) {
                                                <span
                                                    class="documents-viewer__list-item-badge documents-viewer__list-item-badge--success icon icon-tick-bold"
                                                    [title]="('remoteSignature.creation.badgeTooltip.justSignerArea' | translate)">
                                                </span>
                                            }
                                            @if (!!document.location && !!toggleCorporateSeal) {
                                                <span
                                                    class="documents-viewer__list-item-badge icon icon-question-s"
                                                    [ngClass]="{ 'icon-question-s' : !document.stampLocation, 'documents-viewer__list-item-badge--success icon-tick-bold' : !!document.stampLocation }"
                                                    [title]="!document.stampLocation ? ('remoteSignature.creation.badgeTooltip.signerButNoStampArea' | translate) : ('remoteSignature.creation.badgeTooltip.allAreas' | translate)">
                                                </span>
                                            }
                                        </span>
                                    </div>
                                    <span class="documents-viewer__list-item-delete icon icon-delete" (click)="removeDocument(i)"></span>
                                    <span class="documents-viewer__list-item-name" [textContent]="documentFiles[i].name"></span>
                                    @if (document.fileKind && document.fileKind.id) {
                                        <span
                                            class="documents-viewer__list-item-kind white-color"
                                            [textContent]="document.fileKind.name"
                                            [ngStyle]="!!document.fileKind?.color && {'background-color': (document.fileKind.color | numberColor) }"></span>
                                    }
                                </div>
                            }
                            <div class="documents-viewer__button-container">
                                <label class="documents-viewer__button-icon button button--only-icon" for="addDocumentInput">
                                    <span class="button__icon icon icon-add"></span>
                                </label>
                                <input
                                    id="addDocumentInput"
                                    type="file"
                                    onclick="this.value = null"
                                    accept="application/pdf"
                                    multiple
                                    (change)="attachDocuments($event.target.files)" />
                            </div>
                        </div>
                    </div>
                    @if (selectedDocumentIndex !== -1) {
                        <div class="documents-viewer__detail">
                            <div class="documents-viewer__header documents-viewer__header--editable">
                                <div class="flex justify-space-between">
                                    <dialenga-input-text
                                        inputId="document-name"
                                        class="documents-viewer__title documents-viewer__document-name"
                                        [ngClass]="{'documents-viewer__title--focus': documentNameFocus }"
                                        [(inputModel)]="selectedDocumentName"
                                        (inputChanged)="onFilenameChange()"
                                        [required]="{ state: true, errorMessage: '' }"
                                        (onFocus)="onFilenameFocus($event)"
                                        [minLength]="{ amount: 5, errorMessage: 'users.validation.nameminlength' }"
                                        [maxLength]="{ amount: documentNameMaxLength, warningAmount: 90 }">
                                    </dialenga-input-text>
                                    <div class="documents-viewer__kind">
                                        <kendo-dropdownlist
                                            id="document-kind"
                                            name="documentKind"
                                            class="dialenga-dropdownlist dialenga-dropdownlist--label"
                                            [data]="documentKindList"
                                            [(ngModel)]="selectedDocumentKind"
                                            textField="name"
                                            valueField="id"
                                            (valueChange)="onDocumentKindChange($event)"
                                            [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                            <ng-template kendoDropDownListValueTemplate let-dataItem>
                                                @if (selectedDocumentKind && selectedDocumentKind.id) {
                                                    <span
                                                        class="dialenga-dropdownlist__popup-display dialenga-dropdownlist__popup-display--category"
                                                        [textContent]="documents[selectedDocumentIndex]?.fileKind?.name"
                                                        [ngStyle]="!!selectedDocumentKind?.color && { 'background-color': (selectedDocumentKind.color | numberColor) }">
                                                    </span>
                                                }
                                                @if (!selectedDocumentKind || !selectedDocumentKind.id) {
                                                    <span class="dialenga-dropdownlist__popup-icon icon icon-tag-s"></span>
                                                }
                                                @if (!selectedDocumentKind || !selectedDocumentKind.id) {
                                                    <span
                                                        class="dialenga-dropdownlist__popup-display dialenga-dropdownlist__popup-display--empty"
                                                        [translate]="'documents.documentsKind.filterTagNames.other'">
                                                    </span>
                                                }
                                            </ng-template>
                                            <ng-template kendoDropDownListItemTemplate let-dataItem>
                                                <span
                                                    class="dialenga-dropdownlist__popup-icon icon"
                                                    [ngClass]="dataItem.id ? 'icon-tag-fill-s' : 'icon-tag-s'"
                                                    [ngStyle]="!!dataItem?.color && { 'color': (dataItem.color | numberColor) }">
                                                </span>
                                                <span class="dialenga-dropdownlist__popup-value" [textContent]="dataItem.name"></span>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                    </div>
                                </div>
                            </div>
                            @if (documentPreviewSrc) {
                                <div class="documents-viewer__content">
                                    <ngx-extended-pdf-viewer
                                        [src]="documentPreviewSrc"
                                        [logLevel]="0"
                                        [customToolbar]="viewerToolbar"
                                        [showBorders]="false"
                                        [zoom]="'page-fit'">
                                    </ngx-extended-pdf-viewer>
                                    <ng-template #viewerToolbar>
                                        <div id="toolbarViewer">
                                            <div id="toolbarViewerLeft">
                                                <pdf-toggle-sidebar></pdf-toggle-sidebar>
                                                <pdf-paging-area></pdf-paging-area>
                                            </div>
                                            <pdf-zoom-toolbar></pdf-zoom-toolbar>
                                        </div>
                                    </ng-template>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>

        <div class="main-container__content--right-panel signature-data">
            <div class="signature-data__content">
                <div class="signature-data__title margin-bottom-30">
                    <div class="flex align-items-center">
                        <span class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.signerRequired'"></span>
                        <toggle-input
                            class="margin-left-8"
                            [isSmall]="true"
                            [inputId]="'signer-required'"
                            [inputModel]="requiresEmployeeSignature"
                            (inputModelChange)="checkRequiredSignature($event)">
                        </toggle-input>
                    </div>
                </div>
                <section style="margin-bottom: 24px;">
                    <span
                        class="signature-data__label signature-data__label--dropdown"
                        [translate]="requiresEmployeeSignature ? 'remoteSignature.creation.signer' : 'remoteSignature.creation.recipient'"></span>
                    <div class="flex" style="gap:16px;">
                        <div
                            id="single-signers"
                            class="button-square"
                            (click)="setSignersType(SIGNERS_TYPE.SINGLE)"
                            [ngClass]="{'button-square--actived' : signersType === SIGNERS_TYPE.SINGLE }">
                            <span class="icon icon-32 nucleo icon-employee"></span>
                            <span class="button-square__label" [translate]="'remoteSignature.mode.single'"></span>
                        </div>
                        <div
                            id="multiple-signers"
                            class="button-square "
                            (click)="setSignersType(SIGNERS_TYPE.MULTIPLE)"
                            [ngClass]="{'button-square--actived' : signersType == SIGNERS_TYPE.MULTIPLE }">
                            <span class="icon icon-32 nucleo icon-employees"></span>
                            <span class="button-square__label" [translate]="'remoteSignature.mode.massive'"></span>
                        </div>
                    </div>
                </section>
                <!-- Choose signer -->
                @if (signersType === SIGNERS_TYPE.SINGLE) {
                    <section>
                        <!-- <span class="signature-data__label signature-data__label--dropdown" translate="remoteSignature.creation.signer"></span> -->
                        <user-dropdown [(inputModel)]="recipientUser" (onSelection)="onRecipientChange()"></user-dropdown>
                        @if (!!recipientUser && (!recipientUser.phoneNumber?.number || !recipientUser.nationalIdNumber)) {
                            <div>
                                @if (
                                    (!recipientUser.phoneNumber?.number && !recipientUserPhoneNumber) || (!recipientUser.nationalIdNumber && !recipientUserNationalIdNumber)
                                ) {
                                    <div class="notification-box notification-box--warning direction-column align-items-start margin-top-20 margin-bottom-0">
                                        <div class="notification-box__title">
                                            <span [translate]="'remoteSignature.creation.noSignerTitle'"></span>
                                            @if (!recipientUser.phoneNumber?.number && !recipientUserPhoneNumber) {
                                                <span [translate]="'remoteSignature.creation.noSignerTitlePhoneNumber'"></span>
                                            }
                                            @if (
                                                !recipientUser.phoneNumber?.number && !recipientUserPhoneNumber && !recipientUser.nationalIdNumber && !recipientUserNationalIdNumber
                                            ) {
                                                <span [translate]="'remoteSignature.creation.noSignerTitleNor'"></span>
                                            }
                                            @if (!recipientUser.nationalIdNumber && !recipientUserNationalIdNumber) {
                                                <span [translate]="'users.dni'"></span>
                                            }
                                        </div>
                                        @if (!!requiresEmployeeSignature) {
                                            <div class="notification-box__text">
                                                <span [translate]="'remoteSignature.creation.noSignerDescription'"></span>
                                                @if (!recipientUser.phoneNumber?.number && !recipientUserPhoneNumber) {
                                                    <span [translate]="'remoteSignature.creation.noSignerDescriptionPhoneNumber'"></span>
                                                }
                                                @if (
                                                    !recipientUser.phoneNumber?.number && !recipientUserPhoneNumber && !recipientUser.nationalIdNumber && !recipientUserNationalIdNumber
                                                ) {
                                                    <span [translate]="'remoteSignature.creation.noSignerDescriptionAnd'"></span>
                                                }
                                                @if (!recipientUser.nationalIdNumber && !recipientUserNationalIdNumber) {
                                                    <span [translate]="'users.dni'"></span>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                @if (!!recipientUser && !recipientUser.phoneNumber?.number) {
                                    <div class="margin-top-20">
                                        <div class="flex align-items-center width-100-percent">
                                            <kendo-dropdownlist
                                                id="recipient-country-code"
                                                name="countryCode"
                                                class="max-width-100 margin-right-10 dialenga-dropdownlist"
                                                [data]="countryCodesList"
                                                [(ngModel)]="recipientUserCountryCode"
                                                textField="country"
                                                valueField="code"
                                                [popupSettings]="{ animate: {type: 'expand', direction: 'down'}, popupClass: 'dialenga-dropdownlist__popup', width: 'auto' }">
                                                <ng-template kendoDropDownListNoDataTemplate>
                                                    <h5 class="uppercase" [translate]="'global.form.emptyDataSet'"></h5>
                                                </ng-template>
                                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                                    @if (recipientUserCountryCode) {
                                                        <span>{{ recipientUserCountryCode?.country }} (+{{ recipientUserCountryCode?.code }})</span>
                                                    }
                                                </ng-template>
                                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                                    <span class="dialenga-dropdownlist__popup-value">{{ dataItem.country }} (+{{ dataItem.code }})</span>
                                                </ng-template>
                                            </kendo-dropdownlist>
                                            <dialenga-input-text
                                                inputId="recipient-phone-number"
                                                class="width-100-percent"
                                                [isAllwaysActive]="true"
                                                [(inputModel)]="recipientUserPhoneNumber"
                                                placeholder="users.phoneNumber"
                                                [required]="{state: (!!recipientUser && !recipientUser.phoneNumber?.number), errorMessage: 'users.validation.phoneNumber'}"
                                                [maxLength]="{amount: 20, warningAmount: 16}"
                                                [pattern]="{model: 'phone', errorMessage: 'users.validation.phoneNumberFormat'}">
                                            </dialenga-input-text>
                                        </div>
                                    </div>
                                }
                                @if (!!recipientUser && !recipientUser.nationalIdNumber) {
                                    <div [ngClass]="!recipientUser.phoneNumber?.number ? 'margin-top-40' : 'margin-top-20'">
                                        <div class="flex align-items-center width-100-percent">
                                            <span class="group-form__label__text margin-left-10 margin-right-20" [translate]="'users.dni'"></span>
                                            <dialenga-input-text
                                                inputId="recipient-national-id-number"
                                                class="width-100-percent"
                                                [isAllwaysActive]="true"
                                                [(inputModel)]="recipientUserNationalIdNumber"
                                                placeholder="users.dni"
                                                [required]="{state: (!!recipientUser && !recipientUser.nationalIdNumber), errorMessage: 'users.validation.dniRequired'}"
                                                [maxLength]="{amount: 64, warningAmount: 59}">
                                            </dialenga-input-text>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </section>
                }
                <!-- Multiple recipients -->
                @if (signersType == SIGNERS_TYPE.MULTIPLE) {
                    <section>
                        @if (recipientUsers?.length <= 0) {
                            <button id="add-recipients" class="button" (click)="addMultipleRecipients()">
                                <span class="button__label" [textContent]="'global.form.select' | translate"></span>
                            </button>
                        } @else {
                            <section class="flex" [ngClass]="{'direction-column': recipientUsers?.length < 4}">
                                @if (recipientUsers?.length < 4) {
                                    @for (recipient of recipientUsers; track recipient) {
                                        <article [ngClass]="recipientUsers.length - 1 == $index ? 'margin-bottom-0' : 'margin-bottom-8'">
                                            <user-avatar [small]="true" [user]="recipient"></user-avatar>
                                        </article>
                                    }
                                }
                                @if (recipientUsers?.length > 3) {
                                    <article class="conflicts-avatar-list margin-right-10 margin-bottom-8" kendoTooltip filter="div.avatar" position="top">
                                        @for (recipient of recipientUsers | slice:0:4; track recipient; let i = $index) {
                                            <avatar
                                                [name]="recipient.name"
                                                [surname]="recipient.surname"
                                                [title]="recipient.name + ' ' + recipient.surname"
                                                [thumbnails]="{thumbnail_M: recipient.avatarThumbnailMUrl || recipient.avatarUrl, thumbnail_S: recipient.avatarThumbnailSUrl || recipient.avatarThumbnailMUrl}">
                                            </avatar>
                                        }
                                        @if (recipientUsers?.length > 4) {
                                            <span class="avatar conflicts-avatar-list__more">
                                                <span class="avatar__label">+{{recipientUsers.length - 4}}</span>
                                            </span>
                                        }
                                    </article>
                                }
                                <section
                                    class="inline-flex align-items-center justify-space-between"
                                    style="flex: 1;"
                                    [ngClass]="{'margin-top-10': recipientUsers?.length < 4}"
                                    kendoTooltip
                                    filter="span.icon"
                                    tooltipClass="signature-long-tooltip"
                                    [tooltipWidth]="416">
                                    <span
                                        class="info-color cursor-pointer display-inline-block"
                                        (click)="addMultipleRecipients()"
                                        [textContent]="'global.form.select' | translate"></span>
                                    @if (!!hasInvalidUsers && !!requiresEmployeeSignature) {
                                        <span
                                            class="icon nucleo icon-warning-fill icon-24 warning"
                                            [title]="'remoteSignature.creation.modal.warningUsers.message' | translate"></span>
                                    }
                                </section>
                            </section>
                        }
                    </section>
                }
                <!-- Select corporate stamp -->
                <section class="margin-top-30">
                    <div class="signature-data__title margin-bottom-0">
                        <div class="flex align-items-center" kendoTooltip filter="span.icon-help-fill" position="top">
                            <!-- <span class="signature-data__icon icon icon-seal icon-20"></span> -->
                            <span class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.corporateStamp'"></span>
                            <toggle-input
                                class="margin-left-8"
                                [isSmall]="true"
                                [inputId]="'corporate-seal-toggle'"
                                [inputModel]="toggleCorporateSeal"
                                (inputModelChange)="checkRequiredStamp($event)">
                            </toggle-input>
                            <!-- <span class="signature-data__icon icon icon-help-fill margin-left-10" [title]="('remoteSignature.creation.signatureAreaTooltip' | translate)"></span> -->
                        </div>
                    </div>
                    @if (toggleCorporateSeal) {
                        <section class="">
                            <!-- Selection of corporate seal -->
                            <kendo-dropdownlist
                                class="kendo-angular-dropdown signature-dropdown margin-top-16"
                                [data]="corporateStampsList"
                                [(ngModel)]="stamp.id"
                                [textField]="'name'"
                                valueField="id"
                                valuePrimitive="true">
                                <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                <!-- Placeholder -->
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    @if (dataItem) {
                                        <div class="inline-flex align-items-center signature-dropdown__option">
                                            <span class="icon icon-24 nucleo icon-seal black-45 flex align-items-center"></span>
                                            <span class="truncate black-85" style="max-width: 400px" [textContent]="dataItem.name"></span>
                                        </div>
                                    } @else {
                                        <span [textContent]="'remoteSignature.creation.selectCorporateStamp' | translate"></span>
                                    }
                                </ng-template>
                                <!-- Template items -->
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <div class="flex align-items-center list-item-height">
                                        <span class="icon icon-20 nucleo icon-seal margin-right-10 black-45"></span>
                                        <span class="truncate black-85" style="max-width: 400px" [textContent]="dataItem.name"></span>
                                    </div>
                                </ng-template>
                            </kendo-dropdownlist>
                            @if (!!requiresEmployeeSignature) {
                                <div class="margin-top-16 margin-left-8">
                                    <label class="checkbox__container">
                                        <span
                                            class="checkbox icon nucleo"
                                            [ngClass]="!stamp.addAfterEmployee ? 'icon-checkbox' : 'icon-checkbox-selected'"></span>
                                        <div class="flex align-items-center" kendoTooltip filter="span.icon-help-fill" tooltipClass="signature-long-tooltip">
                                            <span class="checkbox__label" [translate]="'remoteSignature.creation.stampAfterSignature'"></span>
                                            <span
                                                class="signature-data__icon icon icon-help-fill margin-left-10"
                                                [title]="('remoteSignature.creation.afterSignatureTooltip' | translate)"></span>
                                        </div>
                                        <input
                                            type="checkbox"
                                            name="seal-after-signature"
                                            id="seal-after-signature"
                                            class="sr-only"
                                            [(ngModel)]="stamp.addAfterEmployee" />
                                    </label>
                                </div>
                            }
                        </section>
                    }
                </section>
            </div>
            <!-- Opciones de firma remota -->
            <div
                class="signature-data__content"
                [ngClass]="{ 'signature-data__content--disabled': selectedDocumentIndex === -1 }"
                style="border-color: #e6e6e6;">
                <div class="signature-data__title black-45" style="margin-bottom: 32px;">
                    <h3 class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.signatureConfiguration'"></h3>
                </div>
                <!-- Area and position of remote signature -->
                @if (!!requiresEmployeeSignature) {
                    <section class="padding-left-8">
                        <div class="signature-data__title">
                            <div
                                class="flex align-items-center"
                                kendoTooltip
                                filter="span.icon-help-fill"
                                [tooltipWidth]="416"
                                tooltipClass="signature-long-tooltip">
                                <span class="signature-data__icon icon icon-signature-zone-s"></span>
                                <span class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.signatureAreaAndPosition'"></span>
                                <span
                                    class="signature-data__icon icon icon-help-fill margin-left-10"
                                    [title]="('remoteSignature.creation.signatureAreaTooltip' | translate)"></span>
                            </div>
                        </div>
                        <section class="padding-left-24">
                            <kendo-dropdownlist
                                class="kendo-angular-dropdown signature-dropdown"
                                [data]="signatureAreasList"
                                [ngModel]="documents[selectedDocumentIndex]?.location"
                                (ngModelChange)="onAreaSelected($event)"
                                [disabled]="selectedDocumentIndex === -1"
                                [textField]="'name'"
                                valueField="id">
                                <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                <!-- Placeholder -->
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    @if (dataItem) {
                                        <div class="inline-flex align-items-center signature-dropdown__option">
                                            <span
                                                class="icon icon-24 nucleo black-45 flex align-items-center"
                                                [ngClass]="dataItem.anchor ? 'icon-anchor' : 'icon-signature-zone-s'">
                                            </span>
                                            <span class="truncate black-85" style="max-width: 400px" [textContent]="dataItem.name"></span>
                                        </div>
                                    } @else {
                                        <span [textContent]="'documents.import.attachFiles' | translate"></span>
                                    }
                                </ng-template>
                                <!-- Template items -->
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <div class="flex align-items-center list-item-height">
                                        <span
                                            class="icon icon-20 nucleo margin-right-10 black-45"
                                            [ngClass]="dataItem.anchor ? 'icon-anchor' : 'icon-signature-zone-s'"></span>
                                        <span class="truncate black-85" style="max-width: 400px" [textContent]="dataItem.name"></span>
                                    </div>
                                </ng-template>
                            </kendo-dropdownlist>
                            @if (selectedDocumentIndex !== -1 && !documents[selectedDocumentIndex]?.location) {
                                <div class="notification-box direction-column align-items-start margin-bottom-0">
                                    <span class="notification-box__title" [translate]="'remoteSignature.creation.noSignatureAreaTitle'"></span>
                                    <span class="notification-box__text" [translate]="'remoteSignature.creation.noSignatureAreaDescription'"></span>
                                </div>
                            }
                            @if (selectedDocumentIndex !== -1 && !!documents[selectedDocumentIndex]?.location) {
                                <div>
                                    <!-- <div class="signature-data__tag">
                                    <div class="signature-data__tag-content">
                                        <span class="icon" [ngClass]="documents[selectedDocumentIndex]?.location.anchor ? 'icon-anchor' : 'icon-signature-zone-s'"></span>
                                        <span [textContent]="documents[selectedDocumentIndex]?.location.name"></span>
                                    </div>
                                    <span class="signature-data__tag-remove icon icon-clear-fill-s" (click)="removeSignatureArea()"></span>
                                    </div> -->
                                    <div class="signature-data__info">
                                        <span class="margin-right-5" [translate]="'remoteSignature.creation.signature'"></span>
                                        @if (!!documents[selectedDocumentIndex]?.location.anchor) {
                                            <span
                                                [translate]="'remoteSignature.creation.signatureAreaAnchor'"
                                                [translateParams]="{anchor: documents[selectedDocumentIndex]?.location.anchor}">
                                            </span>
                                        } @else {
                                            <span
                                                [translate]="'remoteSignature.creation.signatureAreaPage'"
                                                [translateParams]="{page: documents[selectedDocumentIndex]?.location.page}">
                                            </span>
                                        }
                                    </div>
                                </div>
                            }
                        </section>
                    </section>
                }
                <!-- Area and position of corporate stamp -->
                @if (!!toggleCorporateSeal) {
                    <section class="padding-left-8 margin-top-30">
                        <div class="signature-data__title">
                            <div
                                class="flex align-items-center"
                                kendoTooltip
                                filter="span.icon-help-fill"
                                [tooltipWidth]="416"
                                tooltipClass="signature-long-tooltip">
                                <span class="signature-data__icon icon icon-signature-zone-s"></span>
                                <span class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.stampAreaAndPosition'"></span>
                                <span
                                    class="signature-data__icon icon icon-help-fill margin-left-10"
                                    [title]="('remoteSignature.creation.stampAreaTooltip' | translate)"></span>
                            </div>
                        </div>
                        <section class="padding-left-24">
                            <kendo-dropdownlist
                                class="kendo-angular-dropdown signature-dropdown margin-top-16"
                                [data]="corpStampAreasList"
                                [ngModel]="documents[selectedDocumentIndex]?.stampLocation"
                                (ngModelChange)="onStampAreaSelected($event)"
                                [disabled]="selectedDocumentIndex === -1"
                                [textField]="'name'"
                                valueField="id">
                                <kendo-dropdownlist-messages [noDataText]="'global.form.emptyDataSet' | translate"></kendo-dropdownlist-messages>

                                <!-- Placeholder -->
                                <ng-template kendoDropDownListValueTemplate let-dataItem>
                                    @if (dataItem) {
                                        <div class="inline-flex align-items-center signature-dropdown__option">
                                            <span
                                                class="icon icon-24 nucleo black-45 flex align-items-center"
                                                [ngClass]="dataItem.anchor ? 'icon-anchor' : 'icon-signature-zone-s'">
                                            </span>
                                            <span class="truncate black-85" style="max-width: 400px" [textContent]="dataItem.name"></span>
                                        </div>
                                    } @else {
                                        <span [textContent]="'documents.import.attachFiles' | translate"></span>
                                    }
                                </ng-template>
                                <!-- Template items -->
                                <ng-template kendoDropDownListItemTemplate let-dataItem>
                                    <div class="flex align-items-center list-item-height">
                                        <span
                                            class="icon icon-20 nucleo margin-right-10 black-45"
                                            [ngClass]="dataItem.anchor ? 'icon-anchor' : 'icon-signature-zone-s'"></span>
                                        <span class="truncate black-85" style="max-width: 400px" [textContent]="dataItem.name"></span>
                                    </div>
                                </ng-template>
                            </kendo-dropdownlist>
                            @if (selectedDocumentIndex !== -1 && !documents[selectedDocumentIndex].stampLocation) {
                                <div class="notification-box direction-column align-items-start margin-bottom-0">
                                    <span class="notification-box__title" [translate]="'remoteSignature.creation.noStampAreaTitle'"></span>
                                    <span class="notification-box__text" [translate]="'remoteSignature.creation.noStampAreaDescription'"></span>
                                </div>
                            }
                            @if (selectedDocumentIndex !== -1 && !!documents[selectedDocumentIndex].stampLocation) {
                                <div>
                                    <div class="signature-data__info">
                                        <span class="margin-right-5" [translate]="'remoteSignature.creation.stamp'"></span>
                                        @if (!!documents[selectedDocumentIndex].stampLocation.anchor) {
                                            <span
                                                [translate]="'remoteSignature.creation.signatureAreaAnchor'"
                                                [translateParams]="{anchor: documents[selectedDocumentIndex].stampLocation.anchor}">
                                            </span>
                                        } @else {
                                            <span
                                                [translate]="'remoteSignature.creation.signatureAreaPage'"
                                                [translateParams]="{page: documents[selectedDocumentIndex].stampLocation.page}">
                                            </span>
                                        }
                                    </div>
                                </div>
                            }
                        </section>
                    </section>
                }
            </div>
            @if (!!requiresEmployeeSignature) {
                <section>
                    <!-- Message -->
                    <div class="signature-data__content" style="border-color: #e6e6e6;">
                        <div class="flex align-items-center margin-bottom-30">
                            <span class="signature-data__icon icon icon-comment-fill-s"></span>
                            <span class="signature-data__label margin-bottom-0" [translate]="'remoteSignature.creation.sendingMessage'"></span>
                        </div>
                        <div class="margin-bottom-30">
                            <span
                                class="signature-data__label signature-data__label--secondary margin-bottom-15"
                                [translate]="'remoteSignature.creation.shortMessageTitle'"></span>
                            <dialenga-textarea
                                textareaId="short-message-textarea"
                                [(inputModel)]="shortMessage"
                                placeholder="message.captionPlaceholder"
                                [maxLength]="{ amount: 64, warningAmount: 59 }">
                            </dialenga-textarea>
                        </div>
                        <div class="margin-bottom-0">
                            <span
                                class="signature-data__label signature-data__label--secondary margin-bottom-15"
                                [translate]="'remoteSignature.creation.extendedMessageTitle'"></span>
                            <dialenga-textarea
                                textareaId="extended-message-textarea"
                                [(inputModel)]="extendedMessage"
                                placeholder="message.captionPlaceholder"
                                [isRichFormat]="true"
                                [maxLength]="{ amount: 500, warningAmount: 480 }">
                            </dialenga-textarea>
                        </div>
                    </div>
                    <!-- Reminders -->
                    <div class="signature-data__content">
                        <div class="flex direction-row align-items-center margin-bottom-30">
                            <div class="flex align-items-center">
                                <span class="signature-data__icon icon icon-reminder-s"></span>
                                <span class="signature-data__label signature-data__label--toggle" [translate]="'remoteSignature.creation.reminders'"></span>
                            </div>
                            <toggle-input
                                [inputId]="'signatureReminder'"
                                [label]="(!remindersActive ? ('remoteSignature.creation.noReminders' | translate) : '')"
                                [(inputModel)]="remindersActive">
                            </toggle-input>
                        </div>
                        @if (remindersActive) {
                            <div class="margin-bottom-30">
                                <span
                                    class="signature-data__label signature-data__label--secondary"
                                    [translate]="'remoteSignature.creation.remindAfter'"></span>
                                <input
                                    class="signature-data__input-number"
                                    type="number"
                                    [(ngModel)]="remindAfterDays"
                                    min="1"
                                    max="59"
                                    (input)="daysInputValidation($event)" />
                                <span class="signature-data__label signature-data__label--secondary" [translate]="'global.form.timeInterval.days'"></span>
                            </div>
                        }
                        <div class="flex direction-row align-items-center margin-bottom-30">
                            <div class="flex align-items-center">
                                <span class="signature-data__icon icon icon-timer-s"></span>
                                <span
                                    class="signature-data__label signature-data__label--toggle"
                                    [translate]="'remoteSignature.creation.expirationDateAdvance'"></span>
                            </div>
                            <toggle-input
                                [inputId]="'signatureExpirationAdvance'"
                                [(inputModel)]="expirationDateAdvance"
                                (inputChanged)="expirationDateToggleChange()"></toggle-input>
                        </div>
                        @if (!expirationDateAdvance) {
                            <div class="notification-box direction-column align-items-start margin-bottom-30">
                                <span class="notification-box__text" [translate]="'remoteSignature.creation.expirationDateAdvanceDescription'"></span>
                            </div>
                        } @else {
                            <div class="margin-bottom-30">
                                <span
                                    class="signature-data__label signature-data__label--secondary min-width-150"
                                    [translate]="'remoteSignature.creation.expirationMode'"></span>
                                <div class="button-bar button-bar--small margin-right-20">
                                    <button
                                        class="button"
                                        type="button"
                                        [ngClass]="{'button--selected': expirationDateMode == EXPIRATION_MODES.AFTER_N_DAYS}"
                                        (click)="expirationDateMode = EXPIRATION_MODES.AFTER_N_DAYS">
                                        <span class="button__label" [translate]="'remoteSignature.creation.expirationAfterDays'"></span>
                                    </button>
                                    <button
                                        class="button"
                                        type="button"
                                        [ngClass]="{'button--selected': expirationDateMode == EXPIRATION_MODES.EXACT_DATE}"
                                        (click)="expirationDateMode = EXPIRATION_MODES.EXACT_DATE">
                                        <span class="button__label" [translate]="'remoteSignature.creation.expirationInDate'"></span>
                                    </button>
                                </div>
                                @if (expirationDateMode == EXPIRATION_MODES.AFTER_N_DAYS) {
                                    <div>
                                        <span
                                            class="signature-data__label signature-data__label--secondary min-width-150"
                                            [translate]="'remoteSignature.creation.expirationElapsed'"></span>
                                        <input
                                            class="signature-data__input-number margin-left-0"
                                            type="number"
                                            [(ngModel)]="expirationDateElapsed"
                                            min="1"
                                            max="59"
                                            (input)="daysInputValidation($event)" />
                                        <span
                                            class="signature-data__label signature-data__label--secondary"
                                            [translate]="'global.form.timeInterval.days'"></span>
                                    </div>
                                }
                                @if (expirationDateMode == EXPIRATION_MODES.EXACT_DATE) {
                                    <div class="flex align-items-center">
                                        <span
                                            class="signature-data__label signature-data__label--secondary min-width-150 margin-bottom-0"
                                            [translate]="'remoteSignature.creation.expirationDate'"></span>
                                        <dialenga-date-picker
                                            inputId="expiration-date"
                                            [(inputModel)]="expirationDate"
                                            [minDate]="expirationDateMin"
                                            [maxDate]="expirationDateMax">
                                        </dialenga-date-picker>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </section>
            }
        </div>
    </div>
</div>
