import { Component, OnInit } from '@angular/core';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';
import { TimeoffSettingsService } from '../timeoff-settings/timeoff-settings.service';

@Component({
    selector: 'timeoff-settings-global',
    templateUrl: './timeoff-settings-global.component.html',
    styleUrls: ['./_timeoff-settings-global.scss']
})
export class TimeoffSettingsGlobalComponent implements OnInit {
    public timeoffSettingsGlobalForm: timeoffSettingsGlobalForm;
    public TIMEOFF_VALIDATION_KIND: any;

    constructor(
        private timeoffSettingsService: TimeoffSettingsService,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService
    ) {
        this.TIMEOFF_VALIDATION_KIND = Constants.TIMEOFF_VALIDATION_KIND;
        this.timeoffSettingsGlobalForm = {
            allowsHalfDay: false,
            approvalFlow: null,
            responsibleCanManageAbsences: false
        };
    }

    ngOnInit(): void {
        this.getTimeoffSettingsGlobal();
    }

    // PRIVATE METHODS

    private getTimeoffSettingsGlobal(): void {
        this.timeoffSettingsService.getTimeoffGlobalSettings().subscribe(
            (response): void => {
                this.timeoffSettingsGlobalForm = response;
            },
            (onError): void => {}
        );
    }

    /** PUT */

    private setTimeoffSettingsGlobal(): void {
        this.timeoffSettingsService.setTimeoffGlobalSettings(this.timeoffSettingsGlobalForm).subscribe(
            (response): void => {
                let message = this.translate.instant('global.messages.changesUpdated.globalSettingsSuccess');
                this.notificationService.showSuccessNotification(message);
            },
            (onError): void => {}
        );
    }

    // PUBLIC METHODS

    onChange(): void {
        this.setTimeoffSettingsGlobal();
    }
}

type timeoffSettingsGlobalForm = {
    allowsHalfDay: boolean;
    approvalFlow: number;
    responsibleCanManageAbsences: boolean;
};
