import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'userStateText'
})
export class UserStateTextPipe implements PipeTransform {
    constructor(private translate: TranslateService) {}
    transform(user: any): string {
        let state = 'global.userstates.';

        switch (parseInt(user.state)) {
            case Constants.USER_STATES.ANONYMOUS:
                state += 'anonymous';
                break;
            case Constants.USER_STATES.PENDING_ACTIVATION:
                state += 'pendingactivation';
                break;
            case Constants.USER_STATES.REGISTERED:
                state += typeof user.lastAccessDate === 'undefined' || user.lastAccessDate ? 'registered' : 'pendingAccess';
                break;
            case Constants.USER_STATES.EXPIRED:
                state += 'expired';
                break;
            case Constants.USER_STATES.DISABLED:
                state += 'disabled';
                break;
            case Constants.USER_STATES.EXPIRED_CREDENTIALS:
                state += 'expiredCredentials';
                break;
            case Constants.USER_STATES.LOCKED:
                state += 'locked';
                break;
            default:
                state = '';
                break;
        }
        return this.translate.instant(state);
    }
}
