import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Path, Group } from '@progress/kendo-drawing';
import { Constants } from '../../constants';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { ActiveFeaturesService } from './active-features.service';

declare var moment: any;

@Component({
    selector: 'active-features',
    templateUrl: 'active-features.component.html'
})
export class ActiveFeaturesComponent implements OnInit {
    private userSession: any = window['getUserSession']();
    private companyId: number;
    public MODULES_STATES: {
        DISABLED: number;
        ENABLED: number;
        EMPLOYEE_DISABLED: number;
    };
    public MODULES: Modules;
    public activeFeatures: any;
    public signatureStats: any;
    public signatureStatsData: any;
    public webappUrl: string;
    public hasActiveContractFeatures: boolean;
    public hasDisabledContractFeatures: boolean;
    public documentsExpiredDate: { day: string; month: string; year: string };

    constructor(
        private location: Location,
        private translate: TranslateService,
        private notificationService: DuiNotificationsService,
        public dialogService: DialogService,
        private activeFeaturesService: ActiveFeaturesService,
        private formUtilsService: FormUtilsService
    ) {
        this.userSession = window['getUserSession']();
        this.companyId = this.userSession.companyId;
        this.webappUrl = Constants.webappUrl.replace(/^http(s?):\/\//, '').replace(/\/$/, '');
        this.MODULES = Constants.MODULES;
        this.MODULES_STATES = Constants.MODULES_STATES;

        this.activeFeatures = {
            knowledgeCenter: {},
            conversations: {},
            experiences: {},
            webapp: {},
            timeOff: {},
            documents: {},
            chat: {},
            employeeDirectory: {},
            timeTracking: {}
        };

        this.signatureStats = {};

        this.signatureStatsData = {
            category: [],
            consumedBySent: [],
            consumedBySigned: [],
            average: []
        };
    }

    ngOnInit() {
        this.activeFeaturesService.getConfigurationModules(this.companyId).subscribe(
            (response: any) => {
                this.activeFeatures = response;
                this.hasActiveContractFeatures = this.checkAnyActiveContractFeatures(this.activeFeatures);
                this.hasDisabledContractFeatures = this.checkAnyDisabledContractFeature(this.activeFeatures);

                if (this.activeFeatures.documents) {
                    this.formatDocumentExpirationDate();

                    if (this.activeFeatures.documents.productionMode) {
                        this.activeFeaturesService.getRemoteSignatureStats().subscribe((res: any) => {
                            this.signatureStats = res;
                            this.setCategorieMonthsAndParseConsumptions();
                        });
                    }
                }

                this.handleTemporarilyDeactivateToggles();
            },
            (error: any) => {
                console.error(error);
            }
        );
    }

    formatDocumentExpirationDate() {
        const expirationDate = moment(this.activeFeatures.documents.expirationDate);
        this.documentsExpiredDate = {
            day: moment(expirationDate).format('D'),
            month: moment(expirationDate).format('MMMM'),
            year: moment(expirationDate).format('YYYY')
        };
    }

    getContractFeatureStates(activeFeatures): number[] {
        return [activeFeatures.documents.state, activeFeatures.timeOff.state, activeFeatures.chat.state, activeFeatures.timeTracking.state];
    }

    checkAnyActiveContractFeatures(activeFeatures): boolean {
        const featureStates = this.getContractFeatureStates(activeFeatures);
        return featureStates.some((state) => state !== this.MODULES_STATES.DISABLED);
    }

    checkAnyDisabledContractFeature(activeFeatures): boolean {
        const featureStates = this.getContractFeatureStates(activeFeatures);
        return featureStates.some((state) => state === this.MODULES_STATES.DISABLED);
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    // Change state methods

    private changeModuleState(module: any, state: number) {
        module.state = state;

        if (module.state === this.MODULES_STATES.DISABLED) {
            this.handleTemporarilyDeactivateToggles();
        }

        this.setModuleState(module.id, module.state);
    }

    changeModuleStateByName(moduleName: string, state: number) {
        this.changeModuleState(this.activeFeatures[moduleName], state);
    }

    // Toggle state methods

    private toggleModuleState(module: any) {
        module.state = module.temporarilyDeactivate ? this.MODULES_STATES.EMPLOYEE_DISABLED : this.MODULES_STATES.ENABLED;

        this.setTemporarilyDeactivateModule(module.id, module.temporarilyDeactivate);
    }

    toggleModuleStateByName(moduleName: string) {
        this.toggleModuleState(this.activeFeatures[moduleName]);
    }

    onToggleByDefault(moduleId: number, state: number) {
        const handleResponse = (response: any) => {
            this.updateActiveModules(response.module, response.state, response.enabledByDefault);

            const message = this.translate.instant('global.messages.changesUpdated.success');
            this.notificationService.showSuccessNotification(message);
        };

        const handleError = (error: any) => {
            const errorMsg = this.translate.instant('global.messages.changesUpdated.error');
            this.notificationService.showErrorNotification(errorMsg);
        };

        const serviceCall = state
            ? this.activeFeaturesService.enableByDefault(this.companyId, moduleId)
            : this.activeFeaturesService.disableByDefault(this.companyId, moduleId);

        serviceCall.subscribe(handleResponse, handleError);
    }

    onToggleHideSignature() {
        const handleResponse = (response: any) => {
            this.userSession.activeModules.documents.backofficeHidden = response.backofficeHidden;
            const message = this.translate.instant('global.messages.changesUpdated.success');
            this.notificationService.showSuccessNotification(message);
        };

        const handleError = (error: any) => {
            const errorMsg = this.translate.instant('global.messages.changesUpdated.error');
            this.notificationService.showErrorNotification(errorMsg);
        };

        const serviceCall = this.activeFeatures.documents.backofficeHidden
            ? this.activeFeaturesService.hideSignature(this.companyId)
            : this.activeFeaturesService.showSignature(this.companyId);

        serviceCall.subscribe(handleResponse, handleError);
    }

    showNewHireInfo() {
        const dialogValues: DialogValueOptions = {
            title: 'activeFeatures.signature.newHireModal.title',
            message: 'activeFeatures.signature.newHireModal.message',
            acceptButtonText: 'global.form.back',
            acceptButtonTextClass: ' ',
            hideCancelButton: true
        };

        this.dialogService.openConfirmationDialog(dialogValues);
    }

    // Revisar bien esta funcion
    private updateActiveModules(module: number, state: any, byDefault?: boolean) {
        const moduleMap = {
            1: this.MODULES.KNOWLEDGE_CENTER,
            2: this.MODULES.CONVERSATIONS,
            3: this.MODULES.EXPERIENCES,
            4: this.MODULES.WEBAPP,
            5: this.MODULES.HOLIDAYS,
            6: this.MODULES.DOCUMENTS,
            7: this.MODULES.CHAT,
            8: this.MODULES.DIRECTORY,
            9: this.MODULES.TIME_TRACKING
        };

        const moduleName = moduleMap[module];

        if (moduleName) {
            this.userSession.activeModules[moduleName].state = state;

            if (byDefault !== undefined) {
                this.userSession.activeModules[moduleName].enabledByDefault = byDefault;
            }
        }
    }

    private handleTemporarilyDeactivateToggles(): void {
        const modules = Object.keys(this.activeFeatures);
        modules.forEach((module) => {
            const state = this.activeFeatures[module].state;
            this.activeFeatures[module].temporarilyDeactivate = state === this.MODULES_STATES.EMPLOYEE_DISABLED;
        });
    }

    private setModuleState(moduleId: number, moduleState: number) {
        const handleResponse = (response: any) => {
            this.updateActiveModules(response.module, response.state);
            this.formUtilsService.finishModulesUpdate();

            const message = this.translate.instant('global.messages.changesUpdated.success');
            this.notificationService.showSuccessNotification(message);
        };

        const handleError = (error: any) => {
            this.notificationService.showErrorNotification();
        };

        const serviceCall =
            moduleState == this.MODULES_STATES.ENABLED
                ? this.activeFeaturesService.enableModule(this.companyId, moduleId)
                : this.activeFeaturesService.disableModule(this.companyId, moduleId);

        serviceCall.subscribe(handleResponse, handleError);
    }

    private setTemporarilyDeactivateModule(moduleId: number, state: number) {
        const handleResponse = (response: any) => {
            this.updateActiveModules(response.module, response.state);
            const message = this.translate.instant('global.messages.changesUpdated.success');
            this.notificationService.showSuccessNotification(message);
        };

        const handleError = (error: any) => {
            const errorMsg = this.translate.instant('global.messages.changesUpdated.error');
            this.notificationService.showErrorNotification(errorMsg);
        };

        const serviceCall = state
            ? this.activeFeaturesService.temporarilyDeactivateModule(this.companyId, moduleId)
            : this.activeFeaturesService.temporarilyActivateModule(this.companyId, moduleId);

        serviceCall.subscribe(handleResponse, handleError);
    }

    private setCategorieMonthsAndParseConsumptions() {
        this.signatureStats.consumptionBySent.forEach((item, index) => {
            // Category axis
            const formattedMonth = moment(item.month, 'M').format('MMM').charAt(0).toUpperCase();
            this.signatureStatsData.category.push(formattedMonth);

            // consumedBySigned series
            const signedCount = this.signatureStats.consumptionBySigned[index].count;
            this.signatureStatsData.consumedBySigned.push({
                value: signedCount,
                bothRadius: !item.count
            });

            // consumedBySent series
            const sentCount = item.count;
            this.signatureStatsData.consumedBySent.push({
                value: sentCount,
                bothRadius: !signedCount
            });

            // Average series
            this.signatureStatsData.average.push(this.signatureStats.averageConsumption);
        });
    }

    // Chart methods

    signedVisual = (e: any) => {
        return e.dataItem.bothRadius ? this.bothRadius(e.rect, e.options.color, e) : this.radiusBottom(e.rect, e.options.color, e);
    };

    sentVisual = (e: any) => {
        return e.dataItem.bothRadius ? this.bothRadius(e.rect, e.options.color, e) : this.radiusTop(e.rect, e.options.color, e);
    };

    /**
     * Creates a path based on the specified type.
     * @param rect - The rectangle defining the path boundaries.
     * @param color - The color of the path.
     * @param type - The type of path to create ('bothRadius', 'radiusBottom', 'radiusTop').
     * @returns The created path.
     */
    private createPath(rect: any, color: string, type: string): any {
        const origin = rect.origin;
        const bottomRight = rect.bottomRight();
        const radiusX = rect.width() / 2;
        const radiusY = radiusX;

        let path = new Path({
            fill: { color: color },
            stroke: { color: 'none' }
        });

        const drawArc = (startX: number, startY: number, endX: number, endY: number, arcStart: number, arcEnd: number) => {
            path.moveTo(startX, startY).lineTo(startX, endY).arc(arcStart, arcEnd, radiusX, radiusY, true).lineTo(endX, startY);
        };

        switch (type) {
            case 'bothRadius':
                drawArc(origin.x, origin.y, bottomRight.x, bottomRight.y, 180, 0);
                path.arc(0, 180, radiusX, radiusY, true);
                break;
            case 'radiusBottom':
                drawArc(origin.x, origin.y, bottomRight.x, bottomRight.y, 180, 0);
                break;
            case 'radiusTop':
                drawArc(bottomRight.x, bottomRight.y, origin.x, origin.y, 0, 180);
                break;
            default:
                throw new Error(`Unknown path type: ${type}`);
        }

        return path;
    }

    /**
     * Creates a chart group based on the specified type.
     * @param rect - The rectangle defining the group boundaries.
     * @param color - The color of the group.
     * @param type - The type of group to create ('bothRadius', 'radiusBottom', 'radiusTop').
     * @param e - The data element.
     * @returns The created group or an empty string if the element value is less than or equal to 0.
     */
    private createGroup(rect: any, color: string, type: string, e: any): any {
        const path = this.createPath(rect, color, type);
        const group = new Group();
        group.append(path);
        return e.value > 0 ? group : '';
    }

    private bothRadius(rect: any, color: string, e: any): any {
        return this.createGroup(rect, color, 'bothRadius', e);
    }

    private radiusBottom(rect: any, color: string, e: any): any {
        return this.createGroup(rect, color, 'radiusBottom', e);
    }

    private radiusTop(rect: any, color: string, e: any): any {
        return this.createGroup(rect, color, 'radiusTop', e);
    }
}

interface Modules {
    KNOWLEDGE_CENTER: string;
    CONVERSATIONS: string;
    EXPERIENCES: string;
    WEBAPP: string;
    HOLIDAYS: string;
    DOCUMENTS: string;
    SIGNATURE: string;
    CHAT: string;
    DIRECTORY: string;
    TIME_TRACKING: string;
}
