<div class="main-container__content">
    <div class="main-container--full-width">
        <div class="info-advice filter-container">
            <p [translate]="'departments.advice'"></p>
        </div>
        <div class="filter-container">
            <div class="flex">
                <div class="flex direction-column justify-content-end">
                    <!-- Actives / Archived button bar -->
                    <div class="button-bar">
                        <button class="button button--small" [ngClass]="{'button--selected' : dataFilterOpts.active}" (click)="getDepartmentsByIsActive(true)">
                            <span class="button__label" [translate]="'global.listFilters.active'"></span>
                        </button>
                        <button
                            class="button button--small"
                            [ngClass]="{'button--selected' : !dataFilterOpts.active}"
                            (click)="getDepartmentsByIsActive(false)">
                            <span class="button__label" [translate]="'global.listFilters.archived'"></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="search-filter-container align-items-center">
                <input
                    type="text"
                    class="input input--search"
                    maxlength="1024"
                    [placeholder]="'departments.grid.name' | translate"
                    [(ngModel)]="dataFilterOpts.name"
                    (input)="listItemsSearch()"
                    ngModelOptions="{ debounce: 200 }"
                    [ngClass]="dataFilterOpts.name.length > 0 ? 'showClearBtn' : 'hideClearBtn'" />
                <div class="icon icon-remove" (click)="clearSearchInput()"></div>
            </div>
        </div>

        @if (departmentsDatagrid?.data.length > 0) {
            <kendo-grid
                #departmentsGrid
                [data]="departmentsDatagrid"
                class="master-grid department-grid user-select-none margin-bottom-40 org-grid"
                [sortable]="true"
                [sort]="gridSort"
                (sortChange)="onSortChange($event)"
                (cellClick)="onCellClick($event)"
                (dblclick)="onDblClick()"
                [pageable]="gridPagerSettings"
                (pageChange)="onPageChange($event)"
                [skip]="dataPagingOpts.skip"
                [pageSize]="dataPagingOpts.size"
                [navigable]="true"
                [resizable]="false"
                [selectable]="false"
                [loading]="isSyncing">
                <kendo-grid-messages
                    [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                    [pagerOf]="'global.lists.pagerOf' | translate"
                    [pagerItems]="'global.lists.pagerItems' | translate">
                </kendo-grid-messages>
                <!-- Department name -->
                <kendo-grid-column field="name">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center cursor-pointer">
                            <span class="truncate" [textContent]="'departments.grid.name' | translate"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center">
                            <span class="truncate cursor-default" [textContent]="dataItem.name"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- Department manager -->
                <kendo-grid-column field="responsible" [sortable]="false" [headerStyle]="{'cursor': 'default !important', 'pointer-events': 'none'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center">
                            <span [textContent]="'timeoff.teams.grid.teamLeader' | translate"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        @if (dataItem.responsible) {
                            <div class="flex align-items-center">
                                <avatar
                                    [name]="dataItem.responsible.name"
                                    [surname]="dataItem.responsible.surname"
                                    [small]="true"
                                    [thumbnails]="{thumbnail_M: dataItem.responsible.avatarThumbnailMUrl || dataItem.responsible.avatarUrl, thumbnail_S: dataItem.responsible.avatarThumbnailSUrl || dataItem.responsible.avatarThumbnailMUrl}">
                                </avatar>
                                <span class="margin-left-10" [textContent]="dataItem.responsible.name"></span>
                                <span class="margin-left-5 truncate" [textContent]="dataItem.responsible.surname"></span>
                            </div>
                        }
                    </ng-template>
                </kendo-grid-column>
                <!-- Department employees -->
                <kendo-grid-column
                    field="employeeCount"
                    width="64"
                    [sortable]="false"
                    [style]="{'padding': '0 4px'}"
                    [headerStyle]="{'cursor': 'default !important', 'pointer-events': 'none'}">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="flex align-items-center justify-content-center cursor-pointer" [style.marginInline]="'auto'">
                            <span class="icon nucleo icon-user-groups-filled icon-22"></span>
                        </div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center justify-content-center">
                            <span class="document-label__text cursor-default" [textContent]="dataItem.employeeCount"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <!-- CTA buttons column -->
                <kendo-grid-command-column width="136" [style]="{ 'text-align': 'end' }">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <div class="flex justify-end td-on-hover" kendoTooltip filter="a.k-master-button" offset="0">
                            <a class="k-button k-master-button" (click)="updateDepartment(dataItem)" [title]="'global.form.edit' | translate">
                                <span class="icon icon-edit"></span>
                            </a>
                            @if (!dataItem.deletable || !dataItem.active) {
                                <a
                                    class="k-button k-master-button"
                                    (click)="archiveOrActivateDepartment(dataItem)"
                                    [title]="(dataItem.active ? 'global.form.archive' : 'global.form.activate') | translate">
                                    <span class="icon" [ngClass]="dataItem.active ? 'icon-archive' : 'icon-unarchive'"></span>
                                </a>
                            }
                            @if (dataItem.deletable && dataItem.active) {
                                <a class="k-button k-master-button" (click)="deleteDepartment(dataItem)" [title]="'global.form.delete' | translate">
                                    <span class="icon icon-delete"></span>
                                </a>
                            }
                        </div>
                    </ng-template>
                </kendo-grid-command-column>
                <ng-template kendoGridNoRecordsTemplate></ng-template>
                <!-- Custom Pager -->
                <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                    <article class="dui-grid-pager">
                        <kendo-pager-info></kendo-pager-info>
                        @if (departmentsDatagrid.total > 10) {
                            <article class="dui-grid-pager__numbers-wrap" *ngIf="totalPages > 1">
                                <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                            </article>
                            <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                        }
                    </article>
                </ng-template>
            </kendo-grid>
        }

        <!-- Skeleton departments grid -->
        @if (departmentsDatagrid?.total == 0 && isSyncing) {
            <div>
                <kendo-grid [kendoGridBinding]="dataSkeleton" class="master-grid" [rowClass]="skeletonRowCallback">
                    <!-- Department name -->
                    <kendo-grid-column>
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center">
                                <span [textContent]="'departments.grid.name' | translate"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <kendo-skeleton shape="text" animation="pulse" [width]="300" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column>
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center">
                                <span [textContent]="'timeoff.teams.grid.teamLeader' | translate"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center">
                                <kendo-skeleton shape="circle" animation="pulse" [width]="28" [height]="28"></kendo-skeleton>
                                <kendo-skeleton
                                    shape="text"
                                    animation="pulse"
                                    [width]="250"
                                    [height]="20"
                                    style="border-radius: 4px"
                                    class="margin-left-10"></kendo-skeleton>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- Department employees -->
                    <kendo-grid-column media="(min-width: 1240px)" width="64">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="flex align-items-center justify-content-center padding-left-5">
                                <!-- <span [textContent]="'model.workplaces.employees' | translate"></span> -->
                                <span class="icon nucleo icon-user-groups-filled"></span>
                            </div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="flex align-items-center justify-content-center">
                                <kendo-skeleton shape="text" animation="pulse" [width]="20" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- CTA column -->
                    <kendo-grid-command-column width="136"></kendo-grid-command-column>
                </kendo-grid>
            </div>
        }

        <!-- No data information -->
        @if (departmentsDatagrid.total == 0 && !dataFilterOpts.name && !isSyncing) {
            <no-results
                [imageTitle]="'departments.noResultsTitle'"
                [description]="dataFilterOpts.active ? 'departments.noResultsText' : 'departments.noResultsArchiveText'">
            </no-results>
        }
        @if (departmentsDatagrid.total == 0 && dataFilterOpts.name && !isSyncing) {
            <no-search-results></no-search-results>
        }
    </div>
</div>
