import { Component, Input, OnInit } from '@angular/core';
import { CategoriesTreeService } from './categories-tree.service';

@Component({
    selector: 'categories-tree',
    templateUrl: './categories-tree.component.html'
})
export class CategoriesTreeComponent implements OnInit {
    @Input() isEditable: boolean;
    @Input() selectedCategoryId?: number;

    public categories: Array<any>;

    constructor(private categoriesTreeService: CategoriesTreeService) {}

    ngOnInit() {
        this.categoriesTreeService.getKnowledgeCenterCategoriesTree().subscribe((response) => {
            this.categories = response;
            if (this.selectedCategoryId) {
                this.categoriesTreeService.selectCategory(this.selectedCategoryId);
                this.scrollToSelectedItemIfNecessary();
            }
        });
    }

    private scrollToSelectedItemIfNecessary() {
        setTimeout(() => {
            const modalOffset = $('.modal-body').offset().top + 20;
            const contentHeight = $('.modal-body').innerHeight() - 40; // 40px of padding
            const itemPosition = $('#category-' + this.selectedCategoryId + '-name').offset().top - modalOffset;
            if (itemPosition >= contentHeight) {
                $('.modal-body').animate(
                    {
                        scrollTop: itemPosition
                    },
                    0
                );
            }
        }, 100);
    }
}
