import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { WorkplacesService } from '../workplaces/workplaces.service';

@Component({
    templateUrl: './workplace-create-modal.component.html'
})
export class WorkplaceCreateModalComponent implements AfterViewInit {
    public isFormValid: boolean = false;
    public isNameDuplicated: boolean = false;
    public workplaceForm: any = {
        name: '',
        director: {}
    };
    @ViewChild('workplaceName') workplaceNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<WorkplaceCreateModalComponent>,
        private workplacesService: WorkplacesService,
        private _elementRef: ElementRef
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.workplaceNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        // this.workplaceNameRef.nativeElement.classList.remove('input--error');
        this.isFormValid = this.workplaceForm.name.length > 0;
    }

    onEnterPressed(): void {
        if (this.isFormValid) {
            const createChatBtn: HTMLElement = this._elementRef.nativeElement.querySelector('.button--secondary');
            createChatBtn.click();
        }
    }

    onAccept(): void {
        if (!this.workplaceForm.director?.id) {
            this.workplaceForm.director = null;
        }
        this.workplacesService.create(this.workplaceForm).subscribe(
            (onSuccess: any) => {
                // Get the new workplace id, return it and close modal
                this.dialogRef.close({ success: onSuccess });
            },
            (onError: any) => {
                if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
                    this.isNameDuplicated = true;
                }
                this.workplaceNameRef.nativeElement.focus();
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }
}
