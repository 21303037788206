import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { NormalizeUnicodePipe } from '../../pipes/normalize-unicode.pipe';

@Component({
    selector: 'filekinds-selection-boxes',
    templateUrl: './filekinds-selection-boxes.component.html'
})
export class FilekindsSelectionBoxesComponent implements OnChanges {
    @Input() availableFilekinds: Array<any> = [];
    @Input() addedFilekinds: Array<any> = [];
    @Input() filterAvailable = '';
    @Input() filterAdded = '';
    @Input() isSyncing: boolean;
    @Input() addedFilekindsIds: Array<number>;
    @Output() addedFilekindsIdsChange: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();

    private singleClickTimer: any;
    private preventSingleClick = false;
    public selectedFilekinds = [];
    public filteredAddedFilekinds = [];

    constructor(
        private normalizeUnicodePipe: NormalizeUnicodePipe,
        private utilsService: UtilsService
    ) {}

    ngOnChanges(changes: any) {
        if (
            changes.availableFilekinds &&
            changes.availableFilekinds.currentValue &&
            changes.availableFilekinds.currentValue !== changes.availableFilekinds.previousValue
        ) {
            this.unselectAvailableFilekinds();
            this.toggleAddedPropertyInAvailableFilekinds();
        }

        if (
            changes.addedFilekinds &&
            changes.addedFilekinds.currentValue &&
            (changes.addedFilekinds.currentValue !== changes.addedFilekinds.previousValue || changes.addedFilekinds.currentValue.length > 0)
        ) {
            this.searchAddedFilekinds();
            this.addedFilekindsIds = this.getAddedFilekindsIds();
            this.addedFilekindsIdsChange.emit(this.addedFilekindsIds);
        }

        if (changes.filterAdded && changes.filterAdded.currentValue !== changes.filterAdded.previousValue) {
            this.searchAddedFilekinds();
        }
    }

    trackByFn(index: number, item: any): number {
        return index;
    }

    private getIndexOfItemInFilekinds(filekinds: Array<any>, filekindId: number): number {
        const filekindsLength = filekinds.length;
        for (let index = 0; index < filekindsLength; index++) {
            if (filekinds[index].id === filekindId) {
                return index;
            }
        }
        return -1;
    }

    private addAvailableFilekind(filekind: any): void {
        filekind['selected'] = false;

        if (this.addedFilekindsIds.indexOf(filekind.id) === -1) {
            // If filekind is not already added, add it
            this.addedFilekinds.push(filekind);
        }

        const selectedFilekindIndex = this.getIndexOfItemInFilekinds(this.selectedFilekinds, filekind.id);
        if (selectedFilekindIndex !== -1) {
            // If filekind was selected, remove from selectedFilekinds array
            this.selectedFilekinds.splice(selectedFilekindIndex, 1);
        }
    }

    private getAddedFilekindsIds(): Array<number> {
        return this.addedFilekinds.length > 0
            ? this.addedFilekinds.map(function (kind) {
                  return kind.id;
              })
            : [];
    }

    private toggleAddedPropertyInAvailableFilekinds(): void {
        this.availableFilekinds.forEach((kind) => {
            kind['added'] = this.addedFilekindsIds.indexOf(kind.id) !== -1;
        });
    }

    private searchAddedFilekinds(): void {
        const self = this;
        this.filteredAddedFilekinds = this.addedFilekinds.filter(function (filekind) {
            const searchString = self.normalizeUnicodePipe.transform(filekind['name']);
            const inputText = self.normalizeUnicodePipe.transform(self.filterAdded);
            return inputText.length > 2 ? searchString.toLowerCase().indexOf(inputText.toLowerCase()) > -1 : true;
        });
    }

    private syncAddedFilekinds(): void {
        this.searchAddedFilekinds();
        this.addedFilekindsIds = this.getAddedFilekindsIds();
        this.addedFilekindsIdsChange.emit(this.addedFilekindsIds);
        this.toggleAddedPropertyInAvailableFilekinds();
    }

    doubleClickedFilekind(filekind: any): void {
        clearTimeout(this.singleClickTimer);
        this.preventSingleClick = true;
        if (!filekind.added) {
            this.addAvailableFilekind(filekind);
            this.syncAddedFilekinds();
        }
    }

    addAllFilteredAvailableFilekinds(): void {
        this.availableFilekinds.forEach((kind) => {
            this.addAvailableFilekind(kind);
        });

        this.syncAddedFilekinds();
    }

    private unselectAvailableFilekinds(): void {
        this.availableFilekinds.forEach((kind) => {
            kind['selected'] = false;
        });
    }

    addSelectedFilekinds(): void {
        if (document.querySelectorAll('.selected').length > 0) {
            Array.prototype.push.apply(this.addedFilekinds, this.selectedFilekinds);
            this.selectedFilekinds = [];
            this.syncAddedFilekinds();

            this.unselectAvailableFilekinds();
        }
    }

    private toggleFilekindInSelectedFilekinds(filekind: any, index: number): void {
        if (index === -1) {
            this.selectedFilekinds.push(filekind);
        } else {
            this.selectedFilekinds.splice(index, 1);
        }
    }

    selectFilekindFromAvailables(filekind: any, $event: any): void {
        const self = this;
        this.singleClickTimer = setTimeout(function () {
            if (!self.preventSingleClick) {
                if (!filekind.added) {
                    filekind.selected = !filekind.selected;

                    const filekindIndex = self.getIndexOfItemInFilekinds(self.selectedFilekinds, filekind.Id);
                    self.toggleFilekindInSelectedFilekinds(filekind, filekindIndex);
                }
            }
            self.preventSingleClick = false;
        }, 200);
    }

    private removeAddedFilekind(filekind: any): void {
        filekind.added = false;

        const filekindIndex = this.getIndexOfItemInFilekinds(this.addedFilekinds, filekind.id);
        if (filekindIndex !== -1) {
            this.addedFilekinds.splice(filekindIndex, 1);
        }
    }

    removeAddedItemFromList(filekind: any): void {
        this.removeAddedFilekind(filekind);
        this.syncAddedFilekinds();
    }

    removeAllFilteredAddedFilekinds(): void {
        if (this.filteredAddedFilekinds.length > 0) {
            this.filteredAddedFilekinds.forEach((filekind) => {
                this.removeAddedFilekind(filekind);
            });
        }
        this.syncAddedFilekinds();
    }

    filekindColor(color: number): string {
        return this.utilsService.decimalToHex(color);
    }
}
