import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompaniesService } from '../companies/companies.service';

@Component({
    templateUrl: './company-create-modal.component.html'
})
export class CompanyCreateModalComponent implements AfterViewInit {
    public isFormValid: boolean = false;
    public isNameDuplicated: boolean = false;
    public companyForm: any = {
        name: '',
        cif: null,
        director: null
    };
    @ViewChild('companyName') companyNameRef: ElementRef;

    constructor(
        public dialogRef: MatDialogRef<CompanyCreateModalComponent>,
        private _elementRef: ElementRef,
        private companiesService: CompaniesService
    ) {}

    ngAfterViewInit(): void {
        setTimeout(() => this.companyNameRef.nativeElement.focus(), 200);
    }

    onChange(): void {
        // this.companyNameRef.nativeElement.classList.remove('input--error');
        this.isFormValid = this.companyForm.name.length > 0;
    }

    onEnterPressed(): void {
        if (this.isFormValid) {
            const createCompanyBtn: HTMLElement = this._elementRef.nativeElement.querySelector('.button--secondary');
            createCompanyBtn.click();
        }
    }

    onAccept(): void {
        this.companyForm.cif = !!this.companyForm.cif ? this.companyForm.cif : null;
        this.companyForm.director = !!this.companyForm.director && this.companyForm.director.id ? this.companyForm.director : null;

        this.companiesService.create(this.companyForm).subscribe(
            (onSuccess: any) => {
                // Get the new company id, return it and close modal
                this.dialogRef.close({ success: onSuccess });
            },
            (onError: any) => {
                if (onError.status === 422 && onError.error?.error === 'IllegalOperationException') {
                    this.isNameDuplicated = true;
                }
                this.companyNameRef.nativeElement.focus();
            }
        );
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }
}
