<div class="main-container">
    <div class="main-container__header" style="min-width: 891px;">
        <div class="flex align-items-end justify-space-between width-100-percent">
            <div class="flex direction-column width-100-percent" spellcheck="false">
                <ul class="breadcrumb">
                    <li>
                        <a href="/global-settings" [textContent]="'global.navbar.globalSettings' | translate"></a>
                    </li>
                    <li>
                        <a id="go-companies" href="/settings/my-company/companies" [textContent]="'myCompany.tabs.companies' | translate"></a>
                    </li>
                </ul>
                <div class="editable-wrapper">
                    <h2
                        #companyName
                        class="icon icon-edit contenteditable editable-title"
                        [textContent]="companyForm.name"
                        ([ngModel])="companyForm.name"
                        [contentEditable]="true"
                        prevent-event="dragover drop"
                        (blur)="onInputCompanyNameEvent($event)"
                        (keydown.enter)="onInputCompanyNameEvent($event)"
                        (keydown.esc)="onInputCompanyNameEvent($event)"
                        maxlength="64"
                        (paste)="onInputCompanyNamePaste($event)"></h2>
                    <span class="bottom-line"></span>
                </div>
            </div>
            <div class="flex align-items-center" kendoTooltip filter="button" position="top">
                <!-- Add employees to company -->
                @if (selectedTab === COMPANY_TABS.USERS) {
                    <loading-button
                        [buttonId]="'add-users-modal'"
                        [classes]="'button button--secondary button--small'"
                        [iconClass]="'icon-add'"
                        [text]="'schemes.employees.addEmployees'"
                        (action)="openAddUsersModal()"
                        [isValid]="true">
                    </loading-button>
                }
                <!-- Archive company -->
                <!-- <ng-container *ngIf="!companyForm.deletable && selectedTab !== COMPANY_TABS.USERS">
          <button id="archive-unarchive-btn" class="button button--small button--only-icon margin-left-10" (click)="archiveOrDelete()"
            [title]="(companyForm.active ? 'global.form.archive' : 'global.form.activate') | translate">
            <span class="button__icon icon" [ngClass]="companyForm.active ? 'icon-archive' : 'icon-unarchive'"></span>
          </button>
        </ng-container> -->
                <!-- Delete company -->
                <!-- <ng-container *ngIf="(companyForm.deletable && companyForm.active) && selectedTab !== COMPANY_TABS.USERS">
          <button id="delete-btn" class="button button--small button--only-icon margin-left-10" (click)="archiveOrDelete()"
            [title]="'global.form.delete' | translate">
            <span class="button__icon icon icon-delete"></span>
          </button>
        </ng-container> -->
            </div>
        </div>
    </div>
    <div class="main-container__content">
        <div class="main-container--full-width">
            <div class="tabs-language-container margin-bottom-20">
                <div class="tab-nav tab-nav--inline" style="width:100%">
                    <span
                        id="general-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === COMPANY_TABS.GENERAL}"
                        [textContent]="'subsidiaries.tabs.general' | translate"
                        (click)="loadTabContent(COMPANY_TABS.GENERAL)">
                    </span>
                    <span
                        id="users-tab"
                        class="tab-nav__item"
                        [ngClass]="{'tab-nav__item--selected': selectedTab === COMPANY_TABS.USERS}"
                        [textContent]="'subsidiaries.tabs.employees' | translate"
                        (click)="loadTabContent(COMPANY_TABS.USERS)"></span>
                </div>
            </div>
        </div>
    </div>
    @if (selectedTab === COMPANY_TABS.GENERAL) {
        <company-general [companyId]="companyId" [(companyForm)]="companyForm"></company-general>
    }
    @if (selectedTab === COMPANY_TABS.USERS) {
        <company-employees [companyId]="companyId" [(employees)]="companyForm.employees"></company-employees>
    }
</div>
