import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageBankService } from './image-bank.service';
import { TranslateService } from '@ngx-translate/core';
import { AttachedContentUtilsService } from '../../shared/services/attached-content-utils.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { Constants } from '../../constants';

/**
 * Image Bank: Component for search or upload from local all images kind, including gifs
 * How it works:
 * - It is displayed in a modal window. It receives the parameters through the "data" object that is injected into the modal component.
 * - Returns an object with various properties, depending on the operations that have been performed.
 * - You can configure the selection to upload image or browse image, using the boolean property data.uploadAllowed. If true, two buttons are showed
 *      - Upload mode: Detect if file is an image file. If is a gif, no crop are necessary. If is other image, crop method are launched, with no apect ratio.
 *      - Search mode: Show select button (pic or gif) if both gifsAllowed and picsAllowed properties are setted to true.
 *          - If gif selected no crop necessary.
 *          - If pic selected, crop image and upload resource if insertInEditor property is true.
 */

@Component({
    selector: 'image-bank',
    templateUrl: 'image-bank.html',
    styleUrls: ['./_image-bank.component.scss']
})
export class ImageBankComponent implements OnInit {
    public queryText: string = '';
    private initialQueryText: string = '';
    private queryTextsExamples = {
        es: [
            'oficina',
            'comunicar',
            'recursos humanos',
            'gestión',
            'trabajando',
            'reunión de trabajo',
            'videoconferencia',
            'gráfico de ventas',
            'ordenador',
            'finanzas'
        ],
        ca_ES: [
            'oficina de negocis',
            'comunicar',
            'recursos humans',
            'gestió',
            'treballant',
            'reunió de treball',
            'videoconferència',
            'gràfic de vendes',
            'ordinador',
            'finances'
        ],
        en: [
            'business office',
            'communicate',
            'human resources',
            'management',
            'working',
            'work meeting',
            'video conference',
            'sales chart',
            'computer',
            'finances'
        ],
        eu_ES: [
            'business office',
            'communicate',
            'human resources',
            'management',
            'working',
            'work meeting',
            'video conference',
            'sales chart',
            'computer',
            'finances'
        ],
        fr: [
            "bureau d'affaires",
            'communiquer',
            'ressources humaines',
            'gestion',
            'travailler',
            'réunion de travail',
            'vidéoconférence',
            'graphique des ventes',
            'ordinateur',
            'finances'
        ],
        it: [
            'ufficio commerciale',
            'comunicare',
            'risorse umane',
            'gestione',
            'lavorando',
            'riunione di lavoro',
            'videoconferenza',
            'grafico delle vendite',
            'computer',
            'finanze'
        ],
        sk: [
            'obchodná kancelária',
            'komunikovať',
            'ľudské zdroje',
            'riadenie',
            'pracovať',
            'pracovné stretnutie',
            'videokonferencia',
            'graf predaja',
            'počítač',
            'financie'
        ],
        ro: [
            'birou de afaceri',
            'comunica',
            'resurse umane',
            'management',
            'lucrând',
            'ședință de lucru',
            'videoconferință',
            'grafic de vânzări',
            'computer',
            'finanțe'
        ],
        zh: ['商务办公室', '沟通', '人力资源', '管理', '工作中', '工作会议', '视频会议', '销售图表', '计算机', '财务']
    };
    private userSession: any;
    private userLanguage: string;
    private pexelsSearchLang: string;
    public userQueryText: string = '';
    public userGifQueryText: string = '';
    public currentPage: number = 1;
    public responseData: any = {};
    public responseGifData: any = {};
    public totalResults: number = 0;
    public isCropperReady: boolean = false;
    public isSyncing = false;
    public selectImageMode: boolean = true;
    public selectedImageURL: string = '';
    private selectedImageName: string = '';
    private selectedImageCropped: string = '';
    public SOURCE_IMAGE: any = {
        UPLOAD: 1,
        SEARCH: 2
    };
    public SOURCE_KIND: any = {
        PICTURE: 1,
        GIF: 2
    };
    private PEXELS_SEARCH_LANGUAGES = Constants.PEXELS_SEARCH_LANGUAGES;
    public selectedSourceKind: number;
    public selectedSource: number;

    constructor(
        public dialogRef: MatDialogRef<ImageBankComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private imageBankService: ImageBankService,
        private translate: TranslateService,
        private attachedContentUtilsService: AttachedContentUtilsService,
        private notificationService: DuiNotificationsService
    ) {
        this.userSession = window['getUserSession']();
        this.userLanguage = this.userSession.language;
        this.pexelsSearchLang = this.PEXELS_SEARCH_LANGUAGES[this.userLanguage] || 'en_US';
    }

    ngOnInit() {
        this.selectedSource = this.data.uploadAllowed ? this.SOURCE_IMAGE.UPLOAD : this.SOURCE_IMAGE.SEARCH;
        this.selectedSourceKind = this.data.gifsAllowed && !this.data.picsAllowed ? this.SOURCE_KIND.GIF : this.SOURCE_KIND.PICTURE;
        this.queryText = this.getRandomQueryText();
        this.initialQueryText = this.queryText;
        this.getImagesFromBank();
        this.getGifsFromBank();
    }

    getImagesFromBank() {
        this.isSyncing = true;
        this.imageBankService.getImagesFromQuery(this.queryText, this.currentPage, this.pexelsSearchLang).subscribe(
            (response: any) => {
                if (this.currentPage == 1) {
                    this.responseData = response;
                } else {
                    response.photos.forEach((element) => {
                        this.responseData.photos.push(element);
                    });
                }
                this.isSyncing = false;
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    getGifsFromBank(): void {
        this.isSyncing = true;
        this.imageBankService.getGifsFromQuery(this.queryText, this.currentPage).subscribe(
            (response: any) => {
                if (this.currentPage == 1) {
                    this.responseGifData = response;
                } else {
                    response.data.forEach((element) => {
                        this.responseGifData.data.push(element);
                    });
                }
                this.isSyncing = false;
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    uploadImages(event: any) {
        let uploadedFile: File = event[0];

        if (!uploadedFile.type.includes('image')) {
            const notificationOptions: NotificationOptions = {
                kind: 'error',
                message: this.translate.instant('message.imageFormatError')
            };
            this.notificationService.showNotification(notificationOptions);
        } else {
            if (uploadedFile.type.includes('gif') || uploadedFile.type.includes('webp')) {
                this.imageBankService.uploadImageFile(uploadedFile, uploadedFile.name).subscribe((response: Array<any>) => {
                    this.dialogRef.close({
                        accepted: true,
                        cancelled: false,
                        data: {
                            imageSrc: null,
                            imageUrl: response[0].url,
                            filename: response[0].nameWithExtension,
                            fixedGifUrl: null,
                            animatedGifUrl: null
                        }
                    });
                });
            } else {
                this.attachedContentUtilsService.selectImage(uploadedFile).subscribe((response) => {
                    this.selectedImageURL = response.image;
                    this.selectedImageName = uploadedFile.name;
                    this.selectImageMode = false;
                    this.data.title = this.translate.instant('imageBank.modal.titleCrop');
                });
            }
        }
    }

    getRandomQueryText(): string {
        return this.queryTextsExamples[this.userLanguage][Math.floor(Math.random() * 9)];
    }

    selectImage(photo: any) {
        this.selectedImageURL = photo.src.large;
        let extension = photo.src.large.split('?')[0].split('.');

        this.selectedImageName = photo.url.split('/')[4] + '.' + extension[extension.length - 1];
        this.selectImageMode = false;
        this.data.title = this.translate.instant('imageBank.modal.titleCrop');
    }

    async selectGif(gifImage: any) {
        let blob = await fetch(gifImage.images.original.webp).then((file) => file.blob());
        this.attachedContentUtilsService.selectImage(blob).subscribe((response: any) => {
            this.dialogRef.close({
                accepted: true,
                cancelled: false,
                data: {
                    imageSrc: response.image,
                    imageUrl: null,
                    filename: gifImage.title + '.gif',
                    fixedGifUrl: gifImage.images.fixed_width.url,
                    animatedGifUrl: gifImage.images.original.webp
                }
            });
        });
    }

    imageCropped(event: any) {
        this.selectedImageCropped = event.base64;
    }

    secondsCounter(index: number): any {
        if (index < 20) {
            return index * 100 + 'ms';
        } else {
            return (index - (this.currentPage - 1) * 20) * 100 + 'ms';
        }
    }

    getItemRow(index: number): number {
        var indexStr = index.toString();
        indexStr = indexStr.charAt(indexStr.length - 1);
        var rowIndex = 0;
        switch (indexStr) {
            case '0':
            case '1':
            case '2':
                rowIndex = index < 10 ? 1 : 1 + 4 * Math.trunc(index / 10);
                break;
            case '3':
            case '4':
                rowIndex = index < 10 ? 2 : 2 + 4 * Math.trunc(index / 10);
                break;
            case '5':
            case '6':
            case '7':
                rowIndex = index < 10 ? 3 : 3 + 4 * Math.trunc(index / 10);
                break;
            case '8':
            case '9':
                rowIndex = index < 10 ? 4 : 4 + 4 * Math.trunc(index / 10);
                break;
            default:
                break;
        }
        return rowIndex;
    }

    getItemRowSpan(index: number): number {
        var indexStr = index.toString();
        indexStr = indexStr.charAt(indexStr.length - 1);
        var rowSpan = 1;
        switch (indexStr) {
            case '2':
            case '5':
                rowSpan = 2;
                break;
            default:
                break;
        }
        return rowSpan;
    }

    getItemCol(index: number): number {
        var indexStr = index.toString();
        indexStr = indexStr.charAt(indexStr.length - 1);
        var colIndex = 1;
        switch (indexStr) {
            case '0':
            case '3':
            case '5':
                break;
            case '1':
            case '6':
            case '8':
                colIndex = 3;
                break;
            case '2':
            case '7':
                colIndex = 4;
                break;
            case '4':
                colIndex = 2;
                break;
            case '9':
                colIndex = 5;
                break;
            default:
                break;
        }
        return colIndex;
    }

    getItemColSpan(index: number): number {
        var indexStr = index.toString();
        indexStr = indexStr.charAt(indexStr.length - 1);
        var colSpan = 1;
        switch (indexStr) {
            case '0':
            case '2':
            case '4':
            case '5':
            case '7':
            case '8':
                colSpan = 2;
                break;
            default:
                break;
        }
        return colSpan;
    }

    onScroll() {
        this.isSyncing = true;
        this.currentPage++;
        this.selectedSourceKind === this.SOURCE_KIND.PICTURE ? this.getImagesFromBank() : this.getGifsFromBank();
    }

    onInputQueryText() {
        this.queryText = this.userQueryText;
        this.currentPage = 1;
        this.getImagesFromBank();
    }

    onGifQueryText() {
        this.queryText = this.userGifQueryText;
        this.currentPage = 1;
        this.getGifsFromBank();
    }

    clearInputQueryText() {
        this.queryText = this.initialQueryText;
        this.userQueryText = '';
        this.currentPage = 1;
        this.getImagesFromBank();
    }

    clearGifQueryText() {
        this.queryText = this.initialQueryText;
        this.userGifQueryText = '';
        this.currentPage = 1;
        this.getGifsFromBank();
    }

    onCancel(): void {
        this.dialogRef.close({ accepted: false, cancelled: true, data: null });
    }

    onAccept(): void {
        if (this.selectedSource === this.SOURCE_IMAGE.UPLOAD || this.data.insertInEditor) {
            let multiPartImage = this.attachedContentUtilsService.dataURItoBlob(this.selectedImageCropped);
            this.imageBankService.uploadImageFile(multiPartImage, this.selectedImageName).subscribe((response: Array<any>) => {
                this.dialogRef.close({
                    accepted: true,
                    cancelled: false,
                    data: {
                        imageSrc: null,
                        imageUrl: response[0].url,
                        filename: response[0].nameWithExtension,
                        fixedGifUrl: null,
                        animatedGifUrl: null
                    }
                });
            });
        } else {
            this.dialogRef.close({
                accepted: true,
                cancelled: false,
                data: {
                    imageSrc: this.selectedImageCropped,
                    imageUrl: this.selectedImageURL,
                    filename: this.selectedImageName + '.png',
                    fixedGifUrl: null,
                    animatedGifUrl: null
                }
            });
        }
    }
}
