<section class="main-container__content">
    <div class="main-container--full-width">
        <div class="info-advice margin-bottom-24 margin-top-0">
            <p [innerHTML]="'feedbackTrays.advice' | translate"></p>
        </div>
        <div class="flex justify-space-between margin-bottom-24" [style.minHeight]="'32px'">
            <div class="label-filters align-self-end">
                <button
                    type="button"
                    [ngClass]="{'selected': stateIsActive}"
                    (click)="getFeedbackTraysByIsActive(true)"
                    [translate]="'feedbackTrays.titleactive'"></button>
                <button
                    type="button"
                    [ngClass]="{'selected': !stateIsActive}"
                    (click)="getFeedbackTraysByIsActive(false)"
                    [translate]="'feedbackTrays.titleinactive'"></button>
            </div>
            @if (feedbackTraysDataGrid.total || inputSearchField || isSyncing) {
                <article class="search-filter-container align-items-center">
                    <input
                        type="text"
                        id="feedback-trays-search-input"
                        data-cy="feedback-trays-search-input"
                        class="input input--search"
                        [ngClass]="inputSearchField.length > 0 ? 'showClearBtn' : 'hideClearBtn'"
                        maxlength="1024"
                        [placeholder]="'feedbackTrays.search' | translate"
                        [(ngModel)]="inputSearchField"
                        (input)="updateSearch()" />
                    <span class="icon icon-remove" (click)="updateSearch(true)"></span>
                </article>
            }
        </div>

        @if (feedbackTraysDataGrid.data.length > 0) {
            <kendo-grid
                #feedbackTraysGrid
                id="feedback-trays-grid"
                class="dialenga-grid margin-bottom-40 user-select-none"
                [data]="feedbackTraysDataGrid"
                [sortable]="true"
                [sort]="gridSort"
                (sortChange)="onSortChange($event)"
                (cellClick)="onCellClick($event)"
                (dblclick)="onDblClick($event)"
                [pageable]="gridPagerSettings"
                [pageSize]="dataPagingOpts.size"
                (pageChange)="onPageChange($event)"
                [skip]="dataPagingOpts.skip"
                [navigable]="true"
                [resizable]="false"
                [selectable]="{mode: 'single'}"
                [loading]="isSyncing">
                <kendo-grid-column field="initials" title="" [width]="90" [class]="'text-center'" [sortable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <avatar-hexagon class="flex" [initials]="dataItem.initials"></avatar-hexagon>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="alias" [title]="'feedbackTrays.header.name' | translate" [width]="250" headerClass="cursor-pointer">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex align-items-center" kendoTooltip filter="span.icon">
                            @if (isDefaultLanguageHidden(dataItem.availableLanguages)) {
                                <span
                                    class="icon-left-vertical-center icon icon-alert"
                                    [title]="'language.contentTooltip.noDefaultLanguage' | translate"></span>
                            }
                            @if (!!dataItem.anonymous) {
                                <span class="icon icon-heisenberg icon-22 margin-right-5" [title]="'feedbackTrays.isAnonymous' | translate"></span>
                            }
                            <span class="truncate" [textContent]="!!dataItem.alias ? dataItem.alias : dataItem.name" [title]="dataItem.name"></span>
                        </div>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="responsibles" [title]="'feedbackTrays.header.responsible' | translate" [sortable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        @if (dataItem.responsibles.length > 0) {
                            <span
                                class="truncate"
                                [innerHTML]="dataItem.responsibles.sort().join(', ')"
                                kendoTooltip
                                filter="span"
                                position="top"
                                offset="8"
                                [tooltipTemplate]="dataItem.responsibles.length > 1 ? responsiblesTooltipTemplate : null">
                            </span>
                        } @else {
                            <span class="truncate capitalize" [innerHTML]="'feedbackTrays.admins' | translate"></span>
                        }

                        <ng-template #responsiblesTooltipTemplate>
                            @for (responsible of dataItem.responsibles; track $index) {
                                <span class="block" [textContent]="responsible"></span>
                            }
                        </ng-template>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="questionnaire" [title]="'conversations.automaticConversation' | translate" [width]="210" [sortable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        @if (dataItem.questionnaire) {
                            @if (dataItem.questionnaire.enabled) {
                                <span class="state-flag state-flag--active state-flag__label" [translate]="'global.form.active_female'"></span>
                            } @else {
                                <span class="state-flag state-flag--inactive state-flag__label" [translate]="'global.form.inactive_female'"></span>
                            }
                        }
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-command-column [width]="192">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                        <div class="dialenga-grid__actions" kendoTooltip filter="button">
                            <button
                                type="button"
                                id="edit-button"
                                data-cy="edit-button"
                                class="dialenga-grid__action-button"
                                [title]="'global.form.edit' | translate"
                                (click)="editFeedbackTray($event, dataItem.id)">
                                <span class="icon icon-edit icon-24"></span>
                            </button>
                            @if (dataItem.deletable) {
                                <button
                                    type="button"
                                    id="delete-button"
                                    data-cy="delete-button"
                                    class="dialenga-grid__action-button"
                                    [title]="'global.form.delete' | translate"
                                    (click)="deleteFeedbackTray($event, dataItem)">
                                    <span class="icon icon-delete icon-24"></span>
                                </button>
                            }
                            @if (!dataItem.deletable && !dataItem.archived) {
                                <button
                                    type="button"
                                    id="archive-button"
                                    data-cy="archive-button"
                                    class="dialenga-grid__action-button"
                                    [title]="'global.form.archive' | translate"
                                    (click)="archiveFeedbackTray($event, dataItem)">
                                    <span class="icon icon-archive icon-24"></span>
                                </button>
                            }
                            @if (dataItem.archived) {
                                <button
                                    type="button"
                                    id="activate-button"
                                    data-cy="activate-button"
                                    class="dialenga-grid__action-button"
                                    [title]="'global.form.activate' | translate"
                                    (click)="activateFeedbackTray($event, dataItem)">
                                    <span class="icon icon-unarchive icon-24"></span>
                                </button>
                            }
                        </div>
                    </ng-template>
                </kendo-grid-command-column>

                <kendo-grid-messages
                    [pagerItemsPerPage]="'global.lists.itemsPerPage' | translate"
                    [pagerOf]="'global.lists.pagerOf' | translate"
                    [pagerItems]="'global.lists.pagerItems' | translate">
                </kendo-grid-messages>

                <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                    <article class="dui-grid-pager">
                        <kendo-pager-info></kendo-pager-info>
                        @if (feedbackTraysDataGrid.total > 10) {
                            @if (totalPages > 1) {
                                <article class="dui-grid-pager__numbers-wrap">
                                    <kendo-pager-prev-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-prev-buttons>
                                    <kendo-pager-numeric-buttons [buttonCount]="gridPagerSettings.buttonCount"></kendo-pager-numeric-buttons>
                                    <kendo-pager-next-buttons class="dui-grid-pager__navigation-buttons"></kendo-pager-next-buttons>
                                </article>
                            }
                            <kendo-pager-page-sizes [pageSizes]="gridPagerSettings.pageSizes"></kendo-pager-page-sizes>
                        }
                    </article>
                </ng-template>
            </kendo-grid>
        }

        @if (feedbackTraysDataGrid.data.length == 0 && isSyncing) {
            <kendo-grid [kendoGridBinding]="dataSkeleton" class="dialenga-grid" [rowClass]="skeletonRowCallback">
                <kendo-grid-column field="initials" title="" [width]="90">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-skeleton shape="circle" animation="pulse" [width]="40" [height]="40"></kendo-skeleton>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="alias" [title]="'feedbackTrays.header.name' | translate" [width]="250">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-skeleton shape="text" animation="pulse" [width]="220" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="responsibles" [title]="'feedbackTrays.header.responsible' | translate">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-skeleton shape="text" animation="pulse" [width]="400" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column field="questionnaire" [title]="'conversations.automaticConversation' | translate" [width]="210">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <kendo-skeleton shape="text" animation="pulse" [width]="72" [height]="20" style="border-radius: 4px"></kendo-skeleton>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-command-column [width]="192"></kendo-grid-command-column>
            </kendo-grid>
        }

        @if (feedbackTraysDataGrid.total == 0 && !inputSearchField && !isSyncing) {
            <no-results
                [imageTitle]="'feedbackTrays.noResultsTitle'"
                [description]="!stateIsActive ? 'feedbackTrays.noResultsText' : 'feedbackTrays.noResultsArchiveText'">
            </no-results>
        }
        @if (feedbackTraysDataGrid.total == 0 && inputSearchField && !isSyncing) {
            <no-search-results></no-search-results>
        }
    </div>
</section>
