<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onClose()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="data.title | translate"></h3>
</div>
<div class="modal-body">
    @if (data.showSubtitle) {
        <div class="info-advice margin-top-0 margin-bottom-10">
            <p [textContent]="data.subtitle | translate"></p>
        </div>
    }

    <div class="cropper-modal-container">
        <dialenga-image-cropper
            [sourceImage]="data.originImage"
            [cropperOptions]="data.cropperOptions"
            [(targetImage)]="croppedImage"
            [roundedCropper]="data.roundedCanvas"
            [transformOptions]="scale">
        </dialenga-image-cropper>
    </div>
    @if (data.showTransformImage) {
        <div class="flex align-items-center justify-content-center margin-top-8">
            <span class="icon icon-24 icon-landscape black-85"></span>
            <kendo-slider
                #imageTransform
                class="margin-left-16 margin-right-16 no-secondary"
                [tickPlacement]="'none'"
                [showButtons]="false"
                [min]="1"
                [max]="9"
                [smallStep]=".25"
                [fixedTickWidth]="5"
                (valueChange)="onTransformImage()"
                [(ngModel)]="zoomImage">
            </kendo-slider>
            <span class="icon icon-24 icon-macro black-85"></span>
        </div>
    }
</div>
<div class="modal-footer flex justify-end">
    <loading-button
        [buttonId]="'crop-image-button'"
        [classes]="'button button--secondary margin-right-10'"
        (action)="onAccept()"
        [isValid]="true"
        [text]="'global.modal.accept'">
    </loading-button>
    <button type="button" class="button" (click)="onClose()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>
