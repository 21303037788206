import { Component, ViewChild, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { ChatroomsService } from './chatrooms.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { ChatroomCreateModalComponent } from '../chatroom-create/chatroom-create-modal.component';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Constants } from '../../constants';

@Component({
    selector: 'chatrooms',
    templateUrl: './chatrooms.component.html'
})
export class ChatroomsComponent implements OnInit {
    public CHATROOM_STATES: any;
    public isSyncing: boolean = false;

    public chatroomsDatagrid: GridDataResult = {
        data: [],
        total: 0
    };
    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };
    public dataPagingOpts: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: 'createdDate,desc'
    };
    public dataFilterOpts: any = {
        archived: false,
        name: ''
    };
    public totalPages: number = 0;
    public dataSkeleton = [{}, {}, {}];
    public gridSort: SortDescriptor[];
    private clickedRowItem: any = '';
    private dblClickEvent: boolean;

    @ViewChild('chatroomsGrid') chatroomsGrid: GridComponent;

    constructor(
        private chatroomsService: ChatroomsService,
        public Dialog: MatDialog,
        private location: Location,
        private notificationService: DuiNotificationsService,
        private translate: TranslateService,
        private dialogService: DialogService
    ) {
        this.CHATROOM_STATES = Constants.CHATROOM_STATES;
    }

    ngOnInit() {
        this.getChatroomsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    getChatroomsGridData(pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;

        this.chatroomsService.findAll(pagingOptions, filterOptions).subscribe(
            (response) => {
                this.chatroomsDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    createChatroom() {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.Dialog.open(ChatroomCreateModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    // Get id chat room from success and navigate to update view
                    this.navigateToEditView(result.success.id);
                }
            });
    }

    navigateToEditView(id: string): void {
        this.location.go('/settings/chatroom/general/update/' + id);
    }

    clearSearchInput() {
        this.dataFilterOpts.name = '';
        this.getChatroomsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    listItemsSearch() {
        if (this.dataFilterOpts.name.length >= 3 || this.dataFilterOpts.name.length === 0) {
            this.getChatroomsGridData(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    getChatroomsByIsArchived(isArchived: boolean) {
        this.dataFilterOpts.archived = isArchived;
        this.getChatroomsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    toggleActivateChat(item: any) {
        let previousState = item.state;
        // Call change state service
        let promiseService = '';
        promiseService = previousState === this.CHATROOM_STATES.ACTIVE ? 'deactivateChat' : 'activateChat';

        const dialogValues: DialogValueOptions = {
            title: item.state !== this.CHATROOM_STATES.ACTIVE ? 'chatrooms.modal.activate.title' : 'chatrooms.modal.deactivate.title',
            message: item.state !== this.CHATROOM_STATES.ACTIVE ? 'chatrooms.modal.activate.message' : 'chatrooms.modal.deactivate.message',
            messageParam: { chatroom: item.name }
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.chatroomsService[promiseService](item.id).subscribe(
                    (onSuccess) => {
                        item.state = onSuccess.state;
                        const message = this.translate.instant(
                            item.state === this.CHATROOM_STATES.ACTIVE ? 'chatrooms.toast.activate.success' : 'chatrooms.toast.deactivate.success',
                            { chatroom: item.name }
                        );
                        this.notificationService.showSuccessNotification(message);
                    },
                    (onError) => {
                        item.state = previousState;
                    }
                );
            }
        });
    }

    updateChatroom(item: any) {
        this.navigateToEditView(item.id);
    }

    archiveOrReactivateChat(item: any) {
        let promiseService = '';
        promiseService = item.state !== this.CHATROOM_STATES.ARCHIVED ? 'archiveChat' : 'deactivateChat';

        if (promiseService) {
            this.chatroomsService[promiseService](item.id).subscribe(
                (onSuccess) => {
                    const message = this.translate.instant(
                        item.state !== this.CHATROOM_STATES.ARCHIVED ? 'chatrooms.toast.archive.success' : 'chatrooms.toast.reactivate.success',
                        { chatroom: item.name }
                    );
                    this.notificationService.showSuccessNotification(message);
                    this.getChatroomsGridData(this.dataPagingOpts, this.dataFilterOpts);
                },
                (onError) => {}
            );
        }
    }

    deleteChat(item: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'chatrooms.modal.delete.title',
            message: 'chatrooms.modal.delete.message',
            messageParam: { chatroom: item.name }
        };
        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.isSyncing = true;

                this.chatroomsService.deleteChat(item.id).subscribe(
                    (onSuccess) => {
                        const message = this.translate.instant('chatrooms.modal.delete.success', { chatroom: item.name });
                        this.notificationService.showSuccessNotification(message);
                        this.getChatroomsGridData(this.dataPagingOpts, this.dataFilterOpts);
                    },
                    (onError) => {}
                );
            }
        });
    }

    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : 'createdDate,desc';
        this.getChatroomsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / this.dataPagingOpts.size;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;
        this.getChatroomsGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
        if (this.dblClickEvent) {
            this.dblClickEvent = false;
            this.navigateToEditView(this.clickedRowItem.id);
        }
    }

    onDblClick(event: any): void {
        this.dblClickEvent = true;
        event.target.click();
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }

    checkElementOverflow(element: any): boolean {
        return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
    }
}
