import { Component, Input, OnInit } from '@angular/core';
import { CategoriesTreeService } from './categories-tree.service';
import { UtilsService } from '../../shared/services/utils.service';
import { Constants } from '../../constants';
import { SharedDataService } from '../../shared/services/shared-data.service';
import { CategoriesService } from '../../shared/services/categories.service';

@Component({
    selector: 'categories-tree-item',
    templateUrl: './categories-tree-item.component.html'
})
export class CategoriesTreeItemComponent implements OnInit {
    @Input() isEditable: boolean;
    @Input() category: any;
    @Input() color: number;
    @Input() treeLevel = 0;
    @Input() selectedLanguage?: string;

    public categoryIdAttr: string;
    public maxSubcategoriesLevels: number;
    public languages: Array<string>;

    private inputPreviousValue: string;

    constructor(
        private utilsService: UtilsService,
        private categoriesTreeService: CategoriesTreeService,
        private categoriesService: CategoriesService,
        private sharedDataService: SharedDataService
    ) {}

    ngOnInit() {
        this.maxSubcategoriesLevels = Constants.MAX_SUBCATEGORIES_LEVELS;

        this.category.color = this.color;
        const brightnessPercentage = this.treeLevel < 4 ? this.treeLevel * 20 : 80;
        this.category.categoryColor = this.utilsService.increaseBrightness(this.color, brightnessPercentage);
        this.categoryIdAttr = 'category-' + (this.category.id ? this.category.id : '');
        this.treeLevel += 1;

        this.setCategoryLanguages();

        this.categoriesTreeService.selectedCategory.subscribe((selectedCategoryId) => {
            if (this.category) {
                this.category.isSelected = this.category.id === selectedCategoryId;
            }
        });
    }

    setCategoryLanguages() {
        this.languages = Object.keys(this.category.contents);

        if (!this.selectedLanguage) {
            this.selectedLanguage = this.sharedDataService.getMainContentLanguage(this.category.contents);
        }
    }

    toggleCategoryOpenedState($event) {
        $event.stopPropagation();
        if (!this.category.isEditionActive && this.category.children.length > 0) {
            this.category.isOpened = !this.category.isOpened;
        }
    }

    inputCategoryOnBlur() {
        this.category.isEditionActive = false;
        if (!this.category.contents[this.selectedLanguage].name) {
            if (!this.category.id) {
                // If name is empty and category doesn't exist previously, delete it
                delete this.category.contents[this.selectedLanguage];
                if (Object.keys(this.category.contents).length === 0) {
                    this.categoriesTreeService.removeLastChildFromCategory(this.category.parentId);
                    delete this.category;
                }
            } else {
                // If name is empty and category already exists, restore previous name
                this.category.contents[this.selectedLanguage].name = this.inputPreviousValue;
            }
        } else {
            if (!this.category.name) {
                this.category.name = this.category.contents[this.selectedLanguage].name;
            }
            if (!this.inputPreviousValue) {
                this.categoriesService.createCategoryFolder(this.category).subscribe((category) => {
                    this.category.id = category.id;
                    this.categoryIdAttr = 'category-' + category.id;
                });
            } else {
                this.categoriesService.updateCategoryFolder(this.category).subscribe((category) => {
                    this.category.name = category.name;
                    if (this.category.isSelected) {
                        this.categoriesTreeService.updateSelectedCategoryData(category.id, category.name);
                    }
                });
            }
        }
    }

    private focusInput(id: string) {
        setTimeout(() => {
            document.getElementById(id).focus();
        }, 100);
    }

    editCategory($event) {
        $event.stopPropagation();
        this.category.isEditionActive = true;
        if (!this.category.contents[this.selectedLanguage]) {
            this.category.contents[this.selectedLanguage] = {};
        }
        if (!this.category.contents[this.selectedLanguage].name) {
            this.category.contents[this.selectedLanguage].name = this.category.name;
        }
        this.inputPreviousValue = this.category.contents[this.selectedLanguage].name;
        this.focusInput('category-' + (this.category.id ? this.category.id : ''));
    }

    createFolder($event) {
        $event.stopPropagation();
        const newItem = {
            active: true,
            isEditionActive: true,
            parentId: this.category.id,
            deletable: true,
            children: [],
            contentsCount: 0,
            knowledgeCenterContentsCount: 0,
            subfoldersCount: 0,
            contents: {}
        };
        newItem.contents[this.selectedLanguage] = {};
        this.category.children.push(newItem);
        this.category.isOpened = true;
        this.focusInput('category-');
    }

    unselectDeletedCategory(isCategorySelected) {
        if (isCategorySelected) {
            this.categoriesTreeService.clearSelectedCategoryPath();
        }
    }

    deleteCategory($event) {
        $event.stopPropagation();
        if (this.category.deletable) {
            this.categoriesService.deleteCategory(this.category.id).subscribe(() => {
                this.unselectDeletedCategory(this.category.isSelected);
                this.categoriesTreeService.removeChildFromCategory(this.category.id, this.category.parentId);
                delete this.category;
            });
        }
    }

    activateCategory($event) {
        $event.stopPropagation();
        this.categoriesService.activateCategory(this.category.id).subscribe((category) => {
            this.unselectDeletedCategory(this.category.isSelected);
            this.category.active = category.active;
            this.categoriesTreeService.updateCategoriesTree();
        });
    }

    archiveCategory(self: any) {
        self.categoriesService.archiveCategory(self.category.id).subscribe((category) => {
            self.unselectDeletedCategory(self.category.isSelected);
            self.category = category;
            self.categoriesTreeService.updateCategoriesTree();
        });
    }

    confirmCategoryArchivation($event) {
        $event.stopPropagation();
        if (window['showArchiveConfirmationModal']) {
            window['showArchiveConfirmationModal'](this.category, this.archiveCategory, this);
        } else {
            this.archiveCategory(this);
        }
    }

    selectCategory($event) {
        $event.stopPropagation();
        if (!this.isEditable && !!this.category.active) {
            this.categoriesTreeService.selectCategory(this.category.id);
        }
    }

    categoryDoubleClick($event) {
        if (!this.isEditable && window['closeCategoriesTreeModal']) {
            this.categoriesTreeService.selectCategory(this.category.id);
            window['closeCategoriesTreeModal']();
        } else if (this.isEditable && this.treeLevel > 1) {
            this.editCategory($event);
        }
    }

    selectLanguage(language: string) {
        this.selectedLanguage = language;
    }

    isCategoryVisible(): boolean {
        if (!this.category || (!this.isEditable && !this.category.active)) {
            return false;
        } else {
            return (
                (this.category.contents[this.selectedLanguage] && this.category.contents[this.selectedLanguage].name) ||
                this.category.name ||
                this.category.isEditionActive
            );
        }
    }

    isActivationLinkVisible() {
        const categoryParent = this.categoriesTreeService.getCategoryParent(this.category.parentId);
        return !this.category.active && categoryParent.active === true;
    }
}
