<div class="modal-header">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="cancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [translate]="'documents.nameConvention.title'"></h3>
</div>
<div class="modal-body scroll7" style="max-height: 75vh; overflow: auto;">
    <document-settings-conventions></document-settings-conventions>
</div>
<div class="modal-footer flex justify-end">
    <button type="button" class="button" (click)="cancel()">
        <span class="button__label" [translate]="'global.modal.back'"></span>
    </button>
</div>
