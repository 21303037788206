<div class="modal-header" style="border-bottom: none">
    <button type="button" class="button button--only-icon modal__buton-close" (click)="onCancel()">
        <span class="button__icon icon icon-close"></span>
    </button>
    <h3 class="modal-title" [textContent]="modalParams.title"></h3>
</div>
<div class="modal-body">
    <div class="boxes-groups-container margin-top-0">
        <div class="group">
            <div class="filter-search-bar justify-content-end">
                <div class="search-grow-container align-items-center" [ngClass]="{'opened': globalSearchOpened}">
                    <input
                        #globalSearch
                        id="global-search"
                        type="text"
                        maxlength="1024"
                        [placeholder]="'segments.searchPlaceholder' | translate"
                        [(ngModel)]="filter.availableSegments"
                        (input)="searchAvailableSegments(false)"
                        ngModelOptions="{ debounce: 200 }"
                        [disabled]="availableSegments.length == 0 && !filter.availableSegments && !isSyncing"
                        (blur)="!filter.availableSegments && globalSearchOpened = !globalSearchOpened"
                        autocomplete="off" />
                    @if (globalSearchOpened && !!filter.availableSegments) {
                        <span class="icon icon-20 icon-remove black60" (click)="searchAvailableSegments(true)"></span>
                    }
                    @if (!globalSearchOpened || (globalSearchOpened && !filter.availableSegments)) {
                        <span
                            id="global-search-icon"
                            data-cy="global-search-icon"
                            class="icon icon-20 icon-search black60"
                            (click)="globalSearchOpen()"
                            [ngClass]="{'disabled-events': globalSearchOpened || availableSegments.length === 0, 'disabled': availableSegments.length === 0}">
                        </span>
                    }
                </div>
            </div>
        </div>
        <div class="group">
            <div class="filter-search-bar justify-content-end">
                <div class="search-grow-container search-grow-container--right align-items-center" [ngClass]="{'opened': groupSearchOpened}">
                    <input
                        #groupSearch
                        id="group-search"
                        type="text"
                        maxlength="1024"
                        [placeholder]="'segments.searchPlaceholder' | translate"
                        [(ngModel)]="filter.addedSegments"
                        ngModelOptions="{ debounce: 200 }"
                        [disabled]="addedSegments.length == 0 && !filter.addedSegments && !isSyncing"
                        (blur)="!filter.addedSegments && groupSearchOpened = !groupSearchOpened"
                        autocomplete="off" />
                    @if (groupSearchOpened && !!filter.addedSegments) {
                        <span class="icon icon-20 icon-remove black60" (click)="clearGroupSearch()"></span>
                    }
                    @if (!groupSearchOpened || (groupSearchOpened && !filter.addedSegments)) {
                        <span
                            id="group-search-icon"
                            data-cy="group-search-icon"
                            class="icon icon-20 icon-search black60"
                            (click)="groupSearchOpen()"
                            [ngClass]="{'disabled-events': groupSearchOpened || addedSegments.length === 0, 'disabled': addedSegments.length === 0}">
                        </span>
                    }
                </div>
            </div>
        </div>
    </div>
    <segments-selection-boxes
        [availableSegments]="availableSegments"
        [addedSegments]="addedSegments"
        [filterAvailable]="filter.availableSegments"
        [filterAdded]="filter.addedSegments"
        [isSyncing]="isSyncing"
        [(addedSegmentsIds)]="addedSegmentsIds"
        (addedSegmentsIdsChange)="onAddedSegmentsIdsChange()">
    </segments-selection-boxes>

    <!-- Select segmentation kind -->
    @if (modalParams.showSegmentationSelection) {
        <section style="margin-top: 46px;">
            <h4 class="black margin-bottom-10" [translate]="'segments.selectiontype'"></h4>
            <div class="recipient-group-container">
                <!-- UNION -->
                <label
                    id="unionBox"
                    data-cy="union-box"
                    class="recipient-group"
                    [ngClass]="{'recipient-group--active' : modalParams.segmentationKind == SEGMENTATION_KIND.UNION}">
                    <input
                        type="radio"
                        ([ngModel])="modalParams.segmentationKind"
                        (click)="modalParams.segmentationKind = SEGMENTATION_KIND.UNION"
                        value="{{SEGMENTATION_KIND.UNION}}" />
                    <div class="flex align-items-center direction-column min-width-100 margin-right-20">
                        <span class="recipient-group__icon icon icon-union"></span>
                        <span class="recipient-group__title" [translate]="'segments.all'"></span>
                    </div>
                    <span class="recipient-group__text" [translate]="'segments.explanationAll'"></span>
                    @if (addedSegments.length > 0) {
                        <div class="flex align-items-center">
                            <span class="recipient-group__arrow icon icon-arrow-right margin-left-20"></span>
                            <div class="flex align-items-center direction-column min-width-100 margin-left-10">
                                <span class="recipient-group__num" [textContent]="unionCount"></span>
                                @if (unionCount !== null) {
                                    <span class="recipient-group__users" [translate]="'segments.users'" [translateParams]="{count: unionCount}"> </span>
                                }
                            </div>
                        </div>
                    }
                    @if (addedSegments.length === 0) {
                        <div class="width-150"></div>
                    }
                </label>
                <!-- INTERSECTION -->
                <label
                    id="intersectionBox"
                    data-cy="intersection-box"
                    class="recipient-group"
                    [ngClass]="{'recipient-group--active' : modalParams.segmentationKind == SEGMENTATION_KIND.INTERSECTION} ">
                    <input
                        type="radio"
                        ([ngModel])="modalParams.segmentationKind"
                        (click)="modalParams.segmentationKind = SEGMENTATION_KIND.INTERSECTION"
                        value="{{SEGMENTATION_KIND.INTERSECTION}}" />
                    <div class="flex align-items-center direction-column min-width-100 margin-right-20">
                        <span class="recipient-group__icon icon icon-intersect"></span>
                        <span class="recipient-group__title" [translate]="'segments.intersection'"></span>
                    </div>
                    <span class="recipient-group__text" [translate]="'segments.explanationIntersection'"></span>
                    @if (addedSegments.length >= 2) {
                        <div class="flex align-items-center">
                            <span class="recipient-group__arrow icon icon-arrow-right margin-left-10"></span>
                            <div class="flex align-items-center direction-column min-width-100 margin-left-10">
                                <span class="recipient-group__num" [textContent]="intersectionCount"></span>
                                @if (intersectionCount !== null) {
                                    <span class="recipient-group__users" [translate]="'segments.users'" [translateParams]="{count: intersectionCount}"> </span>
                                }
                            </div>
                        </div>
                    }
                    @if (addedSegments.length < 2) {
                        <div class="width-150"></div>
                    }
                </label>
            </div>
        </section>
    }
</div>
<div class="modal-footer flex justify-end" style="border-top: none">
    <loading-button
        [buttonId]="'add-segments'"
        [classes]="'button--secondary margin-right-10'"
        [text]="'global.modal.accept'"
        (action)="onAccept()"
        [isValid]="true">
    </loading-button>
    <button type="button" class="button" (click)="onCancel()">
        <span class="button__label" [textContent]="'global.modal.cancel' | translate"></span>
    </button>
</div>
