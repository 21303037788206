import { Component, ViewChild, OnInit } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeTrackingService } from '../time-tracking/time-tracking.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TimeTrackingDownloadModalComponent } from './time-tracking-download-modal.component';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { DuiNotificationsService, NotificationOptions } from '../../shared/services/dui-notifications.service';
import { SharedDataService } from '../../shared/services/shared-data.service';

declare var moment: any;

@Component({
    selector: 'time-tracking-list',
    templateUrl: './time-tracking-list.component.html',
    styleUrls: ['./_time-tracking-list.scss']
})
export class TimeTrackingListComponent implements OnInit {
    public isSyncing: boolean = false;
    public today: any = moment().hour('01').minute('00').second('00').millisecond('000');

    public DATE_FILTER: any = {
        DAILY: 1,
        WEEKLY: 2,
        MONTHLY: 3
    };
    public dateFilterBtns: Array<any> = [
        { id: this.DATE_FILTER.MONTHLY, text: this.translate.instant('global.filters.date.monthly') },
        { id: this.DATE_FILTER.WEEKLY, text: this.translate.instant('global.filters.date.weekly') }
        // {id: this.DATE_FILTER.DAILY, text: this.translate.instant('global.filters.date.daily')}
    ];
    public dateFilterBtnSelected: number = this.DATE_FILTER.MONTHLY;

    public years = [
        { name: new Date().getFullYear().toString(), value: new Date().getFullYear(), selected: true, disabled: false },
        { name: (new Date().getFullYear() - 1).toString(), value: new Date().getFullYear() - 1, selected: false, disabled: false },
        { name: (new Date().getFullYear() - 2).toString(), value: new Date().getFullYear() - 2, selected: false, disabled: false },
        { name: (new Date().getFullYear() - 3).toString(), value: new Date().getFullYear() - 3, selected: false, disabled: false },
        { name: (new Date().getFullYear() - 4).toString(), value: new Date().getFullYear() - 4, selected: false, disabled: false }
    ];
    public months: Array<any> = [];
    public weeksOfYear: Array<any> = [];

    public companiesData: Array<any> = [];
    public headquartersData: Array<any> = [];
    public departmentsData: Array<any> = [];
    public teamsData: Array<any> = [];

    public timeTrackingDatagrid: GridDataResult = {
        data: [],
        total: 0
    };

    public gridPagerSettings = {
        buttonCount: 5,
        pageSizes: [5, 10, 20, 100]
    };
    public dataPagingOpts: any = {
        page: 0,
        size: 10,
        skip: 0,
        sort: []
    };
    public showAdvancedFilter: boolean = false;
    public dataFilterOpts: any = {
        date: this.today,
        year: null,
        month: null,
        week: null,
        dateRange: {
            startDate: null,
            endDate: null
        },
        advancedFilter: {
            subsidiaryCompany: null,
            headquarter: null,
            department: null,
            team: null,
            name: null,
            startDate: '',
            endDate: ''
        }
    };
    public totalPages: number = 0;
    public dataSkeleton = [{}, {}, {}];
    public gridSort: SortDescriptor[];
    public itemNotIndicated: any = { name: this.translate.instant('global.form.notSet'), id: null };

    private clickedRowItem: any = '';
    private timeRecordDetailUrl: string = '/time-tracking/';
    private _rootScope: any;

    @ViewChild('timeTrackingGrid') timeTrackingGrid: GridComponent;

    constructor(
        private location: Location,
        private timeTrackingService: TimeTrackingService,
        private formUtilsService: FormUtilsService,
        private translate: TranslateService,
        private upgrade: UpgradeModule,
        private Dialog: MatDialog,
        private notificationService: DuiNotificationsService,
        private sharedDataService: SharedDataService
    ) {
        this._rootScope = this.upgrade.$injector.get('$rootScope');
    }

    private initWeeksOfYear(year: number): void {
        this.weeksOfYear = [];
        let firstDayOfYear = moment().date(1).month(0).year(year);
        let weeksInYear = firstDayOfYear.isoWeeksInYear();

        for (let idx = 1; idx <= weeksInYear; idx++) {
            this.weeksOfYear.push({
                value: idx,
                year: year,
                range: this.getWeekRangeInfo(year, idx)
            });
        }
    }

    private getWeekRangeInfo(year: number, week: number): any {
        const firstDate = moment().year(year).week(week).weekday(0);
        const lastDate = moment().year(year).week(week).weekday(6);

        let range = {
            text:
                firstDate.format('DD') +
                ' ' +
                this.translate.instant('global.form.monthsAbbr', { month: firstDate.month() + 1 }) +
                ' - ' +
                lastDate.format('DD') +
                ' ' +
                this.translate.instant('global.form.monthsAbbr', { month: lastDate.month() + 1 }),
            startDate: firstDate.format('DD-MM-YYYY'),
            endDate: lastDate.format('DD-MM-YYYY')
        };
        return range;
    }

    private setFilterDates(): void {
        if (this.dateFilterBtnSelected === this.DATE_FILTER.MONTHLY) {
            this.dataFilterOpts.dateRange.startDate = moment()
                .year(this.dataFilterOpts.year.value)
                .month(this.dataFilterOpts.month.value - 1)
                .startOf('month');
            this.dataFilterOpts.dateRange.endDate = moment()
                .year(this.dataFilterOpts.year.value)
                .month(this.dataFilterOpts.month.value - 1)
                .endOf('month');
        } else if (this.dateFilterBtnSelected === this.DATE_FILTER.WEEKLY) {
            this.dataFilterOpts.dateRange.startDate = moment().year(this.dataFilterOpts.year.value).week(this.dataFilterOpts.week.value).weekday(0);
            this.dataFilterOpts.dateRange.endDate = moment().year(this.dataFilterOpts.year.value).week(this.dataFilterOpts.week.value).weekday(6);
        }
        this.dataFilterOpts.advancedFilter.startDate = moment(this.dataFilterOpts.dateRange.startDate).format('YYYY-MM-DD');
        this.dataFilterOpts.advancedFilter.endDate = moment(this.dataFilterOpts.dateRange.endDate).format('YYYY-MM-DD');
    }

    private updateFilterDatesAndRefresh(): void {
        this.setFilterDates();
        this.dataPagingOpts.page = 0;
        this.dataPagingOpts.skip = 0;
        this.getTimeTrackingGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    ngOnInit() {
        this.getAdvancedFilterData();
        if (window['getLocalizedMonthsNames']) {
            this.months = window['getLocalizedMonthsNames']();
        }
        this.initWeeksOfYear(this.today.year());

        this.dataFilterOpts.year = this.years.find((year) => year.value === this.today.year());
        this.dataFilterOpts.month = this.months.find((month) => month.value === this.today.month() + 1);
        this.dataFilterOpts.week = this.weeksOfYear.find((week) => week.value === this.today.week());
        this.setFilterDates();

        if (this._rootScope.previousRoute?.originalPath.startsWith('/time-tracking/') && this.timeTrackingService.timeTrackingGridLastState) {
            this.dataPagingOpts = this.timeTrackingService.timeTrackingGridLastState.dataPagingOpts;
            this.dataFilterOpts = this.timeTrackingService.timeTrackingGridLastState.dataFilterOpts;
            this.dateFilterBtnSelected = this.timeTrackingService.timeTrackingGridLastState.dateFilterBtnSelected;
        }
        this.getTimeTrackingGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    getAdvancedFilterData(): void {
        this.timeTrackingService.getCompaniesData().subscribe(
            (response) => {
                this.companiesData = response.content;
            },
            (error: any) => {
                // Show notification error
            }
        );
        this.timeTrackingService.getHeadquartersData().subscribe(
            (response) => {
                this.headquartersData = response.content;
            },
            (error: any) => {
                // Show notification error
            }
        );
        this.timeTrackingService.getDepartmentsData().subscribe(
            (response) => {
                this.departmentsData = response.content;
            },
            (error: any) => {
                // Show notification error
            }
        );
        this.timeTrackingService.getTeamsData().subscribe(
            (response) => {
                this.teamsData = response.content;
            },
            (error: any) => {
                // Show notification error
            }
        );
    }

    getTimeTrackingGridData(pagingOptions: any, filterOptions: any) {
        this.isSyncing = true;

        this.timeTrackingService.findUsersTimeTracking(pagingOptions, filterOptions.advancedFilter).subscribe(
            (response) => {
                this.timeTrackingDatagrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
                // Show notification error
            }
        );
    }

    onSetDateFilterBtn(kind: any): void {
        this.dateFilterBtnSelected = kind.id;
        this.updateFilterDatesAndRefresh();
    }

    onSetDailyFilterDate(): void {
        this.dataFilterOpts.date = moment(this.dataFilterOpts.date);
    }

    onSetPrevOrNextDate(item: any): void {
        this.dataFilterOpts.date =
            item.id === 'before' ? moment(this.dataFilterOpts.date).subtract(1, 'days') : moment(this.dataFilterOpts.date).add(1, 'days');
        this.dataPagingOpts.page = 0;
        this.dataPagingOpts.skip = 0;
        this.getTimeTrackingGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onSetYearFilter(): void {
        this.initWeeksOfYear(this.dataFilterOpts.year.value);
        if (this.dateFilterBtnSelected === this.DATE_FILTER.MONTHLY) {
            if (this.dataFilterOpts.year.value == this.today.year() && this.dataFilterOpts.month.value > this.today.month() + 1) {
                this.dataFilterOpts.month = this.months.find((month) => month.value === this.today.month() + 1);
            }
        }
        if (this.dateFilterBtnSelected === this.DATE_FILTER.WEEKLY) {
            if (this.dataFilterOpts.year.value == this.today.year() && this.dataFilterOpts.week.value > this.today.week()) {
                this.dataFilterOpts.week = this.weeksOfYear.find((week) => week.value === this.today.week());
            }
        }
        this.updateFilterDatesAndRefresh();
    }

    onSetMonthOrWeekFilter(): void {
        this.updateFilterDatesAndRefresh();
    }

    setAdvancedFilter(): void {
        this.showAdvancedFilter = !this.showAdvancedFilter;
    }

    onSelectAdvancedFilterItem(): void {
        this.dataPagingOpts.page = 0;
        this.dataPagingOpts.skip = 0;
        this.getTimeTrackingGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    clearSearchInput() {
        let updateDataGrid = this.dataFilterOpts.advancedFilter.name.length >= 3;
        this.dataFilterOpts.advancedFilter.name = '';
        if (updateDataGrid) {
            this.listItemsSearch();
        }
    }

    listItemsSearch() {
        if (this.dataFilterOpts.advancedFilter.name.length >= 3 || this.dataFilterOpts.advancedFilter.name.length === 0) {
            this.getTimeTrackingGridData(this.dataPagingOpts, this.dataFilterOpts);
        }
    }

    goDetailView(userId: number): void {
        // Save params of current state view
        this.timeTrackingService.timeTrackingGridLastState = {
            dataPagingOpts: { ...this.dataPagingOpts },
            dataFilterOpts: { ...this.dataFilterOpts },
            dateFilterBtnSelected: this.dateFilterBtnSelected
        };
        const detailStartDate: string = moment(this.dataFilterOpts.dateRange.startDate).format('YYYY-MM-DD');
        const detailEndDate: string =
            this.dataFilterOpts.dateRange.endDate > this.today
                ? moment(this.today).format('YYYY-MM-DD')
                : moment(this.dataFilterOpts.dateRange.endDate).format('YYYY-MM-DD');
        let urlParams: string = `?start=${detailStartDate}&end=${detailEndDate}`;
        this.location.go(this.timeRecordDetailUrl + userId + '/detail', urlParams);
    }

    openDownloadModal(): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            title: this.translate.instant('timeTracking.list.downloadReport'),
            filter: Object.assign(this.dataFilterOpts.advancedFilter)
        };
        dialogSettings.width = '520px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.Dialog.open(TimeTrackingDownloadModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.accept) {
                    this.timeTrackingService.downloadTimeTrackingReport(this.dataFilterOpts.advancedFilter, result.includeDetail).subscribe(
                        (onSuccess) => {
                            const fileName = 'time-tracking-report-' + moment().format('YYYYMMDD') + (result.includeDetail ? '-detailed' : '') + '.csv';
                            this.sharedDataService.saveDownloadedFile(onSuccess.body, fileName);

                            this.formUtilsService.finishSubmitAction();
                            const notificationOptions: NotificationOptions = {
                                kind: 'info',
                                message: this.translate.instant('timeTracking.list.downloadSuccess')
                            };
                            this.notificationService.showNotification(notificationOptions);
                        },
                        (onError) => {}
                    );
                } else {
                    this.formUtilsService.finishSubmitAction();
                }
            });
    }

    // ** Grid methods
    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : [];
        this.getTimeTrackingGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / this.dataPagingOpts.size;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;
        this.getTimeTrackingGridData(this.dataPagingOpts, this.dataFilterOpts);
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
    }

    onDblClick(): void {
        if (this.clickedRowItem.user.id) {
            this.goDetailView(this.clickedRowItem.user.id);
        }
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }
}
