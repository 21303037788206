import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../../constants';

@Pipe({
    name: 'userOriginIcon'
})
export class UserOriginIconPipe implements PipeTransform {
    transform(origin: string): string {
        let icon = '';
        if (parseInt(origin) === Constants.SEGMENTED_USER_ORIGIN.DIALOGUE) {
            icon = 'icon icon-from-dialog-fill';
        } else if (parseInt(origin) === Constants.SEGMENTED_USER_ORIGIN.SYNCHRONIZED) {
            icon = 'icon icon-from-import-fill';
        }
        return icon;
    }
}
