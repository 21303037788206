import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { FileSaverService } from 'ngx-filesaver';
import { Constants } from '../../constants';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { WKAuthService } from '../../shared/services/wk-auth.service';
import { UsersService } from '../../shared/services/users.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';
import { WKUsageConditionsModalComponent } from './wk-usage-conditions-modal.component';

@Component({
    selector: 'users',
    templateUrl: 'users.component.html'
})
export class UsersComponent implements OnInit {
    STATE_FILTER: any;
    USER_STATES: any;

    a3Configuration: A3Configuration;

    filterCompaniesOptions: Filter[];
    filterWorkplacesOptions: Filter[];
    filterDepartmentsOptions: Filter[];
    filterTeamsOptions: Filter[];
    filterRolesOptions: Filter[];
    filterStatesOptions: Filter[];

    inputSearchOpened: boolean;
    isSyncing: boolean;
    selectedState: any;

    usersDataGrid: GridDataResult;
    gridPagerSettings: any;
    dataPagingOpts: any;
    usersFilterOptions: any;
    totalPages: number;

    dataSkeleton = [{}, {}, {}];
    gridSort: SortDescriptor[];

    private clickedRowItem: any;

    @ViewChild('usersGrid') usersGrid: GridComponent;
    @ViewChild('searchUsersInput') searchUsersRef: ElementRef;

    constructor(
        private location: Location,
        private translate: TranslateService,
        private formUtilsService: FormUtilsService,
        private Dialog: MatDialog,
        private dialogService: DialogService,
        private notificationService: DuiNotificationsService,
        private WKAuthService: WKAuthService,
        private usersService: UsersService,
        private fileSaverService: FileSaverService
    ) {
        this.STATE_FILTER = this.usersService.STATE_FILTER;
        this.USER_STATES = Constants.USER_STATES;

        this.a3Configuration = {
            enabled: false,
            termsAndConditionsAccepted: false,
            clientId: ''
        };

        this.inputSearchOpened = false;
        this.isSyncing = false;

        this.usersDataGrid = {
            data: [],
            total: 0
        };

        this.gridPagerSettings = {
            buttonCount: 5,
            pageSizes: [5, 10, 20, 100]
        };

        this.dataPagingOpts = {
            page: 0,
            size: Constants.PAGE_SIZE,
            skip: 0,
            sort: 'joinDate,desc'
        };

        this.usersFilterOptions = {
            name: '',
            state: [Constants.USER_STATES_FILTER.PENDING_ACTIVATION, Constants.USER_STATES_FILTER.REGISTERED, Constants.USER_STATES_FILTER.LOCKED],
            companies: [],
            workplaces: [],
            departments: [],
            teams: [],
            roles: []
        };

        this.totalPages = 0;
    }

    ngOnInit() {
        const savedFilterOptions = sessionStorage.getItem('usersFilterOptions');
        if (savedFilterOptions) {
            this.usersFilterOptions = JSON.parse(savedFilterOptions);

            if (this.usersFilterOptions.name) {
                this.inputSearchOpened = true;
            }

            this.dataPagingOpts.size = this.usersFilterOptions.pageSize;
            delete this.usersFilterOptions.pageSize;

            setTimeout(() => {
                this.selectedState = this.usersFilterOptions.selectedState;
                delete this.usersFilterOptions.selectedState;
            });
        }

        this.WKAuthService.getA3Configuration().subscribe((response: any) => {
            this.a3Configuration = response;
        });

        this.usersService.getCompanies().subscribe((response: any) => {
            this.filterCompaniesOptions = response;
        });

        this.usersService.getWorkplaces().subscribe((response: any) => {
            this.filterWorkplacesOptions = response;
        });

        this.usersService.getDepartments().subscribe((response: any) => {
            this.filterDepartmentsOptions = response;
        });

        this.usersService.getTeams().subscribe((response: any) => {
            this.filterTeamsOptions = response;
        });

        this.getRolesOptions();
        this.getStatesOptions();

        this.getUsersList();
    }

    navigateTo(url: string): void {
        this.location.go(url);
    }

    getExportCSVUrl(): void {
        const options = this.usersFilterOptions;

        this.usersService.downloadUsersCsv(options).subscribe((response: any) => {
            let currentDate = new Date().toISOString().split('.')[0];
            currentDate = currentDate.replaceAll(':', '_');

            const fileName = `users-${currentDate}.csv`;

            this.fileSaverService.save(response.body, fileName);
        });
    }

    syncExternalUsers(): void {
        if (!this.a3Configuration.termsAndConditionsAccepted) {
            this.promptA3TermsAndConditions(this.a3Configuration.clientId);
        } else {
            this.WKAuthService.promptWKLoginForm(this.a3Configuration.clientId);
        }
    }

    private promptA3TermsAndConditions(clientId) {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '8vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.Dialog.open(WKUsageConditionsModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    this.WKAuthService.acceptA3ConfigurationTerms().subscribe(() => {
                        this.WKAuthService.promptWKLoginForm(this.a3Configuration.clientId);
                    });
                }
            });
    }

    onEntityFilterChanged() {
        this.getUsersList();
    }

    private getRolesOptions() {
        const rolesOptions = this.usersService.getUsersRolesOptions();
        this.filterRolesOptions = rolesOptions.map((role) => ({
            id: role.id,
            name: this.translate.instant(role.nameKey)
        }));
    }

    private getStatesOptions() {
        const statesOptions = this.usersService.getUsersStatesOptions();
        this.filterStatesOptions = statesOptions.map((state) => ({
            id: state.value,
            name: this.translate.instant(state.text),
            icon: state.icon
        }));

        this.selectedState = this.filterStatesOptions[0];
    }

    // GRID METHODS

    private getUsersList() {
        this.isSyncing = true;
        this.usersDataGrid.data = [];

        this.usersService.findAll(this.dataPagingOpts, this.usersFilterOptions).subscribe(
            (response) => {
                this.usersDataGrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.isSyncing = false;
                this.formUtilsService.finishSubmitAction();
            },
            (error: any) => {
                this.isSyncing = false;
                console.error(error);
            }
        );
    }

    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : '';
        this.getUsersList();
    }

    onCellClick(event: any) {
        this.clickedRowItem = event.dataItem;
    }

    onDblClick(): void {
        if (this.clickedRowItem.id) {
            this.navigateToEditView(this.clickedRowItem.id);
        }
    }

    navigateToEditView(id: string): void {
        this.usersFilterOptions.selectedState = this.selectedState;
        this.usersFilterOptions.pageSize = this.dataPagingOpts.size;

        sessionStorage.setItem('usersFilterOptions', JSON.stringify(this.usersFilterOptions));
        this.location.go('user/companydata/update/' + id);
    }

    sendActivationEMail(dataItem: any): void {
        const dialogValues: DialogValueOptions = {
            title: 'users.modal.sendActivation.title',
            message: 'users.modal.sendActivation.message',
            messageParam: { username: dataItem.username },
            hideCancelButton: false
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.usersService.sendActivationEmail(dataItem.id).subscribe(
                    () => {
                        const successMessage = this.translate.instant('users.modal.sendActivation.success');
                        this.notificationService.showSuccessNotification(successMessage);
                    },
                    (error) => {
                        const errorMessage = this.translate.instant('users.modal.sendActivation.error');
                        this.notificationService.showErrorNotification(errorMessage);
                        console.error(error);
                    }
                );
            }
        });
    }

    toggleUserActivationState(dataItem: any): void {
        const CONTENT_TYPE_USER = 5;
        const isUserInactive = dataItem.state === this.USER_STATES.DISABLED;

        const dialogValues: DialogValueOptions = {
            title: isUserInactive ? 'global.modal.reactivate.title' : 'publication.modal.archive.title',
            message: isUserInactive ? 'global.modal.reactivate.messageuserAng' : 'users.modal.archive.message',
            messageParam: { contentType: CONTENT_TYPE_USER, user: dataItem.email },
            acceptButtonText: isUserInactive ? 'users.reactivate' : 'global.form.archive'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                const serviceCall = isUserInactive ? this.usersService.activate(dataItem.id) : this.usersService.deactivate(dataItem.id);
                serviceCall.subscribe(
                    () => {
                        const successMessage = this.translate.instant(
                            isUserInactive ? 'publication.modal.archive.recovered' : 'publication.modal.archive.archived',
                            { contentType: CONTENT_TYPE_USER }
                        );
                        this.notificationService.showSuccessNotification(successMessage);
                        this.getUsersList();
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            }
        });
    }

    //** Search input methods **//

    openInputSearch(): void {
        this.inputSearchOpened = !this.inputSearchOpened;
        setTimeout(() => {
            this.searchUsersRef.nativeElement.focus();
        }, 500);
    }

    searchUsers(clearInput: boolean) {
        if (clearInput) {
            this.usersFilterOptions.name = '';
            this.searchUsersRef.nativeElement.focus();
        }

        if (this.usersFilterOptions.name.length >= 3 || this.usersFilterOptions.name.length === 0) {
            this.getUsersList();
        }
    }

    // REVISAR PORQUE FIJOP SE PUEDE SIMPLIFICAR
    onStateFilterChanged(state) {
        this.selectedState = state;
        this.dataPagingOpts.page = 0;
        this.dataPagingOpts.skip = 0;

        const stateMapping = {
            [this.STATE_FILTER.AVAILABLES]: Constants.USER_STATES.ALL,
            [this.STATE_FILTER.ARCHIVED]: Constants.USER_STATES.EXPIRED,
            [this.STATE_FILTER.ACTIVES]: Constants.USER_STATES.REGISTERED,
            [this.STATE_FILTER.ACTIVES_PENDING_ACCESS]: Constants.USER_STATES.REGISTERED,
            [this.STATE_FILTER.PENDING_ACTIVATION]: Constants.USER_STATES.PENDING_ACTIVATION,
            [this.STATE_FILTER.LOCKED]: Constants.USER_STATES.LOCKED
        };

        const selectedButton = stateMapping[state.id];
        this.usersFilterOptions.state = this.getStateFilter(selectedButton);

        this.getUsersList();
    }

    getStateFilter(selectedButton) {
        const filterMapping = {
            [Constants.USER_STATES.PENDING_ACTIVATION]: [Constants.USER_STATES_FILTER.PENDING_ACTIVATION],
            [Constants.USER_STATES.REGISTERED]: [Constants.USER_STATES_FILTER.REGISTERED],
            [Constants.USER_STATES.EXPIRED_CREDENTIALS]: [Constants.USER_STATES_FILTER.EXPIRED_CREDENTIALS],
            [Constants.USER_STATES.LOCKED]: [Constants.USER_STATES_FILTER.LOCKED],
            [Constants.USER_STATES.EXPIRED]: [Constants.USER_STATES_FILTER.EXPIRED, Constants.USER_STATES_FILTER.DISABLED]
        };

        return (
            filterMapping[selectedButton] || [
                Constants.USER_STATES_FILTER.PENDING_ACTIVATION,
                Constants.USER_STATES_FILTER.REGISTERED,
                Constants.USER_STATES_FILTER.LOCKED
            ]
        );
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / event.take;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;

        this.getUsersList();
    }

    userStateIconFilter(state: number, lastAccessDate: Date): string {
        const stateIconMapping = {
            [Constants.USER_STATES.ANONYMOUS]: 'icon-help-fill',
            [Constants.USER_STATES.PENDING_ACTIVATION]: 'icon-state-pending-activation',
            [Constants.USER_STATES.REGISTERED]: lastAccessDate ? '' : 'icon-state-wating-access',
            [Constants.USER_STATES.EXPIRED]: 'icon-state-expired',
            [Constants.USER_STATES.DISABLED]: 'icon-state-archived',
            [Constants.USER_STATES.EXPIRED_CREDENTIALS]: 'icon-state-locked yellow',
            [Constants.USER_STATES.LOCKED]: 'icon-state-locked red'
        };

        return stateIconMapping[state] || '';
    }

    userStateTooltipFilter(state: number, lastAccessDate: Date): string {
        const stateTooltipMapping = {
            [Constants.USER_STATES.ANONYMOUS]: 'global.userstates.anonymous',
            [Constants.USER_STATES.PENDING_ACTIVATION]: 'global.userstates.pendingactivation',
            [Constants.USER_STATES.REGISTERED]: lastAccessDate ? 'global.userstates.registered' : 'global.userstates.pendingAccess',
            [Constants.USER_STATES.EXPIRED]: 'global.userstates.expired',
            [Constants.USER_STATES.DISABLED]: 'global.userstates.disabled',
            [Constants.USER_STATES.EXPIRED_CREDENTIALS]: 'global.userstates.expiredCredentials',
            [Constants.USER_STATES.LOCKED]: 'global.userstates.locked'
        };

        const tooltipKey = stateTooltipMapping[state] || '';
        return this.translate.instant(tooltipKey);
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }
}

export interface A3Configuration {
    enabled?: boolean;
    termsAndConditionsAccepted?: boolean;
    clientId?: string;
}

export interface Filter {
    id: number | string;
    name: string;
    icon?: string;
}
