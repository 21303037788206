import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../../constants';
import { Category } from '../../models/category';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CategoriesService {
    public apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.apiUrl = Constants.apiUrl;
    }

    getKnowledgeCenterCategoriesHierarchy() {
        return this.httpClient.get<Category[]>(`${this.apiUrl}/categories/knowledge-center/hierarchy`);
    }

    createCategoryFolder(categoryFolder) {
        return this.httpClient.post<Category>(`${this.apiUrl}/categories/${categoryFolder.parentId}/folders`, categoryFolder);
    }

    updateCategoryFolder(categoryFolder) {
        return this.httpClient.post<Category>(`${this.apiUrl}/categories/${categoryFolder.parentId}/folders/${categoryFolder.id}`, categoryFolder);
    }

    deleteCategory(categoryId: number) {
        return this.httpClient.delete(`${this.apiUrl}/categories/${categoryId}`);
    }

    deleteCategoryAsPromise(categoryId: number) {
        return this.deleteCategory(categoryId).toPromise();
    }

    archiveCategory(categoryId): Observable<Category> {
        return this.httpClient.put<Category>(`${this.apiUrl}/categories/${categoryId}/archive`, null);
    }

    archiveCategoryAsPromise(categoryId: number) {
        return this.archiveCategory(categoryId).toPromise();
    }

    activateCategory(categoryId): Observable<Category> {
        return this.httpClient.put<Category>(`${this.apiUrl}/categories/${categoryId}/activate`, null);
    }

    activateCategoryAsPromise(categoryId: number) {
        return this.activateCategory(categoryId).toPromise();
    }

    findAllCategories(pagerOptions: any, filterOptions: any) {
        let params = new HttpParams()
            .set('page', pagerOptions.page || '0')
            .set('size', pagerOptions.size || '10')
            .set('active', filterOptions.active);

        if (pagerOptions?.sort) {
            if (Array.isArray(pagerOptions.sort)) {
                pagerOptions.sort.forEach((sortValue: string) => {
                    params = params.append('sort', sortValue);
                });
            } else {
                params = params.set('sort', pagerOptions.sort);
            }
        }

        if (filterOptions?.name) {
            params = params.set('publicationGroupingContents.name', filterOptions.name);
        }

        const httpOptions = {
            params: params,
            reportProgress: true
        };

        return this.httpClient.get<any>(`${this.apiUrl}/categories`, httpOptions);
    }

    exportCategoriesCSV(startDate: string, endDate: string): any {
        if (!startDate || !endDate) {
            throw new Error('Both startDate and endDate are required');
        }

        let httpOptions: any = {
            withCredentials: true,
            observe: 'response',
            responseType: 'blob'
        };

        const params = new HttpParams().set('startDate', startDate).set('endDate', endDate);

        httpOptions.params = params;

        return this.httpClient.get(`${this.apiUrl}/categories/statistics/export-csv`, httpOptions);
    }
}
