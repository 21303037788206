import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../../constants';

@Injectable({
    providedIn: 'root'
})
export class DocumentSettingsService {
    private documentSettingsApiUrl: string;
    private apiUrl: string;

    constructor(private httpClient: HttpClient) {
        this.documentSettingsApiUrl = Constants.apiUrl + '/companies/my-company/configuration/documents';
        this.apiUrl = Constants.apiUrl;
    }

    getDocumentSettings() {
        return this.httpClient.get<any>(`${this.documentSettingsApiUrl}`);
    }

    updateDocumentSettings(data: any) {
        return this.httpClient.put(`${this.documentSettingsApiUrl}`, data);
    }

    getDocumentConventions() {
        return this.httpClient.get<any>(`${this.apiUrl}/documents/conventions`);
    }
}
