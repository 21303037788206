import { Component, Inject, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressBarAnimation } from '@progress/kendo-angular-progressbar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'new-features',
    templateUrl: 'new-features.component.html',
    styleUrls: ['./_new-features.component.scss']
})
export class NewFeaturesComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('whatsNew') private whatsNewView;

    private interval;
    public progressValue: number = 0;
    public progressAnimation: ProgressBarAnimation | boolean = {
        duration: 8800
    };
    public mainTitle: string = '';

    constructor(
        public dialogRef: MatDialogRef<NewFeaturesComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    public ngAfterViewInit(): void {
        this.interval = this.setSliderInterval();
    }

    ngOnInit(): void {
        this.mainTitle = this.data.modalTitle ? this.data.modalTitle[this.data.language] : this.translate.instant('global.whatsNew');
        this.data.features.forEach((feature) => {
            feature.featureTitle = feature.title[this.data.language];
            feature.tag = feature.tag ? feature.tag[this.data.language] : '';
            feature.featureDescription = feature.description[this.data.language];
            feature.featureTextButton = feature.ctaText[this.data.language];
        });
        setTimeout(() => {
            this.progressValue = 100;
        }, 1200);
    }

    onItemChanged(): void {
        clearInterval(this.interval);
        this.interval = this.setSliderInterval();

        this.resetProgress();
        setTimeout(() => {
            this.progressAnimation = { duration: 9600 };
            this.progressValue = 100;
        }, 100);
    }

    onCancel(): void {
        this.dialogRef.close({ closed: true });
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    resetProgress(): void {
        this.progressAnimation = false;
        this.progressValue = 0;
    }

    setSliderInterval(): any {
        return setInterval(() => {
            this.whatsNewView.next();
        }, 10000);
    }
}
