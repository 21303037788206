import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { GridDataResult, GridComponent, PageChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';

import { Constants } from '../../constants';
import { DialogService, DialogValueOptions } from '../../shared/services/dialog.service';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { FormUtilsService } from '../../shared/services/form-utils.service';

import { FeedbackTraysService } from './feedback-trays.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FeedbackTrayCloneModalComponent } from './feedback-tray-clone-modal.component';

@Component({
    selector: 'feedback-trays',
    templateUrl: './feedback-trays.component.html',
    styleUrls: ['_feedback-trays.component.scss']
})
export class FeedbackTraysComponent implements OnInit, OnChanges {
    @Input() cloneTray: boolean;
    @Output() cloneTrayChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() traySelected = new EventEmitter<boolean>();
    @ViewChild('feedbackTraysGrid') feedbackTraysGrid: GridComponent;

    userSession: any;
    defaultLanguage: string;

    stateIsActive: boolean;
    inputSearchField: string;
    isSyncing: boolean;

    feedbackTraysDataGrid: GridDataResult;
    gridPagerSettings: { buttonCount: number; pageSizes: number[] };
    dataPagingOpts: { page: number; size: number; skip: number; sort: string };
    totalPages: number;

    dataSkeleton = [{}, {}, {}];
    gridSort: SortDescriptor[];

    private clickedRowItem: any;

    constructor(
        private dialog: MatDialog,
        private translate: TranslateService,
        private dialogService: DialogService,
        private notificationService: DuiNotificationsService,
        private location: Location,
        private formUtilsService: FormUtilsService,
        private feedbackTraysService: FeedbackTraysService
    ) {
        this.userSession = window['getUserSession']();
        this.defaultLanguage = this.userSession.companyDefaultLanguage;

        this.stateIsActive = true;
        this.inputSearchField = '';
        this.isSyncing = false;

        this.feedbackTraysDataGrid = {
            data: [],
            total: 0
        };

        this.gridPagerSettings = {
            buttonCount: 5,
            pageSizes: [5, 10, 20, 100]
        };

        this.dataPagingOpts = {
            page: 0,
            size: Constants.PAGE_SIZE,
            skip: 0,
            sort: 'lastModifiedDate,desc'
        };

        this.totalPages = 0;
    }

    ngOnInit() {
        this.getFeedbackTrays();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cloneTray && changes.cloneTray.currentValue !== changes.cloneTray.previousValue && !changes.cloneTray.firstChange) {
            if (changes.cloneTray.currentValue) {
                this.cloneTrayModal();
                this.formUtilsService.finishSubmitAction();
                this.cloneTrayChange.emit(false);
            }
        }
    }

    private getFeedbackTrays() {
        this.isSyncing = true;
        this.traySelected.emit(false);

        const filters = {
            page: this.dataPagingOpts.page,
            size: this.dataPagingOpts.size,
            sort: this.dataPagingOpts.sort,
            archived: !this.stateIsActive,
            alias: this.inputSearchField
        };

        this.feedbackTraysService.findAll(filters).subscribe(
            (response) => {
                this.feedbackTraysDataGrid = {
                    data: response.content,
                    total: response.totalElements
                };
                this.totalPages = response.totalPages;
                this.isSyncing = false;
            },
            (error: any) => {
                this.isSyncing = false;
            }
        );
    }

    // MODAL

    cloneTrayModal(): void {
        const dialogSettings = new MatDialogConfig();
        dialogSettings.data = {
            selectedTray: this.clickedRowItem
        };
        dialogSettings.width = '600px';
        dialogSettings.position = { top: '6vh' };
        dialogSettings.panelClass = ['animated', 'slideInDown'];

        this.dialog
            .open(FeedbackTrayCloneModalComponent, dialogSettings)
            .afterClosed()
            .subscribe((result) => {
                if (result?.success) {
                    var message = this.translate.instant('feedbackTray.notification.clone.message');
                    this.notificationService.showSuccessNotification(message);
                    this.getFeedbackTrays();
                }
                this.formUtilsService.finishSubmitAction();
            });
    }

    showDeleteConfirmation(dataItem): void {
        const feedbackTray = dataItem.alias ? dataItem.alias : dataItem.name;

        const dialogValues: DialogValueOptions = {
            title: 'feedbackTray.modal.delete.title',
            message: 'feedbackTray.modal.delete.message',
            messageParam: { feedbackTray: feedbackTray },
            acceptButtonText: 'global.form.delete'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.feedbackTraysService.deleteTray(dataItem.id).subscribe(
                    () => {
                        const message = this.translate.instant('feedbackTray.notification.delete.messageNg', { feedbackTray: feedbackTray });
                        this.notificationService.showSuccessNotification(message);
                        this.getFeedbackTrays();
                    },
                    (error) => {
                        this.notificationService.showErrorNotification();
                        console.error(error);
                    }
                );
            }
        });
    }

    showArchiveConfirmation(dataItem): void {
        const feedbackTray = dataItem.alias ? dataItem.alias : dataItem.name;

        const dialogValues: DialogValueOptions = {
            title: 'feedbackTray.modal.archive.title',
            message: 'feedbackTray.modal.archive.message',
            messageParam: { feedbackTray: feedbackTray },
            acceptButtonText: 'global.form.archive'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.feedbackTraysService.archiveTray(dataItem.id).subscribe(
                    () => {
                        const message = this.translate.instant('feedbackTray.notification.archive.messageNg', { feedbackTray: feedbackTray });
                        this.notificationService.showSuccessNotification(message);
                        this.getFeedbackTrays();
                    },
                    (error) => {
                        this.notificationService.showErrorNotification();
                        console.error(error);
                    }
                );
            }
        });
    }

    showActivationConfirmation(dataItem): void {
        const feedbackTray = dataItem.alias ? dataItem.alias : dataItem.name;

        const dialogValues: DialogValueOptions = {
            title: 'feedbackTray.modal.activate.title',
            message: 'feedbackTray.modal.activate.message',
            messageParam: { feedbackTray: feedbackTray },
            acceptButtonText: 'global.form.activate'
        };

        this.dialogService.openConfirmationDialog(dialogValues).subscribe((result) => {
            if (result?.accept) {
                this.feedbackTraysService.activateTray(dataItem.id).subscribe(
                    () => {
                        const message = this.translate.instant('feedbackTray.notification.activate.messageNg', { feedbackTray: feedbackTray });
                        this.notificationService.showSuccessNotification(message);
                        this.getFeedbackTrays();
                    },
                    (error) => {
                        this.notificationService.showErrorNotification();
                        console.error(error);
                    }
                );
            }
        });
    }

    getFeedbackTraysByIsActive(isActive: boolean): void {
        if (this.stateIsActive === isActive) {
            return;
        }

        this.stateIsActive = isActive;
        this.resetPagingOptions();
        this.getFeedbackTrays();
    }

    updateSearch(clearInput: boolean = false): void {
        if (!clearInput && this.inputSearchField.length < 3) {
            return;
        }

        if (clearInput) {
            this.inputSearchField = '';
        }

        this.resetPagingOptions();
        this.getFeedbackTrays();
    }

    editFeedbackTray(event: Event, id: number): void {
        event.stopPropagation();
        this.navigateToEditView(id);
    }

    deleteFeedbackTray(event: Event, dataItem: any): void {
        event.stopPropagation();
        this.showDeleteConfirmation(dataItem);
    }

    archiveFeedbackTray(event: Event, dataItem: any): void {
        event.stopPropagation();
        this.showArchiveConfirmation(dataItem);
    }

    activateFeedbackTray(event: Event, dataItem: any): void {
        event.stopPropagation();
        this.showActivationConfirmation(dataItem);
    }

    // GRID

    onSortChange(sort: SortDescriptor[]): void {
        this.gridSort = sort;
        this.dataPagingOpts.sort = sort.length > 0 && sort[0].dir !== undefined ? sort[0].field + ',' + sort[0].dir : '';
        this.getFeedbackTrays();
    }

    onCellClick(event: any) {
        if (this.clickedRowItem === event.dataItem) {
            this.feedbackTraysGrid.rowSelected = () => false;
            this.clickedRowItem = null;
            this.traySelected.emit(false);
        } else {
            this.clickedRowItem = event.dataItem;
            this.feedbackTraysGrid.rowSelected = (row) => row.dataItem === event.dataItem;
            this.traySelected.emit(true);
        }
    }

    onDblClick(event: MouseEvent): void {
        const gridListElement = this.feedbackTraysGrid.wrapper.nativeElement.querySelector('kendo-grid-list');

        if (gridListElement.contains(event.target as Node) && this.clickedRowItem) {
            this.navigateToEditView(this.clickedRowItem.id);
        }
    }

    onPageChange(event: PageChangeEvent): void {
        this.dataPagingOpts.page = event.skip / event.take;
        this.dataPagingOpts.skip = event.skip;
        this.dataPagingOpts.size = event.take;

        this.getFeedbackTrays();
    }

    skeletonRowCallback(row: RowClassArgs) {
        return {
            'opacity-50': row.index === 1,
            'opacity-30': row.index === 2
        };
    }

    // UTILS

    isDefaultLanguageHidden(availableLanguages: string[]): boolean {
        return !availableLanguages.includes(this.defaultLanguage);
    }

    navigateToEditView(id: number): void {
        this.location.go('/settings/conversations/feedback-trays/update/' + id);
    }

    private resetPagingOptions(): void {
        this.dataPagingOpts.page = 0;
        this.dataPagingOpts.skip = 0;
    }
}
