import { Component, Input, OnInit } from '@angular/core';
import { DuiNotificationsService } from '../../shared/services/dui-notifications.service';
import { TimeTrackingService } from '../time-tracking/time-tracking.service';
import { UtilsService } from '../../shared/services/utils.service';
import { Constants } from '../../constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'schedule-record-kinds',
    templateUrl: './schedule-settings.component.html',
    styleUrls: ['./_schedule-settings.scss']
})
export class ScheduleSettingsComponent implements OnInit {
    @Input() scheduleId: number;

    public timeTrackingKinds: any[];
    public scheduleSettingsForm: any = {
        timeTrackingKindRequired: false,
        enabledTimeTrackingKinds: []
    };
    public startShiftLimitations: { value: number; name: string }[];
    public minutesMarginSlider: number;
    public hoursToEndSlider: number;

    public decimalToHex: (color: any) => string;
    public ENABLED_KINDS_DEFAULT: Array<number> = [1];
    public START_SHIFT_LIMITATION: any = Constants.START_SHIFT_LIMITATION;
    public TIME_TRACKING_MODE: any = Constants.TIME_TRACKING_MODE;

    private HOURS_TO_END_DEFAULT: number = 2;

    constructor(
        private timeTrackingService: TimeTrackingService,
        private translate: TranslateService,
        private utilsService: UtilsService,
        private notificationService: DuiNotificationsService
    ) {
        this.decimalToHex = this.utilsService.decimalToHex;

        this.startShiftLimitations = [
            {
                value: this.START_SHIFT_LIMITATION.NO_LIMIT,
                name: this.translate.instant('timeTracking.global.startShiftLimitation.noLimit')
            },
            {
                value: this.START_SHIFT_LIMITATION.WARNING,
                name: this.translate.instant('timeTracking.global.startShiftLimitation.warning')
            },
            {
                value: this.START_SHIFT_LIMITATION.LIMITED,
                name: this.translate.instant('timeTracking.global.startShiftLimitation.limited')
            }
        ];
    }

    private getScheduleSettings(): void {
        this.timeTrackingService.getTimeTrackingScheduleSettings(this.scheduleId).subscribe(
            (response): void => {
                this.scheduleSettingsForm = response;
                this.hoursToEndSlider = response.hoursToEnd;
                this.minutesMarginSlider = response.minutesMarginToStart;
            },
            (error: any): void => {
                console.error(error);
            }
        );
    }

    private getTimeTrackingRecordKinds(): void {
        this.timeTrackingService.getAvailableTimeTrackingKinds().subscribe(
            (response): void => {
                this.timeTrackingKinds = response;
                this.checkIfTrackingKindIsActive();
            },
            (error: any): void => {
                console.error(error);
            }
        );
    }

    private checkIfTrackingKindIsActive() {
        this.timeTrackingKinds = this.timeTrackingKinds.map((kind) => ({
            ...kind,
            active: this.scheduleSettingsForm.enabledTimeTrackingKinds.includes(kind.id)
        }));
    }

    private filterByActiveProperty(array: any[]): number[] {
        return array.filter((item) => item.active === true).map((item) => item.id);
    }

    private setDayRecordKinds(): void {
        this.timeTrackingService.setScheduleTimeTrackingKinds(this.scheduleId, this.scheduleSettingsForm).subscribe(
            (response): void => {
                this.notificationService.showSuccessNotification();
            },
            (error): void => {
                this.notificationService.showErrorNotification();
            }
        );
    }

    private setStartShiftLimitation(): void {
        this.timeTrackingService.setStartShiftLimitation(this.scheduleId, this.scheduleSettingsForm).subscribe(
            (response): void => {
                this.notificationService.showSuccessNotification();
            },
            (error) => {
                this.notificationService.showErrorNotification();
            }
        );
    }

    private setDayRecordMode(): void {
        this.timeTrackingService.setDayRecordMode(this.scheduleId, this.scheduleSettingsForm).subscribe(
            (response): void => {
                this.notificationService.showSuccessNotification();
            },
            (error): void => {
                this.notificationService.showErrorNotification();
            }
        );
    }

    ngOnInit() {
        this.getScheduleSettings();
        this.getTimeTrackingRecordKinds();
    }

    toggleKindRequired(): void {
        this.scheduleSettingsForm.enabledTimeTrackingKinds = this.scheduleSettingsForm.timeTrackingKindRequired ? this.ENABLED_KINDS_DEFAULT : [];

        this.checkIfTrackingKindIsActive();
        this.setDayRecordKinds();
    }

    onChangeTrackingMode(): void {
        if (
            this.scheduleSettingsForm.timeTrackingMode === this.TIME_TRACKING_MODE.CLOCK_OR_MANUAL ||
            this.scheduleSettingsForm.timeTrackingMode === this.TIME_TRACKING_MODE.CLOCK
        ) {
            this.scheduleSettingsForm.hoursToEnd = this.HOURS_TO_END_DEFAULT;
            this.hoursToEndSlider = this.HOURS_TO_END_DEFAULT;
        }
        this.setDayRecordMode();
    }

    onChangeHoursToEnd(slider: any): void {
        if (slider.value !== this.scheduleSettingsForm.hoursToEnd) {
            this.scheduleSettingsForm.hoursToEnd = slider.value;
            this.setDayRecordMode();
        }
    }

    onChangeRecordKindStatus(): void {
        this.scheduleSettingsForm.enabledTimeTrackingKinds = this.filterByActiveProperty(this.timeTrackingKinds);
        if (this.scheduleSettingsForm.enabledTimeTrackingKinds.length > 0) {
            this.setDayRecordKinds();
        }
    }

    onChangeStartShiftLimitation(): void {
        if (this.scheduleSettingsForm.startShiftLimitation !== this.START_SHIFT_LIMITATION.NO_LIMIT) {
            this.scheduleSettingsForm.minutesMarginToStart = this.scheduleSettingsForm.minutesMarginToStart || 15;
            this.minutesMarginSlider = this.scheduleSettingsForm.minutesMarginToStart;
        } else {
            this.scheduleSettingsForm.minutesMarginToStart = null;
        }
        this.setStartShiftLimitation();
    }

    onChangeMarginToStart(sliderItem: any): void {
        if (sliderItem.value !== this.scheduleSettingsForm.minutesMarginToStart) {
            this.scheduleSettingsForm.minutesMarginToStart = sliderItem.value;
            this.setStartShiftLimitation();
        }
    }
}
