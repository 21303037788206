<div id="conversation-preview-container" class="mobile-preview-container" [ngClass]="{'mobile-preview-container--visible': previewState.opened}">
    <button type="button" class="mobile-preview-container__close icon icon-close" (click)="closePreview()"></button>
    <div class="text-center">
        <div class="tab-nav tab-nav--inline margin-top-20">
            <span class="tab-nav__item tab-nav__item--selected" [translate]="'message.preview.preview'"></span>
        </div>
    </div>

    <div class="mobile-container">
        <div class="device-container">
            <div class="device-details-container">
                <div class="preview-header">
                    <div class="preview-header__first-row">
                        <span class="preview-header__icon icon icon-wifi"></span>
                        <div class="flex">
                            <span class="preview-header__label margin-right-5">74%</span>
                            <span class="preview-header__icon icon icon-battery"></span>
                        </div>
                    </div>
                </div>
                <div class="header-device">
                    <span class="icon icon-back"></span>
                    <span class="title" [translate]="'conversations.conversation'"></span>
                </div>
                <div class="scroll-device scroll-device--conversation">
                    <div class="conversation-preview">
                        <div class="flex justify-center padding-24">
                            <img src="/ng1/assets/img/dialenguin-happy-color.svg" class="conversation-preview__image" />
                        </div>
                        <div class="flex direction-column align-items-start">
                            <div class="conversation-preview__message">
                                <span class="conversation-preview__message__text" [translate]="'conversations.preview.goodMorning'"></span>
                            </div>
                            <div class="conversation-preview__message">
                                <span class="conversation-preview__message__text" [translate]="'conversations.preview.chooseMotive'"></span>
                            </div>
                            @for (motive of motives; track motive) {
                                <div class="conversation-preview__button">
                                    <span class="conversation-preview__button__text" [textContent]="motive.motiveInApp"></span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
